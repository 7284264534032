import * as z from "zod";

export const ObjectFitType = z.enum(["Contain", "Cover"]);

export const ImageStyleSchema = z.object({
  objectFit: ObjectFitType,
  opacity: z.number().min(0).max(100),
});

export const ImageSourceSchema = z.object({
  src: z.string(),
  alt: z.string().optional(),
});

export const ImageSchema = z.object({
  source: ImageSourceSchema,
  style: ImageStyleSchema,
}); 