import { useFormContext } from "react-hook-form";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { DocumentFormReturn } from "@/lib/document-form-types";
import { cn } from "@/lib/utils";
import { GripVertical } from "lucide-react";
import { useEffect, useState } from "react";

type DragItem = {
  index: number;
  id: string;
  type: string;
};

const ItemTypes = {
  SLIDE: "slide",
};

function DraggableSlide({ index, moveSlide }: { index: number; moveSlide: (dragIndex: number, hoverIndex: number) => void }) {
  const form: DocumentFormReturn = useFormContext();
  const slides = form.watch("slides");
  const slide = slides[index];

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.SLIDE,
    item: { type: ItemTypes.SLIDE, id: `slide-${index}`, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.SLIDE,
    hover(item: DragItem) {
      if (item.index === index) {
        return;
      }
      moveSlide(item.index, index);
      item.index = index;
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      className={cn(
        "flex items-center gap-2 p-3 rounded-lg border bg-card",
        isDragging && "opacity-50",
        "cursor-move touch-none"
      )}
    >
      <GripVertical className="h-4 w-4 text-muted-foreground" />
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium truncate">
          Slide {index + 1}
        </p>
        <p className="text-xs text-muted-foreground truncate">
          {slide.elements.length} elements
        </p>
      </div>
    </div>
  );
}

export function SlideOrderPanel() {
  const form: DocumentFormReturn = useFormContext();
  const slides = form.watch("slides");
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    setIsTouchDevice('ontouchstart' in window);
  }, []);

  const moveSlide = (dragIndex: number, hoverIndex: number) => {
    const newSlides = [...slides];
    const draggedSlide = newSlides[dragIndex];
    newSlides.splice(dragIndex, 1);
    newSlides.splice(hoverIndex, 0, draggedSlide);
    form.setValue("slides", newSlides);
  };

  return (
    <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend}>
      <div className="space-y-4">
        <p className="text-sm text-muted-foreground">
          Drag and drop slides to reorder them
        </p>
        <div className="space-y-2">
          {slides.map((_, index) => (
            <DraggableSlide
              key={index}
              index={index}
              moveSlide={moveSlide}
            />
          ))}
        </div>
      </div>
    </DndProvider>
  );
} 