import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { Card } from '@/components/ui/card';
import { CalendarIcon, Trash2, Edit, Plus, Clock, ChevronRight, PenTool, FileText, Calendar, Layout } from 'lucide-react';
import { format, isAfter, startOfDay, addDays, startOfWeek } from 'date-fns';
import { supabase } from '@/integrations/supabase/client';
import type { ContentPlanPost as ImportedContentPlanPost } from '@/services/content-planner';
import { ContentCalendar } from './ContentCalendar';
import { useNavigate } from 'react-router-dom';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";

interface DashboardContentPost extends ImportedContentPlanPost {
  category: string;
  description?: string;
  suggestedDate?: string;
  scheduledTime?: string;
  theme?: string;
}

interface GroupedContentPlan {
  type: string;
  posts: DashboardContentPost[];
}

interface SavedPlan {
  id: string;
  created_at: string;
  plan: GroupedContentPlan[];
  start_date: string;
  name: string;
  weekly_distribution?: { weekStart: string; postCount: number }[];
  themes?: string[];
  posts_per_week?: number;
}

interface SavedPost {
  id: string;
  title: string;
  type: string;
  metadata: {
    content_plan: {
      scheduled_date: string;
      scheduled_time: string;
    };
  };
}

interface ExtendedContentPlanPost extends DashboardContentPost {
  suggestedDate: string;
  bestTimeToPost: string;
  description: string;
}

// Helper function to convert ContentPlanPost to ExtendedContentPlanPost
const extendContentPlanPosts = (posts: DashboardContentPost[]): ExtendedContentPlanPost[] => {
  return posts.map(post => {
    const suggestedDate = post.suggestedDate || format(new Date(), 'yyyy-MM-dd');
    const bestTimeToPost = post.scheduledTime || '12:00';
    return {
      ...post,
      suggestedDate,
      bestTimeToPost,
      description: post.description || '',
    };
  });
};

// Helper function to ensure valid time format
const formatTime = (time: string | undefined): string => {
  if (!time) return '12:00';
  // If time is in 12-hour format with AM/PM, convert to 24-hour
  if (time.includes('AM') || time.includes('PM')) {
    const [timeStr, period] = time.split(' ');
    const [hours, minutes] = timeStr.split(':').map(Number);
    if (period === 'PM' && hours !== 12) {
      return `${hours + 12}:${minutes.toString().padStart(2, '0')}`;
    } else if (period === 'AM' && hours === 12) {
      return `00:${minutes.toString().padStart(2, '0')}`;
    }
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
  // If time is already in 24-hour format, ensure proper formatting
  const [hours, minutes] = time.split(':').map(Number);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

// Helper function to group posts by week
const groupPostsByWeek = (groups: GroupedContentPlan[]) => {
  if (!groups || groups.length === 0) return {};
  
  return groups.reduce((acc, group) => {
    group.posts.forEach(post => {
      if (!post.suggestedDate) return;
      const weekStart = format(startOfWeek(new Date(post.suggestedDate), { weekStartsOn: 1 }), 'yyyy-MM-dd');
      if (!acc[weekStart]) {
        acc[weekStart] = [];
      }
      acc[weekStart].push(post);
    });
    return acc;
  }, {} as Record<string, DashboardContentPost[]>);
};

// Helper function to calculate posts per week
const calculatePostsPerWeek = (plan: SavedPlan) => {
  if (!plan.plan || plan.plan.length === 0) return 0;
  if (plan.posts_per_week) return plan.posts_per_week;

  const totalPosts = plan.plan.reduce((acc, group) => acc + (group.posts?.length || 0), 0);
  const firstPost = plan.plan[0]?.posts?.[0];
  const lastPost = plan.plan[plan.plan.length - 1]?.posts?.slice(-1)[0];
  
  if (!firstPost?.suggestedDate || !lastPost?.suggestedDate) return 0;
  
  const startDate = new Date(firstPost.suggestedDate);
  const endDate = new Date(lastPost.suggestedDate);
  const weeks = Math.ceil((endDate.getTime() - startDate.getTime()) / (7 * 24 * 60 * 60 * 1000));
  
  return Math.round(totalPosts / (weeks || 1));
};

// Helper function to count total content ideas - Fixed to correctly count posts
const countContentIdeas = (plan: SavedPlan) => {
  if (!plan.plan) return 0;
  return plan.plan.reduce((acc, group) => acc + (Array.isArray(group.posts) ? group.posts.length : 0), 0);
};

export const Dashboard = () => {
  const [savedPlans, setSavedPlans] = useState<SavedPlan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<SavedPlan | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [upcomingPosts, setUpcomingPosts] = useState<SavedPost[]>([]);
  const { toast } = useToast();
  const navigate = useNavigate();
  const [contentPlan, setContentPlan] = useState<DashboardContentPost[] | null>(null);

  useEffect(() => {
    loadSavedPlans();
    loadContentPlan();
    loadUpcomingPosts();
  }, []);

  const loadSavedPlans = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        toast({
          title: "Authentication required",
          description: "Please sign in to view your saved plans.",
          variant: "destructive",
        });
        return;
      }

      const { data, error } = await supabase
        .from('content_plans')
        .select('*')
        .eq('user_id', session.user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      console.log('Loaded plans from Supabase:', data);
      
      // Validate and clean the data
      const validatedPlans = data?.map(plan => ({
        ...plan,
        plan: Array.isArray(plan.plan) ? plan.plan.map(group => ({
          type: group.type || 'text',
          posts: (group.posts || []).map(post => ({
            ...post,
            postType: post.postType || group.type || 'text',
            topic: post.topic || '',
            category: post.category || group.type || 'text',
            keyPoints: Array.isArray(post.keyPoints) ? post.keyPoints : [],
            description: post.description || '',
            suggestedDate: post.suggestedDate || format(new Date(), 'yyyy-MM-dd'),
            scheduledTime: post.scheduledTime || '12:00'
          }))
        })) : []
      })) || [];

      console.log('Validated plans:', validatedPlans);
      setSavedPlans(validatedPlans);
      setSelectedPlan(null);
    } catch (error) {
      console.error('Error loading saved plans:', error);
      toast({
        title: "Error loading plans",
        description: "Unable to load your saved content plans.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadContentPlan = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) return;

      const { data: contentPlanData, error } = await supabase
        .from('content_plans')
        .select('plan')
        .eq('user_id', session.user.id)
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (error && error.code !== 'PGRST116') {
        throw error;
      }
      setContentPlan(contentPlanData?.plan || []);
    } catch (error) {
      console.error('Error loading content plan:', error);
      toast({
        title: "Error loading content plan",
        description: "Unable to load your content plan. Please try again.",
        variant: "destructive",
      });
    }
  };

  const loadUpcomingPosts = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) return;

      const { data, error } = await supabase
        .from('posts')
        .select('id, title, type, metadata')
        .eq('user_id', session.user.id);

      if (error) throw error;

      const today = startOfDay(new Date());
      const nextTwoWeeks = addDays(today, 14);

      const upcoming = (data || [])
        .filter(post => {
          if (!post.metadata?.content_plan?.scheduled_date) return false;
          const postDate = new Date(post.metadata.content_plan.scheduled_date);
          return postDate >= today && postDate <= nextTwoWeeks;
        })
        .sort((a, b) => {
          const dateA = new Date(a.metadata.content_plan.scheduled_date);
          const dateB = new Date(b.metadata.content_plan.scheduled_date);
          return dateA.getTime() - dateB.getTime();
        });

      setUpcomingPosts(upcoming);
    } catch (error) {
      console.error('Error loading upcoming posts:', error);
    }
  };

  const deletePlan = async (planId: string) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        toast({
          title: "Authentication required",
          description: "Please sign in to delete content plans.",
          variant: "destructive",
        });
        return;
      }

      // Delete from database
      const { error } = await supabase
        .from('content_plans')
        .delete()
        .match({ id: planId, user_id: session.user.id });

      if (error) {
        console.error('Supabase deletion error:', error);
        throw error;
      }

      // Update local state
      setSavedPlans(prev => prev.filter(plan => plan.id !== planId));
      if (selectedPlan?.id === planId) {
        setSelectedPlan(null);
      }

      toast({
        title: "Plan deleted",
        description: "The content plan has been deleted successfully.",
      });

    } catch (error) {
      console.error('Error deleting plan:', error);
      toast({
        title: "Error deleting plan",
        description: "Unable to delete the content plan. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleUpdatePost = async (updatedPost: DashboardContentPost, index: number) => {
    if (!contentPlan) return;
    
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        throw new Error('No authenticated user found');
      }

      // Update the post in the local state
      const updatedPlan = [...contentPlan];
      updatedPlan[index] = updatedPost;
      setContentPlan(updatedPlan);

      // Save to database
      const { error } = await supabase
        .from('content_plans')
        .update({ plan: updatedPlan })
        .eq('user_id', session.user.id);

      if (error) throw error;

      toast({
        title: "Post updated",
        description: "Your content plan has been updated successfully.",
      });
    } catch (error) {
      console.error('Error updating post:', error);
      toast({
        title: "Error updating post",
        description: "Unable to save your changes. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8 sm:px-6 md:px-12 lg:px-24">
      <div className="space-y-8">
        {/* Header */}
        <div className="text-center space-y-4">
          <h1 className="text-4xl font-bold text-gray-900">Content Dashboard</h1>
          <p className="text-lg text-gray-600">
            Manage your content plans and track your upcoming posts
          </p>
        </div>

        {/* Tool Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Profile Optimizer Card */}
          <Card 
            className="p-6 bg-white cursor-pointer hover:shadow-md transition-all"
            onClick={() => navigate('/optimizer')}
          >
            <div className="flex items-start gap-4">
              <div className="p-3 bg-primary/10 rounded-lg">
                <FileText className="h-6 w-6 text-primary" />
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold mb-2">Profile Optimizer</h3>
                <p className="text-gray-600 text-sm mb-4">
                  Enhance your LinkedIn profile with AI-powered suggestions tailored for healthcare professionals.
                </p>
                <Button variant="ghost" size="sm" className="text-primary">
                  Get Started <ChevronRight className="h-4 w-4 ml-2" />
                </Button>
              </div>
            </div>
          </Card>

          {/* Content Planner Card */}
          <Card 
            className="p-6 bg-white cursor-pointer hover:shadow-md transition-all"
            onClick={() => navigate('/planner')}
          >
            <div className="flex items-start gap-4">
              <div className="p-3 bg-primary/10 rounded-lg">
                <Calendar className="h-6 w-6 text-primary" />
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold mb-2">Content Planner</h3>
                <p className="text-gray-600 text-sm mb-4">
                  Create a strategic content calendar with themes and post ideas for consistent engagement.
                </p>
                <Button variant="ghost" size="sm" className="text-primary">
                  Plan Content <ChevronRight className="h-4 w-4 ml-2" />
                </Button>
              </div>
            </div>
          </Card>

          {/* Post Creator Card */}
          <Card 
            className="p-6 bg-white cursor-pointer hover:shadow-md transition-all"
            onClick={() => navigate('/posts/new')}
          >
            <div className="flex items-start gap-4">
              <div className="p-3 bg-primary/10 rounded-lg">
                <PenTool className="h-6 w-6 text-primary" />
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold mb-2">Post Creator</h3>
                <p className="text-gray-600 text-sm mb-4">
                  Generate engaging LinkedIn posts with AI assistance, tailored for medical professionals.
                </p>
                <Button variant="ghost" size="sm" className="text-primary">
                  Create Post <ChevronRight className="h-4 w-4 ml-2" />
                </Button>
              </div>
            </div>
          </Card>

          {/* Carousel Creator Card */}
          <Card 
            className="p-6 bg-white cursor-pointer hover:shadow-md transition-all"
            onClick={() => navigate('/carousel')}
          >
            <div className="flex items-start gap-4">
              <div className="p-3 bg-primary/10 rounded-lg">
                <Layout className="h-6 w-6 text-primary" />
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold mb-2">Carousel Creator</h3>
                <p className="text-gray-600 text-sm mb-4">
                  Create visually appealing carousel posts to showcase medical insights and research.
                </p>
                <Button variant="ghost" size="sm" className="text-primary">
                  Create Carousel <ChevronRight className="h-4 w-4 ml-2" />
                </Button>
              </div>
            </div>
          </Card>
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Card className="p-4 bg-white">
            <div className="flex items-center gap-4">
              <div className="p-3 bg-primary/10 rounded-lg">
                <CalendarIcon className="h-6 w-6 text-primary" />
              </div>
              <div>
                <p className="text-sm text-gray-600">Content Plans</p>
                <p className="text-2xl font-semibold">{savedPlans.length}</p>
              </div>
            </div>
          </Card>

          <Sheet>
            <SheetTrigger asChild>
              <Card className="p-4 bg-white cursor-pointer hover:bg-gray-50 transition-colors">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-primary/10 rounded-lg">
                    <Clock className="h-6 w-6 text-primary" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-600">Upcoming Posts</p>
                    <p className="text-2xl font-semibold">{upcomingPosts.length}</p>
                  </div>
                </div>
              </Card>
            </SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Upcoming Posts (Next 2 Weeks)</SheetTitle>
              </SheetHeader>
              <div className="mt-6 space-y-4">
                {upcomingPosts.length === 0 ? (
                  <p className="text-muted-foreground">No upcoming posts scheduled.</p>
                ) : (
                  upcomingPosts.map(post => {
                    const postTypeColors = {
                      text: { bg: 'bg-blue-100', text: 'text-blue-800', hover: 'hover:bg-blue-200' },
                      image: { bg: 'bg-green-100', text: 'text-green-800', hover: 'hover:bg-green-200' },
                      article: { bg: 'bg-purple-100', text: 'text-purple-800', hover: 'hover:bg-purple-200' },
                      poll: { bg: 'bg-orange-100', text: 'text-orange-800', hover: 'hover:bg-orange-200' }
                    };

                    return (
                      <Button
                        key={post.id}
                        variant="outline"
                        className={`w-full justify-start gap-4 ${postTypeColors[post.type]?.bg || 'bg-gray-100'} ${postTypeColors[post.type]?.text || 'text-gray-800'} ${postTypeColors[post.type]?.hover || 'hover:bg-gray-200'} border-0`}
                        onClick={() => navigate(`/posts/${post.id}`)}
                      >
                        <div className="flex flex-col items-start">
                          <span className="font-medium">{post.title}</span>
                          <span className="text-sm opacity-80">
                            {format(new Date(post.metadata.content_plan.scheduled_date), 'EEEE, MMMM d')} at {post.metadata.content_plan.scheduled_time}
                          </span>
                        </div>
                      </Button>
                    );
                  })
                )}
              </div>
            </SheetContent>
          </Sheet>

          <Sheet>
            <SheetTrigger asChild>
              <Card className="p-4 bg-white cursor-pointer hover:bg-gray-50 transition-colors">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-primary/10 rounded-lg">
                    <FileText className="h-6 w-6 text-primary" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-600">Total Posts</p>
                    <p className="text-2xl font-semibold">{upcomingPosts.length}</p>
                  </div>
                </div>
              </Card>
            </SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>All Posts</SheetTitle>
              </SheetHeader>
              <div className="mt-6 space-y-4">
                {upcomingPosts.length === 0 ? (
                  <p className="text-muted-foreground">No posts created yet.</p>
                ) : (
                  upcomingPosts.map(post => {
                    const postTypeColors = {
                      text: { bg: 'bg-blue-100', text: 'text-blue-800', hover: 'hover:bg-blue-200' },
                      image: { bg: 'bg-green-100', text: 'text-green-800', hover: 'hover:bg-green-200' },
                      article: { bg: 'bg-purple-100', text: 'text-purple-800', hover: 'hover:bg-purple-200' },
                      poll: { bg: 'bg-orange-100', text: 'text-orange-800', hover: 'hover:bg-orange-200' }
                    };

                    return (
                      <Button
                        key={post.id}
                        variant="outline"
                        className={`w-full justify-start gap-4 ${postTypeColors[post.type]?.bg || 'bg-gray-100'} ${postTypeColors[post.type]?.text || 'text-gray-800'} ${postTypeColors[post.type]?.hover || 'hover:bg-gray-200'} border-0`}
                        onClick={() => navigate(`/posts/${post.id}`)}
                      >
                        <div className="flex flex-col items-start">
                          <span className="font-medium">{post.title}</span>
                          <span className="text-sm opacity-80">
                            {format(new Date(post.metadata.content_plan.scheduled_date), 'EEEE, MMMM d')} at {post.metadata.content_plan.scheduled_time}
                          </span>
                        </div>
                      </Button>
                    );
                  })
                )}
              </div>
            </SheetContent>
          </Sheet>
        </div>

        <div className="flex justify-end pb-4">
          <Button onClick={() => navigate('/planner')}>
            <Plus className="mr-2 h-4 w-4" /> Create a new plan
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className="text-center py-8">Loading your content plans...</div>
      ) : savedPlans.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-600">You haven't saved any content plans yet.</p>
          <Button
            className="mt-4"
            onClick={() => navigate('/?tab=planner')}
          >
            Create Your First Plan
          </Button>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {savedPlans.map(plan => (
              <Card
                key={plan.id}
                className={`p-4 cursor-pointer transition-all ${
                  selectedPlan?.id === plan.id ? 'ring-2 ring-primary' : ''
                }`}
                onClick={() => setSelectedPlan(plan)}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <div className="flex items-center gap-2 text-sm text-gray-600">
                      <CalendarIcon className="h-4 w-4" />
                      <span>Created {format(new Date(plan.created_at), 'MMM d, yyyy')}</span>
                    </div>
                    <h3 className="mt-2 font-medium text-lg">{plan.name}</h3>
                    <div className="mt-1 space-y-1">
                      <p className="text-sm text-gray-600">
                        {countContentIdeas(plan)} content ideas starting {format(new Date(plan.start_date), 'MMM d, yyyy')}
                      </p>
                      <p className="text-sm text-gray-600">
                        {calculatePostsPerWeek(plan)} ideas per week
                      </p>
                      {Object.entries(groupPostsByWeek(plan.plan)).map(([weekStart, posts], index) => (
                        <p key={weekStart} className="text-sm text-gray-600">
                          Week {index + 1} Theme: {posts[0]?.theme || 'General Content'}
                        </p>
                      ))}
                    </div>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      deletePlan(plan.id);
                    }}
                    className="text-red-600 hover:text-red-700 hover:bg-red-50"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              </Card>
            ))}
          </div>

          {selectedPlan && (
            <div className="mt-8">
              {/* Debug info */}
              
              <ContentCalendar 
                posts={(() => {
                  // Debug the selected plan data
                  console.log('Selected Plan:', selectedPlan);

                  const transformedPosts = selectedPlan.plan.map(group => {
                    // Debug each group
                    console.log('Processing group:', group);

                    const processedPosts = group.posts.map(post => {
                      // Debug each post's date before processing
                      console.log('Processing post:', {
                        original: post,
                        suggestedDate: post.suggestedDate,
                        parsedDate: post.suggestedDate ? new Date(post.suggestedDate) : null
                      });

                      let formattedDate;
                      try {
                        formattedDate = post.suggestedDate ? 
                          format(new Date(post.suggestedDate), 'yyyy-MM-dd') : 
                          format(new Date(), 'yyyy-MM-dd');
                      } catch (error) {
                        console.error('Date formatting error:', {
                          post,
                          error,
                          suggestedDate: post.suggestedDate
                        });
                        formattedDate = format(new Date(), 'yyyy-MM-dd');
                      }

                      // Format time to ensure it's in 24-hour format
                      let formattedTime;
                      try {
                        formattedTime = formatTime(post.scheduledTime);
                      } catch (error) {
                        console.error('Time formatting error:', {
                          post,
                          error,
                          scheduledTime: post.scheduledTime
                        });
                        formattedTime = '12:00';
                      }

                      // Create a valid date-time string for parsing
                      const dateTimeString = `${formattedDate}T${formattedTime}`;
                      console.log('Formatted date-time:', dateTimeString);

                      const processedPost = {
                        ...post,
                        postType: post.postType || group.type,
                        topic: post.topic || '',
                        category: post.category || group.type,
                        keyPoints: post.keyPoints || [],
                        suggestedDate: formattedDate,
                        scheduledTime: formattedTime,
                        description: post.description || '',
                        dateTime: dateTimeString // Add the combined date-time string
                      };

                      // Debug the processed post
                      console.log('Processed post:', processedPost);
                      return processedPost;
                    });

                    return {
                      type: group.type,
                      posts: processedPosts,
                      planName: selectedPlan.name // Add the plan name here
                    };
                  });

                  // Debug final transformed data
                  console.log('Final transformed posts:', transformedPosts);
                  return transformedPosts;
                })()}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}; 