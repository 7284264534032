import { FormProvider as HookFormProvider, UseFormReturn } from "react-hook-form";
import type { ReactNode } from "react";

interface FormProviderProps<T extends Record<string, unknown>> {
  children: ReactNode;
  methods: UseFormReturn<T>;
}

const FormProvider = <T extends Record<string, unknown>>({ 
  children, 
  methods 
}: FormProviderProps<T>) => {
  return <HookFormProvider {...methods}>{children}</HookFormProvider>;
};

export default FormProvider; 