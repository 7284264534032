import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { X } from 'lucide-react';
import { useState } from 'react';
import { BetaSpots } from '@/components/BetaSpots';

const BetaBanner = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-primary text-white py-3 relative"
    >
      <div className="max-w-6xl mx-auto px-4 flex items-center justify-between">
        <div className="flex-1 text-center pr-8">
          <span className="font-semibold">🚀 Free Beta Program: Only <BetaSpots totalSpots={25} className="font-semibold" /> Available!</span>
          <span className="hidden md:inline"> Join our 3-week beta and get 40% lifetime discount.</span>
        </div>
        <div className="flex items-center gap-4">
          <Button
            variant="secondary"
            className="bg-white text-primary hover:bg-white/90 border-2 border-white"
            onClick={() => navigate('/beta')}
          >
            Apply Now
          </Button>
          <button
            onClick={() => setIsVisible(false)}
            className="text-white/80 hover:text-white"
            aria-label="Close banner"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default BetaBanner; 