import { Button } from '@/components/ui/button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Clock, Zap, Star, Users, Calendar, CheckCircle } from 'lucide-react';
import { motion } from 'framer-motion';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/components/ui/use-toast';
import { useEffect } from 'react';

export default function Pro() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const checkSuccess = async () => {
      const success = searchParams.get('success');
      if (success === 'true') {
        // Clear the success parameter
        searchParams.delete('success');
        window.history.replaceState({}, '', `${window.location.pathname}${searchParams.toString() ? '?' + searchParams.toString() : ''}`);

        try {
          // Verify subscription status
          const { data: { session } } = await supabase.auth.getSession();
          if (!session) return;

          // Call our check-subscription function
          const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/check-subscription`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${session.access_token}`,
              'Content-Type': 'application/json'
            }
          });

          if (!response.ok) {
            throw new Error('Failed to verify subscription');
          }

          const { subscribed } = await response.json();

          if (subscribed) {
            navigate('/subscription/success');
          } else {
            // If not subscribed yet, poll a few times
            let attempts = 0;
            const checkInterval = setInterval(async () => {
              const checkResponse = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/check-subscription`, {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${session.access_token}`,
                  'Content-Type': 'application/json'
                }
              });

              if (checkResponse.ok) {
                const { subscribed: isSubscribed } = await checkResponse.json();
                if (isSubscribed) {
                  clearInterval(checkInterval);
                  navigate('/subscription/success');
                  return;
                }
              }

              attempts++;
              if (attempts >= 3) {
                clearInterval(checkInterval);
                toast({
                  title: "Subscription Status Pending",
                  description: "Your payment was successful. Please allow a few moments for your subscription to activate.",
                  variant: "default",
                });
                navigate('/dashboard');
              }
            }, 2000); // Check every 2 seconds, up to 3 times
          }
        } catch (error) {
          console.error('Error verifying subscription:', error);
          toast({
            title: "Subscription Status Pending",
            description: "Your payment was successful. Please allow a few moments for your subscription to activate.",
            variant: "default",
          });
          navigate('/dashboard');
        }
      }
    };

    checkSuccess();
  }, [searchParams, navigate, toast]);

  const handleUpgrade = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('Please sign in first');
      }

      const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/create-checkout`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          successUrl: `${window.location.origin}/pro?success=true`,
          cancelUrl: `${window.location.origin}/subscription/canceled`
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to create checkout session');
      }

      const { url } = await response.json();
      if (url) {
        window.location.href = url;
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      toast({
        title: "Error",
        description: "Failed to start checkout process. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-sage-50 to-white pt-20">
      <div className="max-w-6xl mx-auto px-4 py-16">
        {/* Hero Section */}
        <motion.div 
          className="text-center space-y-6 mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className="text-4xl md:text-5xl font-bold">
            Unlock Your Full Professional Potential
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Save hours every week with unlimited access to our AI-powered tools
          </p>
          <div className="flex justify-center gap-4">
            <Button size="lg" onClick={handleUpgrade} className="bg-primary hover:bg-primary/90">
              Upgrade Now - £29/month
            </Button>
          </div>
        </motion.div>

        {/* Time Savings Calculator */}
        <motion.div 
          className="bg-white rounded-lg shadow-lg p-8 mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold mb-4">Time Saved with Pro</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="flex flex-col items-center">
                <Clock className="h-12 w-12 text-primary mb-2" />
                <p className="text-3xl font-bold">3+ hours</p>
                <p className="text-gray-600">Saved per week</p>
              </div>
              <div className="flex flex-col items-center">
                <Zap className="h-12 w-12 text-primary mb-2" />
                <p className="text-3xl font-bold">15 minutes</p>
                <p className="text-gray-600">Average post creation</p>
              </div>
              <div className="flex flex-col items-center">
                <Star className="h-12 w-12 text-primary mb-2" />
                <p className="text-3xl font-bold">3x</p>
                <p className="text-gray-600">Faster than manual</p>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Features Grid */}
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <Users className="h-8 w-8 text-primary mb-4" />
            <h3 className="text-xl font-semibold mb-2">Unlimited Profile Optimizations</h3>
            <p className="text-gray-600">
              Continuously refine your professional presence with unlimited profile analyses and improvements
            </p>
          </div>
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <Calendar className="h-8 w-8 text-primary mb-4" />
            <h3 className="text-xl font-semibold mb-2">Advanced Content Planning</h3>
            <p className="text-gray-600">
              Create comprehensive content strategies with our AI-powered planning tools
            </p>
          </div>
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <Star className="h-8 w-8 text-primary mb-4" />
            <h3 className="text-xl font-semibold mb-2">Priority Support</h3>
            <p className="text-gray-600">
              Get faster responses and dedicated assistance for all your needs
            </p>
          </div>
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <Zap className="h-8 w-8 text-primary mb-4" />
            <h3 className="text-xl font-semibold mb-2">Unlimited Posts & Carousels</h3>
            <p className="text-gray-600">
              Create as many posts and carousels as you need, without any restrictions
            </p>
          </div>
        </motion.div>

        {/* Testimonials */}
        <motion.div 
          className="mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <h2 className="text-2xl font-bold text-center mb-8">What Pro Users Say</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <p className="text-gray-600 mb-4">"The time savings are incredible. I can now maintain an active professional presence while focusing on patient care."</p>
              <p className="font-semibold">Dr. Sarah Thompson</p>
              <p className="text-sm text-gray-500">Consultant Cardiologist</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <p className="text-gray-600 mb-4">"The unlimited posts feature has transformed how I share my expertise. Highly recommend upgrading to Pro."</p>
              <p className="font-semibold">Dr. James Chen</p>
              <p className="text-sm text-gray-500">General Practitioner</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <p className="text-gray-600 mb-4">"Priority support alone is worth the upgrade. The team is incredibly responsive and helpful."</p>
              <p className="font-semibold">Prof. Emily Roberts</p>
              <p className="text-sm text-gray-500">Clinical Researcher</p>
            </div>
          </div>
        </motion.div>

        {/* Final CTA */}
        <motion.div 
          className="text-center bg-primary/5 rounded-lg p-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.8 }}
        >
          <h2 className="text-2xl font-bold mb-4">Ready to Save Time and Grow Your Professional Network?</h2>
          <p className="text-lg text-gray-600 mb-8 max-w-2xl mx-auto">
            Join thousands of healthcare professionals who are already saving hours every week with MedicalPost Pro
          </p>
          <ul className="max-w-md mx-auto mb-8 text-left space-y-2">
            <li className="flex items-center">
              <CheckCircle className="h-5 w-5 text-primary mr-2" />
              <span>Unlimited access to all features</span>
            </li>
            <li className="flex items-center">
              <CheckCircle className="h-5 w-5 text-primary mr-2" />
              <span>Priority support</span>
            </li>
            <li className="flex items-center">
              <CheckCircle className="h-5 w-5 text-primary mr-2" />
              <span>Cancel anytime</span>
            </li>
          </ul>
          <Button size="lg" onClick={handleUpgrade} className="bg-primary hover:bg-primary/90">
            Upgrade to Pro - £29/month
          </Button>
        </motion.div>
      </div>
    </div>
  );
} 