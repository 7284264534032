import { Button } from '@/components/ui/button';
import { setAIModel, getCurrentModel, type AIModel } from '@/services/deepseek';
import { useToast } from '@/components/ui/use-toast';
import { useEffect, useState } from 'react';
import { supabase } from '@/integrations/supabase/client';

export const AI_MODELS = [
  {
    id: 'deepseek-chat',
    label: 'DeepSeek Chat',
    description: 'Fast and efficient for general content generation',
    provider: 'deepseek'
  },
  {
    id: 'deepseek-reasoner',
    label: 'DeepSeek Reasoner',
    description: 'Advanced reasoning capabilities for complex tasks',
    provider: 'deepseek'
  },
  {
    id: 'gpt-4o-mini',
    label: 'GPT-4 Turbo',
    description: 'Powerful language model for diverse content creation',
    provider: 'deepseek'
  },
  {
    id: 'claude-3',
    label: 'Claude 3.5',
    description: 'Powerful and precise language model from Anthropic',
    provider: 'openrouter',
    config: {
      model: 'anthropic/claude-3.5-sonnet:beta',
      baseUrl: 'https://openrouter.ai/api/v1',
      apiKeyEnvVar: 'VITE_OPENROUTER_API_KEY',
      extraHeaders: {
        'HTTP-Referer': window.location.origin,
        'X-Title': 'Medical Post'
      }
    }
  },
  {
    id: 'deepseek-r1',
    label: 'DeepSeek R1',
    description: 'Efficient and powerful model for content generation',
    provider: 'openrouter',
    config: {
      model: 'deepseek/deepseek-r1-distill-llama-8b',
      baseUrl: 'https://openrouter.ai/api/v1',
      apiKeyEnvVar: 'VITE_OPENROUTER_API_KEY',
      extraHeaders: {
        'HTTP-Referer': window.location.origin,
        'X-Title': 'Medical Post'
      }
    }
  },
  {
    id: 'gemini-flash',
    label: 'Gemini Flash',
    description: 'Fast and efficient model from Google',
    provider: 'openrouter',
    config: {
      model: 'google/gemini-2.0-flash-lite-preview-02-05:free',
      baseUrl: 'https://openrouter.ai/api/v1',
      apiKeyEnvVar: 'VITE_OPENROUTER_API_KEY',
      extraHeaders: {
        'HTTP-Referer': window.location.origin,
        'X-Title': 'Medical Post'
      }
    }
  },
  {
    id: 'gpt-4o',
    label: 'ChatGPT 4o',
    description: 'Efficient OpenAI model for general tasks',
    provider: 'openai',
    config: {
      model: 'gpt-4o',
      apiKeyEnvVar: 'VITE_OPENAI_API_KEY'
    }
  }
] as const;

export type AIModelId = typeof AI_MODELS[number]['id'];

interface ModelSelectorProps {
  currentModel: AIModel;
  onModelChange?: (model: AIModel) => void;
  className?: string;
}

export function ModelSelector({ currentModel, onModelChange, className = '' }: ModelSelectorProps) {
  const { toast } = useToast();
  const [enabledModels, setEnabledModels] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchModelSettings();
    // Subscribe to changes in llm_settings
    const channel = supabase
      .channel('llm_settings_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'llm_settings'
        },
        () => {
          fetchModelSettings();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  const fetchModelSettings = async () => {
    try {
      const { data, error } = await supabase
        .from('llm_settings')
        .select('id, enabled');

      if (error) throw error;

      const settingsMap = (data || []).reduce((acc, setting) => {
        acc[setting.id] = setting.enabled;
        return acc;
      }, {} as Record<string, boolean>);

      // Initialize any missing models as enabled by default
      AI_MODELS.forEach(model => {
        if (settingsMap[model.id] === undefined) {
          settingsMap[model.id] = true;
        }
      });

      setEnabledModels(settingsMap);

      // If current model is disabled, switch to first enabled model
      if (!settingsMap[currentModel]) {
        const firstEnabledModel = AI_MODELS.find(model => settingsMap[model.id]);
        if (firstEnabledModel) {
          handleModelChange(firstEnabledModel.id as AIModel);
        }
      }
    } catch (error) {
      console.error('Error fetching model settings:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleModelChange = (model: AIModel) => {
    const selectedModel = AI_MODELS.find(m => m.id === model);
    if (!selectedModel) return;

    // Check if model is enabled
    if (!enabledModels[model]) {
      toast({
        title: "Model Unavailable",
        description: "This model is currently disabled by the administrator.",
        variant: "destructive",
      });
      return;
    }

    // Check if required API key is available for the selected model
    if (selectedModel.provider !== 'deepseek') {
      const apiKey = import.meta.env[selectedModel.config.apiKeyEnvVar];
      if (!apiKey) {
        toast({
          title: "API Key Missing",
          description: `Please set the ${selectedModel.config.apiKeyEnvVar} environment variable.`,
          variant: "destructive",
        });
        return;
      }
    }

    setAIModel(model);
    onModelChange?.(model);
    toast({
      title: "Model Changed",
      description: `Switched to ${selectedModel.label}`,
    });
  };

  if (loading) {
    return <div className="animate-pulse">Loading models...</div>;
  }

  // Filter out disabled models
  const availableModels = AI_MODELS.filter(model => enabledModels[model.id]);

  return (
    <div className={`flex flex-wrap justify-center gap-2 ${className}`}>
      {availableModels.map((model) => (
        <Button
          key={model.id}
          variant={currentModel === model.id ? 'default' : 'outline'}
          size="sm"
          onClick={() => handleModelChange(model.id as AIModel)}
          className="gap-2"
        >
          {model.label}
        </Button>
      ))}
    </div>
  );
} 