import { useFormContext } from "react-hook-form";
import { DocumentFormReturn } from "@/lib/document-form-types";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { fontsMap } from "./lib/fonts-map";
import { useEffect } from "react";

export function EditorHeader() {
  const form: DocumentFormReturn = useFormContext();

  const handleFontChange = (value: string, field: "font1" | "font2") => {
    console.log(`[Font Change] Changing ${field} to:`, value);
    console.log('[Font Change] Current form values:', form.getValues());
    
    form.setValue(`config.fonts.${field}`, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
    
    // Force a re-render of the form
    form.trigger(`config.fonts.${field}`).then(() => {
      console.log('[Font Change] Updated form values:', form.getValues());
      console.log('[Font Change] Font class name:', fontsMap[value].className);
    });
  };

  // Log initial font values and watch for changes
  useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      if (name?.startsWith('config.fonts.')) {
        console.log('[Font Watch]', {
          name,
          type,
          value: value.config?.fonts,
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  return (
    <div className="flex items-center justify-between px-4 py-3 border-b bg-muted/40">
      <div className="flex items-center space-x-6">
        {/* Font Controls */}
        <div className="flex items-center space-x-4">
          <div className="flex flex-col gap-1">
            <Label className="text-xs text-muted-foreground">Primary Font</Label>
            <Select
              value={form.watch("config.fonts.font1")}
              onValueChange={(value) => handleFontChange(value, "font1")}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select primary font" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(fontsMap).map(([fontId, font]) => (
                  <SelectItem key={fontId} value={fontId}>
                    <p className={font.className}>{font.name}</p>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-1">
            <Label className="text-xs text-muted-foreground">Secondary Font</Label>
            <Select
              value={form.watch("config.fonts.font2")}
              onValueChange={(value) => handleFontChange(value, "font2")}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select secondary font" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(fontsMap).map(([fontId, font]) => (
                  <SelectItem key={fontId} value={fontId}>
                    <p className={font.className}>{font.name}</p>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>

        {/* View Controls */}
        <div className="flex items-center gap-2 pl-4 border-l">
          <Switch
            id="show-page-numbers"
            checked={form.watch("config.pageNumber.showNumbers")}
            onCheckedChange={(checked) => {
              form.setValue("config.pageNumber.showNumbers", checked, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true
              });
              form.trigger("config.pageNumber.showNumbers");
            }}
          />
          <Label htmlFor="show-page-numbers" className="text-muted-foreground">Show Page Numbers</Label>
        </div>
      </div>
    </div>
  );
} 