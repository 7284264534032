import { format } from 'date-fns';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import type { ContentPlan } from '@/types/post';

interface PostSchedulerProps {
  scheduledDate?: string;
  onScheduledDateChange: (date: string) => void;
  scheduledTime?: string;
  onScheduledTimeChange: (time: string) => void;
  selectedPlanId?: string;
  onPlanChange: (planId: string | undefined) => void;
  contentPlans: ContentPlan[];
}

export function PostScheduler({
  scheduledDate,
  onScheduledDateChange,
  scheduledTime,
  onScheduledTimeChange,
  selectedPlanId,
  onPlanChange,
  contentPlans
}: PostSchedulerProps) {
  return (
    <div className="grid grid-cols-3 gap-4 mt-4 pt-4 border-t">
      <div className="flex flex-col gap-2">
        <Label>Schedule Date</Label>
        <Input
          type="date"
          value={scheduledDate || ''}
          onChange={(e) => onScheduledDateChange(e.target.value)}
          min={format(new Date(), 'yyyy-MM-dd')}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label>Schedule Time</Label>
        <Input
          type="time"
          value={scheduledTime || ''}
          onChange={(e) => onScheduledTimeChange(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label>Content Plan</Label>
        <Select
          value={selectedPlanId || 'none'}
          onValueChange={(value) => onPlanChange(value === 'none' ? undefined : value)}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a plan" />
          </SelectTrigger>
          <SelectContent className="bg-white">
            <SelectItem value="none">No Plan</SelectItem>
            {contentPlans.map((plan) => (
              <SelectItem key={plan.id} value={plan.id}>
                {plan.name || format(new Date(plan.created_at), 'MMM d, yyyy')}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
} 