import { useCallback } from 'react';

export const useFeedback = () => {
  const openFeedback = useCallback(() => {
    const feedbackButton = document.querySelector('[data-feedback-trigger]');
    if (feedbackButton instanceof HTMLElement) {
      feedbackButton.click();
    }
  }, []);

  return { openFeedback };
}; 