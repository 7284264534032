import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';
import { supabase } from '@/integrations/supabase/client';
import ReactMarkdown from 'react-markdown';
import { format } from 'date-fns';

interface SavedPost {
  id: string;
  user_id: string;
  type: string;
  title: string;
  content: string;
  hashtags: string[];
  call_to_action: string;
  target_audience: string[];
  tone: string;
  metadata: {
    image_prompt?: string;
    selected_images?: Array<{
      id: number;
      pageURL: string;
      previewURL: string;
      largeImageURL: string;
      tags: string;
    }>;
    poll_options?: string[];
    content_plan?: {
      plan_id: string;
      scheduled_date: string;
      scheduled_time: string;
    };
    target_audience?: string | string[];
  };
  created_at: string;
  status: string;
}

export function EditPost() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [post, setPost] = useState<SavedPost | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadPost();
  }, [id]);

  const loadPost = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        toast({
          title: "Authentication required",
          description: "Please sign in to view this post.",
          variant: "destructive",
        });
        navigate('/login');
        return;
      }

      const { data, error } = await supabase
        .from('posts')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      if (!data) {
        toast({
          title: "Post not found",
          description: "The requested post could not be found.",
          variant: "destructive",
        });
        navigate('/');
        return;
      }

      if (data.user_id !== session.user.id) {
        toast({
          title: "Access denied",
          description: "You don't have permission to view this post.",
          variant: "destructive",
        });
        navigate('/');
        return;
      }

      console.log('Raw post data:', data);

      // Helper function to ensure array
      const ensureArray = (value: unknown): string[] => {
        if (Array.isArray(value)) return value;
        if (typeof value === 'string') return value.split(',').map(item => item.trim());
        return [];
      };

      // Process target audience - it might be in metadata or at the root level
      let targetAudience: string[] = [];
      if (data.target_audience) {
        targetAudience = ensureArray(data.target_audience);
      } else if (data.metadata?.target_audience) {
        targetAudience = ensureArray(data.metadata.target_audience);
      }

      // Ensure all required properties have default values
      const normalizedData: SavedPost = {
        ...data,
        hashtags: ensureArray(data.hashtags),
        target_audience: targetAudience,
        content: data.content || '',
        call_to_action: data.call_to_action || '',
        tone: data.tone || '',
        metadata: {
          ...data.metadata,
          content_plan: data.metadata?.content_plan || {
            plan_id: '',
            scheduled_date: new Date().toISOString().split('T')[0],
            scheduled_time: '12:00'
          }
        }
      };

      console.log('Normalized post data:', normalizedData);
      setPost(normalizedData);
    } catch (error) {
      console.error('Error loading post:', error);
      toast({
        title: "Error",
        description: "Failed to load the post. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    if (!post) return;

    try {
      // Ensure we're sending the data in the correct format
      const updateData = {
        ...post,
        target_audience: Array.isArray(post.target_audience) ? post.target_audience : [],
        hashtags: Array.isArray(post.hashtags) ? post.hashtags : [],
      };

      const { error } = await supabase
        .from('posts')
        .update(updateData)
        .eq('id', post.id);

      if (error) throw error;

      toast({
        title: "Post saved",
        description: "Your changes have been saved successfully.",
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving post:', error);
      toast({
        title: "Error",
        description: "Failed to save your changes. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (isLoading) {
    return <div className="text-center py-8">Loading...</div>;
  }

  if (!post) {
    return <div className="text-center py-8">Post not found</div>;
  }

  // Ensure we always have arrays for rendering
  const targetAudience = Array.isArray(post.target_audience) ? post.target_audience : [];
  const hashtags = Array.isArray(post.hashtags) ? post.hashtags : [];

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold">Edit Post</h1>
          {post.metadata.content_plan && (
            <p className="text-sm text-gray-500">
              Scheduled for {format(new Date(post.metadata.content_plan.scheduled_date), 'EEEE, MMMM d, yyyy')} at {post.metadata.content_plan.scheduled_time}
            </p>
          )}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            onClick={() => navigate('/')}
          >
            Back
          </Button>
          <Button
            onClick={() => isEditing ? handleSave() : setIsEditing(true)}
          >
            {isEditing ? 'Save Changes' : 'Edit Post'}
          </Button>
        </div>
      </div>

      <div className="space-y-6">
        <div className="space-y-2">
          <Label>Title</Label>
          {isEditing ? (
            <Input
              value={post.title || ''}
              onChange={(e) => setPost(prev => prev ? { ...prev, title: e.target.value } : null)}
            />
          ) : (
            <p className="text-lg font-medium">{post.title}</p>
          )}
        </div>

        <div className="space-y-2">
          <Label>Content</Label>
          {isEditing ? (
            <Textarea
              value={post.content || ''}
              onChange={(e) => setPost(prev => prev ? { ...prev, content: e.target.value } : null)}
              rows={10}
            />
          ) : (
            <div className="prose max-w-none">
              <ReactMarkdown>{post.content || ''}</ReactMarkdown>
            </div>
          )}
        </div>

        {isEditing && (
          <div className="space-y-2">
            <Label>Schedule</Label>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label className="text-sm text-gray-500">Date</Label>
                <Input
                  type="date"
                  value={post.metadata.content_plan?.scheduled_date || ''}
                  onChange={(e) => setPost(prev => prev ? {
                    ...prev,
                    metadata: {
                      ...prev.metadata,
                      content_plan: {
                        ...prev.metadata.content_plan,
                        scheduled_date: e.target.value
                      }
                    }
                  } : null)}
                />
              </div>
              <div>
                <Label className="text-sm text-gray-500">Time</Label>
                <Input
                  type="time"
                  value={post.metadata.content_plan?.scheduled_time || ''}
                  onChange={(e) => setPost(prev => prev ? {
                    ...prev,
                    metadata: {
                      ...prev.metadata,
                      content_plan: {
                        ...prev.metadata.content_plan,
                        scheduled_time: e.target.value
                      }
                    }
                  } : null)}
                />
              </div>
            </div>
          </div>
        )}

        <div className="space-y-2">
          <Label>Target Audience</Label>
          {isEditing ? (
            <Textarea
              value={targetAudience.join('\n')}
              onChange={(e) => {
                const audience = e.target.value
                  .split('\n')
                  .map(line => line.trim())
                  .filter(Boolean);
                setPost(prev => prev ? { ...prev, target_audience: audience } : null);
              }}
              rows={3}
            />
          ) : (
            <ul className="list-disc pl-4">
              {targetAudience.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </div>

        {post.type === 'poll' && post.metadata.poll_options && (
          <div className="space-y-2">
            <Label>Poll Options</Label>
            {isEditing ? (
              <Textarea
                value={post.metadata.poll_options.join('\n')}
                onChange={(e) => {
                  const options = e.target.value
                    .split('\n')
                    .map(line => line.trim())
                    .filter(Boolean);
                  setPost(prev => prev ? {
                    ...prev,
                    metadata: {
                      ...prev.metadata,
                      poll_options: options
                    }
                  } : null);
                }}
                rows={4}
              />
            ) : (
              <ul className="list-disc pl-4">
                {post.metadata.poll_options.map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
            )}
          </div>
        )}

        {post.type === 'image' && post.metadata.selected_images && (
          <div className="space-y-2">
            <Label>Selected Images</Label>
            <div className="grid grid-cols-2 gap-4">
              {post.metadata.selected_images.map((image, index) => (
                <div key={index} className="relative">
                  <img
                    src={image.largeImageURL}
                    alt={image.tags}
                    className="w-full h-48 object-cover rounded-lg"
                  />
                  <p className="text-sm text-gray-500 mt-1">{image.tags}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
} 