import { useState, useRef, useEffect } from 'react';
import type { PostContent } from '@/types/post';
import type { UserProfile } from '@/types/profile';
import { EXAMPLE_FEED_POSTS } from '@/constants/post-creator';
import {
  Dialog,
  DialogContent
} from "@/components/ui/dialog";

interface LinkedInPreviewDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  post: PostContent;
  userProfile: UserProfile;
}

export function LinkedInPreviewDialog({ open, onOpenChange, post, userProfile }: LinkedInPreviewDialogProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [viewMode, setViewMode] = useState<'web' | 'mobile'>('web');
  const contentRef = useRef<HTMLDivElement>(null);
  const [shouldShowMore, setShouldShowMore] = useState(false);
  const [timeAgo] = useState('6d • Edited');

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      setShouldShowMore(contentHeight > 100 || post.content.length > 210);
    }
  }, [post.content, open]);

  const truncateText = (text: string, maxLength: number = 210) => {
    if (text.length <= maxLength || isExpanded) return text;
    const truncated = text.slice(0, maxLength);
    return truncated.slice(0, truncated.lastIndexOf(' '));
  };

  const ViewToggleButtons = () => (
    <div className="sticky top-0 flex justify-center gap-2 p-4 bg-white border-b z-20">
      <button
        onClick={() => setViewMode('web')}
        className={`px-4 py-2 rounded-lg transition-colors ${
          viewMode === 'web'
            ? 'bg-blue-100 text-blue-700'
            : 'hover:bg-gray-100 text-gray-700'
        }`}
      >
        Web View
      </button>
      <button
        onClick={() => setViewMode('mobile')}
        className={`px-4 py-2 rounded-lg transition-colors ${
          viewMode === 'mobile'
            ? 'bg-blue-100 text-blue-700'
            : 'hover:bg-gray-100 text-gray-700'
        }`}
      >
        Mobile View
      </button>
    </div>
  );

  const PostFooter = () => (
    <>
      <div className="flex items-center justify-between text-[12px] text-gray-500 mt-3">
        <div className="flex items-center gap-1">
          <div className="flex -space-x-1">
            <span className="w-4 h-4 rounded-full bg-blue-500 flex items-center justify-center text-[10px] text-white">👍</span>
            <span className="w-4 h-4 rounded-full bg-red-500 flex items-center justify-center text-[10px] text-white">❤️</span>
            <span className="w-4 h-4 rounded-full bg-yellow-500 flex items-center justify-center text-[10px] text-white">👏</span>
          </div>
          <span className="ml-1">32 reactions</span>
        </div>
        <div>24 comments • 2 reposts</div>
      </div>

      <div className={`border-t mt-2 ${viewMode === 'mobile' ? '-mx-4 px-2' : '-mx-4 px-2'} py-1`}>
        <div className={`flex items-center ${viewMode === 'mobile' ? 'justify-around' : 'justify-between'}`}>
          <button className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded-lg transition-colors text-gray-500">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905 0 .905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
            </svg>
            {viewMode !== 'mobile' && <span className="text-[14px]">Like</span>}
          </button>
          <button className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded-lg transition-colors text-gray-500">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2v-6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
            </svg>
            {viewMode !== 'mobile' && <span className="text-[14px]">Comment</span>}
          </button>
          <button className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded-lg transition-colors text-gray-500">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
            </svg>
            {viewMode !== 'mobile' && <span className="text-[14px]">Repost</span>}
          </button>
          <button className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded-lg transition-colors text-gray-500">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            {viewMode !== 'mobile' && <span className="text-[14px]">Send</span>}
          </button>
        </div>
      </div>
    </>
  );

  const PostContent = () => (
    <div className={`bg-white ${viewMode === 'mobile' ? 'max-w-[390px] mx-auto' : ''}`}>
      <div className="p-4">
        <div className="flex items-start gap-2">
          <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center text-gray-500">
            {userProfile.name?.[0] || '👤'}
          </div>
          <div className="flex-1">
            <div className="font-semibold text-[14px] text-gray-900 hover:text-blue-600 cursor-pointer">
              {userProfile.name || 'Your Name'}
            </div>
            <div className="text-[12px] text-gray-600">{userProfile.headline || 'Your Headline'}</div>
            <div className="text-[12px] text-gray-500 mt-1">{timeAgo} • 🌏</div>
          </div>
          {viewMode === 'mobile' && (
            <button className="text-gray-500">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
              </svg>
            </button>
          )}
        </div>

        <div className="mt-3">
          <div 
            ref={contentRef}
            className={`text-[14px] text-gray-900 whitespace-pre-wrap relative ${!isExpanded ? 'line-clamp-5' : ''}`}
          >
            {isExpanded ? post.content : truncateText(post.content)}
            {!isExpanded && shouldShowMore && (
              <button
                onClick={() => setIsExpanded(true)}
                className="text-gray-500 hover:text-gray-700 font-medium ml-1 inline-block bg-white z-10 relative"
              >
                ...see more
              </button>
            )}
          </div>
          {isExpanded && shouldShowMore && (
            <button
              onClick={() => setIsExpanded(false)}
              className="text-gray-500 hover:text-gray-700 text-[14px] font-medium mt-2 block"
            >
              ...see less
            </button>
          )}
        </div>

        {post.selectedImages && post.selectedImages.length > 0 && (
          <div className={`${viewMode === 'mobile' ? '-mx-4 mt-3' : 'mt-3'}`}>
            <div className={`grid ${post.selectedImages.length > 1 ? 'grid-cols-2' : 'grid-cols-1'} gap-1`}>
              {post.selectedImages.map((image) => (
                <div key={image.id} className="relative pt-[56.25%]">
                  <img
                    src={image.largeImageURL}
                    alt={image.tags}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        <PostFooter />
      </div>
    </div>
  );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={`${viewMode === 'mobile' ? 'w-full max-w-[390px] h-[85vh]' : 'sm:max-w-[550px]'} p-0 bg-[#F3F2EF] overflow-hidden max-h-[90vh] overflow-y-auto`}>
        <ViewToggleButtons />
        <div className="space-y-1">
          {/* Example post above with overlay */}
          <div className="relative">
            <div className="absolute inset-0 bg-black/40 z-10" />
            {EXAMPLE_FEED_POSTS.slice(0, 1).map((examplePost, index) => (
              <div key={index} className="p-4 bg-white">
                <div className="flex items-start gap-2">
                  <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center text-gray-500">
                    {examplePost.name[0]}
                  </div>
                  <div>
                    <div className="font-semibold text-[14px] text-gray-900">
                      {examplePost.name}
                    </div>
                    <div className="text-[12px] text-gray-600">{examplePost.headline}</div>
                    <div className="text-[12px] text-gray-500 mt-1">{examplePost.timeAgo} • 🌏</div>
                  </div>
                </div>
                <div className="mt-3 text-[14px]">{examplePost.content.slice(0, 150)}</div>
                <PostFooter />
              </div>
            ))}
          </div>

          {/* Current post */}
          <PostContent />

          {/* Example post below with overlay */}
          <div className="relative">
            <div className="absolute inset-0 bg-black/40 z-10" />
            {EXAMPLE_FEED_POSTS.slice(1).map((examplePost, index) => (
              <div key={index} className="p-4 bg-white">
                <div className="flex items-start gap-2">
                  <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center text-gray-500">
                    {examplePost.name[0]}
                  </div>
                  <div>
                    <div className="font-semibold text-[14px] text-gray-900">
                      {examplePost.name}
                    </div>
                    <div className="text-[12px] text-gray-600">{examplePost.headline}</div>
                    <div className="text-[12px] text-gray-500 mt-1">{examplePost.timeAgo} • 🌏</div>
                  </div>
                </div>
                <div className="mt-3 text-[14px]">{examplePost.content.slice(0, 150)}</div>
                <PostFooter />
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
} 