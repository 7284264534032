import React from "react";
import * as z from "zod";
import { ConfigSchema } from "../lib/validation/document-schema";
import { cn } from "@/lib/utils";
import { fontIdToClassName } from "../lib/fonts-map";
import { DescriptionSchema } from "../lib/validation/text-schema";
import { textStyleToClasses } from "../lib/text-style-to-classes";
import { useFormContext } from "react-hook-form";
import {
  DocumentFormReturn,
  TextFieldPath,
  TextFieldStyle,
  StyleFieldPath,
  TextContentFieldPath,
} from "../lib/document-form-types";
import { TextAreaFormField } from "../forms/fields/text-area-form-field";

const paddingToClasses = {
  Small: "py-1",
  Medium: "py-4",
  Large: "py-8",
} as const;

const paddingXToClasses = {
  Small: "px-6",
  Medium: "px-12",
  Large: "px-24",
} as const;

const fontSizeClasses = {
  Small: "text-base",
  Medium: "text-lg",
  Large: "text-xl",
} as const;

export function Description({
  fieldName,
  className,
}: {
  fieldName: TextFieldPath;
  className?: string;
}) {
  const form: DocumentFormReturn = useFormContext();
  const { watch } = form;
  const description = watch(fieldName) as z.infer<typeof DescriptionSchema>;
  const { style, config } = description;

  const getTextColor = () => {
    if (!style?.color) return undefined;
    
    switch (style.color) {
      case "Primary":
        return config?.theme?.primary;
      case "Secondary":
        return config?.theme?.secondary;
      case "Custom":
        return style.customColor;
      default:
        return undefined;
    }
  };

  const getFontFamily = () => {
    if (!config?.fonts) return "";
    
    const primaryFont = config.fonts.font1;
    const secondaryFont = config.fonts.font2;
    
    if (!style?.font) {
      return secondaryFont ? fontIdToClassName(secondaryFont) : "";
    }
    
    switch (style.font) {
      case "Primary":
        return primaryFont ? fontIdToClassName(primaryFont) : "";
      case "Secondary":
        return secondaryFont ? fontIdToClassName(secondaryFont) : "";
      default:
        return secondaryFont ? fontIdToClassName(secondaryFont) : "";
    }
  };

  return (
    <div className={cn(
      "w-full flex flex-col items-stretch",
      paddingToClasses[style?.margin || "Medium"],
      paddingXToClasses[style?.marginX || "Medium"],
    )}>
      <TextAreaFormField
        form={form}
        fieldName={`${fieldName}.content` as TextFieldPath}
        label=""
        placeholder="Enter description"
        className={cn(
          "w-full",
          fontSizeClasses[style?.fontSize || "Medium"],
          getFontFamily(),
          {
            "text-left": style?.align === "Left",
            "text-center": style?.align === "Center",
            "text-right": style?.align === "Right",
          },
          className
        )}
        style={{
          color: getTextColor(),
        }}
      />
    </div>
  );
}
