import { useEffect, useState } from 'react';
import { supabase } from '@/integrations/supabase/client';

interface BetaSpotsProps {
  className?: string;
  totalSpots?: number;
}

export const BetaSpots = ({ className = '', totalSpots = 25 }: BetaSpotsProps) => {
  const [approvedCount, setApprovedCount] = useState(0);

  useEffect(() => {
    const fetchApprovedCount = async () => {
      const { data, error } = await supabase
        .from('beta_applications')
        .select('id')
        .eq('status', 'approved');

      if (!error && data) {
        setApprovedCount(data.length);
      }
    };

    fetchApprovedCount();
  }, []);

  const remainingSpots = totalSpots - approvedCount;

  return (
    <span className={className}>
      {remainingSpots} {remainingSpots === 1 ? 'spot' : 'spots'}
    </span>
  );
}; 