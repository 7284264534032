import { Button } from '@/components/ui/button';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function TermsOfService() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-sage-50 to-white">
      <div className="max-w-4xl mx-auto px-4 py-12">
        <Button
          variant="ghost"
          onClick={() => navigate(-1)}
          className="mb-8"
        >
          <ChevronLeft className="h-4 w-4 mr-2" />
          Back
        </Button>

        <h1 className="text-4xl font-bold mb-8">Terms of Service</h1>
        
        <div className="prose prose-lg max-w-none">
          <p className="text-gray-600">Last updated: {new Date().toLocaleDateString('en-GB')}</p>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
            <p>By accessing and using MedicalPost, you accept and agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our service.</p>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">2. Service Description</h2>
            <p>MedicalPost provides AI-powered tools for:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>LinkedIn profile optimization</li>
              <li>Content planning and scheduling</li>
              <li>Post generation and management</li>
              <li>Analytics and insights</li>
            </ul>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">3. User Responsibilities</h2>
            <p>As a user of our service, you agree to:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Provide accurate and complete information</li>
              <li>Maintain the security of your account</li>
              <li>Use the service in compliance with all applicable laws</li>
              <li>Not misuse or attempt to manipulate our AI systems</li>
              <li>Not share your account credentials with others</li>
            </ul>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">4. Subscription and Payments</h2>
            <p>Our service offers both free and paid subscription plans:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Free plan includes limited access to basic features</li>
              <li>Pro plan provides full access to all features</li>
              <li>Payments are processed securely through our payment provider</li>
              <li>Subscriptions auto-renew unless cancelled</li>
              <li>Refunds are handled on a case-by-case basis</li>
            </ul>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">5. Intellectual Property</h2>
            <p>Users retain ownership of their content while granting us license to:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Process and analyze content for service provision</li>
              <li>Store and backup content</li>
              <li>Use anonymized data for service improvement</li>
            </ul>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">6. Limitation of Liability</h2>
            <p>MedicalPost provides its service "as is" and:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Does not guarantee specific results</li>
              <li>Is not liable for any indirect damages</li>
              <li>May experience occasional service interruptions</li>
              <li>Reserves the right to modify or discontinue features</li>
            </ul>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">7. Changes to Terms</h2>
            <p>We reserve the right to modify these terms at any time. Users will be notified of significant changes via email or service announcement.</p>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">8. Contact Information</h2>
            <p>For questions about these Terms of Service, please contact us at:</p>
            <p className="mt-2">Email: support@medicalpost.co.uk</p>
          </section>
        </div>
      </div>
    </div>
  );
} 