import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { supabase } from '@/integrations/supabase/client';
import ReactMarkdown from 'react-markdown';
import { Download } from 'lucide-react';
import { format } from 'date-fns';
import html2pdf from 'html2pdf.js';

interface ProfileReview {
  id: string;
  analysis: string;
  created_at: string;
}

interface ProfileReviewsDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  userId: string;
}

export function ProfileReviewsDialog({
  open,
  onOpenChange,
  userId
}: ProfileReviewsDialogProps) {
  const [reviews, setReviews] = useState<ProfileReview[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      loadReviews();
    }
  }, [open, userId]);

  const loadReviews = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('profile_reviews')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setReviews(data || []);
    } catch (error) {
      console.error('Error loading reviews:', error);
    } finally {
      setLoading(false);
    }
  };

  const downloadPDF = async (review: ProfileReview) => {
    const element = document.getElementById(`review-${review.id}`);
    if (!element) return;

    const opt = {
      margin: 1,
      filename: `profile-review-${format(new Date(review.created_at), 'yyyy-MM-dd')}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    try {
      await html2pdf().set(opt).from(element).save();
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[800px] max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Profile Review History</DialogTitle>
        </DialogHeader>

        <div className="space-y-8">
          {loading ? (
            <p className="text-center text-gray-500">Loading reviews...</p>
          ) : reviews.length === 0 ? (
            <p className="text-center text-gray-500">No reviews found</p>
          ) : (
            reviews.map((review) => (
              <div 
                key={review.id}
                id={`review-${review.id}`}
                className="p-6 bg-white rounded-lg shadow-sm space-y-4"
              >
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold">
                    Review from {format(new Date(review.created_at), 'MMMM d, yyyy')}
                  </h3>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => downloadPDF(review)}
                    className="gap-2"
                  >
                    <Download className="h-4 w-4" />
                    Download PDF
                  </Button>
                </div>
                <div className="prose prose-sm max-w-none">
                  <ReactMarkdown
                    components={{
                      p: ({ children }) => (
                        <p className="my-4 text-gray-600">{children}</p>
                      ),
                      ul: ({ children }) => (
                        <ul className="list-disc pl-6 my-4 space-y-2">{children}</ul>
                      ),
                      li: ({ children }) => (
                        <li className="text-gray-600">{children}</li>
                      ),
                    }}
                  >
                    {review.analysis}
                  </ReactMarkdown>
                </div>
              </div>
            ))
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
} 