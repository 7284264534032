import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { IdealClientProfile } from '@/types/profile';

// Web Speech API types
interface SpeechRecognitionEvent extends Event {
  results: SpeechRecognitionResultList;
  resultIndex: number;
  error: Error | null;
}

interface SpeechRecognitionResult {
  isFinal: boolean;
  [index: number]: SpeechRecognitionAlternative;
}

interface SpeechRecognitionAlternative {
  transcript: string;
  confidence: number;
}

interface SpeechRecognitionResultList {
  length: number;
  item(index: number): SpeechRecognitionResult;
  [index: number]: SpeechRecognitionResult;
}

interface SpeechRecognition extends EventTarget {
  continuous: boolean;
  interimResults: boolean;
  lang: string;
  start(): void;
  stop(): void;
  abort(): void;
  onerror: ((this: SpeechRecognition, ev: SpeechRecognitionEvent) => void) | null;
  onend: ((this: SpeechRecognition, ev: Event) => void) | null;
  onresult: ((this: SpeechRecognition, ev: SpeechRecognitionEvent) => void) | null;
}

declare global {
  interface Window {
    SpeechRecognition: {
      new(): SpeechRecognition;
    };
    webkitSpeechRecognition: {
      new(): SpeechRecognition;
    };
  }
}

interface IdealClientProfileDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSave: (profile: IdealClientProfile) => void;
  existingProfile: IdealClientProfile | null;
}

export function IdealClientProfileDialog({
  open,
  onOpenChange,
  onSave,
  existingProfile
}: IdealClientProfileDialogProps) {
  const [profile, setProfile] = useState<IdealClientProfile>({
    description: existingProfile?.description || ''
  });
  const [isRecording, setIsRecording] = useState(false);
  const [recognition, setRecognition] = useState<SpeechRecognition | null>(null);
  const [interimTranscript, setInterimTranscript] = useState('');

  // Update profile when existingProfile changes
  useEffect(() => {
    if (existingProfile) {
      setProfile({
        description: existingProfile.description
      });
    }
  }, [existingProfile]);

  // Initialize speech recognition
  useEffect(() => {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = 'en-US';

      recognition.onresult = (event) => {
        let finalTranscript = '';
        let interimTranscript = '';

        // Process results
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
          } else {
            interimTranscript += transcript;
          }
        }

        // Update interim results
        setInterimTranscript(interimTranscript);

        // Only update content with final results
        if (finalTranscript) {
          setProfile(prev => ({
            ...prev,
            description: prev.description + (prev.description ? ' ' : '') + finalTranscript
          }));
        }
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsRecording(false);
        setInterimTranscript('');
      };

      recognition.onend = () => {
        setIsRecording(false);
        setInterimTranscript('');
      };

      setRecognition(recognition);
    }
  }, []);

  // Start recording function
  const startRecording = () => {
    if (recognition) {
      recognition.start();
      setIsRecording(true);
    } else {
      console.error('Speech recognition not supported');
    }
  };

  // Stop recording function
  const stopRecording = () => {
    if (recognition) {
      recognition.stop();
      setIsRecording(false);
      setInterimTranscript('');
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Target Audience</DialogTitle>
          <DialogDescription>
            Describe your ideal target audience to help create more relevant content.
          </DialogDescription>
        </DialogHeader>

        <div className="py-4">
          <div className="space-y-4">
            <div>
              <div className="flex items-center justify-between">
                <Label className="text-lg font-semibold">Description</Label>
                <Button
                  variant="outline"
                  size="icon"
                  className={`h-8 w-8 shrink-0 ${isRecording ? 'bg-red-50 text-red-500 hover:bg-red-100' : ''}`}
                  onClick={() => {
                    if (!isRecording) {
                      startRecording();
                    } else {
                      stopRecording();
                    }
                  }}
                >
                  {isRecording ? "🔴" : "🎤"}
                </Button>
              </div>
              <div className="relative mt-2">
                <Textarea
                  value={profile.description}
                  onChange={(e) => setProfile(prev => ({ ...prev, description: e.target.value }))}
                  placeholder="Describe your ideal target audience, including their industry, company size, job titles, pain points, and goals."
                  rows={8}
                />
                {isRecording && interimTranscript && (
                  <div className="absolute bottom-0 left-0 right-0 p-2 bg-gray-50 text-gray-500 border-t text-sm">
                    {interimTranscript}
                  </div>
                )}
              </div>
              {isRecording && (
                <div className="text-sm text-muted-foreground animate-pulse flex items-center gap-2 mt-2">
                  <span className="inline-block h-2 w-2 bg-red-500 rounded-full animate-pulse"></span>
                  Recording... Click the microphone to stop
                </div>
              )}
            </div>
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button 
            onClick={() => onSave(profile)}
            disabled={!profile.description.trim()}
          >
            Save Profile
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 