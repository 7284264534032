import React from "react";
import { cn } from "@/lib/utils";
import { useFormContext } from "react-hook-form";
import { DocumentFormReturn } from "../lib/document-form-types";

interface BackgroundImageProps {
  fieldName: string;
  className?: string;
}

export function BackgroundImage({ fieldName, className }: BackgroundImageProps) {
  const form: DocumentFormReturn = useFormContext();
  const { watch } = form;
  const image = watch(fieldName);

  if (!image?.source?.src) {
    return null;
  }

  return (
    <div
      className={cn(
        "absolute inset-0 w-full h-full",
        className
      )}
      style={{
        opacity: image.style?.opacity ? image.style.opacity / 100 : 1,
      }}
    >
      <img
        src={image.source.src}
        alt={image.source.alt || "Background"}
        className="w-full h-full object-cover"
      />
    </div>
  );
} 