/* eslint-disable @next/next/no-img-element */
import React from "react";
import * as z from "zod";
import { cn } from "@/lib/utils";
import {
  ObjectFitType,
  AlignmentType,
  MarginSize,
  ImageSchema,
  ContentImageSchema,
} from "../lib/validation/image-schema";
import { useSelectionContext } from "../lib/providers/selection-context";
import { getSlideNumber } from "../lib/field-path";
import { usePagerContext } from "../lib/providers/pager-context";
import { useFormContext } from "react-hook-form";
import {
  DocumentFormReturn,
  ElementFieldPath,
} from "@/lib/document-form-types";

const marginSizeToClasses = {
  [MarginSize.enum.Small]: "my-2",
  [MarginSize.enum.Medium]: "my-4",
  [MarginSize.enum.Large]: "my-8",
};

const alignmentToClasses = {
  [AlignmentType.enum.Left]: "justify-start",
  [AlignmentType.enum.Center]: "justify-center",
  [AlignmentType.enum.Right]: "justify-end",
};

export function ContentImage({
  fieldName,
  className,
}: {
  fieldName: ElementFieldPath;
  className?: string;
}) {
  const form: DocumentFormReturn = useFormContext();
  const { watch } = form;
  const image = watch(fieldName) as z.infer<typeof ContentImageSchema>;

  const { setCurrentPage } = usePagerContext();
  const { currentSelection, setCurrentSelection } = useSelectionContext();
  const pageNumber = getSlideNumber(fieldName);
  const source = image?.source?.src || "https://placehold.co/300x300";
  const style = image?.style || { opacity: 100, objectFit: "Cover", alignment: "Center", margin: "Medium" };

  // TODO: Convert to Toggle to make it accessible. Control with selection

  return (
    <div
      id={"content-image-" + fieldName}
      className={cn(
        "flex w-full outline-transparent rounded-md ring-offset-background",
        alignmentToClasses[style.alignment],
        marginSizeToClasses[style.margin],
        currentSelection == fieldName &&
          "outline-input ring-2 ring-offset-2 ring-ring",
        className
      )}
    >
      {/* // TODO: Extract to component */}
      <img
        alt="slide image"
        src={source}
        className={cn(
          "rounded-md overflow-hidden",
          style.objectFit == ObjectFitType.enum.Cover
            ? "object-cover w-full h-full"
            : style.objectFit == ObjectFitType.enum.Contain
            ? "object-contain w-fit h-fit"
            : ""
        )}
        style={{
          opacity: style.opacity / 100,
        }}
        onClick={(event) => {
          setCurrentPage(pageNumber);
          setCurrentSelection(fieldName, event);
        }}
      />
    </div>
  );
}
