"use client";

import { useComponentPrinter } from "./lib/hooks/use-component-printer";
import { MainNav } from "./main-nav";
import { SidebarPanel } from "./settings-panel";
import { SlidesEditor } from "./slides-editor";
import { RefProvider } from "./lib/providers/reference-context";
import { EditorHeader } from "./editor-header";

export default function Editor() {
  const { componentRef, handlePrint, isPrinting } = useComponentPrinter();

  return (
    <RefProvider myRef={componentRef}>
      <div className="relative flex min-h-screen flex-col">
        <header className="sticky top-0 z-40 w-full border-b bg-background/95">
          <div className="container">
            <MainNav handlePrint={handlePrint} isPrinting={isPrinting} className="h-14" />
          </div>
        </header>
        
        <div className="flex-1 flex flex-col">
          <div className="container">
            <EditorHeader />
          </div>
          
          <div className="container flex-1">
            <div className="relative flex flex-col md:grid md:grid-cols-[minmax(0,1fr)_280px] md:gap-6 lg:grid-cols-[minmax(0,1fr)_300px] lg:gap-10">
              <main className="flex-1 min-h-0">
                <SlidesEditor />
              </main>
              
              {/* The sidebar panel will handle its own visibility for desktop/mobile */}
              <SidebarPanel />
            </div>
          </div>
        </div>
      </div>
    </RefProvider>
  );
}
