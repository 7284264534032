import { z } from "zod";
import { ImageSchema } from "./image-schema";

// Define slide types
export const SlideType = z.enum(["Intro", "Content", "Outro", "Common"]);
export type SlideType = z.infer<typeof SlideType>;

// Define element types
export const ElementType = z.enum(["Title", "Subtitle", "Description", "ContentImage", "List", "Quote"]);
export type ElementType = z.infer<typeof ElementType>;

// Define background style schema
export const BackgroundStyleSchema = z.object({
  color: z.enum(["Theme", "Custom"]).default("Theme"),
  customColor: z.string().optional(),
  opacity: z.number().min(0).max(100).default(100),
});

// Define overlay schema
export const OverlaySchema = z.object({
  source: z.object({
    src: z.string().url().optional(),
    alt: z.string().default(""),
  }),
  style: z.object({
    opacity: z.number().min(0).max(100).default(100),
    position: z.enum(["Top", "Middle", "Bottom"]).default("Middle"),
  }),
});

// Define the style schema
const StyleSchema = z.object({
  fontSize: z.enum(["Small", "Medium", "Large"]).optional(),
  align: z.enum(["Left", "Center", "Right"]).optional(),
  margin: z.enum(["Small", "Medium", "Large"]).optional(),
  color: z.enum(["Primary", "Secondary", "Custom"]).optional(),
  width: z.enum(["Small", "Medium", "Large"]).optional(),
  customColor: z.string().optional(),
});

// Define the element schema without style
const UnstyledElementSchema = z.object({
  type: ElementType,
  content: z.string().default(""),
});

// Define the element schema with style
const ElementSchema = z.object({
  type: ElementType,
  content: z.string().default(""),
  style: StyleSchema,
});

// Define the slide schemas
export const UnstyledSlideSchema = z.object({
  elements: z.array(UnstyledElementSchema),
  type: SlideType.optional(),
  backgroundImage: ImageSchema.optional(),
  backgroundColor: BackgroundStyleSchema.optional(),
  overlays: z.array(OverlaySchema).optional(),
});

export const SlideSchema = z.object({
  elements: z.array(ElementSchema),
  type: SlideType.optional(),
  backgroundImage: ImageSchema.optional(),
  backgroundColor: BackgroundStyleSchema.optional(),
  overlays: z.array(OverlaySchema).default([]),
});

// Define the multi-slide schemas
export const UnstyledMultiSlideSchema = z.array(UnstyledSlideSchema);
export const MultiSlideSchema = z.array(SlideSchema);

// Export types
export type Style = z.infer<typeof StyleSchema>;
export type Element = z.infer<typeof ElementSchema>;
export type UnstyledElement = z.infer<typeof UnstyledElementSchema>;
export type Slide = z.infer<typeof SlideSchema>;
export type UnstyledSlide = z.infer<typeof UnstyledSlideSchema>;
export type MultiSlide = z.infer<typeof MultiSlideSchema>;
export type UnstyledMultiSlide = z.infer<typeof UnstyledMultiSlideSchema>;
export type BackgroundStyle = z.infer<typeof BackgroundStyleSchema>;
export type Overlay = z.infer<typeof OverlaySchema>; 