import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { generatePostHooks } from '@/services/contentGenerator';
import type { UserProfile, IdealClientProfile } from '@/types/profile';
import type { PostType } from '@/types/post';
import type { AIModel } from '@/services/deepseek';

interface HookGeneratorProps {
  userProfile: UserProfile;
  idealClientProfile: IdealClientProfile;
  postType: PostType;
  currentAIModel: AIModel;
  tones?: string[];
  onHookSelect: (hook: string) => void;
}

export function HookGenerator({
  userProfile,
  idealClientProfile,
  postType,
  currentAIModel,
  tones = ['professional'],
  onHookSelect
}: HookGeneratorProps) {
  const [isGenerating, setIsGenerating] = useState(false);
  const [hookTopic, setHookTopic] = useState('');
  const [hookSuggestions, setHookSuggestions] = useState<string[]>([]);

  const generateHooks = async (topic?: string) => {
    try {
      setIsGenerating(true);
      const hooks = await generatePostHooks(
        postType,
        userProfile,
        idealClientProfile,
        currentAIModel,
        tones,
        topic
      );
      setHookSuggestions(prev => {
        const combined = [...prev, ...hooks];
        return Array.from(new Set(combined));
      });
    } catch (error) {
      console.error('Error generating hooks:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="space-y-4">
      <Label className="text-lg font-semibold">Title/Hook</Label>
      <div className="flex gap-2 mb-4">
        <Input
          type="text"
          placeholder="Enter a topic to generate hooks for..."
          value={hookTopic}
          onChange={(e) => setHookTopic(e.target.value)}
          className="flex-1"
        />
        <Button
          onClick={() => generateHooks(hookTopic)}
          disabled={isGenerating}
          className="shrink-0"
        >
          Generate Hooks
        </Button>
      </div>
      {isGenerating ? (
        <div className="flex justify-center py-2">
          <div className="animate-spin h-6 w-6 border-2 border-primary border-t-transparent rounded-full" />
        </div>
      ) : hookSuggestions.length > 0 && (
        <div className="grid grid-cols-2 gap-2 max-h-[125px] overflow-y-auto pr-2">
          {hookSuggestions.map((hook, index) => (
            <button
              key={index}
              className="text-left p-3 rounded-lg border hover:bg-gray-50 focus:bg-gray-50 focus:outline-none transition-colors text-sm"
              onClick={() => onHookSelect(hook)}
            >
              {hook}
            </button>
          ))}
        </div>
      )}
    </div>
  );
} 