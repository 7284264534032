import { supabase } from '@/integrations/supabase/client';
import type { ContentPlanPost, GroupedContentPlan, ContentPlanInsert } from '../types/post';
import { addToCache } from '../utils/cache';

export async function saveContentPlan(userId: string, contentPlan: ContentPlanPost[], name: string) {
  try {
    if (!userId || !contentPlan || contentPlan.length === 0) {
      throw new Error('Invalid input: userId and contentPlan are required');
    }

    if (!name) {
      throw new Error('Invalid input: name is required');
    }

    const startDate = contentPlan.reduce((earliest, post) => {
      if (!post.suggestedDate) return earliest;
      return !earliest || post.suggestedDate < earliest ? post.suggestedDate : earliest;
    }, '');

    // Group posts by type and theme for storage
    const groupedPlan = contentPlan.reduce((acc, post) => {
      addToCache(post);
      
      const existingGroup = acc.find(group => 
        group.type === post.postType && group.theme === post.theme
      );
      
      if (existingGroup) {
        existingGroup.posts.push(post);
      } else {
        acc.push({
          type: post.postType,
          theme: post.theme,
          posts: [post]
        });
      }
      return acc;
    }, [] as GroupedContentPlan[]);

    const now = new Date().toISOString();
    const newPlan: ContentPlanInsert = {
      user_id: userId,
      plan: groupedPlan,
      created_at: now,
      updated_at: now,
      start_date: startDate || now,
      status: 'active',
      name: name
    };

    // First, deactivate any existing active plans
    const { error: deactivateError } = await supabase
      .from('content_plans')
      .update({ status: 'inactive' })
      .eq('user_id', userId)
      .eq('status', 'active');

    if (deactivateError) {
      console.error('Error deactivating existing plans:', deactivateError);
      throw deactivateError;
    }

    // Then insert the new plan
    const { error: insertError } = await supabase
      .from('content_plans')
      .insert(newPlan);

    if (insertError) {
      console.error('Error inserting new plan:', insertError);
      throw insertError;
    }

    return true;
  } catch (error) {
    console.error('Error saving content plan:', error);
    throw new Error('Failed to save content plan: ' + (error instanceof Error ? error.message : String(error)));
  }
}

export async function getLatestContentPlan(userId: string): Promise<GroupedContentPlan[] | null> {
  try {
    const { data, error } = await supabase
      .from('content_plans')
      .select(`
        id,
        plan,
        created_at,
        start_date
      `)
      .eq('user_id', userId)
      .eq('status', 'active')
      .order('created_at', { ascending: false })
      .limit(1);

    // Handle case where no data exists
    if (error?.code === 'PGRST116' || error?.message?.includes('JSON object requested') || !data || data.length === 0) {
      return null;
    }

    // If there's a different error, throw it
    if (error) {
      throw error;
    }

    return data[0]?.plan || null;
  } catch (error) {
    console.error('Error fetching content plan:', error);
    // Return null for any error related to no data existing
    if (error instanceof Error && 
        (error.message.includes('no rows') || 
         error.message.includes('No rows found') ||
         error.message.includes('JSON object requested'))) {
      return null;
    }
    throw new Error('Failed to fetch content plan: ' + error);
  }
} 