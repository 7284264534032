import { useState, useEffect, useRef } from 'react';
import { Button } from '@/components/ui/button';
import { Loader2, Upload, X, Edit2, Info, Download, History } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import ReactMarkdown from 'react-markdown';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Label } from '@/components/ui/label';
import { supabase } from '@/integrations/supabase/client';
import { Switch } from "@/components/ui/switch";
import { extractTextFromPDF } from '@/services/pdf';
import { Progress } from "@/components/ui/progress";
import type { IdealClientProfile } from '@/types/profile';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { UserProfileDialog } from './UserProfileDialog';
import { IdealClientProfileDialog } from './IdealClientProfileDialog';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import type { UserProfile } from '@/types/profile';
import { ProfileReviewsDialog } from './ProfileReviewsDialog';
import html2pdf from 'html2pdf.js';
import {
  analyzeProfile,
  analyzeAudience,
  suggestImprovements,
  rewriteAboutSection,
  saveProfileReview,
  setAIModel,
  getCurrentModel,
  type AIModel
} from '@/services/deepseek';
import { ModelSelector } from '@/components/ui/model-selector';

type ToneStyle = 'casual' | 'professional' | 'story' | 'impactful';

type StepStatus = "loading" | "complete" | "pending" | "error";

interface Step {
  name: string;
  status: StepStatus;
  result?: string;
}

export const ProfileOptimizer = () => {
  const [file, setFile] = useState<File | null>(null);
  const [extractedText, setExtractedText] = useState('');
  const [analysis, setAnalysis] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedTone, setSelectedTone] = useState<ToneStyle>('professional');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [progress, setProgress] = useState(0);
  const [idealClientProfile, setIdealClientProfile] = useState<IdealClientProfile | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const { toast } = useToast();
  const [userId, setUserId] = useState<string | null>(null);
  const [usageCount, setUsageCount] = useState<number>(0);
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [showUserProfileDialog, setShowUserProfileDialog] = useState(false);
  const [analysisMode, setAnalysisMode] = useState<'quick' | 'deep'>('quick');
  const [analysisSteps, setAnalysisSteps] = useState<Step[]>([
    { 
      name: 'Analyzing current profile', 
      status: 'pending', 
      result: '', 
    },
    { 
      name: 'Identifying target audience', 
      status: 'pending', 
      result: '', 
    },
    { 
      name: 'Suggesting improvements', 
      status: 'pending', 
      result: '', 
    }
  ]);
  const [showReviewsDialog, setShowReviewsDialog] = useState(false);
  const [showOptimizationOptions, setShowOptimizationOptions] = useState(false);
  const [initialAnalysisComplete, setInitialAnalysisComplete] = useState(false);
  const [currentAIModel, setCurrentAIModel] = useState<AIModel>(getCurrentModel());
  const [hasReviews, setHasReviews] = useState(false);

  useEffect(() => {
    const checkProfiles = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session?.user) {
        setUserId(session.user.id);
        const { data: profile } = await supabase
          .from('profiles')
          .select('is_subscribed, ideal_client_profile, user_profile, usage_count')
          .eq('id', session.user.id)
          .single();
        
        setIsSubscribed(!!profile?.is_subscribed);
        setIdealClientProfile(profile?.ideal_client_profile || null);
        setUserProfile(profile?.user_profile);
        setUsageCount(profile?.usage_count?.optimizer || 0);

        // Check if user has any reviews
        const { data: reviews } = await supabase
          .from('profile_reviews')
          .select('id')
          .eq('user_id', session.user.id)
          .limit(1);
        
        setHasReviews(reviews && reviews.length > 0);
      }
    };
    
    checkProfiles();
  }, []);

  const handleSignOut = async () => {
    await supabase.auth.signOut();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
      try {
        console.log('Extracting text from PDF:', selectedFile.name);
        const text = await extractTextFromPDF(selectedFile);
        console.log('Extracted text length:', text.length);
        console.log('First 100 chars:', text.substring(0, 100));
        setExtractedText(text);
        toast({
          title: "PDF Processed",
          description: "Text successfully extracted from PDF.",
        });
      } catch (error) {
        console.error('Error extracting text:', error);
        toast({
          title: "Error",
          description: "Failed to extract text from PDF",
          variant: "destructive",
        });
      }
    } else {
      toast({
        title: "Invalid file",
        description: "Please upload a PDF file",
        variant: "destructive",
      });
    }
  };

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
      setLoading(false);
      setProgress(0);
      toast({
        title: "Analysis cancelled",
        description: "Profile analysis has been cancelled.",
      });
    }
  };

  const handleSaveUserProfile = async (profile: UserProfile) => {
    try {
      console.log('Starting profile save process...');
      if (!userId) {
        console.error('No userId found');
        throw new Error('User not authenticated');
      }

      // Only save the description for now
      const profileData = {
        description: profile.description.trim()
      };

      console.log('Profile data to save:', profileData);

      // Update the state
      setUserProfile(profile);
      setShowUserProfileDialog(false);

      // First check if the profile exists
      const { data: existingProfile, error: fetchError } = await supabase
        .from('profiles')
        .select('id')
        .eq('id', userId)
        .single();

      let updateError;
      if (!existingProfile) {
        console.log('Creating new profile...');
        const insertResult = await supabase
          .from('profiles')
          .insert({
            id: userId,
            user_profile: profileData
          });
        updateError = insertResult.error;
        console.log('Insert result:', insertResult);
      } else {
        console.log('Updating existing profile...');
        const updateResult = await supabase
          .from('profiles')
          .update({
            user_profile: profileData
          })
          .eq('id', userId);
        updateError = updateResult.error;
        console.log('Update result:', updateResult);
      }

      if (updateError) {
        console.error('Save error:', updateError);
        throw updateError;
      }

      console.log('Profile saved successfully');
      toast({
        title: "Profile Updated",
        description: "Your profile has been saved successfully.",
      });
    } catch (error) {
      console.error('Error saving user profile:', error);
      toast({
        title: "Error",
        description: "Failed to save your profile. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleSaveProfile = async (profile: IdealClientProfile) => {
    try {
      if (!userId) throw new Error('User not authenticated');

      // Update the state
      setIdealClientProfile(profile);
      setShowProfileDialog(false);

      // Update the database
      const { error } = await supabase
        .from('profiles')
        .update({ ideal_client_profile: profile })
        .eq('id', userId);

      if (error) throw error;

      toast({
        title: "Target Audience Updated",
        description: "Your target audience profile has been saved successfully.",
      });
    } catch (error) {
      console.error('Error saving ideal client profile:', error);
      toast({
        title: "Error",
        description: "Failed to save your target audience profile. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleAnalyze = async () => {
    if (!extractedText) {
      toast({
        title: "No Content",
        description: "Please upload a PDF file first.",
        variant: "destructive",
      });
      return;
    }

    // Check usage limits
    if (!isSubscribed && usageCount >= 1) {
      toast({
        title: "Usage limit reached",
        description: "Free accounts are limited to one profile analysis. Please upgrade for unlimited analyses.",
        variant: "destructive",
        style: { backgroundColor: '#ff0000' },
      });
      return;
    }

    setLoading(true);
    setProgress(0);
    setInitialAnalysisComplete(false);
    setAnalysis('');
    
    // Reset analysis steps
    setAnalysisSteps(steps => steps.map(step => ({ ...step, status: 'pending', result: '' })));

    try {
      // Run all analysis steps concurrently
      const analysisPromises = [
        {
          name: 'Analyzing current profile',
          promise: analyzeProfile(extractedText)
        },
        {
          name: 'Identifying target audience',
          promise: analyzeAudience(extractedText)
        },
        {
          name: 'Suggesting improvements',
          promise: suggestImprovements(extractedText)
        }
      ];

      let completedSteps = 0;
      const results = await Promise.allSettled(
        analysisPromises.map(async ({ name, promise }) => {
          try {
            setAnalysisSteps(steps => 
              steps.map(step => 
                step.name === name ? { ...step, status: 'loading' } : step
              )
            );

            const result = await promise;
            
            // Save profiles immediately after analysis
            if (userId) {
              if (name === 'Analyzing current profile') {
                const updatedProfile: UserProfile = {
                  description: result,
                  expertise: userProfile?.expertise || [],
                  interests: userProfile?.interests || [],
                  background: userProfile?.background || '',
                  goals: userProfile?.goals || []
                };
                setUserProfile(updatedProfile);
                await supabase
                  .from('profiles')
                  .update({ user_profile: updatedProfile })
                  .eq('id', userId);
              } else if (name === 'Identifying target audience') {
                const updatedProfile: IdealClientProfile = {
                  description: result,
                  demographics: idealClientProfile?.demographics || '',
                  interests: idealClientProfile?.interests || [],
                  challenges: idealClientProfile?.challenges || [],
                  goals: idealClientProfile?.goals || []
                };
                setIdealClientProfile(updatedProfile);
                await supabase
                  .from('profiles')
                  .update({ ideal_client_profile: updatedProfile })
                  .eq('id', userId);
              }
            }
            
            setAnalysisSteps(steps => 
              steps.map(step => 
                step.name === name ? { ...step, status: 'complete', result } : step
              )
            );

            completedSteps++;
            setProgress((completedSteps / analysisPromises.length) * 100);
            
            return { name, result };
          } catch (error) {
            console.error(`Error in ${name}:`, error);
            setAnalysisSteps(steps => 
              steps.map(step => 
                step.name === name ? { ...step, status: 'error' } : step
              )
            );
            throw error;
          }
        })
      );

      // Check if all steps completed successfully
      const allSuccessful = results.every(result => result.status === 'fulfilled');
      if (!allSuccessful) {
        throw new Error('Not all analysis steps completed successfully');
      }

      // Combine results
      const combinedAnalysis = results
        .filter((result): result is PromiseFulfilledResult<{name: string; result: string}> => 
          result.status === 'fulfilled'
        )
        .map(result => result.value.result)
        .join('\n\n');

      setAnalysis(combinedAnalysis);

      // Save the analysis if user is logged in
      if (userId) {
        await saveProfileReview(userId, combinedAnalysis);
        console.log('Analysis saved to profile');

        // Update usage count if not subscribed
        if (!isSubscribed) {
          const { data: currentProfile } = await supabase
            .from('profiles')
            .select('usage_count')
            .eq('id', userId)
            .single();

          const currentCounts = currentProfile?.usage_count || {};
          
          const { error: updateError } = await supabase
            .from('profiles')
            .update({
              usage_count: {
                ...currentCounts,
                optimizer: ((currentCounts.optimizer || 0) + 1)
              }
            })
            .eq('id', userId);

          if (updateError) throw updateError;
          setUsageCount(prev => prev + 1);
        }
      }

      setInitialAnalysisComplete(true);
      setShowOptimizationOptions(true);

      toast({
        title: "Analysis Complete",
        description: "Your profile has been analyzed and saved. You can now optimize your About section.",
      });
    } catch (error) {
      console.error('Error analyzing profile:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to analyze profile. Please try again.",
        variant: "destructive",
      });
      setInitialAnalysisComplete(false);
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  const downloadCurrentAnalysis = async () => {
    const element = document.getElementById('current-analysis');
    if (!element) return;

    const opt = {
      margin: 1,
      filename: `profile-analysis-${new Date().toISOString().split('T')[0]}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    try {
      await html2pdf().set(opt).from(element).save();
    } catch (error) {
      console.error('Error generating PDF:', error);
      toast({
        title: "Error",
        description: "Failed to download PDF. Please try again.",
        variant: "destructive",
      });
    }
  };

  // Update the formatSuggestions function with colorful cards
  const formatSuggestions = (text: string) => {
    // Define card colors
    const cardColors = {
      'Professional Story': 'bg-blue-50 hover:bg-blue-100',
      'Skills and Expertise': 'bg-purple-50 hover:bg-purple-100',
      'Engagement': 'bg-green-50 hover:bg-green-100',
      'Call to Action': 'bg-amber-50 hover:bg-amber-100'
    };

    // Split the text into sections based on double newlines
    const sections = text.split('\n\n');
    let formattedText = '<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">';

    sections.forEach(section => {
      if (section.startsWith('##') || !section.includes('-')) {
        const header = section.replace('##', '').trim();
        if (header === 'Suggesting improvements') return; // Skip the main title
        
        // Get the background color for this card, default to white if not found
        const bgColor = cardColors[header] || 'bg-white hover:bg-gray-50';
        
        // Start a new card for each section
        formattedText += `
          <div class="${bgColor} rounded-lg p-4 shadow-sm transition-all duration-200">
            <h3 class="text-lg font-semibold text-gray-900 mb-3 pb-2 border-b border-gray-200">${header}</h3>
            <div class="space-y-2">
        `;

        // Find the next section that contains the bullet points for this header
        const nextSection = sections[sections.indexOf(section) + 1];
        if (nextSection && !nextSection.startsWith('##')) {
          const points = nextSection.split('\n');
          points.forEach(point => {
            if (point.trim().startsWith('-')) {
              const [label, ...contentParts] = point.substring(1).split(':');
              const content = contentParts.join(':').trim();
              if (content) {
                formattedText += `
                  <div class="mb-2">
                    <span class="font-medium text-gray-900">${label.trim()}:</span>
                    <p class="text-gray-700 mt-1 text-sm">${content}</p>
                  </div>
                `;
              }
            }
          });
        }

        formattedText += `
            </div>
          </div>
        `;
      }
    });

    formattedText += '</div>';
    return formattedText;
  };

  // Update the handleEditAnalysis function to just open dialogs
  const handleEditAnalysis = async (step: Step) => {
    try {
      if (!userId) throw new Error('User not authenticated');

      if (step.name === 'Analyzing current profile') {
        setShowUserProfileDialog(true);
      } else if (step.name === 'Identifying target audience') {
        setShowProfileDialog(true);
      }
    } catch (error) {
      console.error('Error opening dialog:', error);
      toast({
        title: "Error",
        description: "Failed to open edit dialog. Please try again.",
        variant: "destructive",
      });
    }
  };

  // Update the renderAnalysisResults function
  const renderAnalysisResults = () => {
    if (!analysisSteps.some(step => step.status !== 'pending')) {
      return null;
    }

    const isAnalysisComplete = analysisSteps.every(step => step.status === 'complete');
    const profileSteps = analysisSteps.slice(0, 2); // Profile and Audience analysis
    const suggestionStep = analysisSteps[2]; // Improvement suggestions

    const formatMarkdownText = (text: string) => {
      // Ensure paragraphs are properly separated with double newlines
      return text.replace(/\n/g, '\n\n').trim();
    };

    return (
      <div id="current-analysis" className="glass-card p-4 sm:p-6 rounded-lg space-y-4 animate-fade-up">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
          <h3 className="text-lg sm:text-xl font-semibold text-gray-900">Analysis Results</h3>
          <div className="flex gap-2 w-full sm:w-auto">
            <Button
              variant="outline"
              size="sm"
              onClick={downloadCurrentAnalysis}
              disabled={!isAnalysisComplete}
              className="gap-2 w-full sm:w-auto"
            >
              <Download className="h-4 w-4" />
              {isAnalysisComplete ? 'Download PDF' : 'Analysis in Progress...'}
            </Button>
          </div>
        </div>

        {/* Profile and Audience Analysis Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
          {profileSteps.map((step, index) => (
            <div key={index} className="space-y-4">
              <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-2">
                  <h3 className="text-lg font-semibold">{step.name}</h3>
                  {step.status === ('loading' as StepStatus) && (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  )}
                </div>
                {step.status === ('complete' as StepStatus) && (
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handleEditAnalysis(step)}
                  >
                    Edit
                  </Button>
                )}
              </div>
              {step.status === ('complete' as StepStatus) && (
                <div className="rounded-lg bg-white p-4 shadow prose prose-sm max-w-none">
                  <ReactMarkdown
                    components={{
                      // Add extra spacing between paragraphs
                      p: ({ children }) => <p className="mb-4">{children}</p>,
                    }}
                  >
                    {formatMarkdownText(step.result || '')}
                  </ReactMarkdown>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Suggestions Section */}
        {suggestionStep.status === ('complete' as StepStatus) && (
          <div className="mt-6">
            <div className="bg-white rounded-lg p-6 space-y-4">
              <div className="flex items-center gap-2 mb-4">
                <h4 className="text-lg sm:text-xl font-semibold text-gray-900">Profile Improvement Suggestions</h4>
                {suggestionStep.status === ('loading' as StepStatus) && (
                  <Loader2 className="h-4 w-4 animate-spin" />
                )}
              </div>
              <div 
                className="prose prose-sm max-w-none"
                dangerouslySetInnerHTML={{ __html: formatSuggestions(suggestionStep.result || '') }}
              />
            </div>
          </div>
        )}

        {/* Optimization Section */}
        {isAnalysisComplete && (
          <div className="mt-6 space-y-6">
            <div className="border-t pt-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Optimize Your About Section</h3>
              
              {renderToneSelector()}
              
              <div className="mt-6">
                <Label htmlFor="additional-details" className="text-base font-medium">
                  Additional Details
                </Label>
                <p className="text-sm text-gray-600 mb-2">
                  Add any specific achievements, experiences, or details you'd like to highlight
                </p>
                <Textarea
                  id="additional-details"
                  placeholder="Enter any specific details you'd like to include..."
                  value={additionalDetails}
                  onChange={(e) => setAdditionalDetails(e.target.value)}
                  className="min-h-[100px]"
                />
              </div>

              <Button
                onClick={handleOptimize}
                disabled={loading || !selectedTone}
                className="w-full mt-4"
                size="lg"
              >
                {loading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Generating Optimized Version...
                  </>
                ) : (
                  'Generate Optimized About Section'
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderToneSelector = () => (
    <div className="space-y-4">
      <h3 className="text-base sm:text-lg font-semibold text-gray-900">Select Your Preferred Tone</h3>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4">
        <Button
          variant={selectedTone === 'casual' ? 'default' : 'outline'}
          onClick={() => setSelectedTone('casual')}
          className="w-full text-sm sm:text-base py-2"
        >
          Casual
        </Button>
        <Button
          variant={selectedTone === 'professional' ? 'default' : 'outline'}
          onClick={() => setSelectedTone('professional')}
          className="w-full text-sm sm:text-base py-2"
        >
          Professional
        </Button>
        <Button
          variant={selectedTone === 'story' ? 'default' : 'outline'}
          onClick={() => setSelectedTone('story')}
          className="w-full text-sm sm:text-base py-2"
        >
          Story
        </Button>
        <Button
          variant={selectedTone === 'impactful' ? 'default' : 'outline'}
          onClick={() => setSelectedTone('impactful')}
          className="w-full text-sm sm:text-base py-2"
        >
          Impactful
        </Button>
      </div>
    </div>
  );

  const handleOptimize = async () => {
    if (!extractedText || !selectedTone) {
      toast({
        title: "Missing Information",
        description: "Please select a tone before generating the optimized version.",
        variant: "destructive",
      });
      return;
    }

    setLoading(true);
    setProgress(0);

    try {
      const result = await rewriteAboutSection(extractedText, selectedTone, additionalDetails);

      if (!result || !result.trim()) {
        throw new Error('Failed to generate optimized version');
      }

      // Create a new container element
      const container = document.createElement('div');
      container.className = 'glass-card p-4 sm:p-6 rounded-lg space-y-4 animate-fade-up mt-6';
      
      // Create the optimized content
      const content = document.createElement('div');
      content.className = 'prose prose-sm max-w-none bg-white rounded-lg p-4';
      content.innerHTML = `
        <h3 class="text-lg sm:text-xl font-semibold text-gray-900 mb-4">Your Optimized About Section</h3>
        <div class="text-gray-700">${result}</div>
      `;
      
      container.appendChild(content);
      
      // Replace any existing optimized section
      const existingOptimized = document.getElementById('optimized-section');
      if (existingOptimized) {
        existingOptimized.replaceWith(container);
      } else {
        const analysisResults = document.getElementById('current-analysis');
        if (analysisResults) {
          analysisResults.parentNode?.insertBefore(container, analysisResults.nextSibling);
        }
      }

      toast({
        title: "Profile Optimized",
        description: "Your new About section has been generated successfully.",
      });
    } catch (error) {
      console.error('Error optimizing profile:', error);
      toast({
        title: "Error",
        description: "Failed to optimize profile. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleModelChange = (model: AIModel) => {
    setAIModel(model);
    setCurrentAIModel(model);
    toast({
      title: "Model Changed",
      description: `Switched to ${model}`,
    });
  };

  const renderAdditionalDetails = () => {
    if (!additionalDetails) return null;
    return (
      <div className="mt-4">
        <h4 className="text-sm font-medium">Additional Details:</h4>
        <p className="text-sm text-gray-600">{additionalDetails}</p>
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 sm:px-12 md:px-16 lg:px-24 max-w-6xl pt-12 pb-12 ">
      <div className="text-center py-6 sm:py-8 pt-20">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 mb-4">LinkedIn About Section Optimizer</h1>
        <p className="text-base sm:text-lg text-gray-600 max-w-2xl mx-auto">
          Create a compelling LinkedIn About section that engages your audience and highlights your expertise
        </p>
        <div className="flex flex-col sm:flex-row justify-center gap-3 mt-6">
          <Button
            variant="outline"
            size="lg"
            onClick={() => setShowUserProfileDialog(true)}
            className="gap-2"
          >
            <Edit2 className="h-4 w-4" />
            Edit Your Profile
          </Button>
          <Button
            variant="outline"
            size="lg"
            onClick={() => setShowProfileDialog(true)}
            className="gap-2"
          >
            <Edit2 className="h-4 w-4" />
            Edit Target Audience
          </Button>
          {hasReviews && (
            <Button
              variant="outline"
              size="lg"
              onClick={() => setShowReviewsDialog(true)}
              className="gap-2"
            >
              <History className="h-4 w-4" />
              Previous Recommendations
            </Button>
          )}
        </div>

        {/* Model Selection */}
        <ModelSelector
          currentModel={currentAIModel}
          onModelChange={handleModelChange}
          className="mt-4"
        />
      </div>

      <div className="mt-8 space-y-6">
        <div className="glass-card p-4 sm:p-6 rounded-lg space-y-4">
          <div className="flex items-center justify-between flex-wrap gap-2">
            <h3 className="text-lg font-semibold text-gray-900">Upload Your LinkedIn Profile PDF</h3>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button variant="ghost" size="icon">
                      <Info className="h-4 w-4" />
                </Button>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-[300px] p-4">
                    <p className="text-sm">To download your LinkedIn Profile as PDF:</p>
                    <ol className="text-sm mt-2 space-y-1 list-decimal list-inside">
                      <li>Go to your LinkedIn profile</li>
                      <li>Click the "More" button below your profile header</li>
                      <li>Select "Save to PDF"</li>
                      <li>Upload the downloaded PDF here</li>
                    </ol>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            <div className="flex flex-col space-y-4">
            <div 
              className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-gray-400 transition-colors"
              onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDrop={(e: React.DragEvent<HTMLDivElement>) => {
                e.preventDefault();
                e.stopPropagation();
                const file = e.dataTransfer.files[0];
                if (file && file.type === 'application/pdf') {
                  const input = document.createElement('input');
                  input.type = 'file';
                  const dataTransfer = new DataTransfer();
                  dataTransfer.items.add(file);
                  input.files = dataTransfer.files;
                  handleFileChange({ target: input } as React.ChangeEvent<HTMLInputElement>);
                } else {
                  toast({
                    title: "Invalid file",
                    description: "Please upload a PDF file",
                    variant: "destructive",
                  });
                }
              }}
            >
                  <input
                type="file"
                    id="file-upload"
                    className="hidden"
                    accept="application/pdf"
                    onChange={handleFileChange}
                  />
              <label htmlFor="file-upload" className="cursor-pointer">
                <div className="flex flex-col items-center">
                  <Upload className="h-12 w-12 text-gray-400" />
                  <p className="mt-2 text-sm text-gray-600">
                    {file ? file.name : 'Drag and drop your PDF here, or click to browse'}
                  </p>
                  <p className="text-xs text-gray-500 mt-1">PDF files only</p>
                </div>
                </label>
              </div>
              
              {loading && (
                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-600">Analyzing profile...</span>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={handleCancel}
                      className="text-gray-600 hover:text-red-600"
                    >
                      <X className="h-4 w-4 mr-2" />
                      Cancel
                    </Button>
                  </div>
                  <Progress value={progress} className="w-full" />
                </div>
              )}

            {!loading && file && (
                <Button 
                  onClick={handleAnalyze}
                  disabled={!file || loading}
                  className="w-full"
                size="lg"
                >
                  {loading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Analyzing...
                    </>
                  ) : (
                    'Analyze Profile'
                  )}
                </Button>
              )}
            </div>
          </div>

          {renderAnalysisResults()}

        

      
      </div>

      <UserProfileDialog
        open={showUserProfileDialog}
        onOpenChange={setShowUserProfileDialog}
        onSave={handleSaveUserProfile}
        existingProfile={userProfile}
      />

      <IdealClientProfileDialog
        open={showProfileDialog}
        onOpenChange={setShowProfileDialog}
        onSave={handleSaveProfile}
        existingProfile={idealClientProfile}
      />

      {hasReviews && (
        <ProfileReviewsDialog
          open={showReviewsDialog}
          onOpenChange={setShowReviewsDialog}
          userId={userId}
        />
      )}
    </div>
  );
};
