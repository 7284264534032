import { useFormContext } from "react-hook-form";
import * as z from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { RadioGroup } from "../ui/radio-group";
import { CustomIndicatorRadioGroupItem } from "../custom-indicator-radio-group-item";
import { ColorThemeDisplay } from "../color-theme-display";
import { DocumentFormReturn } from "../lib/document-form-types";
import { SizeSelector } from "../size-selector";
import { Separator } from "../ui/separator";

type ColorPalette = {
  name: string;
  primary: string;
  secondary: string;
  background: string;
};

const presetPalettes = {
  modern: {
    name: "Modern",
    primary: "#2563eb",
    secondary: "#f97316",
    background: "#ffffff"
  },
  elegant: {
    name: "Elegant",
    primary: "#18181b",
    secondary: "#a1a1aa",
    background: "#fafafa"
  },
  nature: {
    name: "Nature",
    primary: "#15803d",
    secondary: "#854d0e",
    background: "#f7fee7"
  },
  ocean: {
    name: "Ocean",
    primary: "#0369a1",
    secondary: "#0d9488",
    background: "#f0fdfa"
  },
  sunset: {
    name: "Sunset",
    primary: "#b91c1c",
    secondary: "#c2410c",
    background: "#fff7ed"
  },
  lavender: {
    name: "Lavender",
    primary: "#7c3aed",
    secondary: "#c084fc",
    background: "#faf5ff"
  },
  midnight: {
    name: "Midnight",
    primary: "#312e81",
    secondary: "#4f46e5",
    background: "#eef2ff"
  },
  forest: {
    name: "Forest",
    primary: "#166534",
    secondary: "#65a30d",
    background: "#f0fdf4"
  },
  // New dark themes
  darkModern: {
    name: "Dark Modern",
    primary: "#60a5fa",
    secondary: "#f97316",
    background: "#1f2937"
  },
  darkElegant: {
    name: "Dark Elegant",
    primary: "#e2e8f0",
    secondary: "#94a3b8",
    background: "#0f172a"
  },
  darkNature: {
    name: "Dark Nature",
    primary: "#4ade80",
    secondary: "#fbbf24",
    background: "#064e3b"
  },
  darkOcean: {
    name: "Dark Ocean",
    primary: "#38bdf8",
    secondary: "#2dd4bf",
    background: "#164e63"
  },
  darkSunset: {
    name: "Dark Sunset",
    primary: "#f87171",
    secondary: "#fb923c",
    background: "#18181b"
  }
} as const;

type PaletteKey = keyof typeof presetPalettes;

function ColorSelectors({ form }: { form: DocumentFormReturn }) {
  const { control } = form;

  return (
    <div className="grid grid-cols-3 gap-4 mb-6">
      <FormField
        control={control}
        name="config.theme.primary"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Primary</FormLabel>
            <FormControl>
              <Input 
                type="color" 
                className="h-10 w-full cursor-pointer rounded-full overflow-hidden" 
                {...field} 
              />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="config.theme.secondary"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Secondary</FormLabel>
            <FormControl>
              <Input 
                type="color" 
                className="h-10 w-full cursor-pointer rounded-full overflow-hidden" 
                {...field} 
              />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="config.theme.background"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Background</FormLabel>
            <FormControl>
              <Input 
                type="color" 
                className="h-10 w-full cursor-pointer rounded-full overflow-hidden" 
                {...field} 
              />
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  );
}

function PalletteSelector({ form }: { form: DocumentFormReturn }) {
  const { control, setValue } = form;

  return (
    <FormField
      control={control}
      name="config.theme.pallette"
      render={({ field }) => (
        <FormItem className="space-y-3">
          <FormLabel>Preset Palettes</FormLabel>
          <FormControl>
            <RadioGroup
              onValueChange={(value: PaletteKey) => {
                const colors = presetPalettes[value];
                setValue("config.theme.primary", colors.primary);
                setValue("config.theme.secondary", colors.secondary);
                setValue("config.theme.background", colors.background);
                setValue("config.theme.pallette", value);
                // Trigger form updates
                form.trigger("config.theme.primary");
                form.trigger("config.theme.secondary");
                form.trigger("config.theme.background");
              }}
              defaultValue={field.value}
              className="grid grid-cols-4 gap-4"
            >
              {(Object.entries(presetPalettes) as [PaletteKey, ColorPalette][]).map(([paletteName, colors]) => (
                <FormItem
                  className="flex flex-col items-center space-y-2"
                  key={paletteName}
                >
                  <FormControl>
                    <CustomIndicatorRadioGroupItem 
                      value={paletteName}
                      className="border-0 hover:bg-accent/10 data-[state=checked]:bg-accent/20"
                    >
                      <ColorThemeDisplay colors={colors} />
                    </CustomIndicatorRadioGroupItem>
                  </FormControl>
                  <FormLabel className="font-normal text-sm text-muted-foreground">
                    {colors.name}
                  </FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function ThemeForm() {
  const form = useFormContext<DocumentFormReturn>();

  return (
    <div className="space-y-6 px-2">
      <div className="space-y-4">
        <div className="space-y-2">
          <h3 className="text-lg font-medium">Size</h3>
          <SizeSelector />
        </div>
        <Separator />
        <div className="space-y-2">
          <h3 className="text-lg font-medium">Colors</h3>
          <ColorSelectors form={form} />
          <PalletteSelector form={form} />
        </div>
      </div>
    </div>
  );
}
