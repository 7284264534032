import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { MessageSquare, ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { useFeedback } from '@/hooks/useFeedback';

export default function CanceledSubscription() {
  const navigate = useNavigate();
  const { openFeedback } = useFeedback();

  return (
    <div className="min-h-screen bg-gradient-to-b from-sage-50 to-white pt-20">
      <div className="max-w-3xl mx-auto px-4 py-16">
        <motion.div
          className="text-center space-y-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className="text-3xl font-bold mb-4">
            We're Sorry to See You Go
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            We understand that every professional has unique needs. We'd love to hear your feedback to make MedicalPost better for healthcare professionals like you.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <MessageSquare className="h-8 w-8 text-primary mb-4" />
              <h3 className="text-lg font-semibold mb-2">Share Your Thoughts</h3>
              <p className="text-gray-600 mb-4">
                Help us understand what we could improve. Your feedback is valuable to us.
              </p>
              <Button 
                variant="outline" 
                className="w-full"
                onClick={openFeedback}
              >
                Provide Feedback
              </Button>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-sm">
              <ArrowRight className="h-8 w-8 text-primary mb-4" />
              <h3 className="text-lg font-semibold mb-2">Changed Your Mind?</h3>
              <p className="text-gray-600 mb-4">
                You can still upgrade to Pro and start saving hours every week.
              </p>
              <Button 
                className="w-full bg-primary hover:bg-primary/90"
                onClick={() => navigate('/pro')}
              >
                Continue to Pro
              </Button>
            </div>
          </div>

          <div className="text-center">
            <Button
              variant="ghost"
              onClick={() => navigate('/dashboard')}
              className="text-gray-600"
            >
              Return to Dashboard
            </Button>
          </div>
        </motion.div>
      </div>
    </div>
  );
} 