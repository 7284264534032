import { makeAIRequest, type AIModel } from '@/services/deepseek';
import { POST_LENGTH_WORDS } from '@/constants/post-creator';
import type { PostContent } from '@/types/post';
import { examplePosts } from '@/data/example-posts';
import { supabase } from '@/integrations/supabase/client';

export async function generatePostContent(
  post: PostContent,
  currentAIModel: AIModel
): Promise<string> {
  const wordCount = POST_LENGTH_WORDS[post.type][post.postLength];
  
  // Get language from profiles table for current user
  const { data: { session } } = await supabase.auth.getSession();
  if (!session?.user?.id) {
    throw new Error('No authenticated user found');
  }

  const { data: profileData } = await supabase
    .from('profiles')
    .select('language')
    .eq('id', session.user.id)
    .single();

  const language = profileData?.language || 'en-US';
  const isUK = language === 'en-GB';

  // Get only 1 relevant example to reduce token usage
  const relevantExample = examplePosts
    .find(example => example.type === post.type)?.content || '';

  // Construct a more concise prompt
  const prompt = `Create a professional LinkedIn post for the medical field:

Key Details:
- Type: ${post.type}
- Hook: ${post.title || 'Generate engaging hook (max 80 chars)'}
- Tone: ${post.tones.join(', ')}
- Audience: ${post.targetAudience}
- Length: ~${wordCount} words
- Language: ${isUK ? 'British' : 'American'} English
${post.content ? `- Base Content: ${post.content}` : ''}

Example Post Structure:
${relevantExample}

Content Requirements:
1. Start with a compelling medical insight
2. Focus on ONE clear concept
3. Use ${post.targetAudience === 'peers' ? 'technical medical' : 'accessible'} language
4. Include specific examples or research
5. Maintain professional credibility
${post.selectedImages?.length ? `6. Include [IMAGE] placeholders for ${post.selectedImages.length} images` : ''}

Format:
- Only Return post content, no other text
- Double line breaks between paragraphs
- Short paragraphs (1-3 sentences)
- No emojis or hashtags
- Natural breaks for readability
${post.type === 'text' ? '- Maximum 175 words' : ''}

Ensure content is accurate, relevant to ${post.targetAudience}, and follows LinkedIn best practices.`;

  const systemPrompt = `You are an expert medical content creator specializing in professional LinkedIn posts for healthcare professionals. Write in ${isUK ? 'British' : 'American'} English with appropriate medical terminology and healthcare communication best practices.`;
  
  try {
    const generatedContent = await makeAIRequest(prompt, systemPrompt);

    if (!generatedContent) {
      throw new Error('No content generated');
    }

    // Replace image placeholders with actual markdown images
    let cleanContent = generatedContent;
    if (post.selectedImages?.length) {
      post.selectedImages.forEach((image, index) => {
        const placeholder = '[IMAGE]';
        if (cleanContent.includes(placeholder)) {
          cleanContent = cleanContent.replace(
            placeholder,
            `\n\n![${image.tags}](${image.largeImageURL})\n\n`
          );
        } else if (index === 0) {
          cleanContent += `\n\n![${image.tags}](${image.largeImageURL})`;
        }
      });
    }

    return cleanContent;
  } catch (error) {
    console.error('Error generating content:', error);
    throw new Error('Failed to generate content. The prompt might be too long or the service might be temporarily unavailable.');
  }
} 