import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "../../ui/input";
import { convertFileToDataUrl } from "../../lib/convert-file";
import {
  DocumentFormReturn,
  ImageSourceFieldPath,
} from "../../lib/document-form-types";
import imageCompression from "browser-image-compression";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { ImageInputType } from "../../lib/validation/image-schema";
import { useEffect, useState } from "react";
import { searchPixabayImages } from "@/services/imageSearch";
import type { PixabayImage } from "@/types/post";
import { Label } from "@/components/ui/label";

export const MAX_IMAGE_SIZE_MB = 0.5; // Set your maximum image size limit in megabytes
export const MAX_IMAGE_WIDTH = 800; // Set your maximum image width

export function ImageSourceFormField({
  fieldName,
  form,
}: {
  fieldName: ImageSourceFieldPath;
  form: DocumentFormReturn;
}) {
  const [tabValue, setTabValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<PixabayImage[]>([]);

  const imageType = form.getValues(`${fieldName}.type`);
  useEffect(() => {
    // Use effect is needed to have consistent rendering of same defaults state on server and client. Then the client sets its tab selection
    setTabValue(imageType);
  }, [imageType]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchQuery) {
        handleSearch(searchQuery);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const handleSearch = async (query: string) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    try {
      const results = await searchPixabayImages(query);
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching images:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const handlePixabayImageSelect = (image: PixabayImage) => {
    form.setValue(`${fieldName}.type`, ImageInputType.Url);
    form.setValue(`${fieldName}.src`, image.largeImageURL);
  };

  return (
    <Tabs
      onValueChange={(tabValue) => {
        form.setValue(fieldName, { type: tabValue as ImageInputType, src: "" });
        setTabValue(tabValue);
        // Clear search results when changing tabs
        if (tabValue !== ImageInputType.Pixabay) {
          setSearchQuery("");
          setSearchResults([]);
        }
      }}
      value={tabValue}
      defaultValue={tabValue}
      className="w-full"
    >
      <TabsList className="grid w-full grid-cols-3">
        <TabsTrigger value={ImageInputType.Url}>URL</TabsTrigger>
        <TabsTrigger value={ImageInputType.Upload}>Upload</TabsTrigger>
        <TabsTrigger value={ImageInputType.Pixabay}>Pixabay</TabsTrigger>
      </TabsList>
      <TabsContent value={ImageInputType.Url}>
        <FormField
          control={form.control}
          name={`${fieldName}.src`}
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>{""}</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Url to an image"
                    className="resize-none"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
      </TabsContent>
      <TabsContent value={ImageInputType.Upload}>
        <FormField
          control={form.control}
          name={`${fieldName}.src`}
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>{""}</FormLabel>
                <FormControl>
                  <Input
                    accept=".jpg, .jpeg, .png, .svg, .webp"
                    type="file"
                    onChange={async (e) => {
                      const file = e.target?.files ? e.target?.files[0] : null;

                      if (file) {
                        // Check image dimensions
                        // const image = new Image();
                        // image.src = URL.createObjectURL(file);
                        // await image.decode(); // Wait for image to load
                        // if (image.width > MAX_IMAGE_WIDTH) {
                        //   console.log(
                        //     `Image width exceeds the maximum limit of ${MAX_IMAGE_WIDTH} pixels.`
                        //   );
                        //   return;
                        // }
                        const compressedFile = await imageCompression(file, {
                          maxSizeMB: MAX_IMAGE_SIZE_MB,
                          maxWidthOrHeight: MAX_IMAGE_WIDTH,
                        });
                        const dataUrl = await convertFileToDataUrl(
                          compressedFile
                        );
                        field.onChange(dataUrl ? dataUrl : "");
                      } else {
                        console.error("No valid image file selected.");
                      }
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
      </TabsContent>
      <TabsContent value={ImageInputType.Pixabay} className="space-y-4">
        <div>
          <Label>Search Pixabay Images</Label>
          <Input
            type="text"
            placeholder="Search for images..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="mt-2"
          />
        </div>

        {isSearching && (
          <div className="flex justify-center py-4">
            <div className="animate-spin h-6 w-6 border-2 border-primary border-t-transparent rounded-full" />
          </div>
        )}

        {searchResults.length > 0 && (
          <div className="grid grid-cols-3 gap-2 max-h-[300px] overflow-y-auto rounded-lg border p-2">
            {searchResults.map((image) => (
              <div
                key={image.id}
                className="relative cursor-pointer rounded-lg overflow-hidden border hover:border-primary transition-colors"
                onClick={() => handlePixabayImageSelect(image)}
              >
                <img
                  src={image.previewURL}
                  alt={image.tags}
                  className="w-full h-24 object-cover"
                />
                <div className="absolute inset-0 bg-black/0 hover:bg-black/20 transition-colors" />
              </div>
            ))}
          </div>
        )}
      </TabsContent>
    </Tabs>
  );
}
