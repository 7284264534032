export interface AboutSection {
  aboutSection: {
    industryChallenge: string;
    currentSolutions: string;
    successVision: string;
    expertise: string[];
    credentials: string;
    callToAction: string;
  };
}

export const rewriteAboutPrompt = `Create a compelling LinkedIn About section and return a valid JSON object with the following structure. Do not include any text outside the JSON object.

{
  "aboutSection": {
    "industryChallenge": "Example: Healthcare faces unprecedented staffing challenges",
    "currentSolutions": "Example: Traditional recruitment methods aren't meeting demands",
    "successVision": "Example: Imagine a healthcare system with optimal staffing levels",
    "expertise": [
      "Example: Healthcare workforce optimization",
      "Example: Process automation and efficiency",
      "Example: Staff retention strategies"
    ],
    "credentials": "Example: FACHE certified, MBA Healthcare Administration",
    "callToAction": "Example: Let's discuss your healthcare staffing challenges"
  }
}

RULES:
- No salesy language (discover, skyrocket, revolutionize, etc.)
- Natural language
- First person
- No emojis
- Not CV-style
- Total content should be under 300 words
- Ensure the response is valid JSON`;

export function validateAboutSection(json: unknown): json is AboutSection {
  if (!json || typeof json !== 'object') return false;
  const analysis = json as AboutSection;
  
  return !!(
    analysis.aboutSection?.industryChallenge &&
    analysis.aboutSection?.currentSolutions &&
    analysis.aboutSection?.successVision &&
    Array.isArray(analysis.aboutSection?.expertise) &&
    analysis.aboutSection?.credentials &&
    analysis.aboutSection?.callToAction
  );
}

export function formatAboutSection(about: AboutSection): string {
  return `${about.aboutSection.industryChallenge}\n\n` +
    `${about.aboutSection.currentSolutions}\n\n` +
    `${about.aboutSection.successVision}\n\n` +
    `Key expertise:\n${about.aboutSection.expertise.map(e => `• ${e}`).join('\n')}\n\n` +
    `${about.aboutSection.credentials}\n\n` +
    `${about.aboutSection.callToAction}`;
} 