import { useFieldArray, useFormContext } from "react-hook-form";
import {
  DocumentFormReturn,
  SlidesFieldArrayReturn,
} from "@/lib/document-form-types";
import { Document } from "./pages/document";
import useWindowDimensions from "./lib/hooks/use-window-dimensions";
import { SIZES } from "./lib/page-size";
import { LoadingSpinner } from "./loading-spinner";
import { usePagerContext } from "./lib/providers/pager-context";
import { useFieldArrayValues } from "./lib/hooks/use-field-array-values";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, Download, Loader2 } from "lucide-react";
import { useSelectionContext } from "./lib/providers/selection-context";
import { useKeys } from "./lib/hooks/use-keys";
import { useEffect, useState } from "react";
import { useStatusContext } from "./lib/providers/editor-status-context";
import { DocumentSkeleton } from "../components/editor-skeleton";
import { useComponentPrinter } from "./lib/hooks/use-component-printer";

export function SlidesEditor() {
  const form: DocumentFormReturn = useFormContext();
  const { control, watch } = form;
  const document = watch();
  const selectedSize = watch("config.theme.size");
  const sizeConfig = SIZES[selectedSize];
  
  const { width } = useWindowDimensions();
  const windowWidth = width || 0;
  const isLoadingWidth = !windowWidth;
  const { currentPage, onPreviousClick, onNextClick, setCurrentPage } =
    usePagerContext();
  const { numPages } = useFieldArrayValues("slides");
  const slidesFieldArray: SlidesFieldArrayReturn = useFieldArray({
    control,
    name: "slides",
  });
  const { setCurrentSelection } = useSelectionContext();
  const { status, setStatus } = useStatusContext();
  const { componentRef, handlePrint, isPrinting, error } = useComponentPrinter();

  useEffect(() => {
    setStatus("ready");
  }, [setStatus]);

  // Screen with larger than md side have smaller slides because the sidebar is present
  const mdWindowWidthPx = 770;
  const screenToSlideMinRatio = windowWidth > mdWindowWidthPx ? 2.5 : 1.2;
  const scale = Math.min(1, windowWidth / screenToSlideMinRatio / sizeConfig.width);

  return (
    <div
      className="flex flex-col w-full items-center justify-start bg-muted/20 flex-1 h-full"
      onClick={(event) => {
        setCurrentSelection("", event);
      }}
    >
      <div className="flex flex-col p-4 w-full h-full items-center justify-start gap-8 font-mono text-sm bg-primary/10">
        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded-md mb-4">
            {error}
          </div>
        )}
        <div className="w-full px-4 py-10">
          {isLoadingWidth || status == "loading" ? (
            <DocumentSkeleton />
          ) : (
            <div
              ref={componentRef}
              id="element-to-download-as-pdf"
              className="flex flex-col items-center"
            >
              <Document
                document={document}
                slidesFieldArray={slidesFieldArray}
                scale={scale}
                size={sizeConfig}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
