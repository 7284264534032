export interface ExamplePost {
  id: string;
  title?: string;
  content: string;
  author: {
    name: string;
    headline: string;
    avatar?: string;
  };
  engagement: {
    likes: number;
    comments: number;
    shares: number;
  };
  type: 'text' | 'article';
  hashtags?: string[];
}

export const examplePosts: ExamplePost[] = [
  {
    id: '1',
    content: `I was looking at a candidate's resume a few months ago and noticed a large gap in employment.

It started around March 2020 and lasted about 16 months.

I tried not to overly focus on it (there was a new job and new experience since that happened) and as we started talking, a few things came up.

"So, can you tell me about what you've been up to most recently?" I asked.

As he answered, he mentioned the gap and time off before his current role.

"I took some time off during the very beginning of Covid. We had a newborn, life was crazy, so my partner kept her job and I stayed home due to childcare."

There you go. A few things learned:

1) A resume tells part of the story but talking with candidates adds helpful context

2) Focus on the candidate, their experience, and relevant skills

3) People are figuring it out and doing their best. ESPECIALLY when life gets complex.`,
    author: {
      name: "Sarah Thompson",
      headline: "Senior HR Manager | Tech Recruitment Specialist",
    },
    engagement: {
      likes: 2543,
      comments: 156,
      shares: 89
    },
    type: 'text'
  },
  {
    id: '2',
    content: `Accept that you are not perfect.

But you are enough.

And then start working on everything that destroys you.

Your insecurities, your ego, your dark thoughts.

You will see in the end you're going to make peace with yourself.

And that's the greatest thing in the world.

- Finja Brandenburg`,
    author: {
      name: "Finja Brandenburg",
      headline: "Life Coach | Mindfulness Expert",
    },
    engagement: {
      likes: 15243,
      comments: 432,
      shares: 2891
    },
    type: 'text',
    hashtags: ['mindfulness']
  },
  {
    id: '3',
    content: `Dear All Hiring Managers,

1) Entry-level jobs should not require 3-5 years of experience.

2) Jobs that pay 10K per month should not require a master's degree.

3) A job gap is not a crime. Don't judge candidates based on their Employment gaps. Instead of that judge them based on their skills and Talent. Stop asking job seekers to meet impossible standards.

4) Freshers also deserves a chance too.

Agree..?`,
    author: {
      name: "Michael Chen",
      headline: "Career Coach | Former Tech Recruiter",
    },
    engagement: {
      likes: 42891,
      comments: 3214,
      shares: 12453
    },
    type: 'text'
  }
];

// Helper function to get a random example post
export const getRandomExamplePost = (): ExamplePost => {
  const randomIndex = Math.floor(Math.random() * examplePosts.length);
  return examplePosts[randomIndex];
};

// Helper function to get example posts by type
export const getExamplePostsByType = (type: 'text' | 'article'): ExamplePost[] => {
  return examplePosts.filter(post => post.type === type);
};

export function getExamplePostsByStyle(style: ExamplePost['style']): ExamplePost[] {
  return examplePosts.filter(post => post.style === style);
}

export function getTopPerformingPosts(count: number = 3): ExamplePost[] {
  return [...examplePosts]
    .sort((a, b) => 
      (b.engagement.likes + b.engagement.comments * 2 + b.engagement.shares * 3) -
      (a.engagement.likes + a.engagement.comments * 2 + a.engagement.shares * 3)
    )
    .slice(0, count);
}

export function getPostTemplate(type: ExamplePost['type'], style: ExamplePost['style']): ExamplePost | undefined {
  return examplePosts.find(post => post.type === type && post.style === style);
}

export function getFormattingTips(): string {
  return `Key LinkedIn Post Formatting Tips:
• Use emojis strategically (1-3 per post)
• Break up text with line breaks
• Use bullet points for lists
• Keep paragraphs to 1-2 lines
• End with a question to encourage engagement
• Add 3-5 relevant hashtags
• Include a clear call-to-action`;
} 