import type { PostType } from '@/types/post';

export const TONE_ICONS: Record<string, string> = {
  professional: '👔',
  casual: '😊',
  humorous: '😄',
  serious: '🎯',
  inspirational: '✨',
  educational: '📚',
  conversational: '💭',
  formal: '📝'
};

export const POST_TYPE_ICONS: Record<PostType, string> = {
  text: '📝',
  article: '📰',
  poll: '📊',
  event: '📅'
};

export const POST_LENGTH_WORDS = {
  text: {
    short: 50,
    medium: 125,
    long: 200
  },
  article: {
    short: 250,
    medium: 400,
    long: 700
  },
  poll: {
    short: 50,
    medium: 125,
    long: 200
  },
  event: {
    short: 50,
    medium: 125,
    long: 200
  }
} as const;

export const PIXABAY_API_KEY = '48225649-168edff33d5e1c9f6c07bf2bc';

export const EXAMPLE_FEED_POSTS = [
  {
    name: "Dr. Sarah Johnson",
    headline: "Pediatric Specialist | Healthcare Innovation",
    content: "Excited to share my latest research on childhood nutrition. The results are promising! Our team has been working tirelessly on understanding the impact of early dietary interventions. The data suggests that establishing healthy eating habits in the first five years of life has long-lasting effects on overall health outcomes.",
    timeAgo: "2d",
    reactions: 45,
    comments: 12
  },
  {
    name: "Dr. Michael Chen",
    headline: "Cardiologist | Heart Health Advocate",
    content: "Just finished a fascinating conference on preventive cardiology. Here are my key takeaways from the sessions on lifestyle modifications and their impact on cardiovascular health. The emerging research on plant-based diets and heart health is particularly compelling.",
    timeAgo: "4d",
    reactions: 89,
    comments: 34
  }
]; 