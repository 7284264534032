import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { LogOut, Settings, Calendar, Layout, PenTool, FileText, Menu, X } from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/components/ui/use-toast';
import { useNavigate, useLocation } from 'react-router-dom';
import { SettingsDialog } from './SettingsDialog';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";

interface UsageCount {
  optimizer?: number;
  planner?: number;
  posts?: number;
}

export function AppHeader() {
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [usageCount, setUsageCount] = useState<UsageCount>({});
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const isDashboard = location.pathname === '/dashboard';
  const [showPublicMobileMenu, setShowPublicMobileMenu] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);

  // Get the current tool from the pathname instead of URL params
  const currentTool = location.pathname.split('/')[1] || 'optimizer';

  // Update auth state whenever the component mounts or auth state changes
  useEffect(() => {
    const updateUserState = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUserEmail(session?.user?.email ?? null);
      
      if (session?.user) {
        const { data: profile } = await supabase
          .from('profiles')
          .select('is_subscribed, usage_count')
          .eq('id', session.user.id)
          .single();

        setIsSubscribed(profile?.is_subscribed ?? false);
        setUsageCount(profile?.usage_count || {});
      } else {
        setIsSubscribed(false);
        setUsageCount({});
      }
    };

    // Initial state update
    updateUserState();

    // Subscribe to auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_IN') {
        await updateUserState();
      } else if (event === 'SIGNED_OUT') {
        setUserEmail(null);
        setIsSubscribed(false);
        setUsageCount({});
        // Force reload the page on sign out to reset all state
        window.location.href = '/';
      }
    });

    // Cleanup subscription
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      // The auth state change listener will handle the redirect
    } catch (error) {
      console.error('Error signing out:', error);
      toast({
        title: "Error signing out",
        description: "Please try again",
        variant: "destructive",
      });
    }
  };

  const handleUpgrade = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('Please sign in first');
      }

      const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/create-checkout`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to create checkout session');
      }

      const { url } = await response.json();
      if (url) {
        window.location.href = url;
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      toast({
        title: "Error",
        description: "Failed to start checkout process. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleToolChange = (tool: string) => {
    navigate(`/${tool}`);
    setIsMobileMenuOpen(false);
  };

  return (
    <div className="fixed top-0 left-0 right-0 bg-white border-b border-gray-200 z-50">
      <div className="max-w-7xl mx-auto px-4">
        {/* Main Header Row */}
        <div className="flex items-center h-16">
          {/* Logo */}
          <div className="w-[200px] flex-shrink-0">
            <img
              src="/logos/medical-post-logo-h.svg"
              alt="MedicalPost Logo"
              className="w-full h-auto"
            />
          </div>

          {/* Navigation Links and User Actions */}
          {!userEmail ? (
            <>
              {/* Center Navigation Links - Hidden on Mobile */}
              <div className="hidden md:flex flex-1 items-center justify-center">
                <nav className="flex items-center space-x-8">
                  <a href="#features" className="text-gray-600 hover:text-gray-900 text-sm font-medium transition-colors">
                    Features
                  </a>
                  <a href="#testimonials" className="text-gray-600 hover:text-gray-900 text-sm font-medium transition-colors">
                    Testimonials
                  </a>
                  <a href="#pricing" className="text-gray-600 hover:text-gray-900 text-sm font-medium transition-colors">
                    Pricing
                  </a>
                  <a href="#faq" className="text-gray-600 hover:text-gray-900 text-sm font-medium transition-colors">
                    FAQ
                  </a>
                </nav>
              </div>

              {/* Mobile Menu Button */}
              <div className="flex-1 flex items-center justify-end md:hidden">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setShowPublicMobileMenu(true)}
                  className="text-gray-600"
                >
                  <Menu className="h-5 w-5" />
                </Button>
              </div>

              {/* Right-aligned Buttons */}
              <div className="hidden md:flex items-center justify-end gap-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => navigate('/login')}
                  className="text-gray-600"
                >
                  Sign In
                </Button>
                <Button
                  size="sm"
                  onClick={() => navigate('/login')}
                  className="bg-primary text-white hover:bg-primary/90"
                >
                  Get Started
                </Button>
              </div>
            </>
          ) : (
            <>
              {/* Mobile Menu Button for Authenticated Users */}
              <div className="flex md:hidden items-center ml-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setIsMobileMenuOpen(true)}
                  className="text-gray-600"
                >
                  <Menu className="h-5 w-5" />
                </Button>
              </div>

              {/* Desktop Navigation for Authenticated Users */}
              <div className="hidden md:flex flex-1 items-center justify-center px-4">
                <nav className="flex items-center justify-center w-full max-w-3xl mx-auto space-x-4">
                  <Button
                    variant={currentTool === 'optimizer' ? 'default' : 'ghost'}
                    size="sm"
                    onClick={() => handleToolChange('optimizer')}
                    className={`text-sm ${currentTool === 'optimizer' ? 'text-primary-foreground' : 'text-gray-600'}`}
                  >
                    Profile Optimizer
                  </Button>
                  <Button
                    variant={currentTool === 'planner' ? 'default' : 'ghost'}
                    size="sm"
                    onClick={() => handleToolChange('planner')}
                    className={`text-sm ${currentTool === 'planner' ? 'text-primary-foreground' : 'text-gray-600'}`}
                  >
                    Content Planner
                  </Button>
                  <Button
                    variant={location.pathname === '/posts/new' ? 'default' : 'ghost'}
                    size="sm"
                    onClick={() => navigate('/posts/new')}
                    className={`text-sm ${location.pathname === '/posts/new' ? 'text-primary-foreground' : 'text-gray-600'}`}
                  >
                    Post Creator
                  </Button>
                  <Button
                    variant={location.pathname === '/carousel' ? 'default' : 'ghost'}
                    size="sm"
                    onClick={() => navigate('/carousel')}
                    className={`text-sm ${location.pathname === '/carousel' ? 'text-primary-foreground' : 'text-gray-600'}`}
                  >
                    Carousel Creator
                  </Button>
                  {!isDashboard && (
                    <Button
                      variant={location.pathname === '/dashboard' ? 'default' : 'ghost'}
                      size="sm"
                      onClick={() => navigate('/dashboard')}
                      className={`text-sm ${location.pathname === '/dashboard' ? 'text-primary-foreground' : 'text-gray-600'}`}
                    >
                      Dashboard
                    </Button>
                  )}
                </nav>
              </div>

              {/* Right side: Email, Plan Status, and Actions */}
              <div className="flex items-center gap-2 ml-auto">
                <div className="hidden md:flex flex-col items-end">
                  <span className="text-sm text-gray-600 truncate max-w-[200px]">{userEmail}</span>
                  {!isSubscribed && (
                    <Button 
                      size="sm" 
                      onClick={() => navigate('/pro')}
                      className="bg-primary hover:bg-primary/90 text-xs px-3 py-1 h-7"
                    >
                      Upgrade to Pro
                    </Button>
                  )}
                  {isSubscribed && (
                    <span className="px-2 py-0.5 rounded-full text-xs bg-primary text-primary-foreground">
                      Pro
                    </span>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setShowSettings(true)}
                    className="text-gray-600 hover:text-gray-900 h-8 w-8 p-0"
                  >
                    <Settings className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={handleSignOut}
                    className="text-gray-600 hover:text-gray-900 h-8 w-8 p-0"
                  >
                    <LogOut className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Public Mobile Menu Sheet */}
        {!userEmail && (
          <Sheet open={showPublicMobileMenu} onOpenChange={setShowPublicMobileMenu}>
            <SheetContent side="left" className="w-[300px] sm:w-[400px]">
              <SheetHeader>
                <SheetTitle>Menu</SheetTitle>
              </SheetHeader>
              <div className="flex flex-col gap-4 mt-6">
                <a
                  href="#features"
                  onClick={() => setShowPublicMobileMenu(false)}
                  className="text-gray-600 hover:text-gray-900 text-sm font-medium transition-colors"
                >
                  Features
                </a>
                <a
                  href="#testimonials"
                  onClick={() => setShowPublicMobileMenu(false)}
                  className="text-gray-600 hover:text-gray-900 text-sm font-medium transition-colors"
                >
                  Testimonials
                </a>
                <a
                  href="#pricing"
                  onClick={() => setShowPublicMobileMenu(false)}
                  className="text-gray-600 hover:text-gray-900 text-sm font-medium transition-colors"
                >
                  Pricing
                </a>
                <a
                  href="#faq"
                  onClick={() => setShowPublicMobileMenu(false)}
                  className="text-gray-600 hover:text-gray-900 text-sm font-medium transition-colors"
                >
                  FAQ
                </a>
                <div className="border-t my-4" />
                <div className="flex flex-col gap-2">
                  <Button
                    variant="ghost"
                    onClick={() => {
                      navigate('/login');
                      setShowPublicMobileMenu(false);
                    }}
                    className="w-full justify-center"
                  >
                    Sign In
                  </Button>
                  <Button
                    onClick={() => {
                      navigate('/login');
                      setShowPublicMobileMenu(false);
                    }}
                    className="w-full justify-center bg-primary text-white hover:bg-primary/90"
                  >
                    Get Started
                  </Button>
                </div>
              </div>
            </SheetContent>
          </Sheet>
        )}

        {/* Existing Mobile Menu Sheet for authenticated users */}
        {userEmail && (
          <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
            <SheetContent side="left" className="w-[300px] sm:w-[400px]">
              <SheetHeader>
                <SheetTitle>Menu</SheetTitle>
              </SheetHeader>
              <div className="flex flex-col gap-2 mt-6">
                <Button
                  variant={currentTool === 'optimizer' ? 'default' : 'ghost'}
                  onClick={() => handleToolChange('optimizer')}
                  className="justify-start"
                >
                  <FileText className="h-4 w-4 mr-2" />
                  Profile Optimizer
                </Button>
                <Button
                  variant={currentTool === 'planner' ? 'default' : 'ghost'}
                  onClick={() => handleToolChange('planner')}
                  className="justify-start"
                >
                  <Calendar className="h-4 w-4 mr-2" />
                  Content Planner
                </Button>
                <Button
                  variant={location.pathname === '/posts/new' ? 'default' : 'ghost'}
                  onClick={() => navigate('/posts/new')}
                  className="justify-start"
                >
                  <PenTool className="h-4 w-4 mr-2" />
                  Post Creator
                </Button>
                <Button
                  variant={location.pathname === '/carousel' ? 'default' : 'ghost'}
                  onClick={() => navigate('/carousel')}
                  className="justify-start"
                >
                  <PenTool className="h-4 w-4 mr-2" />
                  Carousel Creator
                </Button>
                {!isDashboard && (
                  <Button
                    variant={location.pathname === '/dashboard' ? 'default' : 'ghost'}
                    onClick={() => {
                      navigate('/dashboard');
                      setIsMobileMenuOpen(false);
                    }}
                    className="justify-start"
                  >
                    <Layout className="h-4 w-4 mr-2" />
                    Dashboard
                  </Button>
                )}
                <div className="border-t my-4" />
                <Button
                  variant="ghost"
                  onClick={() => {
                    setShowSettings(true);
                    setIsMobileMenuOpen(false);
                  }}
                  className="justify-start"
                >
                  <Settings className="h-4 w-4 mr-2" />
                  Settings
                </Button>
              </div>
            </SheetContent>
          </Sheet>
        )}

        {/* Settings Dialog */}
        <SettingsDialog
          open={showSettings}
          onOpenChange={setShowSettings}
          isSubscribed={isSubscribed}
        />
      </div>
    </div>
  );
} 