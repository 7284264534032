import { ContentPlanPost } from '../types/post';

const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

const contentCache = new Map<string, {
  timestamp: number;
  content: ContentPlanPost;
}>();

export function getCacheKey(postType: string, topic: string): string {
  return `${postType}:${topic}`;
}

export function addToCache(post: ContentPlanPost): void {
  const key = getCacheKey(post.postType, post.topic);
  contentCache.set(key, {
    timestamp: Date.now(),
    content: post
  });
}

export function getFromCache(postType: string, topic: string): ContentPlanPost | null {
  const key = getCacheKey(postType, topic);
  const cached = contentCache.get(key);
  
  if (!cached) return null;
  
  if (Date.now() - cached.timestamp > CACHE_DURATION) {
    contentCache.delete(key);
    return null;
  }
  
  return cached.content;
} 