import { useState, useEffect } from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import type { PixabayImage } from '@/types/post';
import { searchPixabayImages, getImageSelectionHandler } from '@/services/imageSearch';

interface ImageSearchProps {
  selectedImages: PixabayImage[];
  onImagesChange: (images: PixabayImage[]) => void;
  imagePrompt?: string;
  onImagePromptChange: (prompt: string) => void;
}

export function ImageSearch({
  selectedImages,
  onImagesChange,
  imagePrompt = '',
  onImagePromptChange
}: ImageSearchProps) {
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<PixabayImage[]>([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (imagePrompt) {
        handleSearch(imagePrompt);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [imagePrompt]);

  const handleSearch = async (query: string) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    try {
      const results = await searchPixabayImages(query);
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching images:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleImageSelect = getImageSelectionHandler(selectedImages, onImagesChange);

  return (
    <div className="p-4 bg-white rounded-lg border">
      <Label className="text-sm font-medium mb-3">Add Images</Label>
      <div className="space-y-4">
        <Input
          type="text"
          placeholder="Search for images..."
          value={imagePrompt}
          onChange={(e) => onImagePromptChange(e.target.value)}
        />
        
        {isSearching && (
          <div className="flex justify-center py-4">
            <div className="animate-spin h-6 w-6 border-2 border-primary border-t-transparent rounded-full" />
          </div>
        )}

        {searchResults.length > 0 && (
          <div className="grid grid-cols-2 gap-2">
            {searchResults.map((image) => (
              <div
                key={image.id}
                className={`relative cursor-pointer rounded-lg overflow-hidden border-2 ${
                  selectedImages?.some(img => img.id === image.id)
                    ? 'border-primary'
                    : 'border-transparent'
                }`}
                onClick={() => handleImageSelect(image)}
              >
                <img
                  src={image.previewURL}
                  alt={image.tags}
                  className="w-full h-24 object-cover"
                />
                {selectedImages?.some(img => img.id === image.id) && (
                  <div className="absolute top-2 right-2 bg-primary text-white rounded-full p-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {selectedImages && selectedImages.length > 0 && (
          <div className="mt-4">
            <Label className="text-sm font-medium mb-2">Selected Images</Label>
            <div className="flex gap-2 mt-2">
              {selectedImages.map((image) => (
                <div key={image.id} className="relative">
                  <img
                    src={image.previewURL}
                    alt={image.tags}
                    className="h-20 w-20 object-cover rounded-lg"
                  />
                  <button
                    className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageSelect(image);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
} 