import { AutoTextarea } from "../../ui/auto-text-area";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  DocumentFormReturn,
  TextFieldPath,
  ElementFieldPath,
} from "../../lib/document-form-types";
import { getParent, getSlideNumber } from "../../lib/field-path";
import { usePagerContext } from "../../lib/providers/pager-context";
import { useSelectionContext } from "../../lib/providers/selection-context";
import { CSSProperties } from "react";
import { cn } from "@/lib/utils";
import { TextStyle } from "../../lib/validation/text-schema";

const marginToClasses = {
  Small: "my-1",
  Medium: "my-4",
  Large: "my-8",
} as const;

export function TextAreaFormField({
  form,
  fieldName,
  label,
  placeholder,
  className = "",
  style = {},
}: {
  form: DocumentFormReturn;
  fieldName: TextFieldPath;
  label: string;
  placeholder: string;
  className?: string;
  style?: CSSProperties;
}) {
  const { setCurrentSelection } = useSelectionContext();
  const { setCurrentPage } = usePagerContext();
  const pageNumber = getSlideNumber(fieldName);
  const parentFieldName = getParent(fieldName) as ElementFieldPath;
  const parentStyle = form.watch(parentFieldName)?.style as TextStyle;
  const config = form.watch("config");

  // Determine text color based on style settings
  const getTextColor = () => {
    if (!parentStyle?.color) return undefined;
    
    switch (parentStyle.color) {
      case "Primary":
        return config?.theme?.primary;
      case "Secondary":
        return config?.theme?.secondary;
      case "Custom":
        return parentStyle.customColor;
      default:
        return undefined;
    }
  };

  return (
    <FormField
      control={form.control}
      name={fieldName}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <AutoTextarea
              {...field}
              placeholder={placeholder}
              className={cn(
                "resize-none overflow-hidden min-h-[1.5em] bg-transparent inline-block w-[inherit]",
                {
                  "text-left": parentStyle?.align === "Left",
                  "text-center": parentStyle?.align === "Center",
                  "text-right": parentStyle?.align === "Right",
                },
                marginToClasses[parentStyle?.margin || "Medium"],
                className
              )}
              style={{
                ...style,
                color: getTextColor(),
                display: "block",
                width: "100%"
              }}
              onClick={(event) => {
                setCurrentPage(pageNumber);
                setCurrentSelection(parentFieldName, event);
              }}
              onKeyDown={(event) => {
                // Stop propagation for all arrow keys when the text area is focused
                if (event.key === "ArrowLeft" || event.key === "ArrowRight" || 
                    event.key === "ArrowUp" || event.key === "ArrowDown") {
                  event.stopPropagation();
                }
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
