"use client";
import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import FormProvider from "@/lib/providers/form-provider";
import * as z from "zod";
import {
  useRetrieveFormValues,
  usePersistFormValues,
} from "../hooks/use-persist-form";

import { DocumentSchema } from "../validation/document-schema";
import { PagerProvider } from "./pager-context";
import { usePager } from "../hooks/use-pager";
import { SelectionProvider } from "./selection-context";
import { useSelection } from "../hooks/use-selection";
import { DocumentFormReturn } from "../document-form-types";
import { defaultValues } from "../default-document";
import { KeysProvider } from "./keys-context";
import { useKeys } from "../hooks/use-keys";
import { StatusProvider } from "./editor-status-context";

const FORM_DATA_KEY = "documentFormKey";

export function DocumentProvider({ children }: { children: React.ReactNode }) {
  const { getSavedData } = useRetrieveFormValues(
    FORM_DATA_KEY,
    defaultValues,
    DocumentSchema
  );
  const initialValues = getSavedData();
  const documentForm = useForm<z.infer<typeof DocumentSchema>>({
    resolver: zodResolver(DocumentSchema),
    defaultValues: initialValues,
    mode: "onChange",
  });

  usePersistFormValues({
    localStorageKey: FORM_DATA_KEY,
    values: documentForm.getValues(),
  });

  const keys = useKeys();
  const selection = useSelection();
  const pager = usePager(0);

  return (
    <KeysProvider value={keys}>
      <FormProvider methods={documentForm}>
        <StatusProvider>
          <SelectionProvider value={selection}>
            <PagerProvider value={pager}>
              <div className="flex-1 flex flex-col">{children}</div>
            </PagerProvider>
          </SelectionProvider>
        </StatusProvider>
      </FormProvider>
    </KeysProvider>
  );
}
