import { Button } from '@/components/ui/button';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

export default function Documentation() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-sage-50 to-white">
      <div className="max-w-4xl mx-auto px-4 py-12">
        <Button
          variant="ghost"
          onClick={() => navigate(-1)}
          className="mb-8"
        >
          <ChevronLeft className="h-4 w-4 mr-2" />
          Back
        </Button>

        <h1 className="text-4xl font-bold mb-8">Documentation</h1>
        
        <div className="prose prose-lg max-w-none">
          <Tabs defaultValue="getting-started" className="w-full">
            <TabsList className="w-full justify-start">
              <TabsTrigger value="getting-started">Getting Started</TabsTrigger>
              <TabsTrigger value="profile-optimizer">Profile Optimizer</TabsTrigger>
              <TabsTrigger value="content-planner">Content Planner</TabsTrigger>
              <TabsTrigger value="post-creator">Post Creator</TabsTrigger>
              <TabsTrigger value="carousel-creator">Carousel Creator</TabsTrigger>
            </TabsList>

            <TabsContent value="getting-started">
              <section className="mt-8">
                <h2 className="text-2xl font-semibold mb-4">Getting Started</h2>
                <p className="mb-6">Welcome to MedicalPost! This guide will help you make the most of our AI-powered LinkedIn optimization tools.</p>
                
                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="account-setup">
                    <AccordionTrigger>Account Setup</AccordionTrigger>
                    <AccordionContent>
                      <ol className="list-decimal pl-6 space-y-2">
                        <li>Click "Sign Up" in the navigation bar</li>
                        <li>Enter your email and create a password</li>
                        <li>Verify your email address</li>
                        <li>Complete your profile setup</li>
                      </ol>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="navigation">
                    <AccordionTrigger>Navigation Guide</AccordionTrigger>
                    <AccordionContent>
                      <ul className="list-disc pl-6 space-y-2">
                        <li>Use the top navigation bar to switch between tools</li>
                        <li>Access your dashboard to view all your content</li>
                        <li>Use the settings menu to customize your experience</li>
                        <li>Find help and support through the feedback widget</li>
                      </ul>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="subscription">
                    <AccordionTrigger>Subscription Plans</AccordionTrigger>
                    <AccordionContent>
                      <div className="space-y-4">
                        <div>
                          <h4 className="font-semibold">Free Plan</h4>
                          <ul className="list-disc pl-6">
                            <li>1 profile optimization</li>
                            <li>1 content plan</li>
                            <li>3 AI-generated posts</li>
                          </ul>
                        </div>
                        <div>
                          <h4 className="font-semibold">Professional Plan</h4>
                          <ul className="list-disc pl-6">
                            <li>Unlimited profile optimizations</li>
                            <li>Unlimited content plans</li>
                            <li>Unlimited posts and carousels</li>
                            <li>Priority support</li>
                          </ul>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </section>
            </TabsContent>

            <TabsContent value="profile-optimizer">
              <section className="mt-8">
                <h2 className="text-2xl font-semibold mb-4">Profile Optimizer</h2>
                <p className="mb-6">Our Profile Optimizer helps you enhance your LinkedIn profile with AI-powered suggestions tailored for healthcare professionals.</p>

                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="how-to-use">
                    <AccordionTrigger>How to Use</AccordionTrigger>
                    <AccordionContent>
                      <ol className="list-decimal pl-6 space-y-4">
                        <li>
                          <strong>Export Your LinkedIn Profile</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Go to your LinkedIn profile</li>
                            <li>Click the "More" button below your cover photo</li>
                            <li>Select "Save to PDF"</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Upload to Profile Optimizer</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Click "Upload Profile" button</li>
                            <li>Select your exported PDF</li>
                            <li>Wait for the AI analysis</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Review Suggestions</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Review section-by-section recommendations</li>
                            <li>Choose which suggestions to implement</li>
                            <li>Copy improved content directly</li>
                          </ul>
                        </li>
                      </ol>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="optimization-areas">
                    <AccordionTrigger>Optimization Areas</AccordionTrigger>
                    <AccordionContent>
                      <ul className="list-disc pl-6 space-y-4">
                        <li>
                          <strong>Headline Optimization</strong>
                          <p>Craft a compelling headline that highlights your expertise and specialties</p>
                        </li>
                        <li>
                          <strong>About Section</strong>
                          <p>Create an engaging narrative that showcases your professional journey and achievements</p>
                        </li>
                        <li>
                          <strong>Experience Section</strong>
                          <p>Highlight key responsibilities and achievements in each role</p>
                        </li>
                        <li>
                          <strong>Skills & Endorsements</strong>
                          <p>Identify and prioritize relevant healthcare skills</p>
                        </li>
                      </ul>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="best-practices">
                    <AccordionTrigger>Best Practices</AccordionTrigger>
                    <AccordionContent>
                      <ul className="list-disc pl-6 space-y-2">
                        <li>Update your profile regularly (every 3-6 months)</li>
                        <li>Include relevant keywords for your specialty</li>
                        <li>Add media and publications to showcase your work</li>
                        <li>Maintain professional tone while being personable</li>
                        <li>Include quantifiable achievements where possible</li>
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </section>
            </TabsContent>

            <TabsContent value="content-planner">
              <section className="mt-8">
                <h2 className="text-2xl font-semibold mb-4">Content Planner</h2>
                <p className="mb-6">Plan and schedule your content effectively with our AI-powered content calendar.</p>

                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="planning-process">
                    <AccordionTrigger>Planning Process</AccordionTrigger>
                    <AccordionContent>
                      <ol className="list-decimal pl-6 space-y-4">
                        <li>
                          <strong>Set Your Goals</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Choose your content duration (7-90 days)</li>
                            <li>Select posting frequency</li>
                            <li>Define your target audience</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Content Mix</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Choose content types (text, images, carousels)</li>
                            <li>Select content themes</li>
                            <li>Balance educational and engagement content</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Review and Customize</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Review AI-generated content plan</li>
                            <li>Adjust post scheduling</li>
                            <li>Customize content themes</li>
                          </ul>
                        </li>
                      </ol>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="calendar-features">
                    <AccordionTrigger>Calendar Features</AccordionTrigger>
                    <AccordionContent>
                      <ul className="list-disc pl-6 space-y-4">
                        <li>
                          <strong>Calendar Views</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Monthly overview</li>
                            <li>Weekly detailed view</li>
                            <li>Daily schedule</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Content Management</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Drag-and-drop scheduling</li>
                            <li>Color-coded content types</li>
                            <li>Quick post preview</li>
                          </ul>
                        </li>
                      </ul>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="content-strategy">
                    <AccordionTrigger>Content Strategy Tips</AccordionTrigger>
                    <AccordionContent>
                      <ul className="list-disc pl-6 space-y-2">
                        <li>Maintain consistent posting schedule</li>
                        <li>Mix different content types for variety</li>
                        <li>Plan content around key healthcare dates</li>
                        <li>Include trending healthcare topics</li>
                        <li>Balance professional and personal content</li>
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </section>
            </TabsContent>

            <TabsContent value="post-creator">
              <section className="mt-8">
                <h2 className="text-2xl font-semibold mb-4">Post Creator</h2>
                <p className="mb-6">Create engaging LinkedIn posts with our AI-powered writing assistant.</p>

                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="post-types">
                    <AccordionTrigger>Post Types</AccordionTrigger>
                    <AccordionContent>
                      <div className="space-y-4">
                        <div>
                          <h4 className="font-semibold">Text Posts</h4>
                          <ul className="list-disc pl-6">
                            <li>Professional updates</li>
                            <li>Industry insights</li>
                            <li>Case studies</li>
                            <li>Educational content</li>
                          </ul>
                        </div>
                        <div>
                          <h4 className="font-semibold">Image Posts</h4>
                          <ul className="list-disc pl-6">
                            <li>Infographics</li>
                            <li>Event photos</li>
                            <li>Behind-the-scenes</li>
                            <li>Visual data</li>
                          </ul>
                        </div>
                        <div>
                          <h4 className="font-semibold">Polls</h4>
                          <ul className="list-disc pl-6">
                            <li>Industry surveys</li>
                            <li>Opinion gathering</li>
                            <li>Educational quizzes</li>
                          </ul>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="creation-process">
                    <AccordionTrigger>Creation Process</AccordionTrigger>
                    <AccordionContent>
                      <ol className="list-decimal pl-6 space-y-4">
                        <li>
                          <strong>Select Post Type</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Choose your post format</li>
                            <li>Select target audience</li>
                            <li>Define key message</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Generate Content</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Input your topic or idea</li>
                            <li>Review AI suggestions</li>
                            <li>Customize content</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Enhance Engagement</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Add relevant hashtags</li>
                            <li>Include call-to-action</li>
                            <li>Preview final post</li>
                          </ul>
                        </li>
                      </ol>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="writing-tips">
                    <AccordionTrigger>Writing Tips</AccordionTrigger>
                    <AccordionContent>
                      <ul className="list-disc pl-6 space-y-2">
                        <li>Start with a strong hook</li>
                        <li>Use clear, professional language</li>
                        <li>Break text into readable chunks</li>
                        <li>Include relevant examples</li>
                        <li>End with engaging questions</li>
                        <li>Use appropriate medical terminology</li>
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </section>
            </TabsContent>

            <TabsContent value="carousel-creator">
              <section className="mt-8">
                <h2 className="text-2xl font-semibold mb-4">Carousel Creator</h2>
                <p className="mb-6">Create visually appealing carousel posts to showcase medical insights and research.</p>

                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="carousel-basics">
                    <AccordionTrigger>Carousel Basics</AccordionTrigger>
                    <AccordionContent>
                      <ul className="list-disc pl-6 space-y-4">
                        <li>
                          <strong>Structure</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Title slide with hook</li>
                            <li>Content slides (4-8 recommended)</li>
                            <li>Call-to-action slide</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Best Uses</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Step-by-step guides</li>
                            <li>Research summaries</li>
                            <li>Case study presentations</li>
                            <li>Educational content</li>
                          </ul>
                        </li>
                      </ul>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="creation-steps">
                    <AccordionTrigger>Creation Steps</AccordionTrigger>
                    <AccordionContent>
                      <ol className="list-decimal pl-6 space-y-4">
                        <li>
                          <strong>Plan Your Content</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Choose your topic</li>
                            <li>Outline key points</li>
                            <li>Select template style</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Design Slides</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Add text and visuals</li>
                            <li>Customize colors and fonts</li>
                            <li>Ensure consistent branding</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Review and Export</strong>
                          <ul className="list-disc pl-6 mt-2">
                            <li>Preview carousel flow</li>
                            <li>Check for consistency</li>
                            <li>Export in high quality</li>
                          </ul>
                        </li>
                      </ol>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="design-tips">
                    <AccordionTrigger>Design Tips</AccordionTrigger>
                    <AccordionContent>
                      <ul className="list-disc pl-6 space-y-2">
                        <li>Keep text concise and readable</li>
                        <li>Use consistent colors and fonts</li>
                        <li>Include relevant medical imagery</li>
                        <li>Maintain professional appearance</li>
                        <li>Use data visualization when appropriate</li>
                        <li>Include source citations</li>
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </section>
            </TabsContent>
          </Tabs>

          <section className="mt-12">
            <h2 className="text-2xl font-semibold mb-4">Need Help?</h2>
            <div className="bg-gray-50 p-6 rounded-lg">
              <p className="mb-4">If you need additional assistance:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Use the feedback widget in the bottom right corner</li>
                <li>Email support at support@medicalpost.co.uk</li>
                <li>Check our FAQ section for common questions</li>
                <li>Pro users get priority support response</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}