export interface AudienceAnalysis {
  description: string;
}

export const analyzeAudiencePrompt = `Analyze this LinkedIn About section and return a valid JSON object with the following structure. Do not include any text outside the JSON object.

{
  "description": "Primary target audience consists of healthcare administrators and clinical directors in mid to large-sized hospitals navigating evidence-based care protocol implementation. These professionals, typically CMOs and Department Heads, face challenges in standardizing treatment approaches while maintaining quality care.\n\nThrough expertise in clinical pathway optimization and proven track record, I help leaders improve outcomes, reduce readmissions, and ensure compliance. Deep understanding of healthcare delivery enables implementation of sustainable solutions transforming patient care."
}

The description should cover:
- Who the profile is trying to reach (target demographics, roles, industries)
- What challenges or pain points these people face
- What goals or outcomes they're seeking
- How the profile owner can help them achieve these goals

The format should be:
- 2 short paragraphs
- Paragraphs should be separated by double newlines (\\n\\n)
- 300 words limit
Ensure the response is valid JSON.`;

export function validateAudienceAnalysis(json: unknown): json is AudienceAnalysis {
  if (!json || typeof json !== 'object') return false;
  const analysis = json as AudienceAnalysis;
  
  return typeof analysis.description === 'string' && analysis.description.length > 0;
}

export function formatAudienceAnalysis(analysis: AudienceAnalysis): string {
  return analysis.description;
} 