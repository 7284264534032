import React, { useEffect } from "react";
import * as z from "zod";
import { ConfigSchema } from "../lib/validation/document-schema";
import { cn } from "@/lib/utils";
import { fontIdToClassName } from "../lib/fonts-map";
import { TitleSchema } from "../lib/validation/text-schema";
import { textStyleToClasses } from "../lib/text-style-to-classes";
import { useFormContext } from "react-hook-form";
import {
  DocumentFormReturn,
  TextFieldPath,
  TextFieldStyle,
  StyleFieldPath,
  TextContentFieldPath,
} from "../lib/document-form-types";
import { TextAreaFormField } from "../forms/fields/text-area-form-field";

const paddingToClasses = {
  Small: "py-1",
  Medium: "py-4",
  Large: "py-8",
} as const;

const paddingXToClasses = {
  Small: "px-6",
  Medium: "px-12",
  Large: "px-24",
} as const;

const fontSizeClasses = {
  Small: "text-3xl",
  Medium: "text-4xl",
  Large: "text-5xl",
} as const;

export function Title({
  fieldName,
  className,
}: {
  fieldName: TextFieldPath;
  className?: string;
}) {
  const form: DocumentFormReturn = useFormContext();
  const { watch } = form;
  const title = watch(fieldName) as z.infer<typeof TitleSchema>;
  
  // Watch for changes in config and style
  const config = watch("config");
  const style = watch(`${fieldName}.style` as StyleFieldPath) as TextFieldStyle;
  const contentFieldName = (fieldName + ".content") as TextContentFieldPath;

  // Debug logging for font changes
  useEffect(() => {
    console.log('Title font updated:', {
      font1: config.fonts.font1,
      className: fontIdToClassName(config.fonts.font1)
    });
  }, [config.fonts.font1]);

  const getTextColor = () => {
    if (!style?.color) return undefined;
    
    switch (style.color) {
      case "Primary":
        return config?.theme?.primary;
      case "Secondary":
        return config?.theme?.secondary;
      case "Custom":
        return style.customColor;
      default:
        return undefined;
    }
  };

  const getFontFamily = () => {
    // Early return with empty string if no config or fonts
    if (!config?.fonts) return "";
    
    const primaryFont = config.fonts.font1;
    const secondaryFont = config.fonts.font2;
    
    // Return empty string if the selected font is not available
    if (!style?.font) {
      return primaryFont ? fontIdToClassName(primaryFont) : "";
    }
    
    switch (style.font) {
      case "Primary":
        return primaryFont ? fontIdToClassName(primaryFont) : "";
      case "Secondary":
        return secondaryFont ? fontIdToClassName(secondaryFont) : "";
      default:
        return primaryFont ? fontIdToClassName(primaryFont) : "";
    }
  };

  return (
    <div className={cn(
      "w-full flex flex-col items-stretch",
      paddingToClasses[style?.margin || "Medium"],
      paddingXToClasses[style?.marginX || "Medium"],
    )}>
      <TextAreaFormField
        form={form}
        fieldName={`${fieldName}.content` as TextFieldPath}
        label=""
        placeholder="Enter title"
        className={cn(
          "font-bold w-full",
          fontSizeClasses[style?.fontSize || "Medium"],
          getFontFamily(),
          {
            "text-left": style?.align === "Left",
            "text-center": style?.align === "Center",
            "text-right": style?.align === "Right",
          },
          className
        )}
        style={{
          color: getTextColor(),
        }}
      />
    </div>
  );
}
