import { useState, useEffect } from 'react';
import type { SpeechRecognition } from '@/types/post';

interface UseSpeechRecognitionReturn {
  isRecording: boolean;
  interimTranscript: string;
  startRecording: () => void;
  stopRecording: () => void;
  recognition: SpeechRecognition | null;
}

export function useSpeechRecognition(onFinalTranscript: (transcript: string) => void): UseSpeechRecognitionReturn {
  const [recognition, setRecognition] = useState<SpeechRecognition | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [interimTranscript, setInterimTranscript] = useState('');

  useEffect(() => {
    const initializeSpeechRecognition = () => {
      if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognition = new SpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.lang = 'en-US';

        recognition.onresult = (event) => {
          let finalTranscript = '';
          let interimTranscript = '';

          // Process results
          for (let i = event.resultIndex; i < event.results.length; i++) {
            const transcript = event.results[i][0].transcript;
            if (event.results[i].isFinal) {
              finalTranscript += transcript;
            } else {
              interimTranscript += transcript;
            }
          }

          // Update interim results
          setInterimTranscript(interimTranscript);

          // Only update content with final results
          if (finalTranscript) {
            onFinalTranscript(finalTranscript);
          }
        };

        recognition.onerror = (event) => {
          console.error('Speech recognition error:', event.error);
          setIsRecording(false);
          setInterimTranscript('');
        };

        recognition.onend = () => {
          setIsRecording(false);
          setInterimTranscript('');
        };

        setRecognition(recognition);
      }
    };

    initializeSpeechRecognition();
  }, [onFinalTranscript]);

  const startRecording = () => {
    if (recognition) {
      recognition.start();
      setIsRecording(true);
    } else {
      console.error('Speech recognition not supported');
    }
  };

  const stopRecording = () => {
    if (recognition) {
      recognition.stop();
      setIsRecording(false);
      setInterimTranscript('');
    }
  };

  return {
    isRecording,
    interimTranscript,
    startRecording,
    stopRecording,
    recognition
  };
} 