import React from "react";
import { Button } from "../../ui/button";
import { cn } from "@/lib/utils";
import { Maximize2, Minimize2, ArrowUpDown } from "lucide-react";

const sizeIcons = {
  Small: <Minimize2 className="h-4 w-4" />,
  Medium: <ArrowUpDown className="h-4 w-4" />,
  Large: <Maximize2 className="h-4 w-4" />,
};

interface MarginControlsProps {
  value: "Small" | "Medium" | "Large";
  onChange: (value: "Small" | "Medium" | "Large") => void;
  className?: string;
  label?: string;
}

export function MarginControls({
  value,
  onChange,
  className,
  label = "Margin",
}: MarginControlsProps) {
  return (
    <div className={cn("space-y-2", className)}>
      {label && (
        <p className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
          {label}
        </p>
      )}
      <div className="flex items-center gap-1">
        {(["Small", "Medium", "Large"] as const).map((size) => (
          <Button
            key={size}
            variant={value === size ? "default" : "outline"}
            size="icon"
            onClick={() => onChange(size)}
            className="h-8 w-8"
          >
            {sizeIcons[size]}
          </Button>
        ))}
      </div>
    </div>
  );
} 