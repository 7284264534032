import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/components/ui/use-toast';

export default function AuthCallback() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    let mounted = true;

    const handleCallback = async () => {
      try {
        // Get the code from URL
        const code = searchParams.get('code');
        
        if (!code) {
          throw new Error('No code provided in URL');
        }

        // Exchange the code for a session
        const { data: { session }, error } = await supabase.auth.exchangeCodeForSession(code);
        if (error) throw error;

        if (!session?.user) {
          throw new Error('No user in session after exchange');
        }

        // Set up auth state listener to handle the session change
        const {
          data: { subscription },
        } = supabase.auth.onAuthStateChange(async (event, currentSession) => {
          if (!mounted) return;

          if (event === 'SIGNED_IN' && currentSession) {
            try {
              // Check if profile exists
              const { data: existingProfile, error: profileError } = await supabase
                .from('profiles')
                .select('id')
                .eq('id', currentSession.user.id)
                .single();

              if (profileError && profileError.code !== 'PGRST116') { // PGRST116 is "not found"
                throw profileError;
              }

              // If profile doesn't exist, create it
              if (!existingProfile) {
                const { error: insertError } = await supabase
                  .from('profiles')
                  .insert([
                    {
                      id: currentSession.user.id,
                      email: currentSession.user.email,
                      full_name: currentSession.user.user_metadata?.full_name,
                      avatar_url: currentSession.user.user_metadata?.avatar_url,
                      is_subscribed: false,
                      usage_count: {
                        optimizer: 0,
                        planner: 0,
                        posts: 0
                      }
                    }
                  ]);

                if (insertError) {
                  throw insertError;
                }
              }

              // Get the next URL from search params or default to /optimizer
              const next = searchParams.get('next') || '/optimizer';
              navigate(next, { replace: true });
            } catch (error) {
              console.error('Error handling sign in:', error);
              toast({
                title: "Error",
                description: "There was an error setting up your profile. Please try again.",
                variant: "destructive",
              });
              navigate('/login', { replace: true });
            }
          }
        });

        return () => {
          subscription.unsubscribe();
        };
      } catch (error) {
        console.error('Error during auth callback:', error);
        if (mounted) {
          toast({
            title: "Authentication Error",
            description: "There was an error completing your sign in. Please try again.",
            variant: "destructive",
          });
          navigate('/login', { replace: true });
        }
      } finally {
        if (mounted) {
          setIsProcessing(false);
        }
      }
    };

    handleCallback();

    return () => {
      mounted = false;
    };
  }, [navigate, searchParams, toast]);

  if (isProcessing) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-sage-50 to-white">
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-2">Completing sign in...</h2>
          <p className="text-gray-600">Please wait while we redirect you.</p>
        </div>
      </div>
    );
  }

  return null;
} 