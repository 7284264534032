import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { CalendarIcon, LogOut, ChevronDown, ChevronRight, Edit2, RefreshCw, Download, Copy, History } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { supabase } from '@/integrations/supabase/client';
import { saveContentPlan, getLatestContentPlan, generateThemes, type GroupedContentPlan, type ContentPlanPost } from '@/services/content-planner';
import { generateContentIdeas } from '@/services/contentGenerator';
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Card } from "@/components/ui/card";
import { format, addDays, startOfWeek, isSameWeek, isWeekend } from 'date-fns';
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import ReactMarkdown from 'react-markdown';
import { UserProfileDialog } from './UserProfileDialog';
import { IdealClientProfileDialog } from './IdealClientProfileDialog';
import type { UserProfile, IdealClientProfile } from '@/types/profile';
import { useNavigate } from 'react-router-dom';
import { setAIModel, getCurrentModel, type AIModel } from '@/services/deepseek';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { ModelSelector } from '@/components/ui/model-selector';

const DURATION_OPTIONS = [
  { value: '7', label: '7 Days' },
  { value: '30', label: '30 Days' },
  { value: '60', label: '60 Days' },
  { value: '90', label: '90 Days' }
];

const POSTS_PER_WEEK_OPTIONS = [
  { value: '3', label: '3 posts per week' },
  { value: '5', label: '5 posts per week' },
  { value: '7', label: '7 posts per week' }
];

const CONTENT_TYPES = [
  { id: 'text', label: 'Text Posts' },
  { id: 'article', label: 'Articles' },
  { id: 'poll', label: 'Polls' },
  { id: 'case_study', label: 'Case Studies' },
  { id: 'tips', label: 'Tips & Tricks' },
  { id: 'industry_news', label: 'Industry News' }
];

const POST_TYPE_COLORS = {
  article: '#4CAF50',
  case_study: '#2196F3',
  industry_news: '#9C27B0',
  tips: '#00BCD4',
  poll: '#F44336',
  text: '#795548'
} as const;

const POST_TIMES: Record<string, string> = {
  'article': '09:00',
  'case_study': '10:00',
  'industry_news': '11:00',
  'tips': '14:00',
  'poll': '15:00',
  'text': '16:00'
} as const;

interface ThemeOption {
  id: string;
  label: string;
}

interface ContentIdeaProps {
  post: ContentPlanPost;
  onEdit: (post: ContentPlanPost) => void;
  onRegenerate: (post: ContentPlanPost) => void;
  isLoading?: boolean;
}

function ContentIdea({ post, onEdit, onRegenerate, isLoading }: ContentIdeaProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTopic, setEditedTopic] = useState(post.topic || '');
  const [editedPoints, setEditedPoints] = useState(post.keyPoints || []);

  const handleSave = () => {
    onEdit({
      ...post,
      topic: editedTopic,
      keyPoints: editedPoints
    });
    setIsEditing(false);
  };

  // Format the date and time safely
  const getFormattedDateTime = () => {
    try {
      if (!post.suggestedDate) return '';
      const date = new Date(`${post.suggestedDate}T${post.scheduledTime || '12:00:00'}`);
      if (isNaN(date.getTime())) {
        return format(new Date(post.suggestedDate), 'EEEE') + ' at 12:00 PM';
      }
      return format(date, 'EEEE, h:mm a');
    } catch (error) {
      console.error('Error formatting date:', error);
      return format(new Date(post.suggestedDate), 'EEEE') + ' at 12:00 PM';
    }
  };

  // Get the display topic, removing any bracketed prefix and handling undefined
  const displayTopic = (post.topic || 'Untitled Post').replace(/^\[.*?\]\s*/, '');

  return (
    <div className="bg-white rounded-lg p-4 shadow-sm space-y-2 relative">
      {isLoading && (
        <div className="absolute inset-0 bg-white/80 flex items-center justify-center rounded-lg z-10">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      )}
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <div className="flex items-center gap-2 mb-1">
            <span 
              className="text-sm font-medium capitalize px-2 py-1 rounded text-white"
              style={{ backgroundColor: POST_TYPE_COLORS[post.postType as keyof typeof POST_TYPE_COLORS] || '#666' }}
            >
              {post.postType}
            </span>
            <span className="text-sm text-gray-500">
              {getFormattedDateTime()}
            </span>
          </div>
          {isEditing ? (
            <input
              type="text"
              value={editedTopic}
              onChange={(e) => setEditedTopic(e.target.value)}
              className="text-lg font-semibold w-full border rounded p-1"
              onBlur={handleSave}
              onKeyDown={(e) => e.key === 'Enter' && handleSave()}
            />
          ) : (
            <h3 className="text-lg font-semibold">
              {displayTopic}
            </h3>
          )}
        </div>
        <div className="flex gap-2">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setIsEditing(!isEditing)}
            className="h-8 w-8 p-0"
            disabled={isLoading}
          >
            <Edit2 className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => onRegenerate(post)}
            className="h-8 w-8 p-0"
            disabled={isLoading}
          >
            <RefreshCw className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <ul className="list-disc pl-6 space-y-1">
        {isEditing ? (
          editedPoints.map((point, index) => (
            <li key={index}>
              <input
                type="text"
                value={point}
                onChange={(e) => {
                  const newPoints = [...editedPoints];
                  newPoints[index] = e.target.value;
                  setEditedPoints(newPoints);
                }}
                className="w-full border rounded p-1"
                onBlur={handleSave}
                onKeyDown={(e) => e.key === 'Enter' && handleSave()}
              />
            </li>
          ))
        ) : (
          (post.keyPoints || []).map((point, index) => (
            <li key={index} className="text-gray-600">{point}</li>
          ))
        )}
      </ul>
    </div>
  );
}

interface WeekCardProps {
  weekStart: string;
  posts: ContentPlanPost[];
  weekIndex: number;
  onEditPost: (weekIndex: number, postIndex: number, post: ContentPlanPost) => void;
  onRegeneratePost: (weekIndex: number, postIndex: number, weekTheme: string) => void;
  onRegenerateWeek: (weekIndex: number) => void;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  isLoading?: boolean;
  loadingPostIndex?: number;
  theme?: string;
}

function WeekCard({
  weekStart,
  posts,
  weekIndex,
  onEditPost,
  onRegeneratePost,
  onRegenerateWeek,
  isCollapsed,
  onToggleCollapse,
  isLoading,
  loadingPostIndex,
  theme
}: WeekCardProps) {
  const [editingTheme, setEditingTheme] = useState(false);
  const [weekTheme, setWeekTheme] = useState(theme || 'Professional Growth & Development');

  const handleThemeSave = () => {
    setEditingTheme(false);
  };

  return (
    <Card className="p-4 relative">
      {isLoading && (
        <div className="absolute inset-0 bg-white/80 flex items-center justify-center rounded-lg z-10">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
        </div>
      )}
      <div className="flex items-center justify-between mb-2">
        <div className="flex-1">
          <h3 className="text-lg font-semibold">
            Week {weekIndex + 1}: {format(new Date(weekStart), 'MMMM d, yyyy')}
          </h3>
          <div className="flex items-center gap-2 mt-1">
            <p className="text-sm text-gray-600">Theme:</p>
            <div className="flex items-center gap-2 flex-1">
              {editingTheme ? (
                <input
                  type="text"
                  value={weekTheme}
                  onChange={(e) => setWeekTheme(e.target.value)}
                  className="text-sm border rounded p-1 flex-1"
                  onBlur={handleThemeSave}
                  onKeyDown={(e) => e.key === 'Enter' && handleThemeSave()}
                  autoFocus
                  disabled={isLoading}
                />
              ) : (
                <p className="text-sm text-gray-600 flex-1">{weekTheme}</p>
              )}
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setEditingTheme(!editingTheme)}
                className="h-8 w-8 p-0"
                disabled={isLoading}
              >
                <Edit2 className="h-4 w-4" />
              </Button>
            </div>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => onRegenerateWeek(weekIndex)}
              className="ml-2"
              disabled={isLoading}
            >
              <RefreshCw className="h-4 w-4 mr-1" />
              Regenerate Week
            </Button>
          </div>
        </div>
        <Button variant="ghost" size="icon" onClick={onToggleCollapse} disabled={isLoading}>
          {isCollapsed ? <ChevronRight /> : <ChevronDown />}
        </Button>
      </div>

      {!isCollapsed && (
        <div className="space-y-3 mt-4">
          {posts.map((post, index) => (
            <ContentIdea
              key={`${post.postType}-${index}`}
              post={post}
              onEdit={(p) => onEditPost(weekIndex, index, p)}
              onRegenerate={(p) => onRegeneratePost(weekIndex, index, weekTheme)}
              isLoading={loadingPostIndex === index}
            />
          ))}
        </div>
      )}
    </Card>
  );
}

export const ContentPlanner = () => {
  const [selectedDuration, setSelectedDuration] = useState<string>('30');
  const [postsPerWeek, setPostsPerWeek] = useState<string>('5');
  const [includeWeekends, setIncludeWeekends] = useState(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [isGenerating, setIsGenerating] = useState(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [useProfileInfo, setUseProfileInfo] = useState(false);
  const [contentIdeas, setContentIdeas] = useState<GroupedContentPlan[]>([]);
  const [generationProgress, setGenerationProgress] = useState<number>(0);
  const [selectedContentTypes, setSelectedContentTypes] = useState<string[]>(['text', 'article', 'poll']);
  const [themeOptions, setThemeOptions] = useState<ThemeOption[]>([]);
  const [selectedThemes, setSelectedThemes] = useState<string[]>([]);
  const [isGeneratingThemes, setIsGeneratingThemes] = useState(false);
  const [themeContext, setThemeContext] = useState('');
  const { toast } = useToast();
  const [usageCount, setUsageCount] = useState<number>(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [collapsedMonths, setCollapsedMonths] = useState<Record<string, boolean>>({});
  const [collapsedWeeks, setCollapsedWeeks] = useState<Record<string, boolean>>({});
  const [showUserProfileDialog, setShowUserProfileDialog] = useState(false);
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [idealClientProfile, setIdealClientProfile] = useState<IdealClientProfile | null>(null);
  const [weeklyPosts, setWeeklyPosts] = useState<{ weekStart: string; posts: ContentPlanPost[] }[]>([]);
  const [loadingWeekIndex, setLoadingWeekIndex] = useState<number | null>(null);
  const [loadingPostIndices, setLoadingPostIndices] = useState<{weekIndex: number; postIndex: number}[]>([]);
  const [currentAIModel, setCurrentAIModel] = useState<AIModel>(getCurrentModel());
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [calendarName, setCalendarName] = useState('');

  useEffect(() => {
    const initializeUserData = async () => {
      try {
        setIsLoading(true);
        const { data: { session }, error: authError } = await supabase.auth.getSession();
        
        if (authError || !session?.user) {
          navigate('/');
          return;
        }

        const user = session.user;
        setUserEmail(user.email);
        setUserId(user.id);
        
        // Fetch all user data in a single query
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('user_profile, ideal_client_profile, is_subscribed, usage_count')
          .eq('id', user.id)
          .single();

        if (profileError) {
          console.error('Error fetching profile:', profileError);
          toast({
            title: "Error loading profile",
            description: "Unable to load your profile data. Please try refreshing the page.",
            variant: "destructive",
          });
          return;
        }

        if (profile) {
          setUserProfile(profile.user_profile);
          setIdealClientProfile(profile.ideal_client_profile);
          setIsSubscribed(!!profile.is_subscribed);
          setUsageCount(profile.usage_count?.calendar || 0);
        }

        try {
          // Fetch latest content plan - don't throw error if none exists
          const plan = await getLatestContentPlan(user.id);
          if (plan) {
            setContentIdeas(plan);
          }
        } catch (error) {
          // Silently handle the case where no content plan exists
          console.log('No existing content plan found for new user');
        }
      } catch (error) {
        console.error('Error initializing user data:', error);
        toast({
          title: "Error loading data",
          description: "Unable to load your data. Please try refreshing the page.",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };

    initializeUserData();
  }, [navigate, toast]);

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
      toast({
        title: "Error",
        description: "Failed to sign out. Please try again.",
        variant: "destructive",
      });
    }
  };

  const toggleCollapse = (id: string, type: 'month' | 'week') => {
    if (type === 'month') {
      setCollapsedMonths(prev => ({ ...prev, [id]: !prev[id] }));
    } else {
      setCollapsedWeeks(prev => ({ ...prev, [id]: !prev[id] }));
    }
  };

  const getNextValidPostDate = (currentDate: Date, index: number): Date => {
    let date = addDays(currentDate, index);
    if (!includeWeekends) {
      while (isWeekend(date)) {
        date = addDays(date, 1);
      }
    }
    return date;
  };

  const handleGenerateIdeas = async () => {
    if (!userId) {
      toast({
        title: "Authentication required",
        description: "Please sign in to generate content ideas.",
        variant: "destructive",
        style: { backgroundColor: '#ff0000' },
      });
      return;
    }

    if (!isSubscribed && usageCount >= 1) {
      toast({
        title: "Usage limit reached",
        description: "Free accounts are limited to 7-day content plans. Please upgrade for longer durations.",
        variant: "destructive",
        style: { backgroundColor: '#ff0000' },
      });
      return;
    }

    if (!isSubscribed && parseInt(selectedDuration) > 7) {
      toast({
        title: "Limit exceeded",
        description: "Free accounts can only generate 7-day plans. Please upgrade for longer durations.",
        variant: "destructive",
      });
      setSelectedDuration('7');
      return;
    }

    if (selectedContentTypes.length === 0) {
      toast({
        title: "Content types required",
        description: "Please select at least one content type.",
        variant: "destructive",
      });
      return;
    }

    if (selectedThemes.length === 0) {
      toast({
        title: "Themes required",
        description: "Please select at least one theme for your content plan.",
        variant: "destructive",
      });
      return;
    }

    setIsGenerating(true);
    setGenerationProgress(0);
    setContentIdeas([]);
    
    try {
      const totalDays = parseInt(selectedDuration);
      const postsPerWeekNum = parseInt(postsPerWeek);
      const totalWeeks = Math.ceil(totalDays / 7);
      
      toast({
        title: "Generating Content Plan",
        description: "Starting generation...",
        duration: 2000,
      });

      let completedWeeks = 0;

      // Get the actual theme labels from themeOptions based on selected theme IDs
      const selectedThemeLabels = themeOptions
        .filter(theme => selectedThemes.includes(theme.id))
        .map(theme => theme.label);

      const allIdeas = await generateContentIdeas(
        Boolean(useProfileInfo && userProfile && idealClientProfile),
        userId,
        (weekContent) => {
          completedWeeks++;
          const progress = (completedWeeks / totalWeeks) * 100;
          setGenerationProgress(progress);
          
          toast({
            title: `Week ${completedWeeks} Complete`,
            description: `Generated ${weekContent.length} posts for week ${completedWeeks}`,
            duration: 3000,
          });

          setContentIdeas(prev => [...prev, ...weekContent]);
        },
        totalWeeks * postsPerWeekNum,
        includeWeekends,
        startDate,
        selectedThemeLabels // Pass the selected theme labels
      );

      if (!isSubscribed) {
        const { error: updateError } = await supabase
          .from('profiles')
          .update({
            usage_count: {
              calendar: (usageCount + 1)
            }
          })
          .eq('id', userId);

        if (updateError) throw updateError;
        setUsageCount(prev => prev + 1);
      }

      toast({
        title: "Content plan generated",
        description: `Successfully generated ${completedWeeks} weeks of content.`,
        duration: 5000,
      });
    } catch (error) {
      console.error('Error generating content plan:', error);
      toast({
        title: "Error",
        description: "Failed to generate content plan. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const groupPostsByWeek = (posts: ContentPlanPost[]) => {
    const weeks: { [key: string]: ContentPlanPost[] } = {};
    
    // Sort posts by date and time
    const sortedPosts = [...posts].sort((a, b) => {
      if (!a.suggestedDate || !b.suggestedDate) return 0;
      const dateA = new Date(`${a.suggestedDate}T${a.scheduledTime || '00:00'}`);
      const dateB = new Date(`${b.suggestedDate}T${b.scheduledTime || '00:00'}`);
      return dateA.getTime() - dateB.getTime();
    });

    // Group posts by week
    sortedPosts.forEach(post => {
      if (!post.suggestedDate) return;
      const date = new Date(post.suggestedDate);
      const weekStart = format(startOfWeek(date, { weekStartsOn: 1 }), 'yyyy-MM-dd');
      
      if (!weeks[weekStart]) {
        weeks[weekStart] = [];
      }
      weeks[weekStart].push({
        ...post,
        scheduledTime: post.scheduledTime || POST_TIMES[post.postType] || '12:00',
        description: post.description || ''
      });
    });

    // Convert to array and sort weeks chronologically
    return Object.entries(weeks)
      .sort(([weekA], [weekB]) => {
        const dateA = new Date(weekA);
        const dateB = new Date(weekB);
        return dateA.getTime() - dateB.getTime();
      })
      .map(([weekStart, posts]) => ({
        weekStart,
        posts: posts.sort((a, b) => {
          const dateA = new Date(`${a.suggestedDate}T${a.scheduledTime || '00:00'}`);
          const dateB = new Date(`${b.suggestedDate}T${b.scheduledTime || '00:00'}`);
          return dateA.getTime() - dateB.getTime();
        })
      }));
  };

  const CalendarActions = () => (
    <div className="flex items-center gap-2 mb-6">
      <Button
        variant="outline"
        size="sm"
        onClick={() => setShowSaveDialog(true)}
        className="flex items-center gap-2"
      >
        <CalendarIcon className="h-4 w-4" />
        Save to Calendar
      </Button>
      <Button
        variant="outline"
        size="sm"
        onClick={downloadICalFile}
        className="flex items-center gap-2"
      >
        <Download className="h-4 w-4" />
        Download Calendar
      </Button>
      <Button
        variant="outline"
        size="sm"
        onClick={copyToClipboard}
        className="flex items-center gap-2"
      >
        <Copy className="h-4 w-4" />
        Copy Calendar
      </Button>
    </div>
  );

  const handleRegeneratePost = async (weekIndex: number, postIndex: number, weekTheme: string) => {
    try {
      setLoadingPostIndices(prev => [...prev, { weekIndex, postIndex }]);
      const weeklyGroups = groupPostsByWeek(contentIdeas.flatMap(group => 
        group.posts.map(post => ({
          postType: group.type || 'text',
          topic: post.topic,
          category: post.category || group.type || 'text',
          keyPoints: post.keyPoints,
          suggestedDate: post.suggestedDate || format(new Date(), 'yyyy-MM-dd'),
          scheduledTime: post.scheduledTime || POST_TIMES[group.type] || '12:00',
          description: post.description || ''
        }))
      ));
      const targetPost = weeklyGroups[weekIndex]?.posts[postIndex];
      
      if (!targetPost || !userId) return;

      const newIdea = await generateContentIdeas(
        Boolean(useProfileInfo && userProfile && idealClientProfile),
        userId,
        () => {}, // Progress callback not needed for single regeneration
        1, // Generate one new idea
        includeWeekends,
        new Date(targetPost.suggestedDate || new Date())
      );

      if (newIdea.length > 0 && newIdea[0].posts.length > 0) {
        const newPost = newIdea[0].posts[0];
        const updatedPosts = [...contentIdeas];
        
        // Find and update the post in the original structure
        const groupIndex = updatedPosts.findIndex(group => 
          group.posts.some(p => 
            p.suggestedDate === targetPost.suggestedDate && 
            p.scheduledTime === targetPost.scheduledTime
          )
        );

        if (groupIndex !== -1) {
          const postIndex = updatedPosts[groupIndex].posts.findIndex(p => 
            p.suggestedDate === targetPost.suggestedDate && 
            p.scheduledTime === targetPost.scheduledTime
          );

          if (postIndex !== -1) {
            updatedPosts[groupIndex].posts[postIndex] = {
              ...newPost,
              suggestedDate: targetPost.suggestedDate,
              scheduledTime: targetPost.scheduledTime,
              postType: targetPost.postType
            };
          }
        }

        // Convert to ContentPlanPost[] format for saving
        const formattedPosts = updatedPosts.flatMap(group => 
          group.posts.map(post => ({
            postType: group.type || 'text',
            topic: post.topic || 'Untitled Post',
            category: post.category || group.type || 'text',
            keyPoints: post.keyPoints || [],
            suggestedDate: post.suggestedDate || format(new Date(), 'yyyy-MM-dd'),
            scheduledTime: post.scheduledTime || POST_TIMES[group.type] || '12:00',
            description: post.description || ''
          }))
        );

        setContentIdeas(updatedPosts);
        await saveContentPlan(userId, formattedPosts, 'Updated Content Plan');
        
        toast({
          title: "Post Regenerated",
          description: "New content has been generated for this post.",
        });
      }
    } catch (error) {
      console.error('Error regenerating post:', error);
      toast({
        title: "Error",
        description: "Failed to regenerate post. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoadingPostIndices(prev => prev.filter(p => !(p.weekIndex === weekIndex && p.postIndex === postIndex)));
    }
  };

  const handleRegenerateWeek = async (weekIndex: number) => {
    try {
      setLoadingWeekIndex(weekIndex);
      const weeklyGroups = groupPostsByWeek(contentIdeas.flatMap(group => 
        group.posts.map(post => ({
          postType: group.type || 'text',
          topic: post.topic,
          category: post.category || group.type || 'text',
          keyPoints: post.keyPoints,
          suggestedDate: post.suggestedDate || format(new Date(), 'yyyy-MM-dd'),
          scheduledTime: post.scheduledTime || POST_TIMES[group.type] || '12:00',
          description: post.description || ''
        }))
      ));
      const targetWeek = weeklyGroups[weekIndex];
      
      if (!targetWeek || !userId) return;

      const newIdeas = await generateContentIdeas(
        Boolean(useProfileInfo && userProfile && idealClientProfile),
        userId,
        () => {}, // Progress callback not needed for single week regeneration
        targetWeek.posts.length // Generate same number of posts as current week
      );

      if (newIdeas.length > 0) {
        const updatedPosts = [...contentIdeas];
        
        targetWeek.posts.forEach((oldPost, postIndex) => {
          if (newIdeas[postIndex]?.posts[0]) {
            const newPost = newIdeas[postIndex].posts[0];
            
            // Find and update the post in the original structure
            const groupIndex = updatedPosts.findIndex(group => 
              group.posts.some(p => 
                p.suggestedDate === oldPost.suggestedDate && 
                p.scheduledTime === oldPost.scheduledTime
              )
            );

            if (groupIndex !== -1) {
              const postIndex = updatedPosts[groupIndex].posts.findIndex(p => 
                p.suggestedDate === oldPost.suggestedDate && 
                p.scheduledTime === oldPost.scheduledTime
              );

              if (postIndex !== -1) {
                updatedPosts[groupIndex].posts[postIndex] = {
                  ...newPost,
                  suggestedDate: oldPost.suggestedDate,
                  scheduledTime: oldPost.scheduledTime,
                  postType: oldPost.postType
                };
              }
            }
          }
        });

        // Convert to ContentPlanPost[] format for saving
        const formattedPosts = updatedPosts.flatMap(group => 
          group.posts.map(post => ({
            postType: group.type || 'text',
            topic: post.topic || 'Untitled Post',
            category: post.category || group.type || 'text',
            keyPoints: post.keyPoints || [],
            suggestedDate: post.suggestedDate || format(new Date(), 'yyyy-MM-dd'),
            scheduledTime: post.scheduledTime || POST_TIMES[group.type] || '12:00',
            description: post.description || ''
          }))
        );

        setContentIdeas(updatedPosts);
        await saveContentPlan(userId, formattedPosts, 'Updated Content Plan');
        
        toast({
          title: "Week Regenerated",
          description: "New content has been generated for all posts in this week.",
        });
      }
    } catch (error) {
      console.error('Error regenerating week:', error);
      toast({
        title: "Error",
        description: "Failed to regenerate week. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoadingWeekIndex(null);
    }
  };

  const downloadICalFile = () => {
    const formattedPosts = contentIdeas.flatMap(group => 
      group.posts.map(post => ({
        ...post,
        postType: group.type,
        scheduledTime: POST_TIMES[group.type] || '12:00'
      }))
    );

    const iCalContent = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'PRODID:-//Medical Post//Content Calendar//EN',
      ...formattedPosts.map(post => {
        const date = new Date(post.suggestedDate);
        const [hours, minutes] = post.scheduledTime.split(':');
        date.setHours(parseInt(hours), parseInt(minutes));
        
        const dateString = format(date, "yyyyMMdd'T'HHmmss");
        const endDate = new Date(date.getTime() + 60 * 60 * 1000); // 1 hour duration
        const endDateString = format(endDate, "yyyyMMdd'T'HHmmss");
        
        return [
          'BEGIN:VEVENT',
          `DTSTART:${dateString}`,
          `DTEND:${endDateString}`,
          `SUMMARY:${post.postType.toUpperCase()}: ${post.topic}`,
          `DESCRIPTION:${post.keyPoints.join('\\n')}`,
          'END:VEVENT'
        ].join('\n');
      }),
      'END:VCALENDAR'
    ].join('\n');

    const blob = new Blob([iCalContent], { type: 'text/calendar' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'content-calendar.ics';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const copyToClipboard = async () => {
    const formattedPosts = contentIdeas.flatMap(group => 
      group.posts.map(post => ({
        ...post,
        postType: group.type,
        scheduledTime: POST_TIMES[group.type] || '12:00'
      }))
    );

    const textContent = formattedPosts.map(post => {
      return [
        `Date: ${format(new Date(post.suggestedDate), 'MMMM d, yyyy')}`,
        `Time: ${post.scheduledTime}`,
        `Type: ${post.postType}`,
        `Topic: ${post.topic}`,
        '\nKey Points:',
        ...post.keyPoints.map(point => `- ${point}`),
        '-------------------'
      ].join('\n');
    }).join('\n\n');

    await navigator.clipboard.writeText(textContent);
    
    toast({
      title: "Copied to Clipboard",
      description: "Calendar content has been copied to your clipboard.",
    });
  };

  const renderContentPlan = () => {
    if (!contentIdeas || contentIdeas.length === 0) {
      return null;
    }

    // Convert GroupedContentPlan[] to ContentPlanPost[]
    const allPosts = contentIdeas.flatMap(group => 
      (group.posts || []).map(post => ({
        ...post,
        postType: group.type || 'text',
        suggestedDate: post.suggestedDate || format(new Date(), 'yyyy-MM-dd'),
        scheduledTime: post.scheduledTime || POST_TIMES[group.type || 'text'] || '12:00',
        description: post.description || '',
        category: post.category || group.type || 'text',
        topic: post.topic || 'Untitled Post',
        keyPoints: post.keyPoints || []
      }))
    );

    const weeklyPosts = groupPostsByWeek(allPosts);

    return (
      <div className="space-y-4">
        {contentIdeas.length > 0 && <CalendarActions />}
        <div className="mt-4 space-y-4">
          {weeklyPosts.map(({ weekStart, posts }, weekIndex) => {
            const weekId = `week-${weekStart}`;
            const isWeekCollapsed = collapsedWeeks[weekId];
            const isLoadingWeek = loadingWeekIndex === weekIndex;
            const loadingPost = loadingPostIndices.find(p => p.weekIndex === weekIndex);
            
            // Find the theme for this week's posts
            const weekTheme = contentIdeas.find(group => 
              group.posts.some(post => post.suggestedDate === weekStart)
            )?.theme;

            return (
              <WeekCard
                key={weekId}
                weekStart={weekStart}
                posts={posts || []}
                weekIndex={weekIndex}
                onEditPost={handleEditPost}
                onRegeneratePost={handleRegeneratePost}
                onRegenerateWeek={handleRegenerateWeek}
                isCollapsed={isWeekCollapsed}
                onToggleCollapse={() => toggleCollapse(weekId, 'week')}
                isLoading={isLoadingWeek}
                loadingPostIndex={loadingPost?.postIndex}
                theme={weekTheme}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const handleEditPost = async (weekIndex: number, postIndex: number, post: ContentPlanPost) => {
    // Implementation will be added in the UI layer
    console.log('Editing post:', { weekIndex, postIndex, post });
  };

  const handleSaveUserProfile = async (profile: UserProfile) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        throw new Error('No authenticated user found');
      }

      const { error } = await supabase
        .from('profiles')
        .update({ user_profile: profile })
        .eq('id', session.user.id);

      if (error) throw error;

      setUserProfile(profile);
      setShowUserProfileDialog(false);
      toast({
        title: "Profile saved",
        description: "Your profile has been saved successfully.",
      });

      // If user profile is saved but target audience isn't set up yet, show that dialog
      if (!idealClientProfile) {
        setShowProfileDialog(true);
      }
    } catch (error) {
      console.error('Error saving user profile:', error);
      toast({
        title: "Error saving profile",
        description: "Unable to save your profile. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleSaveProfile = async (profile: IdealClientProfile) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        throw new Error('No authenticated user found');
      }

      const { error } = await supabase
        .from('profiles')
        .update({ ideal_client_profile: profile })
        .eq('id', session.user.id);

      if (error) throw error;

      setIdealClientProfile(profile);
      setShowProfileDialog(false);
      toast({
        title: "Profile saved",
        description: "Your target audience profile has been saved successfully.",
      });
    } catch (error) {
      console.error('Error saving ideal client profile:', error);
      toast({
        title: "Error saving profile",
        description: "Unable to save your target audience profile. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleSave = async () => {
    try {
      if (!userId) {
        toast({
          title: "Authentication required",
          description: "Please sign in to save your content plan.",
          variant: "destructive",
        });
        return;
      }

      if (!contentIdeas || contentIdeas.length === 0) {
        toast({
          title: "No content to save",
          description: "Please generate a content plan first.",
          variant: "destructive",
        });
        return;
      }

      if (!calendarName.trim()) {
        toast({
          title: "Name required",
          description: "Please enter a name for your calendar.",
          variant: "destructive",
        });
        return;
      }

      // Show saving toast
      toast({
        title: "Saving...",
        description: "Saving your content plan to the dashboard.",
      });

      // Convert to ContentPlanPost[] format for saving
      const formattedPlan = contentIdeas.flatMap(group => 
        group.posts.map(post => ({
          postType: group.type || 'text',
          topic: post.topic || 'Untitled Post',
          category: post.category || group.type || 'text',
          keyPoints: post.keyPoints || [],
          suggestedDate: post.suggestedDate || format(new Date(), 'yyyy-MM-dd'),
          scheduledTime: post.scheduledTime || POST_TIMES[group.type] || '12:00',
          description: post.description || ''
        }))
      );

      const result = await saveContentPlan(userId, formattedPlan, calendarName);
      
      if (result) {
        setShowSaveDialog(false);
        setCalendarName('');
        toast({
          title: "Calendar Saved",
          description: "Your content calendar has been saved to your dashboard.",
        });
        // Navigate to dashboard after successful save
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error saving content plan:', error);
      toast({
        title: "Error",
        description: "Failed to save your content plan. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleGenerateThemes = async () => {
    try {
      setIsGeneratingThemes(true);
      
      // Profiles are now already in the correct format
      const newThemes = await generateThemes(
        themeContext,
        userProfile || undefined,
        idealClientProfile || undefined
      );
      
      setThemeOptions(newThemes);
      toast({
        title: "Themes Generated",
        description: "New content themes have been generated based on your profile and target audience."
      });
    } catch (error) {
      console.error('Error generating themes:', error);
      toast({
        title: "Error",
        description: "Failed to generate themes. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsGeneratingThemes(false);
    }
  };

  const handleModelChange = (model: AIModel) => {
    setAIModel(model);
    setCurrentAIModel(model);
    toast({
      title: "Model Changed",
      description: `Switched to ${model}`,
    });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 sm:px-12 md:px-16 lg:px-24 max-w-6xl pt-12 pb-12">
      <div className="text-center py-6 sm:py-8 pt-20">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 mb-4">LinkedIn Content Planner</h1>
        <p className="text-base sm:text-lg text-gray-600 max-w-2xl mx-auto">
          Create and schedule engaging LinkedIn content that resonates with your professional network
        </p>
        <div className="flex flex-col sm:flex-row justify-center gap-3 mt-6">
          <Button
            variant="outline"
            size="lg"
            onClick={() => setShowUserProfileDialog(true)}
            className="gap-2"
          >
            <Edit2 className="h-4 w-4" />
            Edit Your Profile
          </Button>
          <Button
            variant="outline"
            size="lg"
            onClick={() => setShowProfileDialog(true)}
            className="gap-2"
          >
            <Edit2 className="h-4 w-4" />
            Edit Target Audience
          </Button>
        </div>

        {/* Model Selection */}
        <ModelSelector
          currentModel={currentAIModel}
          onModelChange={handleModelChange}
          className="mt-4"
        />
      </div>

      <div className="mt-8 space-y-6">
        <Card className="p-4 sm:p-6 mb-8">
          <div className="space-y-4 sm:space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <Label>Plan Duration</Label>
                <Select value={selectedDuration} onValueChange={setSelectedDuration}>
                  <SelectTrigger className="w-full bg-white mt-2">
                    <SelectValue placeholder="Select plan duration" />
                  </SelectTrigger>
                  <SelectContent className="bg-white">
                    {DURATION_OPTIONS.map(option => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div>
                <Label>Start Date</Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      className={cn(
                        "w-full mt-2 justify-start text-left font-normal",
                        !startDate && "text-muted-foreground"
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {startDate ? format(startDate, "PPP") : <span>Pick a date</span>}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0 bg-white" align="start">
                    <Calendar
                      mode="single"
                      selected={startDate}
                      onSelect={(date) => date && setStartDate(date)}
                      initialFocus
                      className="bg-white rounded-md border"
                    />
                  </PopoverContent>
                </Popover>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <Label>Posts per Week</Label>
                <Select value={postsPerWeek} onValueChange={setPostsPerWeek}>
                  <SelectTrigger className="w-full bg-white mt-2">
                    <SelectValue placeholder="Select posts per week" />
                  </SelectTrigger>
                  <SelectContent className="bg-white">
                    {POSTS_PER_WEEK_OPTIONS.map(option => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="flex items-center mt-8">
                <Switch
                  id="weekend-posts"
                  checked={includeWeekends}
                  onCheckedChange={setIncludeWeekends}
                />
                <Label htmlFor="weekend-posts" className="ml-2">
                  Include weekend posts
                </Label>
              </div>
            </div>

            <div>
              <Label className="mb-2 block">Content Types</Label>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                {CONTENT_TYPES.map(type => (
                  <div key={type.id} className="flex items-center space-x-2 bg-white p-2 rounded-md border">
                    <input
                      type="checkbox"
                      id={`content-type-${type.id}`}
                      checked={selectedContentTypes.includes(type.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedContentTypes(prev => [...prev, type.id]);
                        } else {
                          setSelectedContentTypes(prev => 
                            prev.filter(t => t !== type.id)
                          );
                        }
                      }}
                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                    />
                    <Label htmlFor={`content-type-${type.id}`} className="cursor-pointer text-sm">
                      {type.label}
                    </Label>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex items-center space-x-2 bg-white p-3 rounded-md border">
              <Switch
                id="use-profile"
                checked={useProfileInfo}
                onCheckedChange={setUseProfileInfo}
              />
              <Label htmlFor="use-profile" className="text-sm">Use my profile information</Label>
            </div>

            <div>
              <Label>Theme Context</Label>
              <textarea
                value={themeContext}
                onChange={(e) => setThemeContext(e.target.value)}
                placeholder="Add any additional context for theme generation (e.g., specific industry focus, current trends, or particular areas you'd like to emphasize)"
                className="w-full mt-2 p-2 border rounded-md h-24 text-sm"
              />
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-2 gap-2">
                <p className="text-sm text-gray-600">
                  {!userProfile && !idealClientProfile 
                    ? "Set up your profile and target audience to get more personalized themes"
                    : `Themes will be generated based on ${[
                        userProfile && "your expertise and interests",
                        idealClientProfile && "your target audience"
                      ].filter(Boolean).join(" and ")}`
                  }
                </p>
                <Button
                  onClick={handleGenerateThemes}
                  disabled={isGeneratingThemes}
                  variant="outline"
                  className="w-full sm:w-auto whitespace-nowrap"
                >
                  {isGeneratingThemes ? 'Generating Themes...' : 'Generate Themes'}
                </Button>
              </div>
            </div>

            {themeOptions.length > 0 && (
              <div>
                <Label className="mb-2 block">Content Themes</Label>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                  {themeOptions.map(theme => (
                    <div key={theme.id} className="flex items-center space-x-2 bg-white p-2 rounded-md border">
                      <input
                        type="checkbox"
                        id={`theme-${theme.id}`}
                        checked={selectedThemes.includes(theme.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedThemes(prev => [...prev, theme.id]);
                          } else {
                            setSelectedThemes(prev => 
                              prev.filter(t => t !== theme.id)
                            );
                          }
                        }}
                        className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                      />
                      <Label htmlFor={`theme-${theme.id}`} className="cursor-pointer text-sm">
                        {theme.label}
                      </Label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <Button
              onClick={handleGenerateIdeas}
              disabled={isGenerating}
              className="w-full"
            >
              {isGenerating ? (
                <div className="flex items-center justify-center">
                  <span className="mr-2">Generating...</span>
                  <span>{generationProgress}%</span>
                </div>
              ) : (
                'Generate Content Plan'
              )}
            </Button>
          </div>
        </Card>

        {contentIdeas && contentIdeas.length > 0 ? (
          <div className="space-y-6">
            {renderContentPlan()}
          </div>
        ) : null}

        <UserProfileDialog
          open={showUserProfileDialog}
          onOpenChange={setShowUserProfileDialog}
          onSave={handleSaveUserProfile}
          existingProfile={userProfile}
        />

        <IdealClientProfileDialog
          open={showProfileDialog}
          onOpenChange={setShowProfileDialog}
          onSave={handleSaveProfile}
          existingProfile={idealClientProfile}
        />

        {/* Add the save dialog */}
        <Dialog open={showSaveDialog} onOpenChange={setShowSaveDialog}>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>Save Calendar</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="calendar-name" className="text-right">
                  Name
                </Label>
                <Input
                  id="calendar-name"
                  value={calendarName}
                  onChange={(e) => setCalendarName(e.target.value)}
                  className="col-span-3"
                  placeholder="Enter a name for your calendar"
                  autoFocus
                />
              </div>
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setShowSaveDialog(false)}>
                Cancel
              </Button>
              <Button onClick={handleSave}>Save</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};
