import { z } from "zod";
import { ElementType } from "./element-type";

export const ObjectFitType = z.enum(["Cover", "Contain"]);
export type ObjectFitType = z.infer<typeof ObjectFitType>;

export const AlignmentType = z.enum(["Left", "Center", "Right"]);
export type AlignmentType = z.infer<typeof AlignmentType>;

export const MarginSize = z.enum(["Small", "Medium", "Large"]);
export type MarginSizeType = z.infer<typeof MarginSize>;

export enum ImageInputType {
  Url = "url",
  Upload = "upload",
  Pixabay = "pixabay",
}

export const ImageInputTypeSchema = z.nativeEnum(ImageInputType);

export const ImageStyleSchema = z.object({
  opacity: z.number().min(0).max(100).default(100),
});

export const ContentImageStyleSchema = ImageStyleSchema.extend({
  objectFit: ObjectFitType.default("Cover"),
  alignment: AlignmentType.default("Center"),
  margin: MarginSize.default("Medium"),
});

export const ImageSourceSchema = z.object({
  src: z.union([z.string().url(), z.string().length(0)]).default(""),
  type: ImageInputTypeSchema.default(ImageInputType.Url),
  alt: z.string().default(""),
}).default({
  src: "",
  type: ImageInputType.Url,
  alt: ""
});

export const ContentImageSourceSchema = ImageSourceSchema;

export const ImageSchema = z.object({
  type: z.literal(ElementType.enum.ContentImage).default(ElementType.enum.ContentImage),
  source: ImageSourceSchema,
  style: ImageStyleSchema.default({}),
}).default({
  type: ElementType.enum.ContentImage,
  source: { src: "", type: ImageInputType.Url, alt: "" },
  style: { opacity: 100 }
});

export const ContentImageSchema = z.object({
  type: z.literal(ElementType.enum.ContentImage).default(ElementType.enum.ContentImage),
  source: ContentImageSourceSchema,
  style: ContentImageStyleSchema.default({}),
}).default({
  type: ElementType.enum.ContentImage,
  source: { src: "", type: ImageInputType.Url, alt: "" },
  style: { opacity: 100, objectFit: "Cover", alignment: "Center", margin: "Medium" }
});

export const DEFAULT_IMAGE_INPUT = ImageSchema.parse({});
export const DEFAULT_CONTENT_IMAGE_INPUT = ContentImageSchema.parse({});
export const DEFAULT_BACKGROUND_IMAGE_INPUT = ImageSchema.parse({
  style: {
    opacity: 30,
  },
});

export type ContentImageStyle = z.infer<typeof ContentImageStyleSchema>;
export type ContentImageSource = z.infer<typeof ContentImageSourceSchema>;
export type ContentImage = z.infer<typeof ContentImageSchema>; 