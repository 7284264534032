"use client";
import { useFormContext } from "react-hook-form";
import * as z from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Image, Plus } from "lucide-react";
import { DocumentFormReturn, BrandFieldPath, ImageSourceFieldPath } from "../lib/document-form-types";
import { ImageSourceFormField } from "./fields/image-source-form-field";

export function BrandForm({ onImageClick }: { onImageClick: (fieldName: string) => void }) {
  const form: DocumentFormReturn = useFormContext();

  return (
    <div className="grid gap-4">
      <div className="grid gap-2">
        <FormField
          control={form.control}
          name={"config.brand.name" as BrandFieldPath}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="Your name" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name={"config.brand.handle" as BrandFieldPath}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Handle</FormLabel>
              <FormControl>
                <Input placeholder="@yourhandle" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name={"config.brand.avatar" as BrandFieldPath}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Avatar</FormLabel>
              <FormControl>
                <ImageSourceFormField
                  fieldName={"config.brand.avatar.source" as ImageSourceFieldPath}
                  form={form}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
}
