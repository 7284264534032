import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { TONE_ICONS, POST_TYPE_ICONS } from '@/constants/post-creator';
import type { PostType } from '@/types/post';

interface PostStyleSelectorProps {
  selectedTones: string[];
  onTonesChange: (tones: string[]) => void;
  selectedType: PostType;
  onTypeChange: (type: PostType) => void;
  targetAudience: 'peers' | 'clients';
  onTargetAudienceChange: (audience: 'peers' | 'clients') => void;
  showAllPostTypes?: boolean;
  onShowAllPostTypesChange?: (show: boolean) => void;
}

export function PostStyleSelector({
  selectedTones,
  onTonesChange,
  selectedType,
  onTypeChange,
  targetAudience,
  onTargetAudienceChange,
  showAllPostTypes = false,
  onShowAllPostTypesChange
}: PostStyleSelectorProps) {
  return (
    <div className="space-y-4">
      {/* Audience Selection */}
      <div className="p-4 bg-white rounded-lg border">
        <Label className="text-sm font-medium mb-3">Target Audience</Label>
        <div className="grid grid-cols-2 gap-2">
          <Button
            variant={targetAudience === 'peers' ? "default" : "outline"}
            className={`h-16 w-full p-1 flex flex-col items-center justify-center gap-1 text-xs ${
              targetAudience === 'peers' 
                ? 'bg-primary/20 border-2 border-primary text-primary font-medium shadow-sm' 
                : ''
            }`}
            onClick={() => onTargetAudienceChange('peers')}
          >
            <span className="text-lg">👥</span>
            <span className="text-[10px] text-center leading-tight">Medical Peers</span>
          </Button>
          <Button
            variant={targetAudience === 'clients' ? "default" : "outline"}
            className={`h-16 w-full p-1 flex flex-col items-center justify-center gap-1 text-xs ${
              targetAudience === 'clients' 
                ? 'bg-primary/20 border-2 border-primary text-primary font-medium shadow-sm' 
                : ''
            }`}
            onClick={() => onTargetAudienceChange('clients')}
          >
            <span className="text-lg">👤</span>
            <span className="text-[10px] text-center leading-tight">Patients/Clients</span>
          </Button>
        </div>
      </div>

      {/* Writing Style Section */}
      <div className="p-4 bg-white rounded-lg border">
        <div className="flex items-center justify-between mb-3">
          <Label className="text-sm font-medium">Writing Style</Label>
        </div>
        <div className="flex flex-col gap-1.5">
          {Object.entries(TONE_ICONS).map(([tone, icon]) => (
            <Button
              key={tone}
              variant={selectedTones.includes(tone) ? "default" : "outline"}
              className={`w-full justify-start gap-2 text-left py-1.5 h-auto ${
                selectedTones.includes(tone) 
                  ? 'bg-primary/20 border-2 border-primary text-primary font-medium shadow-sm' 
                  : ''
              }`}
              onClick={() => {
                const newTones = selectedTones.includes(tone)
                  ? selectedTones.filter(t => t !== tone)
                  : [...selectedTones, tone];
                onTonesChange(newTones);
              }}
            >
              <span className="text-base">{icon}</span>
              <span className="text-sm capitalize">{tone}</span>
            </Button>
          ))}
        </div>
      </div>

      {/* Post Type Section */}
      <div className="p-4 bg-white rounded-lg border">
        <div className="flex items-center justify-between mb-3">
          <Label className="text-sm font-medium">Post Type</Label>
          {Object.keys(POST_TYPE_ICONS).length > 8 && onShowAllPostTypesChange && (
            <Button
              variant="ghost"
              size="sm"
              className="text-xs"
              onClick={() => onShowAllPostTypesChange(!showAllPostTypes)}
            >
              {showAllPostTypes ? 'Show Less' : 'Show More'}
            </Button>
          )}
        </div>
        <div className="grid grid-cols-4 gap-2">
          {Object.entries(POST_TYPE_ICONS)
            .slice(0, showAllPostTypes ? undefined : 8)
            .map(([type, icon]) => (
              <Button
                key={type}
                variant={selectedType === type ? "default" : "outline"}
                className={`h-16 w-full p-1 flex flex-col items-center justify-center gap-1 text-xs ${
                  selectedType === type 
                    ? 'bg-primary/20 border-2 border-primary text-primary font-medium shadow-sm' 
                    : ''
                }`}
                onClick={() => onTypeChange(type as PostType)}
              >
                <span className="text-lg">{icon}</span>
                <span className="text-[10px] text-center leading-tight capitalize">{type.replace('_', ' ')}</span>
              </Button>
            ))}
        </div>
      </div>
    </div>
  );
} 