import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { FileText, Calendar, Layout, Users, Star, ChevronUp, Zap, Wand2, BrainCircuit, BriefcaseMedical, Check, Linkedin, Twitter, Facebook } from 'lucide-react'; // Kept social icons for potential footer use later, although not currently used.
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { supabase } from "@/integrations/supabase/client";
import { Helmet } from 'react-helmet-async';
import BetaBanner from '@/components/BetaBanner';
import { BetaSpots } from '@/components/BetaSpots';

// --- Animation Variants ---
const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

// --- ScrollToTop Component ---
const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.button
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          className="fixed bottom-8 right-8 p-3 bg-primary/90 text-white rounded-full shadow-lg hover:bg-primary hover:scale-110 transition-all z-50"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          aria-label="Scroll back to top"
        >
          <ChevronUp className="h-6 w-6" />
        </motion.button>
      )}
    </AnimatePresence>
  );
};

// --- Main Index Component ---
const Index = () => {
  const navigate = useNavigate();
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [checkoutMessage, setCheckoutMessage] = useState<string>('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
     try {
      const { data: { session } } = await supabase.auth.getSession();
      setIsAuthenticated(!!session);

      if (session?.user) {
        const { data } = await supabase
          .from('profiles')
          .select('is_subscribed')
          .eq('id', session.user.id)
          .single();
        setIsSubscribed(!!data?.is_subscribed);
      }
    } catch (error) {
      console.error('Error checking authentication status:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckout = async () => {
     try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        setShowAuthModal(true);
        return;
      }

      const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/create-checkout`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to create checkout session');
      }

      const { url } = await response.json();
      if (url) {
        window.location.href = url;
      }
    } catch (error: unknown) {
      console.error('Error creating checkout session:', error);
      setCheckoutMessage(error instanceof Error ? error.message : 'An unexpected error occurred. Please try again.');
    }
  };

  // --- Loading State ---
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-sage-50/10">
        <div className="relative flex items-center justify-center w-16 h-16">
            <div className="absolute inset-0 border-4 border-primary/20 rounded-full"></div>
            <div className="absolute inset-0 border-t-4 border-primary rounded-full animate-spin"></div>
            <BriefcaseMedical className="h-6 w-6 text-primary animate-pulse" />
        </div>
      </div>
    );
  }

  // --- Main Page Render ---
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <style>
          {`
            @font-face {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400 700;
              font-display: swap;
              src: url('/fonts/inter-var.woff2') format('woff2');
            }
            body {
              font-family: 'Inter', sans-serif;
            }
          `}
        </style>
        <title>Medical Post - Create Engaging LinkedIn Content, Faster</title>
        <meta name="description" content="Medical Post helps busy UK healthcare professionals craft impactful LinkedIn content in minutes, optimise their profile, and build their network, saving valuable time." />
        {/* Consider adding Open Graph / Twitter Card meta tags here for better social sharing */}
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-sage-50/20 to-white">
        {/* Background elements */}
        <div
          className="fixed inset-0 w-full h-full pointer-events-none"
          style={{ transform: 'translateZ(0)', willChange: 'transform', backfaceVisibility: 'hidden' }}
          aria-hidden="true"
        >
          <div
            className="absolute inset-0 bg-[radial-gradient(theme(colors.gray.300)_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)] opacity-30"
            style={{ transform: 'translateZ(0)' }}
          />
          <div
            className="absolute -top-40 -left-80 w-[700px] h-[700px] bg-gradient-to-r from-primary/5 to-primary/10 rounded-full blur-3xl opacity-60"
            style={{ transform: 'translateZ(0)' }}
          />
          <div
            className="absolute -top-10 -right-80 w-[700px] h-[700px] bg-gradient-to-r from-sage-200/30 to-sage-100/20 rounded-full blur-3xl opacity-60"
            style={{ transform: 'translateZ(0)' }}
          />
        </div>

        {/* Content wrapper */}
        <div className="relative z-10 flex flex-col min-h-screen">
          <BetaBanner />
          <main className="flex-grow">
            {/* --- Hero Section --- */}
            <motion.section
              className="container mx-auto px-4 sm:px-6 lg:px-8 pt-20 md:pt-28 lg:pt-32 pb-16 md:pb-24"
              initial="initial"
              animate="animate"
              variants={staggerChildren}
              role="banner"
              aria-labelledby="hero-headline"
            >
              <div className="max-w-3xl mx-auto text-center">
   <motion.h1
      id="hero-headline"
      className="text-4xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-gray-900 leading-tight sm:leading-snug"
      variants={fadeInUp}
   >
      Elevate Your LinkedIn Presence <span className="text-primary">Whilst Focusing on Patient Care</span>
   </motion.h1>

   <motion.p
      className="mt-5 md:mt-6 text-lg sm:text-xl text-gray-600 max-w-2xl mx-auto"
      variants={fadeInUp}
   >
      AI-powered content creation for busy NHS professionals. Build your digital presence <span className="font-semibold">in minutes, not hours</span>.
   </motion.p>

   {/* Key Benefits (Simplified) */}
   <motion.div
      className="mt-8 md:mt-10 grid grid-cols-1 sm:grid-cols-3 gap-6 text-gray-700"
      variants={fadeInUp}
   >
      <div className="flex flex-col items-center gap-2">
         <Zap className="h-7 w-7 text-primary" aria-hidden="true"/>
         <p className="text-sm sm:text-base font-medium text-gray-800">Posts in Seconds</p>
         <p className="text-xs sm:text-sm text-gray-500">From idea to polished content instantly</p>
      </div>
      <div className="flex flex-col items-center gap-2">
         <BrainCircuit className="h-7 w-7 text-primary" aria-hidden="true"/>
         <p className="text-sm sm:text-base font-medium text-gray-800">Profile Optimisation</p>
         <p className="text-xs sm:text-sm text-gray-500">Highlight your expertise properly</p>
      </div>
      <div className="flex flex-col items-center gap-2">
         <Calendar className="h-7 w-7 text-primary" aria-hidden="true"/>
         <p className="text-sm sm:text-base font-medium text-gray-800">Time Back</p>
         <p className="text-xs sm:text-sm text-gray-500">Save 6+ hours weekly</p>
      </div>
   </motion.div>

   {/* CTA Button */}
   <motion.div
      className="mt-10 md:mt-12"
      variants={fadeInUp}
   >
      <Button
         size="lg"
         onClick={() => navigate('/login')}
         className="w-full sm:w-auto bg-primary hover:bg-primary/90 text-primary-foreground transform hover:scale-[1.03] transition-transform duration-200 shadow-lg hover:shadow-xl px-8 py-3 text-base"
         aria-label="Start your free 14-day Pro trial, no card required"
      >
         Try for Free
         <span className="hidden sm:inline text-sm ml-2 font-normal opacity-80">• No card required</span>
      </Button>
   </motion.div>
</div>
              
               {/* Optional Visual Element Below - Kept but simplified */}
               <motion.div
                    className="mt-16 md:mt-20 max-w-4xl mx-auto"
                    variants={fadeInUp}
                >
                    <div className="relative rounded-xl shadow-xl overflow-hidden border border-gray-200/60 aspect-[16/10] group transition-all duration-300 ease-out hover:shadow-2xl">
                        <img
                            src="/images/product-preview-updated.png" // Ensure path is correct
                            alt="Screenshot of the MedicalPost application interface showing content generation and profile analysis features."
                            className="w-full h-full object-cover transition-transform duration-300 ease-out group-hover:scale-[1.02]"
                            fetchpriority="high" // Prioritize if likely LCP
                            width="1200" // Adjust width based on image dimensions
                            height="750" // Adjust height for 16:10 aspect ratio
                            loading="eager" // Load immediately if above the fold
                        />
                    </div>
                </motion.div>
            </motion.section>

             {/* --- Trust Indicators Section (Moved Up) --- */}
            <motion.section
              className="container mx-auto px-4 sm:px-6 lg:px-8 pt-12 pb-16 md:pt-16 md:pb-20 lg:pb-24 border-t border-gray-200/80"
              initial="initial"
              whileInView="animate"
              viewport={{ once: true, amount: 0.2 }}
              variants={fadeInUp}
              aria-labelledby="trust-heading"
            >
              <h3 id="trust-heading" className="text-center text-sm sm:text-base font-semibold text-gray-500 mb-10 tracking-wider uppercase">
                Trusted by Leading UK Healthcare Professionals & Organisations
              </h3>
              {/* Logo Cloud */}
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-8 gap-y-10 md:gap-x-12 items-center justify-items-center opacity-75 hover:opacity-100 transition-opacity duration-300 max-w-4xl mx-auto">
                {/* Provide specific dimensions if known, otherwise rely on height/width auto */}
                <img src="/logos/hospital1.svg" alt="Partner Hospital Logo 1" className="h-20 sm:h-20 w-auto max-w-[150px] filter grayscale hover:filter-none transition-all duration-300" width="150" height="36" />
                <img src="/logos/hospital2.svg" alt="Partner Hospital Logo 2" className="h-20 sm:h-20 w-auto max-w-[150px] filter grayscale hover:filter-none transition-all duration-300" width="150" height="36" />
                <img src="/logos/hospital3.jpg" alt="Partner Medical Association Logo" className="h-20 sm:h-20 w-auto max-w-[150px] filter grayscale hover:filter-none transition-all duration-300" width="150" height="36" />
                <img src="/logos/hospital4.svg" alt="Partner University Medical Logo" className="h-20 sm:h-20 w-auto max-w-[150px] filter grayscale hover:filter-none transition-all duration-300" width="150" height="36" />
              </div>

              {/* Key Metrics */}
              <div className="flex flex-wrap justify-center items-baseline gap-x-10 gap-y-8 sm:gap-x-16 mt-16 md:mt-20 text-center">
                <motion.div variants={fadeInUp}>
                  <p className="text-3xl sm:text-4xl font-bold text-primary">75%</p>
                  <p className="text-sm text-gray-600 mt-1 tracking-wide">Less Time Writing Posts</p>
                </motion.div>
                <motion.div variants={fadeInUp}>
                  <p className="text-3xl sm:text-4xl font-bold text-primary">3x</p>
                  <p className="text-sm text-gray-600 mt-1 tracking-wide">Higher Post Engagement</p>
                </motion.div>
                <motion.div variants={fadeInUp}>
                  <p className="text-3xl sm:text-4xl font-bold text-primary">47%</p>
                  <p className="text-sm text-gray-600 mt-1 tracking-wide">Average Network Growth</p>
                </motion.div>
              </div>
            </motion.section>

            {/* --- Container for the rest of the sections --- */}
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">

                {/* --- Beta Testers Wanted Section --- */}
                <motion.section
                  id="beta"
                  className="py-20 my-16 md:my-20 relative group scroll-mt-20"
                  initial="initial"
                  whileInView="animate"
                  viewport={{ once: true, amount: 0.3 }}
                  variants={fadeInUp}
                  aria-labelledby="beta-heading"
                >
                  {/* Outer blur effect */}
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-primary to-blue-500 rounded-lg blur opacity-40 group-hover:opacity-50 transition duration-1000 group-hover:duration-300 z-0" aria-hidden="true"></div>
                  {/* Content Container */}
                  <div className="relative rounded-lg px-4 py-16 z-10 border border-transparent">
                    <div className="max-w-3xl mx-auto text-center">
                      <div className="inline-block px-4 py-1.5 bg-primary/10 text-primary rounded-full text-sm font-medium mb-5">
                        Exclusive Beta Programme
                      </div>
                      <h2 id="beta-heading" className="text-3xl md:text-4xl font-bold mb-5 text-gray-900">Help Shape Medical Post</h2>
                      <p className="text-lg text-gray-600 mb-8">
                        Join our limited beta programme for UK healthcare professionals. Get early access, provide feedback, and enjoy exclusive benefits. Only <BetaSpots totalSpots={25} /> places remaining!
                      </p>
                      <div className="grid sm:grid-cols-3 gap-6 mb-10 text-gray-700">
                        <div className="flex flex-col items-center justify-center gap-2">
                          <Star className="text-primary h-5 w-5" aria-hidden="true"/>
                          <span className="text-sm text-center"><span className="font-semibold">40% Lifetime Discount</span></span>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-2">
                          <Calendar className="text-primary h-5 w-5" aria-hidden="true"/>
                          <span className="text-sm text-center"><span className="font-semibold">Extended Free Access</span></span>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-2">
                          <Users className="text-primary h-5 w-5" aria-hidden="true"/>
                          <span className="text-sm text-center">Direct Input on Features</span>
                        </div>
                      </div>
                      <Button
                        size="lg"
                        onClick={() => navigate('/beta')}
                        className="bg-primary hover:bg-primary/90 transform hover:scale-105 transition-transform"
                        aria-label="Apply for the Medical Post beta programme"
                      >
                        Apply for Beta Access
                      </Button>
                    </div>
                  </div>
                </motion.section>

                {/* --- Features Section --- */}
                <motion.section
                  id="features"
                  className="scroll-mt-20 py-24"
                  initial="initial"
                  whileInView="animate"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={staggerChildren}
                  aria-labelledby="features-heading"
                >
                  <motion.div
                    className="text-center mb-16 md:mb-20"
                    variants={fadeInUp}
                  >
                    <h2 id="features-heading" className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
                      Everything You Need for LinkedIn Success
                    </h2>
                    <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                      Spend less time managing LinkedIn, more time making a difference.
                    </p>
                  </motion.div>

                  <div className="flex flex-col gap-24 max-w-5xl mx-auto">
                    {/* Feature 1: Profile Optimisation */}
                    <motion.div
                      className="flex flex-col lg:flex-row items-center gap-12 lg:gap-16"
                      variants={fadeInUp}
                    >
                      <div className="lg:w-1/2 relative rounded-xl overflow-hidden border border-gray-200 shadow-md aspect-video group">
                        <img
                          src="/images/feature-profile-updated.png"
                          alt="MedicalPost Profile Optimisation interface showing suggestions for a healthcare professional's LinkedIn profile."
                          className="w-full h-full object-cover group-hover:scale-[1.03] transition-transform duration-300 ease-out"
                          loading="lazy"
                          width="1024" height="625" // Aspect ratio approx 1.64:1
                        />
                      </div>
                      <div className="lg:w-1/2 space-y-5">
                         <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center mb-3">
                            <Layout className="h-6 w-6 text-primary" aria-hidden="true"/>
                        </div>
                        <h3 className="text-2xl font-semibold text-gray-900">AI-Powered Profile Review</h3>
                        <p className="text-gray-600">
                            Ensure your LinkedIn profile clearly communicates your expertise and attracts the right opportunities. Get specific, actionable suggestions tailored for medical professionals.
                        </p>
                        <ul className="space-y-2 text-gray-700 pt-2">
                            <li className="flex items-start gap-2"><span className="text-primary mt-1 font-bold">✓</span><span>Highlight clinical skills & specialities effectively.</span></li>
                            <li className="flex items-start gap-2"><span className="text-primary mt-1 font-bold">✓</span><span>Showcase research, publications & presentations.</span></li>
                            <li className="flex items-start gap-2"><span className="text-primary mt-1 font-bold">✓</span><span>Optimise language for your specific field and goals.</span></li>
                        </ul>
                      </div>
                    </motion.div>

                    {/* Feature 2: Ideas & Content Plan */}
                    <motion.div
                      className="flex flex-col lg:flex-row-reverse items-center gap-12 lg:gap-16"
                      variants={fadeInUp}
                    >
                      <div className="lg:w-1/2 relative rounded-xl overflow-hidden border border-gray-200 shadow-md aspect-video group">
                        <img
                          src="/images/feature-planning-updated.png"
                          alt="MedicalPost Content Calendar interface displaying scheduled posts and topic ideas."
                          className="w-full h-full object-cover group-hover:scale-[1.03] transition-transform duration-300 ease-out"
                          loading="lazy"
                          width="1024" height="576" // 16:9 aspect ratio
                        />
                      </div>
                      <div className="lg:w-1/2 space-y-5">
                         <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center mb-3">
                            <BrainCircuit className="h-6 w-6 text-primary" aria-hidden="true"/>
                        </div>
                        <h3 className="text-2xl font-semibold text-gray-900">Smart Content Planning</h3>
                        <p className="text-gray-600">
                            Never wonder what to post next. Receive AI-generated topic ideas relevant to your speciality and organise your content schedule with ease.
                        </p>
                         <ul className="space-y-2 text-gray-700 pt-2">
                            <li className="flex items-start gap-2"><span className="text-primary mt-1 font-bold">✓</span><span>Generate content ideas based on medical trends & your profile.</span></li>
                            <li className="flex items-start gap-2"><span className="text-primary mt-1 font-bold">✓</span><span>Visualise your posting schedule (Google Calendar sync coming).</span></li>
                            <li className="flex items-start gap-2"><span className="text-primary mt-1 font-bold">✓</span><span>Maintain a consistent and professional online presence.</span></li>
                        </ul>
                      </div>
                    </motion.div>

                    {/* Feature 3: AI Post Generator */}
                    <motion.div
                      className="flex flex-col lg:flex-row items-center gap-12 lg:gap-16"
                      variants={fadeInUp}
                    >
                      <div className="lg:w-1/2 relative rounded-xl overflow-hidden border border-gray-200 shadow-md aspect-video group">
                        <video
                          src="/videos/screen-recording.mp4"
                          alt="MedicalPost AI Post Generator screen recording."
                          className="w-full h-full object-cover group-hover:scale-[1.03] transition-transform duration-300 ease-out"
                          loading="lazy"
                          width="1024" height="576" // 16:9 aspect ratio
                          controls
                          muted
                          loop
                          autoPlay
                        />
                      </div>
                      <div className="lg:w-1/2 space-y-5">
                        <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center mb-3">
                            <Wand2 className="h-6 w-6 text-primary" aria-hidden="true"/>
                        </div>
                        <h3 className="text-2xl font-semibold text-gray-900">Effortless Post Drafting</h3>
                        <p className="text-gray-600">
                            Quickly transform your thoughts and brief notes into engaging LinkedIn posts. Our AI assists in drafting content that resonates, whilst maintaining a professional tone.
                        </p>
                        <ul className="space-y-2 text-gray-700 pt-2">
                            <li className="flex items-start gap-2"><span className="text-primary mt-1 font-bold">✓</span><span>Draft posts from outlines or keywords in seconds.</span></li>
                            <li className="flex items-start gap-2"><span className="text-primary mt-1 font-bold">✓</span><span>Easily refine the tone and style to match your voice.</span></li>
                            <li className="flex items-start gap-2"><span className="text-primary mt-1 font-bold">✓</span><span>Create varied content suitable for different medical topics.</span></li>
                        </ul>
                      </div>
                    </motion.div>

                    {/* Feature 4: Carousel Studio */}
                    <motion.div
                      className="flex flex-col lg:flex-row-reverse items-center gap-12 lg:gap-16"
                      variants={fadeInUp}
                    >
                      <div className="lg:w-1/2 relative">
                         {/* Simplified visual - maybe show fewer, cleaner examples */}
                        <div className="grid grid-cols-2 gap-4">
                          <motion.div
                             className="rounded-xl overflow-hidden border border-gray-200 shadow-md aspect-square transform -rotate-3 transition-transform duration-300 hover:rotate-0 hover:scale-105"
                             whileHover={{ zIndex: 1 }}
                          >
                            <img src="/images/carousel-1a.png" alt="Example of a professional medical carousel slide template" className="w-full h-full object-cover" loading="lazy" width="400" height="400"/>
                          </motion.div>
                          <motion.div
                            className="rounded-xl overflow-hidden border border-gray-200 shadow-md aspect-square mt-8 transform rotate-3 transition-transform duration-300 hover:rotate-0 hover:scale-105"
                             whileHover={{ zIndex: 1 }}
                          >
                            <img src="/images/carousel-1b.png" alt="Another example of a carousel slide for medical content" className="w-full h-full object-cover" loading="lazy" width="400" height="400"/>
                          </motion.div>
                        </div>
                      </div>
                      <div className="lg:w-1/2 space-y-5">
                         <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center mb-3">
                            <FileText className="h-6 w-6 text-primary" aria-hidden="true"/> {/* Changed Icon */}
                        </div>
                        <h3 className="text-2xl font-semibold text-gray-900">Easy Carousel Creation</h3>
                        <p className="text-gray-600">
                            Visually engaging content gets noticed. Create professional, multi-slide LinkedIn carousels simply using our templates and intuitive editor.
                        </p>
                        <ul className="space-y-2 text-gray-700 pt-2">
                            <li className="flex items-start gap-2"><span className="text-primary mt-1 font-bold">✓</span><span>Use templates designed for clear medical communication.</span></li>
                            <li className="flex items-start gap-2"><span className="text-primary mt-1 font-bold">✓</span><span>Add your branding (logos, colours) effortlessly.</span></li>
                            <li className="flex items-start gap-2"><span className="text-primary mt-1 font-bold">✓</span><span>Design impactful carousels in under 5 minutes.</span></li>
                        </ul>
                      </div>
                    </motion.div>
                  </div>
                </motion.section>

                {/* --- Social Proof / Testimonials --- */}
                <motion.section
                  id="testimonials"
                  className="scroll-mt-20 py-24"
                  initial="initial"
                  whileInView="animate"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={staggerChildren}
                  aria-labelledby="testimonials-heading"
                >
                  <motion.div
                    className="text-center mb-16"
                    variants={fadeInUp}
                  >
                    <h2 id="testimonials-heading" className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">What Your Colleagues Say</h2>
                    <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                      Discover how Medical Post is helping UK healthcare professionals like you.
                    </p>
                  </motion.div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
                    {/* Testimonial 1 */}
                    <motion.div
                        className="bg-white p-6 rounded-lg shadow-md border border-gray-100 hover:shadow-lg transition-all duration-300 hover:-translate-y-1 flex flex-col"
                        variants={fadeInUp}
                    >
                         <p className="text-gray-600 mb-5 mt-5 relative italic flex-grow">
                            <span className="absolute -top-8 -left-3 text-5xl text-primary/20 font-serif leading-none" aria-hidden="true">“</span>
                             Medical Post is a game-changer. I'm saving hours each week, and my content feels much more professional and targeted. The profile suggestions were spot on.
                            <span className="absolute -bottom-8 right-0 text-5xl text-primary/20 font-serif leading-none" aria-hidden="true">”</span>
                        </p>
                        <div className="mt-auto pt-4 border-t border-gray-100">
                            <p className="font-semibold text-gray-800">Dr. Sarah Thompson</p>
                            <p className="text-sm text-gray-500">Consultant Cardiologist, London</p>
                        </div>
                    </motion.div>
                    {/* Testimonial 2 */}
                     <motion.div
                        className="bg-white p-6 rounded-lg shadow-md border border-gray-100 hover:shadow-lg transition-all duration-300 hover:-translate-y-1 flex flex-col"
                        variants={fadeInUp}
                    >
                        <p className="text-gray-600 mb-5 mt-5 relative italic flex-grow">
                            <span className="absolute -top-8 -left-3 text-5xl text-primary/20 font-serif leading-none" aria-hidden="true">“</span>
                             As a busy GP, finding time for LinkedIn was tricky. This tool makes it manageable and effective. The content ideas are relevant and the carousel maker is surprisingly simple.
                             <span className="absolute -bottom-8 right-0 text-5xl text-primary/20 font-serif leading-none" aria-hidden="true">”</span>
                        </p>
                         <div className="mt-auto pt-4 border-t border-gray-100">
                            <p className="font-semibold text-gray-800">Dr. James Chen</p>
                            <p className="text-sm text-gray-500">General Practitioner, Manchester</p>
                         </div>
                    </motion.div>
                    {/* Testimonial 3 */}
                    <motion.div
                        className="bg-white p-6 rounded-lg shadow-md border border-gray-100 hover:shadow-lg transition-all duration-300 hover:-translate-y-1 flex flex-col"
                        variants={fadeInUp}
                    >
                       <p className="text-gray-600 mb-5 mt-5 relative italic flex-grow">
                            <span className="absolute -top-8 -left-3 text-5xl text-primary/20 font-serif leading-none" aria-hidden="true">“</span>
                            Finally, a tool that understands the nuances of professional branding for medics on LinkedIn. The profile optimisation helped me better showcase my research impact. Highly recommended.
                            <span className="absolute -bottom-8 right-0 text-5xl text-primary/20 font-serif leading-none" aria-hidden="true">”</span>
                        </p>
                        <div className="mt-auto pt-4 border-t border-gray-100">
                            <p className="font-semibold text-gray-800">Prof. Emily Roberts</p>
                            <p className="text-sm text-gray-500">Clinical Researcher, Edinburgh</p>
                        </div>
                    </motion.div>
                  </div>
                </motion.section>

                {/* --- Pricing Section --- */}
                <motion.section
                  id="pricing"
                  className="scroll-mt-20 py-24 bg-gradient-to-b from-white via-sage-50/30 to-white rounded-lg my-10" // Added subtle margin
                  initial="initial"
                  whileInView="animate"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={staggerChildren}
                  aria-labelledby="pricing-heading"
                >
                  <motion.div
                    className="text-center mb-16"
                    variants={fadeInUp}
                  >
                    <h2 id="pricing-heading" className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Simple, Fair Pricing</h2>
                    <p className="text-lg text-gray-600 max-w-xl mx-auto">
                      Choose the plan that fits your needs. Start saving time and boosting your impact today.
                    </p>
                  </motion.div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto items-stretch">
                    {/* Free Plan */}
                    <motion.div
                        className="bg-white p-8 rounded-lg shadow-sm border border-gray-200 flex flex-col transition-transform hover:scale-[1.02] duration-300"
                        variants={fadeInUp}
                    >
                        <h3 className="text-xl font-semibold mb-2 text-gray-800">Starter</h3>
                        <p className="text-3xl font-bold mb-1">Free</p>
                        <p className="text-sm text-gray-500 mb-6">Get started with the basics</p>
                        <ul className="space-y-3 text-left text-gray-700 mb-8 flex-grow text-sm">
                            <li className="flex items-center gap-2"><CheckIcon />Basic Profile Analysis Report</li>
                            <li className="flex items-center gap-2"><CheckIcon />Weekly Content Idea Digest</li>
                            <li className="flex items-center gap-2"><CheckIcon />3 AI-Drafted Posts per Month</li>
                            <li className="flex items-center gap-2"><CheckIcon />1 Carousel Creation per Month</li>
                        </ul>
                        <Button variant="outline" onClick={() => navigate('/login')} className="w-full mt-auto">
                        Get Started Free
                        </Button>
                    </motion.div>

                    {/* Pro Plan */}
                    <motion.div
                        className="bg-white p-8 rounded-lg shadow-lg border-2 border-primary relative flex flex-col transition-transform hover:scale-[1.02] duration-300"
                        variants={fadeInUp}
                    >
                        <div className="absolute top-0 right-0 -mt-3 mr-3">
                            <span className="inline-block px-3 py-1 rounded-full bg-primary text-white text-xs font-semibold uppercase tracking-wider shadow">
                            Most Popular
                            </span>
                        </div>
                        <h3 className="text-xl font-semibold mb-2 text-gray-800">Pro</h3>
                        <p className="text-3xl font-bold mb-1">£29<span className="text-lg font-normal text-gray-600"> / month</span></p>
                        <p className="text-sm text-gray-500 mb-6">Full access & unlimited usage</p>
                        <ul className="space-y-3 text-left text-gray-700 mb-8 flex-grow text-sm">
                            <li className="flex items-center gap-2"><CheckIcon primary /><strong>Everything in Starter, plus:</strong></li>
                            <li className="flex items-center gap-2"><CheckIcon primary />Unlimited Profile Optimisation & Updates</li>
                            <li className="flex items-center gap-2"><CheckIcon primary />Advanced Content Planning Calendar</li>
                            <li className="flex items-center gap-2"><CheckIcon primary />Unlimited AI Post Drafting</li>
                            <li className="flex items-center gap-2"><CheckIcon primary />Unlimited Carousel Creation & Templates</li>
                            <li className="flex items-center gap-2"><CheckIcon primary />Performance Analytics (Coming Soon)</li>
                            <li className="flex items-center gap-2"><CheckIcon primary />Priority Support</li>
                        </ul>
                        <Button onClick={() => navigate('/login')} className="w-full mt-auto bg-primary hover:bg-primary/90">
                        Start 14-Day Pro Trial
                        </Button>
                        <p className="text-xs text-gray-500 text-center mt-3">No payment card required for trial.</p>
                    </motion.div>
                  </div>
                   <motion.p variants={fadeInUp} className="text-center mt-10 text-sm text-gray-600">
                    Prefer to pay annually? <a href="#pricing" onClick={(e) => {e.preventDefault(); alert('Annual plans coming soon!');}} className="text-primary hover:underline font-medium">Save 20% with an annual plan</a> (coming soon).
                  </motion.p>
                </motion.section>

                {/* --- FAQ Section --- */}
                <motion.section
                  id="faq"
                  className="scroll-mt-20 py-24"
                  initial="initial"
                  whileInView="animate"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={fadeInUp} // Animate as one block
                  aria-labelledby="faq-heading"
                >
                  <div className="text-center mb-16">
                    <h2 id="faq-heading" className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Your Questions Answered</h2>
                    <p className="text-lg text-gray-600 max-w-xl mx-auto">
                      Got a question? We've likely got the answer right here.
                    </p>
                  </div>
                  <div className="max-w-3xl mx-auto bg-white rounded-lg p-8 shadow-sm border border-gray-100 space-y-8">
                    {/* Using details/summary for potential expand/collapse, though not implemented yet */}
                    <div className="grid grid-cols-1 gap-6">
                      <div>
                        <h3 className="font-semibold mb-1 text-gray-800">How much time can I realistically save each week?</h3>
                        <p className="text-gray-600 text-sm">Our users typically find they save between 3 to 6 hours per week – time previously spent brainstorming ideas, writing drafts, creating visuals, and planning posts.</p>
                      </div>
                      <div>
                        <h3 className="font-semibold mb-1 text-gray-800">How does the AI work? Is it just generic content?</h3>
                        <p className="text-gray-600 text-sm">Not at all. Our AI is specifically trained on effective communication patterns within the healthcare sector. It provides relevant ideas and drafts content based on your input, which you always review and refine to ensure it reflects your authentic voice and expertise.</p>
                      </div>
                      <div>
                        <h3 className="font-semibold mb-1 text-gray-800">Will Medical Post work for my specific medical speciality?</h3>
                        <p className="text-gray-600 text-sm">Yes. You provide context about your speciality, areas of interest, and target audience. This allows the AI to tailor suggestions for profile improvements and content creation more accurately to your needs.</p>
                      </div>
                       <div>
                        <h3 className="font-semibold mb-1 text-gray-800">What sort of content can I create?</h3>
                        <p className="text-gray-600 text-sm">You can draft standard text posts, get help outlining longer articles, and create multi-slide carousels. It's ideal for sharing clinical insights, research summaries, professional updates, conference takeaways, practice news, and more.</p>
                      </div>
                      <div>
                        <h3 className="font-semibold mb-1 text-gray-800">What happens when my 14-day free Pro trial ends?</h3>
                        <p className="text-gray-600 text-sm">We'll let you know before it ends. You'll have the choice to upgrade to the paid Pro plan to keep using all the features. If you decide not to, your account will automatically switch to our free Starter plan with reduced limits.</p>
                      </div>
                      <div>
                        <h3 className="font-semibold mb-1 text-gray-800">Can I cancel my Pro subscription easily?</h3>
                        <p className="text-gray-600 text-sm">Absolutely. You can cancel your Pro subscription at any time directly from your account settings. You'll keep Pro access until the end of your current billing period (monthly or annual).</p>
                      </div>
                    </div>
                    <p className="text-center pt-6 border-t border-gray-100 text-gray-600">
                        Have another question? <a href="mailto:support@medicalpost.co.uk" className="text-primary hover:underline font-medium">Get in touch with support</a>
                    </p>
                  </div>
                </motion.section>

                {/* --- Call to Action --- */}
                <motion.section
                  className="text-center relative group my-24 py-16 px-6"
                  initial="initial"
                  whileInView="animate"
                  viewport={{ once: true, amount: 0.3 }}
                  variants={fadeInUp}
                  aria-labelledby="cta-heading"
                >
                  <div className="absolute -inset-2 bg-gradient-to-r from-primary/80 to-blue-500/80 rounded-xl blur-lg opacity-20 group-hover:opacity-30 transition duration-1000 z-0" aria-hidden="true"></div>
                  <div className="relative bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg p-10 md:p-16 shadow-xl z-10 overflow-hidden">
                    <h2 id="cta-heading" className="text-3xl md:text-4xl font-bold mb-4 text-white">Ready to Elevate Your LinkedIn Impact?</h2>
                    <p className="text-lg text-gray-300 mb-8 max-w-2xl mx-auto">
                      Join hundreds of UK healthcare professionals saving valuable time and building meaningful connections on LinkedIn. Start your free Pro trial today – no strings attached.
                    </p>
                    <div className="flex flex-col sm:flex-row gap-4 justify-center">
                      <Button
                        size="lg"
                        onClick={() => navigate('/login')}
                        className="bg-primary hover:bg-primary/90 text-white transform hover:scale-105 transition-transform duration-200 shadow-lg hover:shadow-primary/40"
                        aria-label="Start your free 14-day Pro trial now"
                      >
                        Start Free 14-Day Pro Trial
                      </Button>
                       {/* Example: Link to features if desired
                       <Button
                        size="lg"
                        variant="outline"
                        onClick={() => document.getElementById('features')?.scrollIntoView({ behavior: 'smooth' })}
                        className="border-gray-600 text-gray-300 hover:bg-gray-700 hover:text-white"
                       >
                        See Features
                       </Button>
                       */}
                    </div>
                    <p className="text-xs text-gray-400 mt-4">No payment card needed for the trial period.</p>
                  </div>
                </motion.section>

            </div> {/* End max-w-6xl container */}
          </main> {/* End Main Content Area */}

          


          {/* --- Modals & Floating Elements --- */}
          {/* Authentication Required Modal */}
          <Dialog open={showAuthModal} onOpenChange={setShowAuthModal}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Sign In Required</DialogTitle>
                <DialogDescription>
                  Please sign in or create an account to continue.
                </DialogDescription>
              </DialogHeader>
              <DialogFooter className="mt-4">
                <Button variant="outline" onClick={() => setShowAuthModal(false)}>Cancel</Button>
                <Button onClick={() => navigate('/login')}>Sign In / Register</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          {/* Checkout Message Toast */}
          <AnimatePresence>
            {checkoutMessage && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                className="fixed top-20 right-4 p-4 bg-red-100 border border-red-300 text-red-800 rounded-lg shadow-lg z-[60] text-sm max-w-sm" // Constrained width
                role="alert"
                aria-live="assertive"
              >
                {checkoutMessage}
                <button onClick={() => setCheckoutMessage('')} className="ml-4 font-bold text-red-800 hover:text-red-900" aria-label="Dismiss message">✕</button>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Subscribe Modal (Shows if logged in but not subscribed) */}
          {/* No change needed for this modal based on request */}
          {!isLoading && isAuthenticated && !isSubscribed && (
            <Dialog open={showSubscribeModal} onOpenChange={setShowSubscribeModal}>
              <DialogContent className="max-w-[90%] sm:max-w-md p-6">
                <DialogHeader className="space-y-2 text-center">
                  <DialogTitle className="text-xl sm:text-2xl font-bold">Unlock Medical Post Pro</DialogTitle>
                  <DialogDescription className="text-base text-gray-600">
                    Get unlimited access to all features and save hours weekly.
                  </DialogDescription>
                </DialogHeader>
                <div className="mt-6 space-y-4">
                  <ul className="space-y-2 text-sm sm:text-base text-gray-700">
                    <li className="flex items-center gap-2"><CheckIcon primary /> Unlimited Profile Optimisations</li>
                    <li className="flex items-center gap-2"><CheckIcon primary /> Unlimited AI Posts & Carousels</li>
                    <li className="flex items-center gap-2"><CheckIcon primary /> Advanced Content Planning</li>
                    <li className="flex items-center gap-2"><CheckIcon primary /> Priority Support & Early Access</li>
                  </ul>
                  <Button className="w-full bg-primary hover:bg-primary/90" size="lg" onClick={handleCheckout}>
                    Upgrade to Pro (£29/month)
                  </Button>
                  <p className="text-xs text-gray-500 text-center">Cancel anytime via your account settings.</p>
                </div>
              </DialogContent>
            </Dialog>
          )}

          <ScrollToTop />

        </div> {/* End Relative z-10 wrapper */}

      </div> {/* End Outer background wrapper */}
    </>
  );
};

// Helper component for check icons in lists
const CheckIcon = ({ primary }: { primary?: boolean }) => (
  <span className={`inline-flex items-center justify-center w-4 h-4 rounded-full flex-shrink-0 ${primary ? 'bg-primary/10' : 'bg-green-100'} mr-1.5`}>
    <Check className={`w-2.5 h-2.5 ${primary ? 'text-primary' : 'text-green-600'}`} strokeWidth={2.5} />
  </span>
);


export default Index;