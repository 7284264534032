import { createContext, useContext, ReactNode } from 'react';
import { Size, SizeOption, SIZES, DEFAULT_SIZE } from '../page-size';

interface SizeContextValue {
  currentSize: SizeOption;
  setCurrentSize: (size: SizeOption) => void;
  sizeConfig: Size;
}

const SizeContext = createContext<SizeContextValue | null>(null);

interface SizeProviderProps {
  children: ReactNode;
  value?: Partial<SizeContextValue>;
}

export function SizeProvider({ children, value }: SizeProviderProps) {
  const currentSize = value?.currentSize ?? DEFAULT_SIZE;
  const sizeConfig = SIZES[currentSize];
  
  const contextValue: SizeContextValue = {
    currentSize,
    setCurrentSize: value?.setCurrentSize ?? (() => {}),
    sizeConfig,
  };

  return (
    <SizeContext.Provider value={contextValue}>
      {children}
    </SizeContext.Provider>
  );
}

export function useSize() {
  const context = useContext(SizeContext);
  if (!context) {
    throw new Error('useSize must be used within a SizeProvider');
  }
  return context;
} 