"use client";
import * as React from "react";
import { Colors } from "@/lib/pallettes";

export function ColorThemeDisplay({ colors }: { colors: Colors }) {
  return (
    <div className="flex flex-row gap-1 items-center">
      <span
        className="h-4 w-4 rounded-full"
        style={{
          backgroundColor: colors.primary,
        }}
      ></span>
      <span
        className="h-4 w-4 rounded-full"
        style={{
          backgroundColor: colors.secondary,
        }}
      ></span>
      <span
        className="h-4 w-4 rounded-full"
        style={{
          backgroundColor: colors.background,
        }}
      ></span>
    </div>
  );
}
