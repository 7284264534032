import { useFormContext } from "react-hook-form";
import { DocumentFormReturn } from "./lib/document-form-types";
import { SIZES } from "./lib/page-size";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import { Label } from "./ui/label";
import { z } from "zod";
import { SizeSchema } from "./lib/validation/theme-schema";

type Size = z.infer<typeof SizeSchema>;

export function SizeSelector() {
  const form = useFormContext<DocumentFormReturn>();

  return (
    <div className="space-y-2">
      <Label>Carousel Size</Label>
      <RadioGroup
        value={form.watch("config.theme.size")}
        onValueChange={(value: Size) => {
          form.setValue("config.theme.size", value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }}
        className="flex gap-4"
      >
        {Object.entries(SIZES).map(([key, config]) => (
          <div key={key} className="flex items-center space-x-2">
            <RadioGroupItem value={key} id={`size-${key}`} />
            <Label htmlFor={`size-${key}`} className="font-normal">
              {config.label} ({config.width}x{config.height})
            </Label>
          </div>
        ))}
      </RadioGroup>
    </div>
  );
} 