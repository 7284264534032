import { Button } from "../../components/ui/button";
import { Plus, Type, TextCursor, Text, ImageIcon } from "lucide-react";
import { getSlideNumber } from "../lib/field-path";
import { useFormContext } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import {
  DocumentFormReturn,
  ElementArrayFieldPath,
} from "../lib/document-form-types";
import { DEFAULT_CONTENT_IMAGE_INPUT } from "../lib/validation/image-schema";
import {
  DescriptionSchema,
  SubtitleSchema,
  TitleSchema,
} from "../lib/validation/text-schema.tsx";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

export function AddElement({
  className,
  fieldName,
}: {
  className?: string;
  fieldName: ElementArrayFieldPath;
}) {
  const pageNumber = getSlideNumber(fieldName);
  const form: DocumentFormReturn = useFormContext();
  const { control } = form;
  const { append, fields } = useFieldArray({
    control,
    name: fieldName,
  });

  const hasNoElements = fields.length === 0;

  return (
    <div 
      className={cn(
        "relative w-full group",
        hasNoElements && "min-h-[100px] flex items-center justify-center",
        hasNoElements && "border-2 border-dashed border-gray-300 rounded-lg py-8",
      )} 
      data-export="hidden"
    >
      {hasNoElements && (
        <div className="text-gray-400 text-sm text-center pointer-events-none">
          Hover here to add elements to your slide
        </div>
      )}
      <TooltipProvider delayDuration={100}>
        <div
          className={cn(
            "absolute left-1/2 -translate-x-1/2 z-50",
            hasNoElements ? "top-1/2 -translate-y-1/2" : "bottom-4",
            "opacity-0 transition-all duration-200 ease-in-out",
            "group-hover:opacity-100 group-focus-within:opacity-100"
          )}
        >
          <div className={cn(
            "flex items-center gap-2 p-2",
            "bg-white/95 dark:bg-gray-800/95",
            "border border-gray-200 dark:border-gray-700",
            "rounded-full shadow-lg",
            "backdrop-blur-sm"
          )}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => append(TitleSchema.parse({}))}
                  className="w-9 h-9 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <Type className="w-4 h-4" />
                  <span className="sr-only">Add Title</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent side="bottom">Add Title (H1)</TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => append(SubtitleSchema.parse({}))}
                  className="w-9 h-9 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <TextCursor className="w-4 h-4" />
                  <span className="sr-only">Add Subtitle</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent side="bottom">Add Subtitle (H2)</TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => append(DescriptionSchema.parse({}))}
                  className="w-9 h-9 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <Text className="w-4 h-4" />
                  <span className="sr-only">Add Description</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent side="bottom">Add Description Text</TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => append({ ...DEFAULT_CONTENT_IMAGE_INPUT })}
                  className="w-9 h-9 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <ImageIcon className="w-4 h-4" />
                  <span className="sr-only">Add Image</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent side="bottom">Add Image</TooltipContent>
            </Tooltip>
          </div>
        </div>
      </TooltipProvider>
    </div>
  );
}
