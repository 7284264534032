import { Navigate } from 'react-router-dom';
import { useAuth } from '@/App';
import { supabase } from '@/integrations/supabase/client';
import { useState, useEffect } from 'react';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requireSubscription?: boolean;
}

export function ProtectedRoute({ children, requireSubscription = false }: ProtectedRouteProps) {
  const { isAuthenticated, isLoading, user } = useAuth();
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [checkingSubscription, setCheckingSubscription] = useState(requireSubscription);

  useEffect(() => {
    const checkSubscription = async () => {
      if (requireSubscription && user) {
        try {
          const { data } = await supabase
            .from('profiles')
            .select('is_subscribed')
            .eq('id', user.id)
            .single();

          setIsSubscribed(!!data?.is_subscribed);
        } catch (error) {
          console.error('Error checking subscription:', error);
        } finally {
          setCheckingSubscription(false);
        }
      }
    };

    checkSubscription();
  }, [requireSubscription, user]);

  if (isLoading || checkingSubscription) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (requireSubscription && !isSubscribed) {
    return <Navigate to="/?subscribe=true" />;
  }

  return <>{children}</>;
} 