import OpenAI from "openai";
import { supabase } from '@/integrations/supabase/client';
import type { Database } from '@/types/supabase';
import { IdealClientProfile } from '@/types/profile';
import { AI_MODELS } from '@/components/ui/model-selector';
import type { ClientOptions } from "openai";

import {
  ProfessionalAnalysis,
  analyzeProfilePrompt,
  validateProfessionalAnalysis
} from './prompts/analyzeProfile';

import {
  AudienceAnalysis,
  analyzeAudiencePrompt,
  validateAudienceAnalysis
} from './prompts/analyzeAudience';

import {
  ImprovementAnalysis,
  suggestImprovementsPrompt,
  validateImprovementAnalysis,
  formatImprovementAnalysis
} from './prompts/suggestImprovements';

import {
  AboutSection,
  rewriteAboutPrompt,
  validateAboutSection,
  formatAboutSection
} from './prompts/rewriteAbout';

export type AIModel = typeof AI_MODELS[number]['id'];

let currentModel: AIModel = 'deepseek-chat';

// Initialize DeepSeek client
const deepseekClient = new OpenAI({
  baseURL: 'https://api.deepseek.com',
  apiKey: import.meta.env.VITE_DEEPSEEK_API_KEY,
  dangerouslyAllowBrowser: true
});

export async function initializeAIModel() {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (session?.user) {
      const { data: profile } = await supabase
        .from('profiles')
        .select('default_model')
        .eq('id', session.user.id)
        .single();
      
      if (profile?.default_model) {
        currentModel = profile.default_model as AIModel;
        console.log('Initialized AI model from user settings:', currentModel);
      }
    }
  } catch (error) {
    console.error('Error initializing AI model from settings:', error);
  }
}

export function getCurrentModel(): AIModel {
  return currentModel;
}

export function setAIModel(model: AIModel) {
  console.log('Switching to model:', model);
  currentModel = model;
}

function getModelConfig(model: AIModel) {
  const modelConfig = AI_MODELS.find(m => m.id === model);
  if (!modelConfig) throw new Error(`Invalid model: ${model}`);
  return modelConfig;
}

function createOpenAIClient(baseUrl?: string, apiKey?: string) {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json'
  };

  const clientOptions: ClientOptions = {
    baseURL: baseUrl,
    apiKey: apiKey || import.meta.env.VITE_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
    defaultHeaders: headers
  };

  return new OpenAI(clientOptions);
}

interface OpenRouterRequestOptions {
  model: string;
  messages: Array<{ role: string; content: string }>;
  temperature?: number;
  max_tokens?: number;
  presence_penalty?: number;
  frequency_penalty?: number;
  response_format?: { type: string };
  top_p?: number;
  top_k?: number;
  stream?: boolean;
  stop?: string | string[];
  repetition_penalty?: number;
}

async function makeOpenRouterRequest(requestOptions: OpenRouterRequestOptions) {
  const response = await fetch("https://openrouter.ai/api/v1/chat/completions", {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${import.meta.env.VITE_OPENROUTER_API_KEY}`,
      "HTTP-Referer": window.location.origin,
      "X-Title": "Medical Post",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(requestOptions)
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => null);
    throw new Error(`OpenRouter API error: ${response.status} - ${errorData?.error?.message || response.statusText}`);
  }

  const data = await response.json();
  
  // Validate response structure
  if (!data || typeof data !== 'object') {
    throw new Error('Invalid response format from OpenRouter');
  }

  // Check for API-level errors
  if (data.error) {
    throw new Error(`OpenRouter API error: ${data.error.message || JSON.stringify(data.error)}`);
  }

  // Validate choices array
  if (!Array.isArray(data.choices) || data.choices.length === 0) {
    throw new Error('No choices returned from OpenRouter');
  }

  // Validate first choice has message and content
  const firstChoice = data.choices[0];
  if (!firstChoice?.message?.content) {
    throw new Error('Invalid choice format from OpenRouter');
  }

  return data;
}

function sanitizeJsonResponse(response: string): string {
  // Clean the response by only keeping printable characters
  return response
    .split('')
    .filter(char => char.charCodeAt(0) > 31 && char.charCodeAt(0) < 127 || char === '\n')
    .join('')
    .replace(/\r\n/g, '\n')
    .replace(/^\uFEFF/, '')
    .trim();
}

export async function generateCompletion(prompt: string, model: AIModel = currentModel) {
  const modelConfig = getModelConfig(model);

  try {
    if (!modelConfig.provider) {
      throw new Error(`Provider not specified for model: ${model}`);
    }

    switch (modelConfig.provider) {
      case 'deepseek': {
        const completion = await deepseekClient.chat.completions.create({
          model: model === 'deepseek-reasoner' ? 'deepseek-reasoner' : 'deepseek-chat',
          messages: [{ role: 'user', content: prompt }]
        });
        return completion.choices[0]?.message?.content || '';
      }
      
      case 'openrouter': {
        const response = await makeOpenRouterRequest({
          model: modelConfig.config.model,
          messages: [{ role: 'user', content: prompt }],
          temperature: 0.7,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
          repetition_penalty: 1
        });
        return response.choices[0]?.message?.content || '';
      }

      case 'openai': {
        const client = createOpenAIClient();
        const completion = await client.chat.completions.create({
          model: modelConfig.config.model,
          messages: [{ role: 'user', content: prompt }]
        });
        return completion.choices[0]?.message?.content || '';
      }
    }
  } catch (error) {
    console.error('Error generating completion:', error);
    throw error;
  }
}

// Helper function to get the actual model name to use with the API
function getApiModelName(model: AIModel): string {
  const modelConfig = getModelConfig(model);
  if ('config' in modelConfig && modelConfig.config?.model) {
    return modelConfig.config.model;
  }
  return model;
}

function truncateText(text: string, maxLength: number = 6000): string {
  if (text.length <= maxLength) return text;
  
  // Try to find a sentence boundary near the middle
  const midPoint = Math.floor(maxLength / 2);
  let firstHalf = text.slice(0, midPoint).trim();
  let secondHalf = text.slice(-midPoint).trim();
  
  // Try to break at sentence boundaries
  const firstSentenceEnd = firstHalf.lastIndexOf('.');
  const secondSentenceStart = secondHalf.indexOf('.');
  
  if (firstSentenceEnd !== -1) {
    firstHalf = firstHalf.slice(0, firstSentenceEnd + 1);
  }
  if (secondSentenceStart !== -1) {
    secondHalf = secondHalf.slice(secondSentenceStart + 1);
  }
  
  return `${firstHalf.trim()}\n...\n${secondHalf.trim()}`;
}

// Add a delay helper function
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export async function makeAIRequest(prompt: string, systemPrompt: string, requireJson: boolean = false): Promise<string> {
  const requestId = Math.random().toString(36).substring(7);
  console.log(`[${requestId}] Making AI request`);
  console.time(`request_${requestId}`);

  let attempt = 0;
  const maxAttempts = 3;

  while (attempt < maxAttempts) {
    attempt++;
    try {
      const modelConfig = getModelConfig(currentModel);
      const { provider } = modelConfig;
      let client: OpenAI;
      let response: string;

      switch (provider) {
        case 'deepseek':
          client = deepseekClient;
          break;
        case 'openrouter': {
          console.log(`[${requestId}] Making OpenRouter request (attempt ${attempt})`);
          const openRouterResponse = await makeOpenRouterRequest({
            model: getApiModelName(currentModel),
            messages: [
              { role: 'system', content: requireJson ? `You are a professional LinkedIn profile analyzer. You must return a valid JSON object matching the specified structure. Do not include any text, markdown, or content outside the JSON object. ${systemPrompt}` : systemPrompt },
              { role: 'user', content: prompt }
            ],
            temperature: requireJson ? 0.5 : 0.7,
            max_tokens: 4000,
            presence_penalty: 0.0,
            frequency_penalty: 0.0,
            response_format: requireJson ? { type: "json_object" } : undefined
          });

          if (!openRouterResponse?.choices?.[0]?.message?.content) {
            throw new Error('Invalid or empty response from OpenRouter');
          }
          response = openRouterResponse.choices[0].message.content;
          break;
        }
        case 'openai':
          client = createOpenAIClient();
          break;
        default:
          throw new Error(`Unsupported provider: ${provider}`);
      }
      
      if (provider !== 'openrouter') {
        const requestOptions = {
          model: getApiModelName(currentModel),
          messages: [
            {
              role: 'system' as const,
              content: requireJson 
                ? `You are a professional LinkedIn profile analyzer. You must return a valid JSON object matching the specified structure. Do not include any text, markdown, or content outside the JSON object. ${systemPrompt}`
                : systemPrompt
            },
            {
              role: 'user' as const,
              content: prompt
            }
          ],
          temperature: requireJson ? 0.5 : 0.7,
          max_tokens: 4000,
          presence_penalty: 0.0,
          frequency_penalty: 0.0,
          response_format: requireJson ? { type: "json_object" as const } : undefined
        };

        const completion = await client.chat.completions.create(requestOptions);
        response = completion.choices[0]?.message?.content || '';
      }

      if (requireJson) {
        const cleanedResponse = sanitizeJsonResponse(response);
        JSON.parse(cleanedResponse);
        response = cleanedResponse;
      }

      console.timeEnd(`request_${requestId}`);
      return response;

    } catch (error: Error | unknown) {
      console.error(`[${requestId}] Request failed (attempt ${attempt}):`, error instanceof Error ? error.message : String(error));

      if (attempt >= maxAttempts) {
        console.error(`[${requestId}] Max retries reached. Failing request.`);
        throw error;
      }

      const delay = Math.pow(2, attempt) * 100;
      console.log(`[${requestId}] Retrying in ${delay}ms...`);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }

  throw new Error(`[${requestId}] Request failed after ${maxAttempts} attempts.`);
}

export async function analyzeProfile(profileText: string): Promise<string> {
  const requestId = Math.random().toString(36).substring(7);
  console.log(`[${requestId}] Starting profile analysis`);
  console.time(`analyze_profile_${requestId}`);
  
  try {
    const truncatedText = truncateText(profileText);
    console.log(`[${requestId}] Truncated text length:`, truncatedText.length);
    
    const response = await makeAIRequest(
      truncatedText,
      analyzeProfilePrompt,
      true // Require JSON response
    );

    // Log the raw response for debugging
    console.log(`[${requestId}] Raw response:`, response);

    const jsonResponse = JSON.parse(response);
    console.log(`[${requestId}] Successfully parsed profile analysis response:`, jsonResponse);
    
    if (!validateProfessionalAnalysis(jsonResponse)) {
      console.error(`[${requestId}] Invalid response structure:`, jsonResponse);
      throw new Error('Invalid JSON structure received from API');
    }

    console.timeEnd(`analyze_profile_${requestId}`);
    return jsonResponse.description;
  } catch (error) {
    console.error(`[${requestId}] Profile analysis failed:`, error);
    throw error;
  }
}

export async function analyzeAudience(profileText: string): Promise<string> {
  const requestId = Math.random().toString(36).substring(7);
  console.log(`[${requestId}] Starting audience analysis`);
  console.time(`analyze_audience_${requestId}`);
  
  try {
    const truncatedText = truncateText(profileText);
    console.log(`[${requestId}] Truncated text length:`, truncatedText.length);
    
    const response = await makeAIRequest(
      truncatedText,
      analyzeAudiencePrompt,
      true // Require JSON response
    );

    // Log the raw response for debugging
    console.log(`[${requestId}] Raw response:`, response);

    const jsonResponse = JSON.parse(response);
    console.log(`[${requestId}] Successfully parsed audience analysis response:`, jsonResponse);
    
    if (!validateAudienceAnalysis(jsonResponse)) {
      console.error(`[${requestId}] Invalid response structure:`, jsonResponse);
      throw new Error('Invalid JSON structure received from API');
    }

    console.timeEnd(`analyze_audience_${requestId}`);
    return jsonResponse.description;
  } catch (error) {
    console.error(`[${requestId}] Audience analysis failed:`, error);
    throw error;
  }
}

export async function suggestImprovements(profileText: string): Promise<string> {
  const requestId = Math.random().toString(36).substring(7);
  console.log(`[${requestId}] Starting improvements analysis`);
  console.time(`suggest_improvements_${requestId}`);
  
  try {
    const truncatedText = truncateText(profileText);
    console.log(`[${requestId}] Truncated text length:`, truncatedText.length);
    
    const response = await makeAIRequest(
      truncatedText,
      suggestImprovementsPrompt,
      true // Require JSON response
    );

    // Log the raw response for debugging
    console.log(`[${requestId}] Raw response:`, response);

    const jsonResponse = JSON.parse(response);
    console.log(`[${requestId}] Successfully parsed improvements analysis response:`, jsonResponse);
    
    if (!validateImprovementAnalysis(jsonResponse)) {
      console.error(`[${requestId}] Invalid response structure:`, jsonResponse);
      throw new Error('Invalid JSON structure received from API');
    }

    console.timeEnd(`suggest_improvements_${requestId}`);
    return formatImprovementAnalysis(jsonResponse);
  } catch (error) {
    console.error(`[${requestId}] Improvements analysis failed:`, error);
    throw error;
  }
}

export async function rewriteAboutSection(
  profileText: string,
  tone: string,
  additionalDetails: string
): Promise<string> {
  const requestId = Math.random().toString(36).substring(7);
  console.log('Starting about section rewrite');
  console.time(`rewrite_about_${requestId}`);
  
  try {
    const truncatedText = truncateText(profileText);
    console.log('Truncated text length:', truncatedText.length);
    
    const response = await makeAIRequest(
      truncatedText,
      `Use a ${tone} tone. Additional context: ${additionalDetails}. ${rewriteAboutPrompt}`,
      true // Require JSON response
    );

    const jsonResponse = JSON.parse(response);
    if (!validateAboutSection(jsonResponse)) {
      console.error('Invalid response structure:', jsonResponse);
      throw new Error('Invalid JSON structure received from API');
    }

    const formatted = formatAboutSection(jsonResponse);
    console.timeEnd(`rewrite_about_${requestId}`);
    return formatted;
  } catch (error) {
    console.error('About section rewrite failed:', error);
    throw error;
  }
}

export async function saveProfileReview(userId: string, analysis: string) {
  console.log('Saving profile review');
  console.time('save_review');
  
  try {
    const { error } = await supabase
      .from('profile_reviews')
      .insert({
        user_id: userId,
        analysis: analysis,
        created_at: new Date().toISOString()
      });

    if (error) throw error;
    console.timeEnd('save_review');
  } catch (error) {
    console.error('Error saving profile review:', error);
    throw new Error('Failed to save profile review');
  }
}

export const defaultAnalysisSteps = [
  {
    name: 'Analyzing current profile',
    status: 'pending',
    weight: 0.4,
    result: null
  },
  {
    name: 'Identifying target audience',
    status: 'pending',
    weight: 0.3,
    result: null
  },
  {
    name: 'Suggesting improvements',
    status: 'pending',
    weight: 0.3,
    result: null
  }
];