import { Linkedin, Twitter } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="border-t bg-white mt-auto">
      <div className="max-w-7xl mx-auto px-4 py-12 my-10 pt-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="space-y-4">
            <h3 className="font-semibold text-lg">Medical Post</h3>
            <p className="text-sm text-gray-600">
              Your AI-powered assistant for professional content creation and LinkedIn profile optimization.
            </p>
          </div>

          <div className="space-y-4">
            <h4 className="font-semibold">Features</h4>
            <ul className="space-y-2 text-sm text-gray-600">
              <li>
                <Link to="/?tab=optimizer" className="hover:text-primary">Profile Optimization</Link>
              </li>
              <li>
                <Link to="/?tab=planner" className="hover:text-primary">Content Planning</Link>
              </li>
              <li>
                <Link to="/?tab=creator" className="hover:text-primary">Post Creation</Link>
              </li>
              <li>
                <Link to="/dashboard" className="hover:text-primary">Content Calendar</Link>
              </li>
            </ul>
          </div>

          <div className="space-y-4">
            <h4 className="font-semibold">Resources</h4>
            <ul className="space-y-2 text-sm text-gray-600">
              <li>
                <Link to="/documentation" className="hover:text-primary">Documentation</Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="hover:text-primary">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-of-service" className="hover:text-primary">Terms of Service</Link>
              </li>
              <li>
                <a href="mailto:support@medicalpost.com" className="hover:text-primary">Support</a>
              </li>
            </ul>
          </div>

          <div className="space-y-4">
            <h4 className="font-semibold">Connect</h4>
            <div className="flex space-x-2">
              
            
              <a href="https://www.linkedin.com/company/medicalpost/" target="_blank" rel="noopener noreferrer">
              <Button variant="ghost" size="icon" className="hover:text-primary">
                <Linkedin className="h-5 w-5" />
              </Button>
              </a>
            </div>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t text-center text-sm text-gray-600">
          <p>© {new Date().getFullYear()} Medical Post. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
} 