import { useSelectionContext } from "./lib/providers/selection-context";
import { getStyleSibling } from "./lib/field-path";
import { EnumRadioGroupField } from "./forms/fields/enum-radio-group-field";
import {
  DocumentFormReturn,
  ElementFieldPath,
  ImageSourceFieldPath,
  ImageSourceSrcFieldPath,
  ImageStyleObjectFitFieldPath,
  ImageStyleOpacityFieldPath,
  StyleFieldPath,
  TextStyleAlignFieldPath,
  TextStyleFontSizeFieldPath,
  TextStyleMarginFieldPath,
  TextStyleWidthFieldPath,
  TextStyleColorFieldPath,
  TextStyleCustomColorFieldPath,
  TextStyleFontFieldPath,
} from "@/lib/document-form-types";
import { cn } from "@/lib/utils";
import React from "react";
import {
  AlignCenter,
  AlignLeft,
  AlignRight,
  Maximize2,
  Minimize2,
  Type,
} from "lucide-react";
import { FontSizeType, TextALignType, ColorType, FontType } from "./lib/validation/text-schema";
import { OpacityFormField } from "./forms/fields/opacity-form-field";
import { ImageSourceFormField } from "./forms/fields/image-source-form-field";
import { ImageStyleFormField } from "./forms/fields/image-style-form-field";
import { ObjectFitType } from "./lib/validation/image-schema";
import { ElementType } from "./lib/validation/element-type";
import {
  TypographyFieldName,
  TypographyH3,
  TypographyH4,
  TypographyLarge,
} from "./typography";
import { Separator } from "./ui/separator";
import { MarginControls } from "./forms/fields/margin-controls";
import { FormField } from "./ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { BackgroundStyleSchema } from "./lib/validation/slide-schema";
import { Button } from "./ui/button";

const fontSizeMap: Record<typeof FontSizeType["_type"], React.ReactElement> = {
  [FontSizeType.enum.Small]: <Type className="h-2 w-2" />,
  [FontSizeType.enum.Medium]: <Type className="h-3 w-3" />,
  [FontSizeType.enum.Large]: <Type className="h-4 w-4" />,
};

const textAlignMap: Record<typeof TextALignType["_type"], React.ReactElement> = {
  [TextALignType.enum.Left]: <AlignLeft className="h-4 w-4" />,
  [TextALignType.enum.Center]: <AlignCenter className="h-4 w-4" />,
  [TextALignType.enum.Right]: <AlignRight className="h-4 w-4" />,
};

const objectFitMap: Record<typeof ObjectFitType["_type"], React.ReactElement> = {
  [ObjectFitType.enum.Contain]: <Minimize2 className="h-4 w-4" />,
  [ObjectFitType.enum.Cover]: <Maximize2 className="h-4 w-4" />,
};

// Add some example overlay options (you can replace these with your actual overlay images)
const OVERLAY_OPTIONS = [
  { src: "/overlays/pattern1.png", alt: "Geometric Pattern 1" },
  { src: "/overlays/pattern2.png", alt: "Geometric Pattern 2" },
  { src: "/overlays/pattern3.png", alt: "Geometric Pattern 3" },
  { src: "/overlays/pattern4.png", alt: "Geometric Pattern 4" },
  { src: "/overlays/pattern5.png", alt: "Geometric Pattern 5" },
  { src: "/overlays/pattern6.png", alt: "Geometric Pattern 6" },
];

export function StyleMenu({
  form,
  className = "",
  onImageClick,
}: {
  form: DocumentFormReturn;
  className?: string;
  onImageClick?: (fieldName: string) => void;
}) {
  const { currentSelection: elementPath } = useSelectionContext();
  const stylePath = elementPath ? elementPath + ".style" : "";
  if (!elementPath) {
    return <></>;
  }

  // Check if we're selecting a slide
  const isSlide = elementPath.match(/^slides\.\d+$/);
  const backgroundImagePath = isSlide ? `${elementPath}.backgroundImage` : "";
  
  // Get values based on whether we're selecting a slide or an element
  const values = isSlide 
    ? form.getValues(elementPath)
    : form.getValues(elementPath as ElementFieldPath);

  if (!values) {
    return <></>;
  }

  const style = values.style;
  const type = values.type;

  const hasProperty = (obj: unknown, prop: string): boolean => {
    return obj !== null && typeof obj === 'object' && prop in obj;
  };

  const isTextElement = type === ElementType.enum.Title || 
                       type === ElementType.enum.Subtitle || 
                       type === ElementType.enum.Description;

  const isSlideBackground = elementPath?.endsWith('backgroundImage');

  return (
    <div
      className={cn("grid gap-4", className)}
      onClick={(event) => event.stopPropagation()}
      key={elementPath}
    >
      <div className="space-y-2">
        <TypographyH3>Style</TypographyH3>
        <p className="text-sm text-muted-foreground">
          Set the selected element style.
        </p>
      </div>
      <Separator orientation="horizontal"></Separator>
      <div className="flex flex-col gap-6 items-start">
        {isSlide && (
          <>
          <div className="w-full flex flex-col gap-3">
              <h4 className="text-base font-semibold">Background</h4>
              
              {/* Background Color */}
              <div className="space-y-2">
                <Label>Background Color</Label>
                <FormField
                  control={form.control}
                  name={`${elementPath}.backgroundColor.color`}
                  render={({ field }) => (
                    <Select 
                      onValueChange={(value) => {
                        form.setValue(`${elementPath}.backgroundColor`, {
                          color: value,
                          customColor: value === "Custom" ? "#ffffff" : undefined,
                          opacity: 100
                        }, {
                          shouldDirty: true,
                          shouldTouch: true
                        });
                      }} 
                      value={field.value || "Theme"}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select color type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Theme">Theme Color</SelectItem>
                        <SelectItem value="Custom">Custom Color</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                />
                {form.watch(`${elementPath}.backgroundColor.color`) === "Custom" && (
                  <FormField
                    control={form.control}
                    name={`${elementPath}.backgroundColor.customColor`}
                    render={({ field }) => (
                      <div className="flex flex-col gap-2">
                        <Label>Custom Color</Label>
                        <Input
                          type="color"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            form.setValue(`${elementPath}.backgroundColor.customColor`, e.target.value, {
                              shouldDirty: true,
                              shouldTouch: true
                            });
                          }}
                          value={field.value || "#ffffff"}
                          className="h-10 px-2 py-1"
                        />
                      </div>
                    )}
                  />
                )}
              </div>

              {/* Background Overlay Gallery */}
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <Label>Background Overlay</Label>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      form.setValue(`${elementPath}.backgroundImage`, undefined);
                    }}
                  >
                    Clear
                  </Button>
                </div>
                
                <div className="grid grid-cols-3 gap-2">
                  {OVERLAY_OPTIONS.map((overlay, index) => (
                    <Button
                      key={index}
                      variant="outline"
                      className="p-1 aspect-square"
                      onClick={() => {
                        form.setValue(`${elementPath}.backgroundImage.source`, {
                          src: overlay.src,
                          alt: overlay.alt
                        });
                        form.setValue(`${elementPath}.backgroundImage.style.opacity`, 100);
                      }}
                    >
                      <img
                        src={overlay.src}
                        alt={overlay.alt}
                        className="w-full h-full object-cover rounded"
                      />
                    </Button>
                  ))}
                </div>

                <div className="space-y-4">
            <ImageSourceFormField
                    fieldName={`${elementPath}.backgroundImage.source` as ImageSourceFieldPath}
              form={form}
            />
                </div>

                {form.watch(`${elementPath}.backgroundImage.source.src`) && (
            <OpacityFormField
                    fieldName={`${elementPath}.backgroundImage.style.opacity` as ImageStyleOpacityFieldPath}
              form={form}
                    label="Opacity"
              className="w-full"
            />
                )}
              </div>
          </div>
          </>
        )}
        {style && hasProperty(style, "fontSize") ? (
          <>
          <EnumRadioGroupField
            name="Font Size"
            form={form}
            fieldName={`${stylePath}.fontSize` as TextStyleFontSizeFieldPath}
            enumValueElements={fontSizeMap}
            groupClassName="grid grid-cols-3 gap-1"
            itemClassName="h-10 w-10"
          />

            <div className="space-y-2">
              <Label>Font</Label>
            <FormField
              control={form.control}
                name={`${stylePath}.font` as TextStyleFontFieldPath}
              render={({ field }) => (
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select font" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.values(FontType.enum).map((value) => (
                        <SelectItem key={value} value={value}>
                          {value}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>

            <div className="space-y-2">
              <Label>Color</Label>
              <FormField
                control={form.control}
                name={`${stylePath}.color` as TextStyleColorFieldPath}
                render={({ field }) => (
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select color" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.values(ColorType.enum).map((value) => (
                        <SelectItem key={value} value={value}>
                          {value}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
              {form.watch(`${stylePath}.color`) === "Custom" && (
                <FormField
                  control={form.control}
                  name={`${stylePath}.customColor` as TextStyleCustomColorFieldPath}
                  render={({ field }) => (
                    <div className="flex flex-col gap-2">
                      <Label>Custom Color</Label>
                      <Input
                        type="color"
                        {...field}
                        value={field.value || "#000000"}
                        className="h-10 px-2 py-1"
                      />
                    </div>
                  )}
                />
              )}
            </div>
          </>
        ) : null}
        {style && hasProperty(style, "align") ? (
          <EnumRadioGroupField
            name="Alignment"
            form={form}
            fieldName={`${stylePath}.align` as TextStyleAlignFieldPath}
            enumValueElements={textAlignMap}
            groupClassName="grid grid-cols-3 gap-1"
            itemClassName="h-10 w-10"
          />
        ) : null}
        {isTextElement && (
          <div className="flex flex-col gap-4 w-full">
            <FormField
              control={form.control}
              name={`${stylePath}.margin` as TextStyleMarginFieldPath}
              render={({ field }) => (
                <MarginControls
                  value={field.value || "Medium"}
                  onChange={field.onChange}
                  label="Vertical Margin"
                />
              )}
            />
            <FormField
              control={form.control}
              name={`${stylePath}.marginX` as TextStyleMarginFieldPath}
              render={({ field }) => (
                <MarginControls
                  value={field.value || "Medium"}
                  onChange={field.onChange}
                  label="Horizontal Margin"
                />
              )}
            />
          </div>
        )}
        {style && hasProperty(style, "objectFit") ? (
          <EnumRadioGroupField
            name={"Object Fit"}
            form={form}
            fieldName={`${stylePath}.objectFit` as ImageStyleObjectFitFieldPath}
            enumValueElements={objectFitMap}
            groupClassName="grid grid-cols-3  gap-1"
            itemClassName="h-10 w-10"
          />
        ) : null}
        {type === ElementType.enum.ContentImage ? (
          <>
            <div className="w-full flex flex-col gap-3">
              <h4 className="text-base font-semibold">Image</h4>
              <TypographyFieldName>Source</TypographyFieldName>
              <ImageSourceFormField
                fieldName={`${elementPath}.source` as ImageSourceFieldPath}
                form={form}
              />
            <OpacityFormField
              fieldName={`${stylePath}.opacity` as ImageStyleOpacityFieldPath}
              form={form}
              label={"Opacity"}
              className="w-full"
              disabled={
                form.getValues(
                  `${elementPath}.source.src` as ImageSourceSrcFieldPath
                ) == ""
              }
            />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
