"use client";

import { cn } from "@/lib/utils";
import { BrandForm } from "./forms/brand-form";
import { ThemeForm } from "./forms/theme-form";
import {
  VerticalTabs,
  VerticalTabsContent,
  VerticalTabsList,
  VerticalTabsTrigger,
} from "./ui/vertical-tabs";
import { usePagerContext } from "./lib/providers/pager-context";
import { Separator } from "./ui/separator";
import { FontsForm } from "./forms/fonts-form";
import { PageNumberForm } from "./forms/page-number-form";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import {
  Briefcase,
  Brush,
  FileDigit,
  LucideIcon,
  Palette,
  Plus,
  Type,
  Sparkles,
  Minus,
  ChevronDown,
  ChevronUp,
  X,
  Settings,
  List,
} from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Drawer } from "vaul";
import { DrawerContent, DrawerTrigger } from "./drawer";
import { ReactNode, useEffect, useState } from "react";
import { buttonVariants } from "./ui/button";
import { ScrollBar } from "./ui/scroll-area";
import { useSelectionContext } from "./lib/providers/selection-context";
import { useFieldsFileImporter } from "./lib/hooks/use-fields-file-importer";
import { StyleMenu } from "./style-menu";
import { useFormContext } from "react-hook-form";
import { DocumentFormReturn } from "@/lib/document-form-types";
import { ImageSearchDialog } from "./image-search-dialog";
import { ElementType } from "./lib/validation/element-type";
import { AIInputForm } from "./ai-input-form";
import { SlideOrderPanel } from "./slide-order-panel";

type TabInfo = {
  name: string;
  value: string;
  icon: LucideIcon;
};

const ALL_FORMS: Record<string, TabInfo> = {
  ai: {
    name: "AI Generate",
    value: "ai",
    icon: Sparkles,
  },
  brand: {
    name: "Profile",
    value: "profile",
    icon: Briefcase,
  },
  theme: {
    name: "Theme",
    value: "theme",
    icon: Palette,
  },
};

export function SidebarPanel({ className }: { className?: string }) {
  const form: DocumentFormReturn = useFormContext();
  const { currentSelection, setCurrentSelection } = useSelectionContext();
  const [isImageSearchOpen, setIsImageSearchOpen] = useState(false);
  const [currentImageField, setCurrentImageField] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [activePanel, setActivePanel] = useState<"element" | "settings" | "order">("element");
  const [isMobile, setIsMobile] = useState(false);

  // Check if we're on mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // 768px is the md breakpoint in Tailwind
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Auto-open drawer when element is selected (only on mobile)
  useEffect(() => {
    if (currentSelection && isMobile) {
      setIsDrawerOpen(true);
      setIsMinimized(false);
      setActivePanel("element");
    }
  }, [currentSelection, isMobile]);

  const handleImageClick = (fieldName: string) => {
    setCurrentImageField(fieldName);
    setIsImageSearchOpen(true);
  };

  const handleImageSelect = (url: string) => {
    if (currentImageField) {
      form.setValue(currentImageField, url, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      });
      form.trigger(currentImageField);
    }
  };

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
    if (!isMinimized) {
      setIsDrawerOpen(false);
    }
  };

  return (
    <>
      {/* Desktop Sidebar */}
      <aside className="top-14 z-30 hidden h-full w-full shrink-0 md:sticky md:block border-l">
        <SidebarTabsPanel onImageClick={handleImageClick} />
      </aside>
      
      {/* Mobile Floating Buttons */}
      <div className="fixed bottom-6 left-6 z-50 md:hidden flex flex-col gap-4">
        {/* Order Button */}
        <CircularFloatingButton 
          onClick={() => {
            setActivePanel("order");
            setIsDrawerOpen(true);
            setIsMinimized(false);
          }}
        >
          <List className="h-4 w-4" />
        </CircularFloatingButton>

        {/* Element Settings Button */}
        {currentSelection && (
          <CircularFloatingButton 
            onClick={() => {
              setActivePanel("element");
              setIsDrawerOpen(true);
              setIsMinimized(false);
            }}
          >
            <Brush className="h-4 w-4" />
          </CircularFloatingButton>
        )}
        
        {/* Main Settings Button */}
        <CircularFloatingButton 
          onClick={() => {
            setActivePanel("settings");
            setIsDrawerOpen(true);
            setIsMinimized(false);
          }}
        >
          <Settings className="h-4 w-4" />
        </CircularFloatingButton>
      </div>

      {/* Mobile Drawer */}
      <div className="md:hidden">
        <Drawer.Root modal={true} open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
          <DrawerContent 
            className={cn(
              "fixed inset-x-0 bottom-0 z-[100] bg-background transition-all duration-300",
              isMinimized ? "h-16" : "h-[85vh] rounded-t-[10px]"
            )}
          >
            <div className="mx-auto mt-2 h-1.5 w-12 rounded-full bg-muted" />
            <div className="flex items-center justify-between p-4 border-b">
              <h2 className="text-lg font-semibold">
                {activePanel === "element" ? "Element Settings" : 
                 activePanel === "order" ? "Slide Order" : 
                 "Editor Settings"}
              </h2>
              <div className="flex items-center gap-2">
                <button 
                  onClick={() => setIsMinimized(!isMinimized)}
                  className="rounded-full p-2 hover:bg-muted"
                >
                  {isMinimized ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
                </button>
                {!isMinimized && (
                  <button 
                    onClick={() => setIsDrawerOpen(false)}
                    className="rounded-full p-2 hover:bg-muted"
                  >
                    <X className="h-4 w-4" />
                  </button>
                )}
              </div>
            </div>
            {!isMinimized && (
              <ScrollArea className="flex-1 h-[calc(85vh-4rem)] overflow-y-auto">
                <div className="p-4 pb-8">
                  {activePanel === "element" && currentSelection ? (
                    <StyleMenu 
                      form={form} 
                      onImageClick={handleImageClick}
                      className="w-full" 
                    />
                  ) : activePanel === "order" ? (
                    <SlideOrderPanel />
                  ) : (
                    <MobileDrawerFormsPanel onImageClick={handleImageClick} />
                  )}
                </div>
              </ScrollArea>
            )}
          </DrawerContent>
        </Drawer.Root>
      </div>

      <ImageSearchDialog
        open={isImageSearchOpen}
        onOpenChange={setIsImageSearchOpen}
        onImageSelect={handleImageSelect}
      />
    </>
  );
}

function VerticalTabTriggerButton({ tabInfo }: { tabInfo: TabInfo }) {
  const { setCurrentSelection } = useSelectionContext();
  //  TODO Convert this comp into a forwardref like its child
  return (
    <VerticalTabsTrigger
      value={tabInfo.value}
      className="h-16 flex flex-col gap-2 items-center py-2 justify-center"
      onFocus={() => setCurrentSelection("", null)}
    >
      <tabInfo.icon className="h-4 w-4" />
      <span className="sr-only ">{tabInfo.name}</span>
      <p className="text-xs">{tabInfo.name}</p>
    </VerticalTabsTrigger>
  );
}

function HorizontalTabTriggerButton({ tabInfo }: { tabInfo: TabInfo }) {
  const { setCurrentSelection } = useSelectionContext();
  //  TODO Convert this comp into a forwardref like its child
  return (
    <TabsTrigger
      value={tabInfo.value}
      className="h-16 flex flex-col gap-2 items-center py-2 justify-center"
      onFocus={() => setCurrentSelection("", null)}
    >
      <tabInfo.icon className="h-4 w-4" />
      <span className="sr-only ">{tabInfo.name}</span>
      <p className="text-xs">{tabInfo.name}</p>
    </TabsTrigger>
  );
}

export function SidebarTabsPanel({ onImageClick }: { onImageClick: (fieldName: string) => void }) {
  const { currentSelection, setCurrentSelection } = useSelectionContext();
  const [tab, setTab] = useState(ALL_FORMS.ai.value);
  const form: DocumentFormReturn = useFormContext();

  return (
    <VerticalTabs
      value={currentSelection ? "" : tab}
      onValueChange={(val) => {
        if (val) {
          setTab(val);
          setCurrentSelection("", null);
        }
      }}
      className="flex-1 h-full p-0"
    >
      <div className="flex flex-col h-full w-full">
        <div className="border-b">
          <div className="flex h-14 items-center px-4">
            <TabsList className="flex w-full justify-start gap-4 bg-transparent p-0">
              <TabsTrigger
                value={ALL_FORMS.ai.value}
                className="data-[state=active]:bg-muted px-3 py-1.5"
                onClick={() => setCurrentSelection("", null)}
              >
                {ALL_FORMS.ai.name}
              </TabsTrigger>
              <TabsTrigger
                value={ALL_FORMS.brand.value}
                className="data-[state=active]:bg-muted px-3 py-1.5"
                onClick={() => setCurrentSelection("", null)}
              >
                {ALL_FORMS.brand.name}
              </TabsTrigger>
              <TabsTrigger
                value={ALL_FORMS.theme.value}
                className="data-[state=active]:bg-muted px-3 py-1.5"
                onClick={() => setCurrentSelection("", null)}
              >
                {ALL_FORMS.theme.name}
              </TabsTrigger>
            </TabsList>
          </div>
        </div>
        <div className="p-2 flex flex-col items-stretch w-full overflow-auto">
          {currentSelection ? (
            <StyleMenu form={form} className={"m-4"} onImageClick={onImageClick} />
          ) : null}
          <VerticalTabsContent
            value={ALL_FORMS.ai.value}
            className="mt-0 border-0 p-0 m-4"
          >
            <AIInputForm />
          </VerticalTabsContent>
          <VerticalTabsContent
            value={ALL_FORMS.brand.value}
            className="mt-0 border-0 p-0 m-4"
          >
            <BrandForm onImageClick={onImageClick} />
          </VerticalTabsContent>
          <VerticalTabsContent
            value={ALL_FORMS.theme.value}
            className="mt-0 border-0 p-0 m-4"
          >
            <ThemeForm />
          </VerticalTabsContent>
        </div>
      </div>
    </VerticalTabs>
  );
}

export function DrawerFormsPanel({ className, onImageClick }: { className?: string; onImageClick: (fieldName: string) => void }) {
  const { currentSelection } = useSelectionContext();
  const [tab, setTab] = useState(ALL_FORMS.ai.value);
  const form: DocumentFormReturn = useFormContext();

  return (
    <Tabs
      value={currentSelection ? "" : tab}
      onValueChange={(val) => {
        if (val) {
          setTab(val);
        }
      }}
      className={cn("w-full", className)}
    >
      <div className="flex flex-col">
        <TabsList className="flex justify-between gap-1 rounded-lg bg-muted/50 p-1">
          <TabsTrigger
            value={ALL_FORMS.ai.value}
            className="flex-1 rounded-md px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm"
          >
            <span className="flex items-center gap-2">
              <Sparkles className="h-4 w-4" />
              {ALL_FORMS.ai.name}
            </span>
          </TabsTrigger>
          <TabsTrigger
            value={ALL_FORMS.brand.value}
            className="flex-1 rounded-md px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm"
          >
            <span className="flex items-center gap-2">
              <Briefcase className="h-4 w-4" />
              {ALL_FORMS.brand.name}
            </span>
          </TabsTrigger>
          <TabsTrigger
            value={ALL_FORMS.theme.value}
            className="flex-1 rounded-md px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm"
          >
            <span className="flex items-center gap-2">
              <Palette className="h-4 w-4" />
              {ALL_FORMS.theme.name}
            </span>
          </TabsTrigger>
        </TabsList>
        
        <div className="mt-4 space-y-4">
          <TabsContent value={ALL_FORMS.ai.value} className="focus-visible:outline-none">
            <AIInputForm />
          </TabsContent>
          <TabsContent value={ALL_FORMS.brand.value} className="focus-visible:outline-none">
            <BrandForm onImageClick={onImageClick} />
          </TabsContent>
          <TabsContent value={ALL_FORMS.theme.value} className="focus-visible:outline-none">
            <ThemeForm />
          </TabsContent>
        </div>
      </div>
    </Tabs>
  );
}

const CircularFloatingButton = ({
  children,
  className,
  onClick,
}: {
  children: ReactNode;
  className?: string;
  onClick: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        buttonVariants({
          variant: "default",
          size: "icon",
        }),
        "w-12 h-12 rounded-full shadow-lg hover:shadow-xl transition-shadow",
        className
      )}
    >
      {children}
    </div>
  );
};

// New optimized mobile version of the forms panel
function MobileDrawerFormsPanel({ onImageClick }: { onImageClick: (fieldName: string) => void }) {
  const [tab, setTab] = useState(ALL_FORMS.ai.value);

  return (
    <Tabs value={tab} onValueChange={setTab}>
      <div className="flex flex-col">
        {/* Sticky Tab Header */}
        <div className="sticky top-0 bg-background p-4 border-b">
          <TabsList className="flex justify-between gap-1 rounded-lg bg-muted/50 p-1">
            {Object.values(ALL_FORMS).map((formInfo) => (
              <TabsTrigger
                key={formInfo.value}
                value={formInfo.value}
                className={cn(
                  "flex-1 rounded-md px-3 py-2.5 text-sm font-medium",
                  "ring-offset-background transition-all",
                  "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
                  "disabled:pointer-events-none disabled:opacity-50",
                  "data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm",
                  tab === formInfo.value ? "bg-background shadow-sm" : "hover:bg-muted/60"
                )}
              >
                <span className="flex items-center gap-2">
                  <formInfo.icon className="h-4 w-4" />
                  <span className="hidden sm:inline">{formInfo.name}</span>
                </span>
              </TabsTrigger>
            ))}
          </TabsList>
        </div>

        {/* Scrollable Content Area */}
        <div className="p-4 space-y-6">
          <TabsContent value={ALL_FORMS.ai.value} className="mt-0">
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">AI Generation</h3>
              <AIInputForm />
            </div>
          </TabsContent>
          <TabsContent value={ALL_FORMS.brand.value} className="mt-0">
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">Profile Settings</h3>
              <BrandForm onImageClick={onImageClick} />
            </div>
          </TabsContent>
          <TabsContent value={ALL_FORMS.theme.value} className="mt-0">
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">Theme Settings</h3>
              <ThemeForm />
            </div>
          </TabsContent>
        </div>
      </div>
    </Tabs>
  );
}
