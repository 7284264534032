"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFormContext } from "react-hook-form";
import * as z from "zod";
import { useState } from "react";
import { Sparkles } from "lucide-react";

import { Button } from "./ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Input } from "./ui/input";
import { toast } from "./ui/use-toast";
import { LoadingSpinner } from "./loading-spinner";
import { useKeysContext } from "./lib/providers/keys-context";
import { useStatusContext } from "./lib/providers/editor-status-context";
import { generateCarouselSlidesAction } from "./lib/actions";
import { DocumentFormReturn } from "./lib/document-form-types";
import { ModelSelector } from "@/components/ui/model-selector";
import { getCurrentModel, type AIModel } from "@/services/deepseek";

const FormSchema = z.object({
  prompt: z.string().min(2, {
    message: "Prompt must be at least 2 characters.",
  }),
  numPages: z.number().min(1).max(10, {
    message: "Number of pages must be between 1 and 10.",
  }),
});

export function AIInputForm() {
  const { apiKey } = useKeysContext();
  const { setValue }: DocumentFormReturn = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const { status, setStatus } = useStatusContext();
  const [currentModel, setCurrentModel] = useState<AIModel>(getCurrentModel());

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      prompt: "",
      numPages: 3,
    },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    if (!apiKey) {
      toast({
        title: "API Key Required",
        description: "Please provide your OpenAI API key in the settings.",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);
    setStatus("loading");

    try {
      const generatedSlides = await generateCarouselSlidesAction(
        data.prompt,
        apiKey,
        data.numPages,
        currentModel
      );
      
      if (generatedSlides) {
        setValue("slides", generatedSlides);
        toast({
          title: "Success",
          description: "Your carousel slides have been generated!",
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to generate slides. Please try again.",
          variant: "destructive",
        });
      }
    } catch (error) {
      console.error("Error generating slides:", error);
      toast({
        title: "Error",
        description: "An error occurred while generating slides.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
      setStatus("ready");
      form.reset();
    }
  }

  const handleModelChange = (model: AIModel) => {
    setCurrentModel(model);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
        <div className="space-y-4">
          <FormField
            control={form.control}
            name="prompt"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Theme/Topic</FormLabel>
                <FormControl>
                  <Input 
                    placeholder="E.g., '5 tips for better sleep' or 'The evolution of mobile technology'" 
                    {...field} 
                  />
                </FormControl>
                <FormDescription>
                  Describe the theme or topic for your carousel. Be specific about what you want to communicate.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="numPages"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Number of Pages</FormLabel>
                <FormControl>
                  <Input 
                    type="number" 
                    min={1} 
                    max={10} 
                    {...field} 
                    onChange={e => field.onChange(parseInt(e.target.value))}
                  />
                </FormControl>
                <FormDescription>
                  Choose how many pages your carousel should have (1-10).
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="space-y-2">
            <FormLabel>AI Model</FormLabel>
            <ModelSelector
              currentModel={currentModel}
              onModelChange={handleModelChange}
              className="justify-start"
            />
            <FormDescription>
              Select the AI model to generate your carousel content.
            </FormDescription>
          </div>
        </div>

        <Button type="submit" disabled={isLoading} className="w-full">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Sparkles className="mr-2 h-4 w-4" /> Generate Carousel
            </>
          )}
        </Button>
      </form>
    </Form>
  );
}
