export const SIZES = {
  SQUARE: {
    width: 500,  // LinkedIn optimal width
    height: 500, // LinkedIn optimal height
    aspectRatio: 1,
    label: 'Square'
  },
  PORTRAIT: {
    width: 400,
    height: 500,
    aspectRatio: 0.8,
    label: 'Portrait'
  }
} as const;

export type SizeOption = keyof typeof SIZES;
export type Size = typeof SIZES[SizeOption];

// Default size
export const DEFAULT_SIZE: SizeOption = 'SQUARE';
