"use client";
import React from 'react';
import {
  DocumentFormReturn,
  SlidesFieldArrayReturn,
} from "./lib/document-form-types";
import { cn } from "@/lib/utils";

import { useSelectionContext } from "./lib/providers/selection-context";
import SlideMenubar from "./slide-menubar";

interface SlideMenubarWrapperProps {
  fieldName: string;
  slidesFieldArray: SlidesFieldArrayReturn;
  children: React.ReactNode;
  className?: string;
}

const SlideMenubarWrapper = React.forwardRef<HTMLDivElement, SlideMenubarWrapperProps>(
  ({ fieldName, slidesFieldArray, children, className = "" }, ref) => {
    const { currentSelection } = useSelectionContext();

    return (
      <div className="relative w-fit px-2 group" ref={ref} id={"slide-wrapper-" + fieldName}>
        <div
          id={`slide-menubar-${fieldName}`}
          className={cn(
            "absolute -top-10 right-0 z-50", // Increased z-index to match element-menubar
            "opacity-0 transition-all duration-200 ease-in-out",
            "group-hover:opacity-100 group-focus-within:opacity-100",
            currentSelection !== fieldName && "hidden",
            className
          )}
        >
          <SlideMenubar
            slidesFieldArray={slidesFieldArray}
            fieldName={fieldName}
            className="mr-2"
          />
        </div>
        {children}
      </div>
    );
  }
);

SlideMenubarWrapper.displayName = 'SlideMenubarWrapper';

export default SlideMenubarWrapper;
