import type { PixabayImage } from '@/types/post';
import { PIXABAY_API_KEY } from '@/constants/post-creator';

export async function searchPixabayImages(query: string): Promise<PixabayImage[]> {
  if (!query.trim()) {
    return [];
  }

  try {
    const response = await fetch(
      `https://pixabay.com/api/?key=${PIXABAY_API_KEY}&q=${encodeURIComponent(query)}&image_type=photo&per_page=12`
    );
    const data = await response.json();
    return data.hits;
  } catch (error) {
    console.error('Error searching images:', error);
    throw new Error('Failed to search for images');
  }
}

export function getImageSelectionHandler(
  currentImages: PixabayImage[],
  onUpdate: (images: PixabayImage[]) => void
) {
  return (image: PixabayImage) => {
    if (currentImages.find(img => img.id === image.id)) {
      // Remove image if already selected
      onUpdate(currentImages.filter(img => img.id !== image.id));
    } else if (currentImages.length < 2) {
      // Add image if less than 2 selected
      onUpdate([...currentImages, image]);
    }
  };
} 