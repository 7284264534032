import { supabase } from '@/integrations/supabase/client';
import type { Database } from '@/types/supabase';
import { IdealClientProfile, UserProfile } from '@/types/profile';
import { getExamplePostsByType, getFormattingTips } from '@/data/example-posts';
import type { ContentPlanPost, GroupedContentPlan } from './contentPlanner';
import { addDays, format } from 'date-fns';
import type { AIModel } from '@/services/deepseek';
import { makeAIRequest } from '@/services/deepseek';
import type { PostType } from '@/types/post';

interface ProfileContext {
  userProfile?: {
    description: string;
  };
  idealClient?: {
    description: string;
  };
}

const BATCH_SIZE = 15;

// Improved system prompt for better content quality
const SYSTEM_PROMPT = `You are an expert LinkedIn content strategist specializing in creating engaging, evidence-based content for medical professionals. Your expertise is in translating complex healthcare topics into engaging social media content that maintains clinical accuracy while driving professional engagement. You understand the unique voice and concerns of healthcare practitioners and can help them communicate effectively with colleagues, patients, and the broader healthcare community.`;

// Types for structured response
interface WeeklyContentResponse {
  posts: {
    date: string;
    type: 'text' | 'image' | 'article' | 'poll';
    topic: string;
    keyPoints: string[];
    imageDescription?: string;
  }[];
  theme: string;
}

// Main prompt template for generating weekly content
function getWeekPrompt(includeWeekends: boolean, postsNeeded: number, isPartialWeek: boolean): string {
  return `Create a LinkedIn content plan for the ${isPartialWeek ? 'partial' : 'full'} week.
Return a JSON object (without markdown formatting) matching this TypeScript interface:

interface WeeklyContent {
  posts: {
    date: string;        // YYYY-MM-DD format, must be weekday
    type: 'text' | 'image' | 'article' | 'poll';
    topic: string;       // Compelling title/question
    keyPoints: string[]; // Exactly 2 key points
    imageDescription?: string;  // Required for image posts
  }[];
  theme: string;        // The theme used for this week's content
}

Requirements:
- Generate exactly ${postsNeeded} posts starting {startDate}
- Each post must have exactly 2 key points
- Posts must align with theme: "{theme}"
- Include a diverse mix of post types
- For poll posts: topic must be a direct question
- For image posts: include clear image description
- All dates must be consecutive ${includeWeekends ? 'days' : 'weekdays'}

Special Instructions for Post Types:

TEXT POSTS:
• Share actionable insights from experience
• Include specific examples or case studies

IMAGE POSTS:
• Describe a clear, professional visual concept
• Explain how the image supports the message

ARTICLE POSTS:
• Use "How to" or numbered list formats
• Preview key takeaways that provide value

POLL POSTS:
• Topic must be a direct question to the audience
• Key points should explain value of participation

{profileContext}

Example post structure:
{
  "posts": [{
    "date": "2025-02-12",
    "type": "poll",
    "topic": "What's Your Biggest Challenge in Growing Your Business?",
    "keyPoints": [
      "Understanding your challenges helps us create targeted solutions",
      "Your insights will help other business owners avoid common pitfalls"
    ]
  }],
  "theme": "Professional Growth & Development"
}`;
}

// Hook generation prompt
const HOOK_PROMPT = `Generate 5 focused LinkedIn post hooks:
- Under 80 characters each
- Clear value proposition
- Professional tone
Format as array of strings only.`;

// Add caching for profile data
const profileCache = new Map<string, ProfileContext>();

// Function to generate profile context string
function generateProfileContext(profile?: ProfileContext): string {
  if (!profile?.userProfile || !profile?.idealClient) {
    return '';
  }

  return `Profile Context:
Writing as: ${profile.userProfile.description || 'Professional'}
Target Audience: ${profile.idealClient.description || 'Professionals seeking growth'}`;
}

// Helper function to get profile data with caching
async function getProfileData(userId: string): Promise<ProfileContext | undefined> {
  // Check cache first
  if (profileCache.has(userId)) {
    return profileCache.get(userId);
  }

  // Fetch from Supabase if not cached
  const profile = await fetchProfileData(userId);
  if (profile) {
    profileCache.set(userId, profile);
  }
  return profile;
}

// Helper function to get the number of posts needed for a given date range
function getPostsNeededForRange(startDate: Date, endDate: Date, includeWeekends: boolean): number {
  let count = 0;
  let currentDate = new Date(startDate);
  
  while (currentDate <= endDate) {
    const day = currentDate.getDay();
    if (includeWeekends || (day !== 0 && day !== 6)) {
      count++;
    }
    currentDate = addDays(currentDate, 1);
  }
  
  return count;
}

// Helper function to get next Monday
function getNextMonday(date: Date): Date {
  const day = date.getDay();
  const daysUntilMonday = day === 0 ? 1 : 8 - day;
  return addDays(date, daysUntilMonday);
}

// Helper function to get next valid post date
function getNextValidPostDate(currentDate: Date, includeWeekends: boolean): Date {
  let nextDate = new Date(currentDate);
  if (!includeWeekends) {
    while (nextDate.getDay() === 0 || nextDate.getDay() === 6) {
      nextDate = addDays(nextDate, 1);
    }
  }
  return nextDate;
}

// Helper function to calculate post dates for a week
function calculatePostDates(startDate: Date, endDate: Date, includeWeekends: boolean): string[] {
  const dates: string[] = [];
  let currentDate = new Date(startDate);
  
  // If not including weekends and starting on a weekend, move to next valid day
  if (!includeWeekends && (currentDate.getDay() === 0 || currentDate.getDay() === 6)) {
    currentDate = getNextValidPostDate(currentDate, includeWeekends);
  }
  
  while (currentDate <= endDate) {
    const day = currentDate.getDay();
    if (includeWeekends || (day !== 0 && day !== 6)) {
      dates.push(format(currentDate, 'yyyy-MM-dd'));
    }
    currentDate = addDays(currentDate, 1);
  }
  
  return dates;
}

// Helper function to generate week start dates
function generateWeekStartDates(totalWeeks: number, startDate: Date = new Date(), includeWeekends: boolean = false): { date: string; isPartialWeek: boolean }[] {
  const dates: { date: string; isPartialWeek: boolean }[] = [];
  let currentDate = startDate;
  
  // Handle first week
  const startDay = currentDate.getDay();
  const isFirstWeekPartial = startDay !== 1; // Any start day other than Monday is a partial week
  
  // Add first week
  dates.push({
    date: format(currentDate, 'yyyy-MM-dd'),
    isPartialWeek: isFirstWeekPartial
  });
  
  // If starting on a weekend and not including weekends, adjust the start date
  if (!includeWeekends && (startDay === 0 || startDay === 6)) {
    currentDate = getNextValidPostDate(currentDate, includeWeekends);
  }
  
  // Move to next Monday for remaining full weeks
  currentDate = getNextMonday(currentDate);
  
  // Generate remaining weeks
  for (let i = (isFirstWeekPartial ? 1 : 1); i < totalWeeks; i++) {
    dates.push({
      date: format(currentDate, 'yyyy-MM-dd'),
      isPartialWeek: false
    });
    currentDate = addDays(currentDate, 7);
  }
  
  return dates;
}

// Helper function to generate content for a single week
async function generateWeekContent(
  startDate: string,
  weekIndex: number,
  profileContext: string,
  includeWeekends: boolean = false,
  isPartialWeek: boolean = false,
  weekTheme: string = WEEKLY_THEMES[0]
): Promise<GroupedContentPlan[]> {
  console.log(`Starting generation for ${isPartialWeek ? 'partial' : 'full'} week ${weekIndex + 1}, theme: ${weekTheme}`);

  const MAX_RETRIES = 3;
  let retryCount = 0;

  while (retryCount < MAX_RETRIES) {
    try {
      const currentDate = new Date(startDate);
      let endDate: Date;
      
      if (isPartialWeek) {
        // If it's a partial week starting on Sunday, set end date to next Friday
        if (currentDate.getDay() === 0 && !includeWeekends) {
          endDate = addDays(currentDate, 5); // Sunday to Friday
        } else {
          endDate = getNextMonday(currentDate);
          endDate = addDays(endDate, -1);
        }
      } else {
        endDate = addDays(currentDate, 6);
      }

      const postDates = calculatePostDates(currentDate, endDate, includeWeekends);
      
      if (postDates.length === 0) {
        console.log(`No valid post dates found for week ${weekIndex + 1}, skipping`);
        return [];
      }

      console.log(`Generating ${postDates.length} posts for dates:`, postDates);

      const prompt = getWeekPrompt(includeWeekends, postDates.length, isPartialWeek)
        .replace('{startDate}', startDate)
        .replace('{theme}', weekTheme)
        .replace('{profileContext}', profileContext);

      const content = await makeAIRequest(prompt, SYSTEM_PROMPT, true);
      if (!content) {
        throw new Error('Empty response from API');
      }

      console.log('Raw API response:', content);
      
      // Enhanced JSON cleaning and validation
      let cleanedContent = content;
      try {
        cleanedContent = cleanJsonString(content);
        console.log('Cleaned JSON content:', cleanedContent);
        
        // Attempt to parse JSON before proceeding
        const testParse = JSON.parse(cleanedContent);
        if (!testParse.posts || !Array.isArray(testParse.posts)) {
          throw new Error('Invalid JSON structure');
        }
      } catch (parseError) {
        console.error('JSON parsing error:', parseError);
        throw parseError;
      }

      const posts = parseAndValidatePosts(cleanedContent, startDate);
      if (posts.length !== postDates.length) {
        throw new Error(`Post count mismatch: expected ${postDates.length}, got ${posts.length}`);
      }

      // Ensure each post has the correct date and theme
      posts.forEach((group, index) => {
        if (group.posts[0]) {
          group.posts[0].suggestedDate = postDates[index];
          group.theme = weekTheme; // Ensure theme is set for each group
        }
      });

      console.log(`Successfully generated ${posts.length} posts for week ${weekIndex + 1}`);
      return posts;

    } catch (error) {
      retryCount++;
      console.error(`Attempt ${retryCount}/${MAX_RETRIES} failed for week ${weekIndex + 1}:`, error);
      
      if (retryCount === MAX_RETRIES) {
        console.error(`All ${MAX_RETRIES} attempts failed for week ${weekIndex + 1}`);
        return [];
      }
      
      await new Promise(resolve => setTimeout(resolve, Math.pow(2, retryCount) * 1000));
    }
  }

  return [];
}

// Helper function to clean potential markdown from JSON
function cleanJsonString(content: string): string {
  try {
    // Remove any non-JSON content before the first {
    const firstBrace = content.indexOf('{');
    const lastBrace = content.lastIndexOf('}');
    if (firstBrace === -1 || lastBrace === -1) {
      throw new Error('No valid JSON object found in content');
    }
    
    // Extract just the JSON part
    content = content.slice(firstBrace, lastBrace + 1);
    
    // Remove markdown code block markers
    content = content.replace(/```json\n?/g, '').replace(/```\n?/g, '');
    
    // Remove control characters and non-printable characters
    content = content.split('').filter(char => {
      const code = char.charCodeAt(0);
      return code >= 32 && code < 127;
    }).join('');
    
    // Fix common JSON formatting issues
    content = content
      .replace(/,\s*([}\]])/g, '$1') // Remove trailing commas
      .replace(/([{,])\s*}/g, '}') // Remove empty object properties
      .replace(/\n/g, ' ') // Remove newlines
      .replace(/\s+/g, ' ') // Normalize whitespace
      .replace(/"\s*:\s*/g, '":') // Normalize property separators
      .replace(/,\s*/g, ',') // Normalize commas
      .trim();
    
    // Validate the cleaned JSON by parsing it
    const parsed = JSON.parse(content);
    if (!parsed || typeof parsed !== 'object') {
      throw new Error('Cleaned content is not a valid JSON object');
    }
    
    return content;
  } catch (error) {
    console.error('Error cleaning JSON string:', error);
    console.error('Original content:', content);
    throw error;
  }
}

// Helper function to parse and validate posts
function parseAndValidatePosts(content: string, startDate: string): GroupedContentPlan[] {
  try {
    // Clean and parse JSON response
    const cleanContent = cleanJsonString(content);
    console.log('Cleaned JSON content:', cleanContent); // Debug log
    
    const response = JSON.parse(cleanContent);
    if (!response || !Array.isArray(response.posts)) {
      console.error('Invalid response structure:', response);
      return [];
    }
    
    // Validate and transform posts
    const groupedPosts = response.posts
      .map(post => {
        try {
          // Validate required fields
          if (!post.date || !post.type || !post.topic || !post.keyPoints) {
            console.error('Missing required fields in post:', post);
            return null;
          }

          // Validate date format
          if (!/^\d{4}-\d{2}-\d{2}$/.test(post.date)) {
            console.error('Invalid date format:', post.date);
            return null;
          }

          // Validate post type
          if (!['text', 'image', 'article', 'poll'].includes(post.type)) {
            console.error('Invalid post type:', post.type);
            return null;
          }

          // Validate key points
          if (!Array.isArray(post.keyPoints) || post.keyPoints.length !== 2) {
            console.error('Must have exactly 2 key points:', post);
            return null;
          }

          // Create post with only the fields we want
          const cleanPost: ContentPlanPost = {
            topic: post.topic,
            postType: post.type,
            category: post.type,
            keyPoints: post.keyPoints,
            description: post.imageDescription || '',
            scheduledTime: '12:00 PM',
            suggestedDate: post.date
          };

          return {
            type: post.type,
            posts: [cleanPost],
            theme: response.theme // Include the theme in the grouped content
          } as GroupedContentPlan;
        } catch (error) {
          console.error('Error processing post:', error);
          return null;
        }
      })
      .filter((post): post is GroupedContentPlan => post !== null);

    return groupedPosts;
  } catch (error) {
    console.error('Error parsing JSON response:', error);
    console.error('Raw content:', content);
    return [];
  }
}

// Weekly themes
const WEEKLY_THEMES = [
  "Professional Growth & Development",
  "Leadership & Team Management",
  "Innovation & Technology Trends",
  "Productivity & Time Management",
  "Business Strategy & Success",
  "Industry Insights & Analysis",
  "Client Relations & Service Excellence",
  "Personal Branding & Career Development"
];

export async function generatePostHooks(
  postType: PostType,
  userProfile: UserProfile & {
    settings?: {
      language: 'en-US' | 'en-GB';
    };
  },
  idealClientProfile: IdealClientProfile,
  currentAIModel: AIModel,
  tones: string[] = ['professional'],
  topic?: string
): Promise<string[]> {
  // Get language preference from user profile first
  const language = userProfile.settings?.language || 'en-US';
  const isUK = language === 'en-GB';

  const systemPrompt = `You are an expert LinkedIn content strategist specializing in the healthcare and medical sector. You create highly engaging, evidence-based content that resonates with medical professionals while maintaining clinical credibility. You understand what makes healthcare professionals stop scrolling and engage with content. You write in ${isUK ? 'British' : 'American'} English, adapting medical terminology appropriately.`;

  const prompt = `Generate 5 compelling LinkedIn post hooks for a medical professional:

Context:
- Post Type: ${postType}
- Writing Style/Tone: ${tones.join(', ')}
- Medical Professional Profile: ${JSON.stringify(userProfile)}
- Target Healthcare Audience: ${JSON.stringify(idealClientProfile)}
${topic ? `- Specific Medical Topic: ${topic}` : ''}
- Language: ${isUK ? 'British English' : 'American English'}

LinkedIn-Specific Requirements:
1. Each hook MUST be under 80 characters (LinkedIn's optimal visibility length)
2. Hooks should prompt discussion and invite professional comments
3. Focus on healthcare insights, patient outcomes, or clinical advancements
4. Each hook should contain a clear value proposition for healthcare peers
5. Must be relevant to the medical professional's specific expertise
6. Use ${isUK ? 'British' : 'American'} medical terminology appropriately
7. Hook style should match the post type:
   - For articles: Present a compelling knowledge gap or surprising finding
   - For polls: Frame as a thought-provoking clinical question
   - For image posts: Highlight a visual insight or contrast
   - For text posts: Share a provocative medical opinion or counterintuitive result
8. Write in simple, accessible language (avoid medical jargon unless crucial)
9. Use specificity - include numbers, statistics, or concrete examples where possible
10. For each hook, consider: "Would a busy healthcare professional stop scrolling for this?"

Content Don'ts:
- No generic healthcare platitudes or clichés
- No clickbait techniques or overpromising
- No hashtags, emojis, or excessive punctuation
- Avoid overused phrases like "transform patient care," "healthcare revolution," "unlock potential"
- No medical misinformation or controversial claims without context

Format:
- Return exactly 5 hooks
- Each hook on a new line
- Each hook must start with a number and a period (e.g., "1. ")
- Keep each hook under 80 characters

Strong Healthcare Hook Examples:
1. 78% of Patients Misunderstand This Common Medication Instruction
2. Why I Changed My Approach to Diabetic Foot Exams After 15 Years
3. The 3-Minute Assessment That Prevented 12 Missed Diagnoses
4. Are We Over-Prescribing in Geriatric Care? My Evidence Says Yes
5. What 500 Patient Follow-ups Taught Me About Post-Surgical Care`;

  const response = await makeAIRequest(prompt, systemPrompt);
  if (!response) {
    throw new Error('No hooks generated');
  }

  // Split the response into lines and filter out any empty lines
  const hooks = response
    .split('\n')
    .map(line => line.trim())
    .filter(line => line.match(/^\d+\.\s/)) // Only keep lines that start with a number and period
    .map(line => line.replace(/^\d+\.\s/, '').trim()) // Remove the numbering
    .filter(line => line.length <= 80); // Ensure hooks are under 80 characters

  return hooks;
}

// Optimized content generation with parallel processing
export async function generateContentIdeas(
  useProfileInfo: boolean,
  userId: string,
  onBatchGenerated: (posts: GroupedContentPlan[]) => void,
  totalIdeas: number = 1,
  includeWeekends: boolean = false,
  startDate: Date = new Date(),
  selectedThemes: string[] = []
): Promise<GroupedContentPlan[]> {
  try {
    // Clear existing content at the start of generation
    const allGeneratedIdeas: GroupedContentPlan[] = [];
    
    // Get profile data with caching
    const profile = useProfileInfo ? await getProfileData(userId) : undefined;
    const profileContext = generateProfileContext(profile);
    
    const POSTS_PER_WEEK = includeWeekends ? 7 : 5;
    const totalWeeks = Math.ceil(totalIdeas / POSTS_PER_WEEK);
    
    // Use selected themes if provided, otherwise fall back to default themes
    const themesToUse = selectedThemes.length > 0 ? selectedThemes : WEEKLY_THEMES;
    
    // Calculate week start dates using provided start date and weekend preference
    const weekStartDates = generateWeekStartDates(totalWeeks, startDate, includeWeekends);

    // Process first week immediately
    try {
      const firstWeek = weekStartDates[0];
      const weekTheme = themesToUse[0 % themesToUse.length]; // Use first selected theme
      const weekContent = await generateWeekContent(
        firstWeek.date,
        0,
        profileContext,
        includeWeekends,
        firstWeek.isPartialWeek,
        weekTheme
      );

      if (weekContent.length > 0) {
        allGeneratedIdeas.push(...weekContent);
        onBatchGenerated(weekContent);
      }
    } catch (error) {
      console.error('Error generating first week:', error);
    }

    // Now process remaining weeks in parallel batches
    const remainingWeeks = weekStartDates.slice(1);
    const BATCH_SIZE = 1; // Process one week at a time to avoid rate limits
    
    for (let i = 0; i < remainingWeeks.length; i += BATCH_SIZE) {
      const batchDates = remainingWeeks.slice(i, i + BATCH_SIZE);
      const batchPromises = batchDates.map((weekInfo, index) => {
        const weekIndex = i + 1 + index;
        const weekTheme = themesToUse[weekIndex % themesToUse.length];
        return generateWeekContent(
          weekInfo.date,
          weekIndex,
          profileContext,
          includeWeekends,
          weekInfo.isPartialWeek,
          weekTheme
        );
      });

      try {
        const batchResults = await Promise.allSettled(batchPromises);
        
        for (const result of batchResults) {
          if (result.status === 'fulfilled' && result.value.length > 0) {
            allGeneratedIdeas.push(...result.value);
            onBatchGenerated(result.value);
          } else if (result.status === 'rejected') {
            console.error(`Week generation failed:`, result.reason);
          }
        }

        // Add a small delay between batches to avoid rate limits
        await new Promise(resolve => setTimeout(resolve, 1000));
      } catch (error) {
        console.error('Error processing batch:', error);
      }
    }

    return allGeneratedIdeas;
  } catch (error) {
    console.error('Error in generateContentIdeas:', error);
    throw new Error('Failed to generate content ideas');
  }
}

// Helper function to fetch profile data
async function fetchProfileData(userId: string): Promise<ProfileContext | undefined> {
  const { data } = await supabase
    .from('profiles')
    .select('user_profile, ideal_client_profile')
    .eq('id', userId)
    .single();

  if (!data?.user_profile || !data?.ideal_client_profile) return undefined;

  return {
    userProfile: data.user_profile,
    idealClient: data.ideal_client_profile
  };
} 