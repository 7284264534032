import React from "react";
import { useReactToPrint } from "react-to-print";
import { SIZES } from "../page-size";
import { useFieldArrayValues } from "./use-field-array-values";
import { useFormContext } from "react-hook-form";
import { DocumentFormReturn } from "../document-form-types";
import { toCanvas } from "html-to-image";
import { Options as HtmlToImageOptions } from "html-to-image/lib/types";
import { jsPDF, jsPDFOptions } from "jspdf";


// TODO: Create a reusable component and package with this code

type HtmlToPdfOptions = {
  margin: [number, number, number, number];
  filename: string;
  image: { type: string; quality: number };
  htmlToImage: HtmlToImageOptions;
  jsPDF: jsPDFOptions;
  size?: typeof SIZES.SQUARE;
};

// Convert units to px using the conversion value 'k' from jsPDF.
export const toPx = function toPx(val: number, k: number) {
  return Math.floor(((val * k) / 72) * 96);
};

// Ensure all images are loaded before proceeding
const ensureImagesLoaded = async (element: HTMLElement): Promise<void> => {
  const images = Array.from(element.getElementsByTagName('img'));
  
  // First, handle any external images that need proxying
  const externalImages = images.filter(
    (img) => !img.src.startsWith("/") && !img.src.startsWith("data:") && !img.src.includes("/proxy")
  );

  // Create a map to store original sources
  const originalSources = new Map<HTMLImageElement, string>();

  externalImages.forEach((img) => {
    const originalSrc = img.src;
    originalSources.set(img, originalSrc);
    const apiRequestURL = new URL("/api/proxy", window.location.origin);
    apiRequestURL.searchParams.set("url", originalSrc);
    img.src = apiRequestURL.toString();
  });

  // Then wait for all images to load
  await Promise.all(
    images.map(img => 
      img.complete ? 
        Promise.resolve() : 
        new Promise<void>((resolve) => {
          const originalSrc = originalSources.get(img) || img.src;
          img.onload = () => resolve();
          img.onerror = async () => {
            console.warn(`Failed to load image: ${originalSrc}`);
            // If proxy failed and it was an external image, try direct URL as fallback
            if (originalSources.has(img)) {
              img.src = originalSrc;
              try {
                await new Promise((res, rej) => {
                  img.onload = res;
                  img.onerror = rej;
                });
                resolve();
                return;
              } catch {
                // If direct URL also fails, use placeholder
                img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
              }
            } else {
              // For non-external images that fail, use placeholder
            img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
            }
            resolve();
          };
        })
    )
  );
};

// Handle canvas conversion with retries
const handleCanvasConversion = async (
  html: HTMLElement, 
  options: HtmlToImageOptions, 
  retries = 3
): Promise<HTMLCanvasElement> => {
  try {
    await ensureImagesLoaded(html);
    const canvas = await toCanvas(html, {
      ...options,
      cacheBust: true,
      skipAutoScale: true,
      quality: 1,
      canvasWidth: options.canvasWidth,
      canvasHeight: options.canvasHeight,
      pixelRatio: 2,
      backgroundColor: options.backgroundColor || '#ffffff',
      style: {
        transform: 'none',
        transformOrigin: 'none'
      }
    });
    if (!canvas) throw new Error('Canvas creation failed');
    return canvas;
  } catch (error) {
    if (retries > 0) {
      console.warn(`Canvas conversion failed, retrying... (${retries} attempts left)`);
      await new Promise(resolve => setTimeout(resolve, 500));
      return handleCanvasConversion(html, options, retries - 1);
    }
    throw error;
  }
};

function getPdfPageSize(opt: HtmlToPdfOptions) {
  const pdf = new jsPDF(opt.jsPDF);
  const pageSize = {
    width: pdf.internal.pageSize.getWidth(),
    height: pdf.internal.pageSize.getHeight(),
    k: pdf.internal.scaleFactor,
  };

  const inner = {
    width: pageSize.width - opt.margin[1] - opt.margin[3],
    height: pageSize.height - opt.margin[0] - opt.margin[2],
  };

  return {
    ...pageSize,
    inner: {
      ...inner,
      px: {
        width: toPx(inner.width, pageSize.k),
        height: toPx(inner.height, pageSize.k),
      },
      ratio: inner.height / inner.width,
    },
  };
}

function canvasToPdf(canvas: HTMLCanvasElement, opt: HtmlToPdfOptions) {
  const pdfPageSize = getPdfPageSize(opt);
  const currentSize = opt.size || SIZES.SQUARE;
  const slideHeight = canvas.height / opt.htmlToImage.height * currentSize.height;
  const numSlides = opt.htmlToImage.height / currentSize.height;

  // Initialize the PDF with improved settings
  const pdf = new jsPDF({
    ...opt.jsPDF,
    orientation: currentSize.width >= currentSize.height ? 'landscape' : 'portrait',
    unit: 'px',
    format: [currentSize.width, currentSize.height],
    compress: true,
    precision: 4,
    hotfixes: ['px_scaling'],
  });

  // Remove default margins
  pdf.setProperties({
    title: opt.filename,
    creator: 'MedicalPost ',
    author: '',
  });

  // Calculate scaling to fit the page while maintaining aspect ratio
  const scale = Math.min(
    pdfPageSize.width / currentSize.width,
    pdfPageSize.height / currentSize.height
  );

  // Calculate centered position
  const xOffset = (pdfPageSize.width - currentSize.width * scale) / 2;
  const yOffset = (pdfPageSize.height - currentSize.height * scale) / 2;

  for (let slideIndex = 0; slideIndex < numSlides; slideIndex++) {
    try {
      if (slideIndex > 0) {
        pdf.addPage([currentSize.width, currentSize.height], currentSize.width >= currentSize.height ? 'landscape' : 'portrait');
      }

      // Create a temporary canvas for each slide
      const slideCanvas = document.createElement('canvas');
      slideCanvas.width = canvas.width;
      slideCanvas.height = slideHeight;
      const ctx = slideCanvas.getContext('2d');
      if (!ctx) throw new Error('Failed to get canvas context');

      // Draw the current slide portion
      ctx.drawImage(
        canvas,
        0,
        slideIndex * slideHeight,
        canvas.width,
        slideHeight,
        0,
        0,
        slideCanvas.width,
        slideCanvas.height
      );

      // Convert to high-quality image
      const imgData = slideCanvas.toDataURL('image/png', 1.0);

      // Add the image to the PDF - ensure it fills the page completely
      pdf.addImage(
        imgData,
        'PNG',
        xOffset,
        yOffset,
        currentSize.width * scale,
        currentSize.height * scale,
        undefined,
        'FAST'
      );
    } catch (error) {
      console.error(`Error processing slide ${slideIndex + 1}:`, error);
      throw new Error(`Failed to process slide ${slideIndex + 1}`);
    }
  }

  return pdf;
}

export function useComponentPrinter() {
  const { numPages } = useFieldArrayValues("slides");
  const { watch }: DocumentFormReturn = useFormContext();
  const [error, setError] = React.useState<string | null>(null);
  const [isPrinting, setIsPrinting] = React.useState(false);
  const componentRef = React.useRef(null);
  const currentSize = SIZES[watch("config.theme.size") || "SQUARE"];

  const reactToPrintContent = React.useCallback(() => {
    const current = componentRef.current;

    if (current && typeof current === "object" && 'cloneNode' in current) {
      const clone = current.cloneNode(true) as HTMLElement;
      
      // Remove interactive elements and styling for print
      removeSelectionStyleById(clone, "page-base-");
      removeSelectionStyleById(clone, "content-image-");
      removePaddingStyleById(clone, "carousel-item-");
      removeStyleById(clone, "slide-wrapper-", "px-2");
      removeAllById(clone, "add-slide-");
      removeAllById(clone, "add-element-");
      removeAllById(clone, "element-menubar-");
      removeAllById(clone, "slide-menubar-");
      insertFonts(clone);
      
      // Reset container styles
      clone.className = "";
      clone.style.cssText = `
        width: ${currentSize.width}px;
        height: ${currentSize.height * numPages}px;
        margin: 0;
        padding: 0;
        overflow: hidden;
        position: relative;
      `;

      // Process each slide
      const slides = clone.querySelectorAll('[id^="slide-wrapper-"]');
      slides.forEach((slide: Element, index: number) => {
        if (slide instanceof HTMLElement) {
          // Reset slide styles completely
          slide.style.cssText = `
            width: ${currentSize.width}px;
            height: ${currentSize.height}px;
            margin: 0;
            padding: 0;
            overflow: hidden;
            position: absolute;
            top: ${index * currentSize.height}px;
            left: 0;
            display: block;
          `;
          
          // Ensure slide content is properly contained
          const content = slide.querySelector('[id^="carousel-item-"]');
          if (content instanceof HTMLElement) {
            content.style.cssText = `
              width: 100%;
              height: 100%;
              margin: 0;
              padding: 0;
              overflow: hidden;
              display: block;
            `;
          }
        }
      });

      return clone;
    }

    return componentRef.current;
  }, [currentSize.width, currentSize.height, numPages]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    removeAfterPrint: true,
    onBeforePrint: () => {
      setError(null);
      setIsPrinting(true);
    },
    onAfterPrint: () => setIsPrinting(false),
    pageStyle: `
      @page { 
        size: ${currentSize.width}px ${currentSize.height}px; 
        margin: 0; 
        padding: 0; 
      } 
      @media print { 
        body, html { 
          margin: 0; 
          padding: 0; 
          width: ${currentSize.width}px;
          height: ${currentSize.height}px;
          overflow: hidden;
        }
        * {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
      }
    `,
    print: async (printIframe) => {
      try {
        const contentDocument = printIframe.contentDocument;
        if (!contentDocument) {
          throw new Error("iFrame does not have a document content");
        }

        const html = contentDocument.getElementById("element-to-download-as-pdf");
        if (!html) {
          throw new Error("Couldn't find element to convert to PDF");
        }

        const config = watch('config');
        const backgroundColor = config?.theme?.background || '#ffffff';
        const SCALE_FACTOR = 2;

        const options: HtmlToPdfOptions = {
          margin: [0, 0, 0, 0],
          filename: watch("filename") || "carousel.pdf",
          image: { type: "png", quality: 1.0 },
          htmlToImage: {
            height: currentSize.height * numPages,
            width: currentSize.width,
            canvasHeight: currentSize.height * numPages * SCALE_FACTOR,
            canvasWidth: currentSize.width * SCALE_FACTOR,
            backgroundColor,
            style: {
              margin: '0',
              padding: '0',
              transform: 'none'
            }
          },
          jsPDF: { 
            unit: 'px',
            format: [currentSize.width, currentSize.height],
            orientation: currentSize.width >= currentSize.height ? 'landscape' : 'portrait',
            hotfixes: ['px_scaling'],
          },
          size: currentSize,
        };

        const canvas = await handleCanvasConversion(html, options.htmlToImage);
        
        // Initialize PDF with exact dimensions
        const pdf = new jsPDF({
          unit: 'px',
          format: [currentSize.width, currentSize.height],
          orientation: currentSize.width >= currentSize.height ? 'landscape' : 'portrait',
          compress: true,
          hotfixes: ['px_scaling'],
        });

        pdf.setProperties({
          title: options.filename,
          creator: 'MedicalPost Carousel Studio',
          author: '',
        });

        // Calculate exact dimensions
        const pageHeight = currentSize.height;
        const scale = 1;

        // Process each page with exact positioning
        for (let page = 0; page < numPages; page++) {
          if (page > 0) {
            pdf.addPage([currentSize.width, currentSize.height], currentSize.width >= currentSize.height ? 'landscape' : 'portrait');
          }

          // Create temporary canvas for this page
          const pageCanvas = document.createElement('canvas');
          pageCanvas.width = canvas.width;
          pageCanvas.height = canvas.width * (currentSize.height / currentSize.width);
          const pageCtx = pageCanvas.getContext('2d');
          if (!pageCtx) throw new Error('Failed to get canvas context');

          // Fill background
          pageCtx.fillStyle = backgroundColor;
          pageCtx.fillRect(0, 0, pageCanvas.width, pageCanvas.height);

          // Draw exact page portion
          pageCtx.drawImage(
            canvas,
            0,
            page * (canvas.height / numPages),
            canvas.width,
            canvas.height / numPages,
            0,
            0,
            pageCanvas.width,
            pageCanvas.height
          );

          // Add to PDF with exact dimensions
          const imgData = pageCanvas.toDataURL('image/png', 1.0);
          pdf.addImage(
            imgData,
            'PNG',
            0,
            0,
            currentSize.width,
            currentSize.height,
            undefined,
            'FAST'
          );
        }

        pdf.save(options.filename);
        setError(null);
      } catch (err) {
        console.error(err);
        setError(err instanceof Error ? err.message : "Failed to generate PDF");
        setIsPrinting(false);
      }
    },
  });

  return {
    componentRef,
    handlePrint,
    isPrinting,
    error
  };
}

function removeAllById(html: HTMLElement, id: string) {
  const elements = Array.from(
    html.querySelectorAll(`[id^=${id}]`)
  ) as HTMLDivElement[];

  elements.forEach((element) => {
    element.remove();
  });
}

function removePaddingStyleById(html: HTMLElement, id: string) {
  const classNames = "pl-2 md:pl-4";
  removeStyleById(html, id, classNames);
}

function removeSelectionStyleById(html: HTMLElement, id: string) {
  const elements = Array.from(
    html.querySelectorAll(`[id^=${id}]`)
  ) as HTMLDivElement[];
  elements.forEach((element) => {
    element.className = removeClassnames(element, "ring-2 ring-primary");
  });
}

function removeStyleById(html: HTMLElement, id: string, classNames: string) {
  const elements = Array.from(
    html.querySelectorAll(`[id^=${id}]`)
  ) as HTMLDivElement[];
  elements.forEach((element) => {
    element.className = removeClassnames(element, classNames);
  });
}

function removeClassnames(element: HTMLDivElement, classNames: string): string {
  return element.className
    .split(" ")
    .filter((el) => !classNames.split(" ").includes(el))
    .join(" ");
}

function insertFonts(element: HTMLElement) {
  // Get all text elements that might have font classes
  const textElements = Array.from(
    element.querySelectorAll('p, h1, h2, h3, h4, h5, h6, span, div, textarea')
  ) as HTMLElement[];

  // Iterate through each element
  textElements.forEach(function (element) {
    const tailwindFonts = element.className
      .split(" ")
      .filter((cn) => cn.startsWith("font-"));

    // Get the computed style of the element
    tailwindFonts.forEach((font) => {
      const fontFaceValue = getComputedStyle(
        element.ownerDocument.body
      ).getPropertyValue("--" + font);
      if (fontFaceValue) {
        element.style.fontFamily = fontFaceValue;
      }
    });
  });
}

