import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircle, ArrowRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { supabase } from '@/integrations/supabase/client';

export default function SubscriptionSuccess() {
  const navigate = useNavigate();

  // Force a refresh of the auth state when this component mounts
  useEffect(() => {
    const refreshState = async () => {
      const hasRefreshed = sessionStorage.getItem('subscription_success_refreshed');
      
      if (!hasRefreshed) {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) {
          navigate('/');
          return;
        }

        // Set the flag before refreshing
        sessionStorage.setItem('subscription_success_refreshed', 'true');
        // Force refresh the page to update all components
        window.location.reload();
      }
    };

    refreshState();
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-sage-50 to-white pt-20">
      <div className="max-w-3xl mx-auto px-4 py-16 text-center">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
          className="mb-8"
        >
          <div className="inline-flex items-center justify-center w-20 h-20 rounded-full bg-primary/10">
            <CheckCircle className="w-10 h-10 text-primary" />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="space-y-6"
        >
          <h1 className="text-4xl font-bold">Welcome to MedicalPost Pro!</h1>
          
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Thank you for subscribing. Your account has been upgraded, and you now have access to all premium features.
          </p>

          <div className="grid gap-4 max-w-lg mx-auto">
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <h3 className="font-semibold mb-2">What's included in Pro:</h3>
              <ul className="text-left space-y-2">
                <li className="flex items-center text-gray-600">
                  <CheckCircle className="w-4 h-4 text-primary mr-2" />
                  Unlimited profile optimizations
                </li>
                <li className="flex items-center text-gray-600">
                  <CheckCircle className="w-4 h-4 text-primary mr-2" />
                  Unlimited content plans
                </li>
                <li className="flex items-center text-gray-600">
                  <CheckCircle className="w-4 h-4 text-primary mr-2" />
                  Unlimited posts and carousels
                </li>
                <li className="flex items-center text-gray-600">
                  <CheckCircle className="w-4 h-4 text-primary mr-2" />
                  Priority support
                </li>
              </ul>
            </div>
          </div>

          <div className="pt-8">
            <Button
              size="lg"
              onClick={() => navigate('/dashboard')}
              className="bg-primary hover:bg-primary/90"
            >
              Go to Dashboard
              <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </div>
        </motion.div>
      </div>
    </div>
  );
} 