import Editor from "./editor";
import { DocumentProvider } from "./lib/providers/document-provider";
import { PagerProvider } from "./lib/providers/pager-context";
import { SelectionProvider } from "./lib/providers/selection-context";
import { StatusProvider } from "./lib/providers/editor-status-context";
import { SizeProvider } from "./lib/providers/size-context";
import { Toaster } from "./ui/toaster";
import { useState } from "react";
import { SizeOption, DEFAULT_SIZE } from "./lib/page-size";

export function CarouselCreator() {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSize, setCurrentSize] = useState<SizeOption>(DEFAULT_SIZE);

  const pagerValue = {
    currentPage,
    setCurrentPage,
    onPreviousClick: () => setCurrentPage(Math.max(1, currentPage - 1)),
    onNextClick: () => setCurrentPage(currentPage + 1),
  };

  const selectionValue = {
    currentSelection: "",
    setCurrentSelection: () => {},
  };

  const sizeValue = {
    currentSize,
    setCurrentSize,
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-primary-50 to-white">
      <div className="h-full animate-fadeIn">
        <SizeProvider value={sizeValue}>
          <PagerProvider value={pagerValue}>
            <SelectionProvider value={selectionValue}>
              <StatusProvider>
                <DocumentProvider>
                  <Editor />
                </DocumentProvider>
              </StatusProvider>
            </SelectionProvider>
          </PagerProvider>
        </SizeProvider>
      </div>
      <Toaster />
    </div>
  );
} 