import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useToast } from '@/components/ui/use-toast';
import { supabase } from '@/integrations/supabase/client';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { UserProfileDialog } from '../UserProfileDialog';
import { IdealClientProfileDialog } from '../IdealClientProfileDialog';
import { setAIModel, getCurrentModel, type AIModel } from '@/services/deepseek';
import type { UserProfile, IdealClientProfile } from '@/types/profile';
import type { PostContent, ContentPlan } from '@/types/post';
import { generatePostContent } from '@/services/postGeneration';
import { LinkedInPreviewDialog } from './LinkedInPreviewDialog';
import { PostStyleSelector } from './PostStyleSelector';
import { ImageSearch } from './ImageSearch';
import { HookGenerator } from './HookGenerator';
import { ContentEditor } from './ContentEditor';
import { PostScheduler } from './PostScheduler';
import { ModelSelector } from '@/components/ui/model-selector';

const initialPost: PostContent = {
  type: 'text',
  title: '',
  content: '',
  callToAction: '',
  tones: ['professional'],
  pollOptions: [],
  selectedImages: [],
  isEditing: false,
  postLength: 'medium',
  targetAudience: 'peers',
  keyPoints: [],
  hashtags: []
};

export function PostCreator() {
  const [showProfileSetup, setShowProfileSetup] = useState(false);
  const [showUserProfileSetup, setShowUserProfileSetup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [idealClientProfile, setIdealClientProfile] = useState<IdealClientProfile | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [usageCount, setUsageCount] = useState<number>(0);
  const [currentAIModel, setCurrentAIModel] = useState<AIModel>(getCurrentModel());
  const [showPreview, setShowPreview] = useState(false);
  const [showAllPostTypes, setShowAllPostTypes] = useState(false);
  const [contentPlans, setContentPlans] = useState<ContentPlan[]>([]);
  const { toast } = useToast();
  const [isGenerating, setIsGenerating] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);

  const [searchParams] = useSearchParams();
  const dateFromUrl = searchParams.get('date');
  const titleFromUrl = searchParams.get('title');
  const typeFromUrl = searchParams.get('type') as PostContent['type'];
  const keyPointsFromUrl = searchParams.get('keyPoints')?.split('\n') || [];
  const hashtagsFromUrl = searchParams.get('hashtags')?.split('\n') || [];
  const planIdFromUrl = searchParams.get('planId');

  const [post, setPost] = useState<PostContent>({
    ...initialPost,
    type: typeFromUrl || initialPost.type,
    title: titleFromUrl || initialPost.title,
    keyPoints: keyPointsFromUrl,
    hashtags: hashtagsFromUrl,
    scheduledDate: dateFromUrl,
    scheduledTime: '09:00',
    planId: planIdFromUrl
  });

  useEffect(() => {
    let isMounted = true;

    const checkProfiles = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        
        if (!isMounted) return;
        if (!session?.user) {
          setIsLoading(false);
          return;
        }

        const { data: profile, error } = await supabase
          .from('profiles')
          .select('ideal_client_profile, user_profile, is_subscribed, usage_count')
          .eq('id', session.user.id)
          .single();

        if (!isMounted) return;

        if (error) {
          console.error('Error fetching profile:', error);
          setIsLoading(false);
          return;
        }

        if (!profile?.user_profile) {
          setShowUserProfileSetup(true);
        } else {
          setUserProfile(profile.user_profile as UserProfile);
        }

        if (!profile?.ideal_client_profile) {
          setShowProfileSetup(true);
        } else {
          setIdealClientProfile(profile.ideal_client_profile as IdealClientProfile);
        }

        setIsSubscribed(!!profile.is_subscribed);
        setUsageCount(profile.usage_count?.posts || 0);
      } catch (error) {
        console.error('Error checking profiles:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        checkProfiles();
      } else if (event === 'SIGNED_OUT') {
        setIsLoading(false);
        setUserProfile(null);
        setIdealClientProfile(null);
        setIsSubscribed(false);
        setUsageCount(0);
      }
    });

    checkProfiles();
    loadContentPlans();

    return () => {
      isMounted = false;
      subscription.unsubscribe();
    };
  }, []);

  const loadContentPlans = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) return;

      const { data, error } = await supabase
        .from('content_plans')
        .select('id, name, created_at')
        .eq('user_id', session.user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setContentPlans(data || []);
    } catch (error) {
      console.error('Error loading content plans:', error);
    }
  };

  const handleSaveUserProfile = async (profile: UserProfile) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        throw new Error('No authenticated user found');
      }

      const { error } = await supabase
        .from('profiles')
        .update({ user_profile: profile })
        .eq('id', session.user.id);

      if (error) throw error;

      setUserProfile(profile);
      setShowUserProfileSetup(false);
      toast({
        title: "Profile saved",
        description: "Your profile has been saved successfully.",
      });

      if (!idealClientProfile) {
        setShowProfileSetup(true);
      }
    } catch (error) {
      console.error('Error saving user profile:', error);
      toast({
        title: "Error saving profile",
        description: "Unable to save your profile. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleSaveProfile = async (profile: IdealClientProfile) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        throw new Error('No authenticated user found');
      }

      const { error } = await supabase
        .from('profiles')
        .update({ ideal_client_profile: profile })
        .eq('id', session.user.id);

      if (error) throw error;

      setIdealClientProfile(profile);
      setShowProfileSetup(false);
      toast({
        title: "Profile saved",
        description: "Your target audience profile has been saved successfully.",
      });
    } catch (error) {
      console.error('Error saving ideal client profile:', error);
      toast({
        title: "Error saving profile",
        description: "Unable to save your target audience profile. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleModelChange = (model: AIModel) => {
    setAIModel(model);
    setCurrentAIModel(model);
    toast({
      title: "Model Changed",
      description: `Switched to ${model}`,
    });
  };

  const handleCreatePost = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        toast({
          title: "Authentication Required",
          description: "Please sign in to save posts.",
          variant: "destructive",
        });
        return;
      }

      if (!post.title || !post.content) {
        toast({
          title: "Missing Content",
          description: "Please add a title and content to your post.",
          variant: "destructive",
        });
        return;
      }

      const postData = {
        user_id: session.user.id,
        type: post.type,
        title: post.title,
        content: post.content,
        tones: post.tones,
        call_to_action: post.callToAction || null,
        target_audience: post.targetAudience,
        post_length: post.postLength,
        metadata: {
          selected_images: post.selectedImages || [],
          content_plan: {
            plan_id: post.planId,
            scheduled_date: post.scheduledDate,
            scheduled_time: post.scheduledTime
          }
        },
        status: 'draft',
        scheduled_date: post.scheduledDate || null,
        scheduled_time: post.scheduledTime || null
      };

      const { error } = await supabase
        .from('posts')
        .insert(postData);

      if (error) throw error;

      toast({
        title: "Post Saved",
        description: "Your post has been saved successfully.",
      });

      setPost(initialPost);
      setShowPreview(false);
    } catch (error) {
      console.error('Error saving post:', error);
      toast({
        title: "Error",
        description: "Failed to save your post. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleGenerateContent = async () => {
    try {
      setIsGenerating(true);
      const generatedContent = await generatePostContent(post, currentAIModel);
      setPost(prev => ({ ...prev, content: generatedContent }));

      toast({
        title: "Content Generated",
        description: "Your post has been generated. Click Preview to see how it will look on LinkedIn.",
        className: "bg-white border",
      });
    } catch (error) {
      console.error('Error generating content:', error);
      toast({
        title: "Error",
        description: "Failed to generate content. Please try again.",
        variant: "destructive",
        className: "bg-white border",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-sage-50 to-white">
      <div className="max-w-4xl mx-auto px-4 py-12">
        {/* Usage Limit Alert for Free Users */}
        {!isSubscribed && (
          <div className="mb-8 bg-primary/5 rounded-lg p-6 flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
            <div>
              <h3 className="font-semibold mb-1">Free Plan Usage</h3>
              <p className="text-sm text-gray-600">
                You have used {usageCount} of 3 free posts
              </p>
            </div>
            <Button
              onClick={() => setShowSubscribeModal(true)}
              className="bg-primary hover:bg-primary/90 whitespace-nowrap"
            >
              Upgrade to Pro
            </Button>
          </div>
        )}

        <UserProfileDialog
          open={showUserProfileSetup}
          onOpenChange={setShowUserProfileSetup}
          onSave={handleSaveUserProfile}
          existingProfile={userProfile}
        />

        <IdealClientProfileDialog
          open={showProfileSetup}
          onOpenChange={setShowProfileSetup}
          onSave={handleSaveProfile}
          existingProfile={idealClientProfile}
        />

        {userProfile && idealClientProfile ? (
          <div className="space-y-8 pt-12 pb-12">
            <div className="flex flex-col md:flex-row justify-between items-center p-4">
  <h2 className="text-2xl font-bold mb-4 md:mb-0">Create a New Post</h2>
  
  {/* Model Selection */}
  <ModelSelector
    currentModel={currentAIModel}
    onModelChange={handleModelChange}
    className="mb-4 md:mb-0" // Add margin for mobile
  />

  <div className="flex flex-row md:flex-row gap-4">
    <Button
      variant="outline"
      onClick={() => setShowUserProfileSetup(true)}
      className="w-full md:w-auto" // Full width on mobile
    >
      Edit Your Profile
    </Button>
    <Button
      variant="outline"
      onClick={() => setShowProfileSetup(true)}
      className="w-full md:w-auto" // Full width on mobile
    >
      Edit Target Audience
    </Button>
  </div>
</div>
            
            <div className="grid grid-cols-1 md:grid-cols-[3fr_7fr] gap-4">
              {/* Left Column - Writing Style and Post Type */}
              <div className="space-y-4">
                <PostStyleSelector
                  selectedTones={post.tones}
                  onTonesChange={(tones) => setPost(prev => ({ ...prev, tones }))}
                  selectedType={post.type}
                  onTypeChange={(type) => setPost(prev => ({ ...prev, type }))}
                  targetAudience={post.targetAudience}
                  onTargetAudienceChange={(audience) => setPost(prev => ({ ...prev, targetAudience: audience }))}
                  showAllPostTypes={showAllPostTypes}
                  onShowAllPostTypesChange={setShowAllPostTypes}
                />

                <ImageSearch
                  selectedImages={post.selectedImages}
                  onImagesChange={(images) => setPost(prev => ({ ...prev, selectedImages: images }))}
                  imagePrompt={post.imagePrompt}
                  onImagePromptChange={(prompt) => setPost(prev => ({ ...prev, imagePrompt: prompt }))}
                />
              </div>

              {/* Right Column - Content Section */}
              <div className="p-4 bg-white rounded-lg border">
                <div className="space-y-4">
                  <HookGenerator
                    userProfile={userProfile}
                    idealClientProfile={idealClientProfile}
                    postType={post.type}
                    currentAIModel={currentAIModel}
                    onHookSelect={(hook) => setPost(prev => ({ ...prev, title: hook }))}
                  />

                  <div>
                    <Label className="text-sm font-medium">Title</Label>
                    <Input
                      value={post.title}
                      onChange={(e) => setPost(prev => ({ ...prev, title: e.target.value }))}
                      placeholder="Enter or select a title for your post"
                      className="mt-2"
                      disabled={isGenerating}
                    />
                  </div>

                  <ContentEditor
                    content={post.content}
                    onContentChange={(content) => setPost(prev => ({ ...prev, content }))}
                    postLength={post.postLength}
                    onPostLengthChange={(length) => setPost(prev => ({ ...prev, postLength: length }))}
                    postType={post.type}
                    isLoading={isGenerating}
                  />
                </div>

                {/* Action Buttons */}
                <div className="flex flex-col gap-4 mt-6 pt-4 border-t">
                  <div className="flex justify-end gap-4">
                    <Button 
                      variant="outline" 
                      onClick={handleGenerateContent}
                      disabled={isGenerating}
                    >
                      {isGenerating ? 'Generating...' : 'Generate with AI'}
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => setShowPreview(true)}
                      disabled={!post.content}
                    >
                      Preview Post
                    </Button>
                  </div>

                  <PostScheduler
                    scheduledDate={post.scheduledDate}
                    onScheduledDateChange={(date) => setPost(prev => ({ ...prev, scheduledDate: date }))}
                    scheduledTime={post.scheduledTime}
                    onScheduledTimeChange={(time) => setPost(prev => ({ ...prev, scheduledTime: time }))}
                    selectedPlanId={post.planId}
                    onPlanChange={(planId) => setPost(prev => ({ ...prev, planId }))}
                    contentPlans={contentPlans}
                  />

                  <div className="flex justify-end mt-4">
                    <Button
                      onClick={handleCreatePost}
                      className="bg-primary text-white hover:bg-primary/90 px-8"
                    >
                      Save Post
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center py-12">
            <h2 className="text-xl font-semibold mb-4">Complete Your Profile Setup</h2>
            <p className="text-gray-600 mb-6">
              Before creating targeted posts, we need to understand both your profile and your target audience
              to help generate more relevant content.
            </p>
            {!userProfile && (
              <Button 
                onClick={() => setShowUserProfileSetup(true)}
                className="mr-4"
              >
                Set Up Your Profile
              </Button>
            )}
            {userProfile && !idealClientProfile && (
              <Button onClick={() => setShowProfileSetup(true)}>
                Set Up Target Audience
              </Button>
            )}
          </div>
        )}

        <LinkedInPreviewDialog
          open={showPreview}
          onOpenChange={setShowPreview}
          post={post}
          userProfile={userProfile!}
        />
      </div>
    </div>
  );
} 