import React from 'react';
import { useFormContext, useFieldArray } from "react-hook-form";
import { 
  ArrowUp, 
  ArrowDown, 
  Copy, 
  Trash, 
  Move 
} from "lucide-react";
import { cn } from "@/lib/utils";
import { 
  ElementFieldPath, 
  DocumentFormReturn 
} from "./lib/document-form-types";
import { useSelectionContext } from "./lib/providers/selection-context";
import { getParent, getElementNumber } from "./lib/field-path";
import { useFieldArrayValues } from "./lib/hooks/use-field-array-values";
import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface ElementMenubarProps {
  fieldName: ElementFieldPath;
  className?: string;
}

const ElementMenubar: React.FC<ElementMenubarProps> = ({ 
  fieldName, 
  className = "" 
}) => {
  const { control, watch } = useFormContext<DocumentFormReturn>();
  const { numPages: numElements } = useFieldArrayValues(getParent(fieldName));
  const { swap, remove, insert } = useFieldArray({
    control,
    name: getParent(fieldName),
  });

  const currentElementNumber = getElementNumber(fieldName);
  const currentElementValue = watch(fieldName);

  const handleMoveUp = () => {
    if (currentElementNumber > 0) {
      swap(currentElementNumber, currentElementNumber - 1);
    }
  };

  const handleMoveDown = () => {
    if (currentElementNumber < numElements - 1) {
      swap(currentElementNumber, currentElementNumber + 1);
    }
  };

  const handleDuplicate = () => {
    const values = JSON.parse(JSON.stringify(currentElementValue));
    insert(currentElementNumber + 1, values);
  };

  const handleDelete = () => {
    remove(currentElementNumber);
  };

  return (
    <TooltipProvider delayDuration={100}>
      <div 
        className={cn(
          "group flex items-center",
          "bg-white dark:bg-gray-800", // Adaptive background
          "border border-gray-200 dark:border-gray-700", // Adaptive border
          "rounded-full shadow-sm",
          "p-1 space-x-1", // Tighter spacing
          "z-50", // Ensure high z-index
          className
        )}
      >
        {/* Primary and Secondary Actions - Compact Layout */}
        <div className="flex items-center space-x-1">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={handleMoveUp}
                variant="ghost"
                size="icon"
                className="w-8 h-8 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                disabled={currentElementNumber <= 0}
              >
                <ArrowUp className="w-4 h-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent 
              side="bottom" 
              className="z-[100]" // Ensure tooltip is above other elements
            >
              Move Up
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={handleMoveDown}
                variant="ghost"
                size="icon"
                className="w-8 h-8 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                disabled={currentElementNumber >= numElements - 1}
              >
                <ArrowDown className="w-4 h-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent 
              side="bottom" 
              className="z-[100]" // Ensure tooltip is above other elements
            >
              Move Down
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={handleDuplicate}
                variant="ghost"
                size="icon"
                className="w-8 h-8 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                disabled={numElements === 0}
              >
                <Copy className="w-4 h-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent 
              side="bottom" 
              className="z-[100]" // Ensure tooltip is above other elements
            >
              Duplicate
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={handleDelete}
                variant="ghost"
                size="icon"
                className="w-8 h-8 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-red-500"
                disabled={numElements === 0}
              >
                <Trash className="w-4 h-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent 
              side="bottom" 
              className="z-[100]" // Ensure tooltip is above other elements
            >
              Delete
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
    </TooltipProvider>
  );
};

interface ElementMenubarWrapperProps {
  fieldName: ElementFieldPath;
  children: React.ReactNode;
  className?: string;
}

const ElementMenubarWrapper = React.forwardRef<HTMLDivElement, ElementMenubarWrapperProps>(
  ({ fieldName, children, className = "" }, ref) => {
    const { currentSelection } = useSelectionContext();

    return (
      <div className="relative w-full group" ref={ref}>
        <div
          id={`element-menubar-${fieldName}`}
          className={cn(
            "absolute -top-10 right-0 z-50", // Increased z-index
            "opacity-0 transition-all duration-200 ease-in-out",
            "group-hover:opacity-100 group-focus-within:opacity-100",
            currentSelection !== fieldName && "hidden",
            className
          )}
        >
          <ElementMenubar fieldName={fieldName} />
        </div>
        <div className="w-full">
          {children}
        </div>
      </div>
    );
  }
);

ElementMenubarWrapper.displayName = 'ElementMenubarWrapper';

export default ElementMenubarWrapper;