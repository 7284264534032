import { ElementType } from "./element-type";
import * as z from "zod";

export const FontSizeType = z.enum(["Small", "Medium", "Large"]);
export const TextALignType = z.enum(["Left", "Center", "Right"]);
export const MarginType = z.enum(["Small", "Medium", "Large"]);
export const ColorType = z.enum(["Primary", "Secondary", "Custom"]);
export const MarginSizeType = z.enum(["Small", "Medium", "Large"]);
export const FontType = z.enum(["Primary", "Secondary", "Custom"]);

export const TextStyleSchema = z.object({
  fontSize: FontSizeType.default(FontSizeType.enum.Medium),
  align: TextALignType.default(TextALignType.enum.Center),
  margin: MarginSizeType.default(MarginSizeType.enum.Medium),
  marginX: MarginSizeType.default(MarginSizeType.enum.Medium),
  color: ColorType.default(ColorType.enum.Primary),
  customColor: z.string().optional(),
  font: FontType.default(FontType.enum.Primary),
  width: MarginType.default("Large"),
});

export const UnstyledTextStyleSchema = z.object({
  fontSize: FontSizeType.default("Medium"),
  align: TextALignType.default("Left"),
});

export const UnstyledTitleSchema = z.object({
  type: z.literal(ElementType.enum.Title).default(ElementType.enum.Title),
  content: z.string().default(""),
  style: UnstyledTextStyleSchema.default({}),
});

export const UnstyledSubtitleSchema = z.object({
  type: z.literal(ElementType.enum.Subtitle).default(ElementType.enum.Subtitle),
  content: z.string().default(""),
  style: UnstyledTextStyleSchema.default({}),
});

export const UnstyledDescriptionSchema = z.object({
  type: z.literal(ElementType.enum.Description).default(ElementType.enum.Description),
  content: z.string().default(""),
  style: UnstyledTextStyleSchema.default({}),
});

export const TitleSchema = z.object({
  type: z.literal(ElementType.enum.Title).default(ElementType.enum.Title),
  content: z.string().default(""),
  style: TextStyleSchema.default({}),
});

export const SubtitleSchema = z.object({
  type: z.literal(ElementType.enum.Subtitle).default(ElementType.enum.Subtitle),
  content: z.string().default(""),
  style: TextStyleSchema.default({}),
});

export const DescriptionSchema = z.object({
  type: z.literal(ElementType.enum.Description).default(ElementType.enum.Description),
  content: z.string().default(""),
  style: TextStyleSchema.default({}),
});

export const DEFAULT_TITLE: z.infer<typeof TitleSchema> = TitleSchema.parse({
  content: "YOUR TITLE",
});

export const DEFAULT_SUBTITLE: z.infer<typeof SubtitleSchema> =
  SubtitleSchema.parse({
    content: "Your awesome subtitle",
  });

export const DEFAULT_DESCRIPTION: z.infer<typeof DescriptionSchema> =
  DescriptionSchema.parse({
    content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita, dolorum. awesome subtitle",
  });

export type TextStyle = z.infer<typeof TextStyleSchema>;
export type Title = z.infer<typeof TitleSchema>;
export type Subtitle = z.infer<typeof SubtitleSchema>;
export type Description = z.infer<typeof DescriptionSchema>;
