import React from "react";
import { cn } from "@/lib/utils";
import { usePagerContext } from "../lib/providers/pager-context";
import { getSlideNumber } from "../lib/field-path";
import { useSelection } from "../lib/hooks/use-selection";
import { useSelectionContext } from "../lib/providers/selection-context";
import { useFormContext } from "react-hook-form";
import { DocumentFormReturn } from "../lib/document-form-types";
import { BackgroundImage } from "../elements/background-image";
import { SlideFieldPath } from "../lib/document-form-types";
import { SIZES } from "../lib/page-size";

export function PageBase({
  children,
  className,
  fieldName,
  id,
}: {
  children: React.ReactNode;
  className?: string;
  fieldName: SlideFieldPath;
  id?: string;
}) {
  const { currentSelection } = useSelectionContext();
  const pageNumber = getSlideNumber(fieldName);
  const form: DocumentFormReturn = useFormContext();
  const { watch } = form;
  const slide = watch(fieldName);
  const config = watch("config");
  const size = SIZES[config?.theme?.size || "SQUARE"];

  const getBackgroundColor = () => {
    if (!slide.backgroundColor) {
      return config?.theme?.background;
    }
    
    if (slide.backgroundColor.color === "Custom") {
      return slide.backgroundColor.customColor || config?.theme?.background;
    }
    
    return config?.theme?.background;
  };

  return (
    <div
      id={id}
      className={cn(
        "relative w-full h-full overflow-hidden",
        currentSelection == fieldName &&
          "outline-input ring-2 ring-offset-2 ring-ring",
        className
      )}
      style={{
        backgroundColor: getBackgroundColor(),
        width: `${size.width}px`,
        height: `${size.height}px`,
        minWidth: `${size.width}px`,
        minHeight: `${size.height}px`,
      }}
    >
      {/* Background Color Layer */}
      <div className="absolute inset-0 w-full h-full" style={{ backgroundColor: getBackgroundColor(), zIndex: 0 }} />

      {/* Background Image */}
      {slide.backgroundImage && (
        <BackgroundImage
          fieldName={`${fieldName}.backgroundImage`}
          className="absolute inset-0 w-full h-full object-cover"
          style={{ zIndex: 1 }}
        />
      )}

      {/* Overlays */}
      {slide.overlays?.map((overlay, index) => (
        <div
          key={index}
          className={cn(
            "absolute w-full",
            {
              "top-0": overlay.style.position === "Top",
              "top-1/2 -translate-y-1/2": overlay.style.position === "Middle",
              "bottom-0": overlay.style.position === "Bottom",
            }
          )}
          style={{
            opacity: overlay.style.opacity / 100,
            zIndex: 2
          }}
        >
          <img
            src={overlay.source.src}
            alt={overlay.source.alt}
            className="w-full h-auto"
          />
        </div>
      ))}

      {/* Content */}
      <div className="relative w-full h-full" style={{ zIndex: 3 }}>
        {children}
      </div>
    </div>
  );
}
