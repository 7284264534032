import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Timer, Users, Star, CheckCircle2, Linkedin, Twitter, Facebook } from 'lucide-react'; // Removed ChevronRight as it wasn't used in the final timeline design
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import type { Session } from '@supabase/supabase-js';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { BetaSpots } from '@/components/BetaSpots';

// --- Animation Variants ---
const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.7, ease: "easeOut" }
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

// --- Footer Component ---
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <motion.footer
      className="bg-gray-100 border-t border-gray-200 py-8 mt-auto" // Ensure footer stays at bottom
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-6xl mx-auto px-4 text-center text-gray-500">
        <div className="flex justify-center space-x-6 mb-4">
          {/* Replace # with your actual links */}
          <a href="#" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition-colors" aria-label="LinkedIn">
            <Linkedin className="h-5 w-5" />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition-colors" aria-label="Twitter">
            <Twitter className="h-5 w-5" />
          </a>
          {/* Optional Facebook link
          <a href="#" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition-colors" aria-label="Facebook">
            <Facebook className="h-5 w-5" />
          </a> */}
        </div>
        <div className="text-sm mb-2 space-x-2"> {/* Added space-x for better spacing */}
          <a href="/privacy" className="hover:text-primary transition-colors">Privacy Policy</a>
          <span>|</span>
          <a href="/terms" className="hover:text-primary transition-colors">Terms of Service</a>
          <span>|</span>
          <a href="/contact" className="hover:text-primary transition-colors">Contact</a>
        </div>
        <p className="text-sm">© {currentYear} Medical Post. All rights reserved.</p>
      </div>
    </motion.footer>
  );
};


// --- Beta Application Form Interface ---
interface BetaApplicationForm {
  full_name: string;
  specialty: string;
  linkedin_url: string;
  linkedin_connections: number;
  why_join_beta: string;
  email: string;
}

// --- Main Beta Landing Page Component ---
const BetaLandingPage = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showApplicationModal, setShowApplicationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userSession, setUserSession] = useState<Session | null>(null);
  const [formData, setFormData] = useState<BetaApplicationForm>({
    full_name: '',
    specialty: '',
    linkedin_url: '',
    linkedin_connections: 0,
    why_join_beta: '',
    email: ''
  });

  // Add state for parallax scroll effect
  const [scrollY, setScrollY] = useState(0);

  // Effect for parallax scroll tracking
  useEffect(() => {
    const handleScroll = () => setScrollY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    // Cleanup function to remove listener when component unmounts
    return () => window.removeEventListener('scroll', handleScroll);
  }, []); // Empty dependency array means this effect runs once on mount

  // Effect for checking user session and pre-filling form data
  useEffect(() => {
    const checkSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        setUserSession(session);
        // Use functional updates for setting state based on previous state
        if (session?.user?.email) {
          setFormData(prev => ({ ...prev, email: session.user.email || '' }));
        }
        if (session?.user?.user_metadata?.full_name) {
           setFormData(prev => ({ ...prev, full_name: session.user.user_metadata.full_name || '' }));
        }
      } catch (error) {
          console.error("Error checking session:", error);
      }
    };
    checkSession();
  }, []); // Empty dependency array means this effect runs once on mount

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
     const { id, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [id]: id === 'linkedin_connections' ? parseInt(value) || 0 : value
    }));
  };

  const validateForm = (): string | null => { // Added return type annotation
     if (!formData.full_name.trim()) return 'Name is required'; // Added trim()
    if (!formData.specialty.trim()) return 'Specialty is required'; // Added trim()
    if (!formData.linkedin_url.trim()) return 'LinkedIn URL is required'; // Added trim()
    if (!formData.linkedin_connections || formData.linkedin_connections < 1) return 'Please enter your approximate LinkedIn connections count (must be > 0)';
    if (!formData.why_join_beta.trim()) return 'Please tell us why you want to join the beta'; // Added trim()
    if (!formData.email.trim()) return 'Email is required'; // Added trim()
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) return 'Invalid email address';

    const linkedinUrl = formData.linkedin_url.toLowerCase();
    // Simple check - ensure it starts with http and includes linkedin.com/in/
    if (!linkedinUrl.startsWith('http') || !linkedinUrl.includes('linkedin.com/in/')) {
        return 'Please enter a valid LinkedIn profile URL (e.g., https://linkedin.com/in/yourname)';
    }
    return null;
  };

  const handleSubmit = async () => {
     const validationError = validateForm();
    if (validationError) {
      toast({ title: "Validation Error", description: validationError, variant: "destructive" });
      return;
    }
    setIsSubmitting(true);
    try {
      // Fetch session again *just before* submitting to ensure it's fresh
      const { data: { session } } = await supabase.auth.getSession();
      const applicationData = {
        ...formData,
        user_id: session?.user?.id || null, // Get potentially updated user ID
        browser_info: navigator.userAgent,
        applied_at: new Date().toISOString()
      };
      const { error } = await supabase.from('beta_applications').insert([applicationData]);
      if (error) {
          console.error('Supabase insert error:', error);
          throw error; // Re-throw to be caught by the catch block
      }
      toast({
        title: "Application Submitted!",
        description: "Thank you for applying. We'll review your application and be in touch soon!",
        className: "bg-green-100 border-green-300 text-green-800", // Success styling
      });
      setShowApplicationModal(false);
      // Reset form fields, but keep email if user was logged in initially
      setFormData({
        full_name: '',
        specialty: '',
        linkedin_url: '',
        linkedin_connections: 0,
        why_join_beta: '',
        email: userSession?.user?.email || '' // Use the session state captured on load
      });
    } catch (error) {
      // Log the specific error if possible
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred.";
      console.error('Error submitting application:', errorMessage, error);
      toast({
        title: "Submission Error",
        description: "Something went wrong submitting your application. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  }; // End of handleSubmit

  // Ensure return statement is immediately after function logic
  return (
    <div className="min-h-screen bg-gradient-to-b from-sage-50/20 to-white relative" role="main">
       {/* Background elements with parallax */}
       <div
        className="absolute inset-0 pointer-events-none z-0"
        style={{ transform: `translateY(${scrollY * 0.1}px)` }} // Apply parallax style
      >
        <div className="absolute inset-0 bg-[radial-gradient(theme(colors.gray.300)_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)] opacity-30"></div>
        <div className="absolute -top-40 -left-80 w-[700px] h-[700px] bg-gradient-to-r from-primary/5 to-primary/10 rounded-full blur-3xl opacity-60" />
        <div className="absolute -top-10 -right-80 w-[700px] h-[700px] bg-gradient-to-r from-sage-200/30 to-sage-100/20 rounded-full blur-3xl opacity-60" />
      </div>

      {/* Content Wrapper */}
      <div className="relative z-10 flex flex-col min-h-screen">
        <Helmet>
          <title>Medical Post Beta Program | Exclusive Access for Healthcare Professionals</title>
          <meta name="description" content="Join our exclusive beta program limited to 25 healthcare professionals. Get early access, lifetime discounts, and shape our AI-powered LinkedIn platform." />
           {/* Add other meta tags if needed (keywords, OG tags etc) */}
           <meta name="keywords" content="medical linkedin beta, healthcare social media beta, doctor linkedin tool, medical professional software, beta program healthcare" />
           <meta property="og:title" content="Medical Post Beta Program" />
           <meta property="og:description" content="Apply for exclusive access to Medical Post's AI LinkedIn tools for healthcare professionals. Limited spots available." />
           {/* <meta property="og:image" content="/images/beta-og-image.png" /> */}
           <link rel="canonical" href="https://yourdomain.com/beta" /> {/* UPDATE DOMAIN */}
        </Helmet>

        <main className="flex-grow"> {/* Main content area */}
          <div className="max-w-5xl mx-auto px-4 py-16 md:py-24"> {/* Content container */}

              {/* Hero Section */}
              <motion.section
                className="text-center mb-20 md:mb-24 relative group"
                initial="initial" // Use variant names
                animate="animate" // Use variant names
                variants={fadeInUp} // Apply animation variant
                aria-labelledby="beta-hero-heading"
              >
                {/* Background Glow Effect */}
                <div className="absolute -inset-4 bg-gradient-to-r from-primary to-blue-500 rounded-lg blur-xl opacity-20 group-hover:opacity-30 transition duration-1000 z-0"></div>

                {/* Content Box */}
                <div className="relative bg-white/80 backdrop-blur-sm rounded-lg p-8 md:p-12 z-10 border border-gray-200/50 shadow-sm">
                  <div className="inline-block px-4 py-1.5 bg-primary/10 text-primary rounded-full text-sm font-medium mb-6">
                    Limited Beta Program: <BetaSpots totalSpots={25} /> Spots Remaining!
                  </div>
                  <h1 id="beta-hero-heading" className="text-4xl md:text-5xl font-bold mb-5 text-gray-900 leading-tight">
                    Shape the Future of <span className="text-primary">Medical LinkedIn Content</span>
                  </h1>
                  <p className="text-lg text-gray-600 mb-8 max-w-3xl mx-auto">
                    Join an exclusive group of healthcare professionals testing our AI platform. Get 3 weeks free access and lock in a 40% lifetime discount.
                  </p>
                  {/* Benefits Row */}
                  <div className="flex flex-wrap justify-center items-center gap-x-8 gap-y-4 mb-10 text-gray-700">
                    <div className="flex items-center gap-2">
                      <Users className="h-5 w-5 text-primary" />
                      <span>Exclusive: <BetaSpots totalSpots={25} /> Participants</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Timer className="h-5 w-5 text-primary" />
                      <span><span className="font-semibold">3 Weeks Free</span> Pro Access</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-primary" />
                      <span><span className="font-semibold">40% Lifetime Discount</span></span>
                    </div>
                  </div>
                  <Button
                    size="lg"
                    onClick={() => setShowApplicationModal(true)}
                    className="bg-primary hover:bg-primary/90 transform hover:scale-105 transition-transform"
                    aria-label="Apply for Beta Access"
                  >
                    Apply for Beta Access
                  </Button>
                </div>
              </motion.section>

              {/* Beta Benefits */}
              <motion.section
                className="mb-20 md:mb-24"
                variants={staggerChildren} // Apply stagger container variant
                initial="initial"
                whileInView="animate" // Animate when in view
                viewport={{ once: true, amount: 0.2 }}
                aria-labelledby="benefits-heading"
              >
                 <h2 id="benefits-heading" className="text-3xl font-bold text-center mb-12 text-gray-900">What You'll Gain as a Beta Tester</h2>
                <div className="grid md:grid-cols-3 gap-6 md:gap-8">
                  {/* Card 1 */}
                  <motion.div
                    className="bg-white p-6 rounded-lg shadow-md border border-gray-100 hover:shadow-lg transition-shadow duration-300 hover:-translate-y-1"
                    variants={fadeInUp} // Apply item animation variant
                  >
                    <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center mb-4">
                        <Timer className="h-6 w-6 text-primary" />
                    </div>
                    <h3 className="text-xl font-semibold mb-3 text-gray-800">Early & Free Access</h3>
                    <p className="text-gray-600">Be the first to leverage our AI tools for 3 full weeks at no cost.</p>
                  </motion.div>
                   {/* Card 2 */}
                  <motion.div
                    className="bg-white p-6 rounded-lg shadow-md border border-gray-100 hover:shadow-lg transition-shadow duration-300 hover:-translate-y-1"
                    variants={fadeInUp}
                  >
                     <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center mb-4">
                        <Star className="h-6 w-6 text-primary" />
                    </div>
                    <h3 className="text-xl font-semibold mb-3 text-gray-800">Exclusive Discount</h3>
                    <p className="text-gray-600">Secure a 40% lifetime discount on the Pro plan after the beta concludes.</p>
                  </motion.div>
                   {/* Card 3 */}
                  <motion.div
                    className="bg-white p-6 rounded-lg shadow-md border border-gray-100 hover:shadow-lg transition-shadow duration-300 hover:-translate-y-1"
                    variants={fadeInUp}
                  >
                    <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center mb-4">
                        <Users className="h-6 w-6 text-primary" />
                    </div>
                    <h3 className="text-xl font-semibold mb-3 text-gray-800">Shape the Product</h3>
                    <p className="text-gray-600">Your feedback directly influences features and future development priorities.</p>
                  </motion.div>
                </div>
              </motion.section>

              {/* Beta Timeline */}
              <motion.section
                className="mb-20 md:mb-24"
                initial={{ opacity: 0 }} // Use simple fade-in for this section
                whileInView={{ opacity: 1 }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{ duration: 0.6 }}
                aria-labelledby="timeline-heading"
              >
                <h2 id="timeline-heading" className="text-3xl font-bold mb-12 text-center text-gray-900">Beta Program Roadmap (3 Weeks)</h2>
                <div className="relative max-w-3xl mx-auto">
                  {/* Connecting line visuals */}
                  <div className="absolute left-1/2 top-3 bottom-3 w-0.5 bg-primary/30 hidden md:block transform -translate-x-1/2" aria-hidden="true"></div>
                  <div className="absolute left-3 top-1/2 right-3 h-0.5 bg-primary/30 block md:hidden transform -translate-y-1/2" aria-hidden="true"></div>

                  <div className="grid md:grid-cols-3 gap-8 relative">
                      {/* Step 1 */}
                      <div className="flex md:flex-col items-center gap-4 text-center relative z-10">
                          <div className="w-10 h-10 rounded-full bg-primary text-white flex items-center justify-center font-semibold flex-shrink-0">1</div>
                          <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm flex-grow w-full">
                              <h4 className="font-semibold text-gray-800">Week 1: Onboarding</h4>
                              <p className="text-sm text-gray-600 mt-1">Profile setup, initial feature exploration, first feedback round.</p>
                          </div>
                      </div>
                      {/* Step 2 */}
                      <div className="flex md:flex-col items-center gap-4 text-center relative z-10">
                          <div className="w-10 h-10 rounded-full bg-primary text-white flex items-center justify-center font-semibold flex-shrink-0">2</div>
                          <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm flex-grow w-full">
                              <h4 className="font-semibold text-gray-800">Week 2: Deep Dive</h4>
                              <p className="text-sm text-gray-600 mt-1">Content generation, carousel studio use, profile optimisation testing.</p>
                          </div>
                      </div>
                     {/* Step 3 */}
                     <div className="flex md:flex-col items-center gap-4 text-center relative z-10">
                          <div className="w-10 h-10 rounded-full bg-primary text-white flex items-center justify-center font-semibold flex-shrink-0">3</div>
                          <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm flex-grow w-full">
                              <h4 className="font-semibold text-gray-800">Week 3: Feedback & Future</h4>
                              <p className="text-sm text-gray-600 mt-1">Advanced features, final feedback session, post-beta discussion.</p>
                          </div>
                      </div>
                  </div>
                </div>
              </motion.section>

              {/* Beta Requirements */}
              <motion.section
                className="mb-20 md:mb-24 bg-white p-8 md:p-10 rounded-lg shadow-sm border border-gray-100"
                initial={{ opacity: 0 }} // Simple fade-in
                whileInView={{ opacity: 1 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.6 }}
                aria-labelledby="requirements-heading"
              >
                <h2 id="requirements-heading" className="text-3xl font-bold mb-10 text-center text-gray-900">Is the Beta Program Right for You?</h2>
                <div className="grid md:grid-cols-2 gap-x-10 gap-y-8 max-w-4xl mx-auto">
                  <div>
                    <h3 className="text-xl font-semibold mb-4 text-gray-800">Ideal Candidate Profile</h3>
                    <ul className="space-y-3 text-gray-700">
                      <li className="flex items-start gap-3">
                        <CheckCircle2 className="h-5 w-5 text-primary flex-shrink-0 mt-0.5" />
                        <span>Active Healthcare Professional (Doctor, Nurse, Allied Health, Researcher, etc.)</span>
                      </li>
                       <li className="flex items-start gap-3">
                        <CheckCircle2 className="h-5 w-5 text-primary flex-shrink-0 mt-0.5" />
                        <span>Established LinkedIn presence (ideally 500+ connections)</span>
                      </li>
                      <li className="flex items-start gap-3">
                        <CheckCircle2 className="h-5 w-5 text-primary flex-shrink-0 mt-0.5" />
                        <span>Interested in regularly sharing expertise or insights on LinkedIn</span>
                      </li>
                       <li className="flex items-start gap-3">
                        <CheckCircle2 className="h-5 w-5 text-primary flex-shrink-0 mt-0.5" />
                        <span>Willing to actively use the platform during the beta</span>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-4 text-gray-800">Beta Tester Commitments</h3>
                    <ul className="space-y-3 text-gray-700">
                      <li className="flex items-start gap-3">
                        <CheckCircle2 className="h-5 w-5 text-primary flex-shrink-0 mt-0.5" />
                        <span>Provide honest feedback via short surveys or forms (weekly)</span>
                      </li>
                      <li className="flex items-start gap-3">
                        <CheckCircle2 className="h-5 w-5 text-primary flex-shrink-0 mt-0.5" />
                        <span>Optionally participate in 1-2 short feedback calls (30 mins)</span>
                      </li>
                      <li className="flex items-start gap-3">
                        <CheckCircle2 className="h-5 w-5 text-primary flex-shrink-0 mt-0.5" />
                        <span>Report any bugs or usability issues encountered</span>
                      </li>
                       <li className="flex items-start gap-3">
                        <CheckCircle2 className="h-5 w-5 text-primary flex-shrink-0 mt-0.5" />
                        <span>Maintain confidentiality about beta features until public launch</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </motion.section>

              {/* Final Application CTA */}
              <motion.section
                className="text-center relative group my-16 py-16 px-6" // Consistent padding/margin
                initial={{ opacity: 0 }} // Simple fade-in
                whileInView={{ opacity: 1 }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{ duration: 0.6 }}
                aria-labelledby="final-cta-heading"
              >
                {/* Background effect */}
                <div className="absolute -inset-2 bg-gradient-to-r from-primary/80 to-blue-500/80 rounded-xl blur-lg opacity-20 group-hover:opacity-30 transition duration-1000 z-0" aria-hidden="true"></div>
                {/* Content Box */}
                <div className="relative bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg p-10 md:p-16 shadow-xl z-10">
                  <h2 id="final-cta-heading" className="text-3xl md:text-4xl font-bold mb-4 text-white">Don't Miss Out on This Opportunity</h2>
                  <p className="text-lg text-gray-300 mb-8 max-w-2xl mx-auto">
                    Applications close soon! Secure your spot among the first <BetaSpots totalSpots={25} /> healthcare professionals to transform their LinkedIn strategy.
                  </p>
                  <Button
                    size="lg"
                    onClick={() => setShowApplicationModal(true)}
                    className="bg-primary hover:bg-primary/90 text-white transform hover:scale-105 transition-transform duration-200 shadow-lg hover:shadow-primary/40"
                    aria-label="Apply for the beta program now"
                  >
                    Apply Now - Limited Spots!
                  </Button>
                </div>
              </motion.section>

          </div> {/* End max-w-5xl container */}
        </main> {/* End Main Content Area */}

        {/* --- Application Modal --- */}
        <Dialog open={showApplicationModal} onOpenChange={setShowApplicationModal}>
           <DialogContent className="max-w-[90%] sm:max-w-lg max-h-[90vh] overflow-y-auto p-6">
            <DialogHeader className="mb-4 text-left"> {/* Ensure header text is aligned left */}
              <DialogTitle className="text-2xl font-semibold">Beta Program Application</DialogTitle>
              <DialogDescription className="text-gray-600">
                Complete this short form to apply. We're excited to potentially work with you!
              </DialogDescription>
            </DialogHeader>
            {/* Form Fields */}
            <div className="space-y-4">
              <div>
                <Label htmlFor="full_name" className="text-sm font-medium">Full Name</Label>
                <Input id="full_name" value={formData.full_name} onChange={handleInputChange} placeholder="e.g., Dr. Jane Smith" className="mt-1" required aria-required="true" />
              </div>
              {!userSession?.user?.email && ( // Conditionally render email input
                 <div>
                    <Label htmlFor="email" className="text-sm font-medium">Email Address</Label>
                    <Input id="email" type="email" value={formData.email} onChange={handleInputChange} placeholder="your.email@example.com" className="mt-1" required aria-required="true" />
                 </div>
              )}
              <div>
                <Label htmlFor="specialty" className="text-sm font-medium">Primary Specialty / Role</Label>
                <Input id="specialty" value={formData.specialty} onChange={handleInputChange} placeholder="e.g., Cardiology, General Practice, Nursing Lead" className="mt-1" required aria-required="true" />
              </div>
              <div>
                <Label htmlFor="linkedin_url" className="text-sm font-medium">LinkedIn Profile URL</Label>
                <Input id="linkedin_url" type="url" value={formData.linkedin_url} onChange={handleInputChange} placeholder="https://linkedin.com/in/yourprofile" className="mt-1" required aria-required="true" />
              </div>
              <div>
                <Label htmlFor="linkedin_connections" className="text-sm font-medium">Approx. LinkedIn Connections</Label>
                <Input id="linkedin_connections" type="number" min="1" value={formData.linkedin_connections || ''} onChange={handleInputChange} placeholder="e.g., 1500" className="mt-1" required aria-required="true" />
              </div>
              <div>
                <Label htmlFor="why_join_beta" className="text-sm font-medium">Why are you interested in joining the beta?</Label>
                <Textarea id="why_join_beta" value={formData.why_join_beta} onChange={handleInputChange} rows={4} placeholder="Briefly tell us what you hope to achieve or learn..." className="mt-1" required aria-required="true" />
              </div>
            </div>
            {/* Modal Footer */}
            <DialogFooter className="mt-6 sm:justify-end"> {/* Adjusted footer alignment */}
              <Button variant="outline" onClick={() => setShowApplicationModal(false)} disabled={isSubmitting}>Cancel</Button>
              <Button onClick={handleSubmit} disabled={isSubmitting} className="min-w-[130px]">
                {isSubmitting ? (
                  // Simple loading indicator
                  <motion.span
                    animate={{ rotate: 360 }}
                    transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
                    style={{ display: 'inline-block' }} className="mr-2"
                  >
                    {/* You can replace this SVG with a Lucide icon like Loader2 if preferred */}
                    <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" > <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" /> </svg>
                  </motion.span>
                ) : null} {/* Conditionally render spinner */}
                {isSubmitting ? 'Submitting...' : 'Submit Application'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        {/* Add Footer */}
       

      </div> {/* End Relative z-10 wrapper */}
    </div> // End Root Div
  );
}; // End of BetaLandingPage component

// Default export should be the last thing
export default BetaLandingPage;