import { SlideType } from "@/lib/validation/slide-schema";
import { Button } from "../../components/ui/button";
import { Plus } from "lucide-react";
import { cn } from "@/lib/utils";

export function NewPage({
  size,
  className = "",
  handleAddPage,
  isSideButton,
}: {
  size: { width: number; height: number };
  className?: string;
  handleAddPage: (pageType: typeof SlideType.enum) => void;
  isSideButton: boolean;
}) {
  const buttonWidth = isSideButton ? size.width / 4 : size.width;
  const buttonStyle = {
    width: `${buttonWidth}px`,
    height: `${size.height / 3}px`,
    minWidth: `${buttonWidth}px`,
    minHeight: `${size.height / 3}px`,
  };

  return (
    <div 
      className={cn("flex flex-col gap-2", className)}
      style={{
        width: `${buttonWidth}px`,
        minWidth: `${buttonWidth}px`,
      }}
    >
      <Button
        className="border-dashed border-2 bg-white/90 hover:bg-white/70 text-black hover:text-black"
        variant="outline"
        style={buttonStyle}
        onClick={() => handleAddPage(SlideType.enum.Content)}
      >
        <div className="flex flex-col items-center gap-2">
          <Plus className="w-6 h-6" />
          Content Slide
        </div>
      </Button>
      <Button
        className="border-dashed border-2 bg-white/90 hover:bg-white/70 text-black hover:text-black"
        variant="outline"
        style={buttonStyle}
        onClick={() => handleAddPage(SlideType.enum.Intro)}
      >
        <div className="flex flex-col items-center gap-2">
          <Plus className="w-6 h-6" />
          Intro Slide
        </div>
      </Button>
      <Button
        className="border-dashed border-2 bg-white/90 hover:bg-white/70 text-black hover:text-black"
        variant="outline"
        style={buttonStyle}
        onClick={() => handleAddPage(SlideType.enum.Outro)}
      >
        <div className="flex flex-col items-center gap-2">
          <Plus className="w-6 h-6" />
          Outro Slide
        </div>
      </Button>
    </div>
  );
}
