import * as z from "zod";

export const ElementType = z.enum([
  "Title",
  "Subtitle",
  "Description",
  "ContentImage",
  "List",
  "Quote"
]);

export type ElementType = z.infer<typeof ElementType>;
