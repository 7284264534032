import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useSpeechRecognition } from '@/hooks/useSpeechRecognition';
import type { PostContent, PostType } from '@/types/post';

interface ContentEditorProps {
  content: string;
  onContentChange: (content: string) => void;
  postLength: PostContent['postLength'];
  onPostLengthChange: (length: PostContent['postLength']) => void;
  postType: PostType;
  isLoading?: boolean;
}

const POST_LENGTH_OPTIONS = {
  text: [
    { value: 'short', label: 'Short (~50 words)' },
    { value: 'medium', label: 'Medium (~125 words)' },
    { value: 'long', label: 'Long (~200 words)' }
  ],
  article: [
    { value: 'short', label: 'Short (200-300 words)' },
    { value: 'medium', label: 'Medium (300-500 words)' },
    { value: 'long', label: 'Long (600-800 words)' }
  ],
  poll: [
    { value: 'short', label: 'Short (~50 words)' },
    { value: 'medium', label: 'Medium (~125 words)' },
    { value: 'long', label: 'Long (~200 words)' }
  ],
  event: [
    { value: 'short', label: 'Short (~50 words)' },
    { value: 'medium', label: 'Medium (~125 words)' },
    { value: 'long', label: 'Long (~200 words)' }
  ]
};

export function ContentEditor({
  content,
  onContentChange,
  postLength,
  onPostLengthChange,
  postType,
  isLoading = false
}: ContentEditorProps) {
  const {
    isRecording,
    interimTranscript,
    startRecording,
    stopRecording
  } = useSpeechRecognition((finalTranscript) => {
    onContentChange(content + (content ? ' ' : '') + finalTranscript);
  });

  const handleTextAreaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const target = e.target as HTMLTextAreaElement;
      const value = target.value;
      const selectionStart = target.selectionStart;
      const newValue = value.slice(0, selectionStart) + '\n' + value.slice(target.selectionEnd);
      
      onContentChange(newValue);

      // Set cursor position after the new line
      setTimeout(() => {
        target.selectionStart = target.selectionEnd = selectionStart + 1;
      }, 0);
    }
  };

  const getPlaceholder = () => {
    switch (postType) {
      case 'article':
        return "Write your article content here. Focus on providing in-depth insights, analysis, or educational content for healthcare professionals...";
      case 'poll':
        return "Write your poll question and provide context. Remember to keep it focused and relevant to healthcare professionals...";
      case 'event':
        return "Describe your event, including key details like date, time, location, and what healthcare professionals will learn...";
      default:
        return "Start typing or use voice input to create your post content...";
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-3">
        <Label className="text-sm font-medium">Content</Label>
        <div className="flex items-center gap-4">
          <Select
            value={postLength}
            onValueChange={(value: PostContent['postLength']) => onPostLengthChange(value)}
            disabled={isLoading}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Post Length" />
            </SelectTrigger>
            <SelectContent className="bg-white">
              {POST_LENGTH_OPTIONS[postType].map(option => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Button
            variant="outline"
            size="icon"
            className={`h-8 w-8 shrink-0 ${isRecording ? 'bg-red-50 text-red-500 hover:bg-red-100' : ''}`}
            onClick={() => {
              if (!isRecording) {
                startRecording();
              } else {
                stopRecording();
              }
            }}
            disabled={isLoading}
          >
            {isRecording ? "🔴" : "🎤"}
          </Button>
        </div>
      </div>
      <div className="relative">
        <Textarea
          value={content}
          onChange={(e) => onContentChange(e.target.value)}
          onKeyDown={handleTextAreaKeyDown}
          placeholder={getPlaceholder()}
          rows={12}
          className="w-full resize-none"
          disabled={isLoading}
        />
        {isLoading && (
          <div className="absolute inset-0 bg-white/80 flex items-center justify-center">
            <div className="flex flex-col items-center gap-2">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
              <span className="text-sm text-muted-foreground">Generating content...</span>
            </div>
          </div>
        )}
        {isRecording && interimTranscript && (
          <div className="absolute bottom-0 left-0 right-0 p-2 bg-gray-50 text-gray-500 border-t text-sm">
            {interimTranscript}
          </div>
        )}
      </div>
      {isRecording && (
        <div className="text-sm text-muted-foreground animate-pulse flex items-center gap-2 mt-2">
          <span className="inline-block h-2 w-2 bg-red-500 rounded-full animate-pulse"></span>
          Recording... Click the microphone to stop
        </div>
      )}
    </div>
  );
} 