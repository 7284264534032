import { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { searchPixabayImages, getImageSelectionHandler } from "@/services/imageSearch";
import type { PixabayImage } from "@/types/post";

interface ImageSearchDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onImageSelect: (url: string) => void;
}

export function ImageSearchDialog({
  open,
  onOpenChange,
  onImageSelect,
}: ImageSearchDialogProps) {
  const [activeTab, setActiveTab] = useState("upload");
  const [imageUrl, setImageUrl] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<PixabayImage[]>([]);
  const [selectedImage, setSelectedImage] = useState<PixabayImage | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchQuery) {
        handleSearch(searchQuery);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const handleSearch = async (query: string) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    try {
      const results = await searchPixabayImages(query);
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching images:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleImageSelect = (image: PixabayImage) => {
    setSelectedImage(image);
  };

  const handleConfirm = () => {
    if (activeTab === "url" && imageUrl) {
      onImageSelect(imageUrl);
      onOpenChange(false);
    } else if (activeTab === "pixabay" && selectedImage) {
      onImageSelect(selectedImage.largeImageURL);
      onOpenChange(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Add Image</DialogTitle>
        </DialogHeader>
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="upload">Upload</TabsTrigger>
            <TabsTrigger value="url">URL</TabsTrigger>
            <TabsTrigger value="pixabay">Pixabay</TabsTrigger>
          </TabsList>
          
          <TabsContent value="upload" className="py-4">
            <Input type="file" accept="image/*" className="w-full" />
          </TabsContent>

          <TabsContent value="url" className="py-4">
            <div className="space-y-4">
              <div>
                <Label>Image URL</Label>
                <Input
                  type="url"
                  placeholder="Enter image URL..."
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                />
              </div>
            </div>
          </TabsContent>

          <TabsContent value="pixabay" className="py-4">
            <div className="space-y-4">
              <div>
                <Label>Search Images</Label>
                <Input
                  type="text"
                  placeholder="Search Pixabay images..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              {isSearching && (
                <div className="flex justify-center py-4">
                  <div className="animate-spin h-6 w-6 border-2 border-primary border-t-transparent rounded-full" />
                </div>
              )}

              {searchResults.length > 0 && (
                <div className="grid grid-cols-3 gap-4 max-h-[400px] overflow-y-auto">
                  {searchResults.map((image) => (
                    <div
                      key={image.id}
                      className={`relative cursor-pointer rounded-lg overflow-hidden border-2 ${
                        selectedImage?.id === image.id
                          ? 'border-primary'
                          : 'border-transparent'
                      }`}
                      onClick={() => handleImageSelect(image)}
                    >
                      <img
                        src={image.previewURL}
                        alt={image.tags}
                        className="w-full h-32 object-cover"
                      />
                      {selectedImage?.id === image.id && (
                        <div className="absolute top-2 right-2 bg-primary text-white rounded-full p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </TabsContent>
        </Tabs>

        <div className="flex justify-end gap-2 mt-4">
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
} 