import { ContentPlanPost, GroupedContentPlan as DBGroupedContentPlan } from '@/services/content-planner';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { CalendarIcon, Download, Copy, ChevronDown, ChevronRight, Save, Edit2, ExternalLink, Wand2, ChevronLeft } from 'lucide-react';
import {
  format,
  startOfWeek,
  addWeeks,
  isSameWeek,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isSameDay,
  addMonths,
  subMonths,
  parseISO,
  addDays,
  startOfDay,
  endOfDay,
  isToday,
  endOfWeek,
} from 'date-fns';
import { useState, useEffect, useMemo } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { supabase } from '@/integrations/supabase/client';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import OpenAI from "openai";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from '@/lib/utils';
import { useNavigate } from 'react-router-dom';

const openai = new OpenAI({
  baseURL: 'https://api.deepseek.com',
  apiKey: import.meta.env.VITE_DEEPSEEK_API_KEY,
  dangerouslyAllowBrowser: true
});

// Update interfaces at the top of the file
interface ExtendedContentPlanPost {
  id?: string;
  suggestedDate: string;
  bestTimeToPost?: string;
  scheduledTime?: string;
  description?: string;
  postType: string;
  topic: string;
  category: string;
  keyPoints: string[];
  hashtags: string[];
  callToAction?: string;
}

interface GroupedContentPlan {
  type: string;
  posts: ExtendedContentPlanPost[];
}

interface SavedPost {
  id: string;
  title: string;
  type: string;
  scheduled_date: string;
  scheduled_time: string;
  metadata?: {
    content_plan?: {
      scheduled_date: string;
      scheduled_time: string;
    };
  };
}

// Add interface for post data from the database
interface DBPost extends ContentPlanPost {
  bestTimeToPost?: string;
}

// Update type guard
function isExtendedContentPlanPost(post: Partial<ExtendedContentPlanPost>): post is ExtendedContentPlanPost {
  return Boolean(
    post &&
    typeof post.suggestedDate === 'string' &&
    typeof post.postType === 'string' &&
    typeof post.topic === 'string' &&
    typeof post.category === 'string' &&
    Array.isArray(post.keyPoints) &&
    Array.isArray(post.hashtags)
  );
}

// Update ContentCalendarProps interface
interface ContentCalendarProps {
  posts: DBGroupedContentPlan[];  // Use the imported type
  onSave?: () => void;
  showSaveButton?: boolean;
  onUpdatePost?: (updatedPost: ExtendedContentPlanPost, index: number) => void;
}

// Color mapping for different post types
const postTypeColors: Record<string, { bg: string, text: string, hover: string }> = {
  text: { bg: 'bg-blue-100', text: 'text-blue-800', hover: 'hover:bg-blue-200' },
  image: { bg: 'bg-green-100', text: 'text-green-800', hover: 'hover:bg-green-200' },
  article: { bg: 'bg-purple-100', text: 'text-purple-800', hover: 'hover:bg-purple-200' },
  poll: { bg: 'bg-orange-100', text: 'text-orange-800', hover: 'hover:bg-orange-200' },
  case_study: { bg: 'bg-indigo-100', text: 'text-indigo-800', hover: 'hover:bg-indigo-200' },
  tips: { bg: 'bg-teal-100', text: 'text-teal-800', hover: 'hover:bg-teal-200' },
  industry_news: { bg: 'bg-pink-100', text: 'text-pink-800', hover: 'hover:bg-pink-200' },
  thought_leadership: { bg: 'bg-yellow-100', text: 'text-yellow-800', hover: 'hover:bg-yellow-200' }
};

type CalendarView = 'month' | 'week' | 'day';

export function ContentCalendar({ posts = [], onSave, showSaveButton = false, onUpdatePost }: ContentCalendarProps) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState<CalendarView>('month');
  const [isVerticalWeek, setIsVerticalWeek] = useState(false);
  const [savedPosts, setSavedPosts] = useState<SavedPost[]>([]);
  const [isGenerating, setIsGenerating] = useState<string | null>(null);
  const [editingPost, setEditingPost] = useState<{ post: ExtendedContentPlanPost; index: number } | null>(null);
  const [editedPost, setEditedPost] = useState<ExtendedContentPlanPost | null>(null);
  const { toast } = useToast();
  const navigate = useNavigate();

  // Type guard for view comparison
  const isMonthView = (v: CalendarView): v is 'month' => v === 'month';
  const isWeekView = (v: CalendarView): v is 'week' => v === 'week';
  const isDayView = (v: CalendarView): v is 'day' => v === 'day';

  // Helper function for date formatting
  const getDateFormat = (date: Date) => {
    if (isDayView(view)) return 'EEEE, MMMM d';
    if (isWeekView(view) && isVerticalWeek) return 'EEEE, MMMM d';
    return 'd';
  };

  // Helper function for cell height
  const getCellHeight = () => {
    if (isDayView(view)) return 'min-h-[500px]';
    if (isWeekView(view) && !isVerticalWeek) return 'min-h-[200px]';
    return 'min-h-[150px]';
  };

  // Helper function for post display style
  const getPostDisplayStyle = () => {
    if (isDayView(view) || (isWeekView(view) && !isVerticalWeek)) return 'p-4 text-sm space-y-3';
    return 'px-2 py-1.5 text-xs';
  };

  const ColorKey = () => {
    const uniquePostTypes = Array.from(new Set((posts || []).map(group => group.type)));
    return (
      <div className="flex gap-4 items-center mt-4 mb-2 px-4">
        <span className="text-sm font-medium">Post Types:</span>
        {uniquePostTypes.map((type) => (
          <div key={type} className="flex items-center gap-2">
            <div className={cn("w-3 h-3 rounded-full", getPostTypeColor(type))} />
            <span className="text-sm">{type}</span>
          </div>
        ))}
      </div>
    );
  };

  const calendarDays = useMemo(() => {
    let days: Date[] = [];
    const start = view === 'month' 
      ? startOfMonth(currentDate)
      : view === 'week'
      ? startOfWeek(currentDate)
      : currentDate;
    
    const end = view === 'month'
      ? endOfMonth(currentDate)
      : view === 'week'
      ? endOfWeek(currentDate)
      : currentDate;

    days = eachDayOfInterval({ start, end });
    return days;
  }, [currentDate, view]);

  const firstDayOffset = view === 'month' ? startOfMonth(currentDate).getDay() : 0;

  const navigateDate = (direction: 'prev' | 'next') => {
    const amount = view === 'month' ? 30 : view === 'week' ? 7 : 1;
    setCurrentDate(prev => 
      direction === 'prev' 
        ? addDays(prev, -amount)
        : addDays(prev, amount)
    );
  };

  // Update postsForDate function to use type guard
  const postsForDate = (date: Date) => {
    // Flatten the grouped posts array and ensure we have valid ExtendedContentPlanPost objects
    const flattenedPosts = (posts || []).flatMap(group => 
      (group.posts || []).map(post => {
        const extendedPost: ExtendedContentPlanPost = {
          ...post,
          postType: group.type || post.postType || 'text',
          suggestedDate: post.suggestedDate || format(new Date(), 'yyyy-MM-dd'),
          topic: post.topic || 'Untitled Post',
          category: post.category || group.type || 'text',
          keyPoints: post.keyPoints || [],
          hashtags: post.hashtags || [],
          bestTimeToPost: (post as DBPost).bestTimeToPost
        };

        return extendedPost;
      })
    );

    return flattenedPosts.filter(post => {
      try {
        return isSameDay(new Date(post.suggestedDate), date);
      } catch (error) {
        console.error('Invalid date format:', post.suggestedDate);
        return false;
      }
    });
  };

  const getPostTypeColor = (type: string | undefined) => {
    if (!type) return 'bg-gray-100 text-gray-800 hover:bg-gray-200';
    
    const normalizedType = type.toLowerCase();
    const colors = postTypeColors[normalizedType];
    
    return colors ? `${colors.bg} ${colors.text} ${colors.hover}` : 'bg-gray-100 text-gray-800 hover:bg-gray-200';
  };

  useEffect(() => {
    loadSavedPosts();
  }, []);

  const loadSavedPosts = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) return;

      const { data, error } = await supabase
        .from('posts')
        .select('id, title, type, metadata')
        .eq('user_id', session.user.id);

      if (error) throw error;

      const formattedPosts = data
        .filter(post => post.metadata?.content_plan?.scheduled_date)
        .map(post => ({
          id: post.id,
          title: post.title,
          type: post.type,
          scheduled_date: post.metadata.content_plan.scheduled_date,
          scheduled_time: post.metadata.content_plan.scheduled_time || '00:00',
          metadata: post.metadata
        }));

      setSavedPosts(formattedPosts);
    } catch (error) {
      console.error('Error loading saved posts:', error);
    }
  };

  // Group posts by week
  const groupedPosts = posts.reduce((acc, group) => {
    if (!group.posts) return acc;
    
    group.posts.forEach(post => {
      try {
        if (!post.suggestedDate) return;
        const date = new Date(post.suggestedDate);
        if (isNaN(date.getTime())) return; // Skip invalid dates
        
        const weekStart = format(startOfWeek(date), 'yyyy-MM-dd');
        if (!acc[weekStart]) {
          acc[weekStart] = [];
        }
        acc[weekStart].push({
          ...post,
          postType: post.postType || group.type || 'text'
        });
      } catch (error) {
        console.error('Error processing post date:', error, post);
      }
    });
    return acc;
  }, {} as Record<string, ExtendedContentPlanPost[]>);

  // Update downloadICalFile function
  const downloadICalFile = () => {
    // Flatten the grouped posts array
    const flattenedPosts = (posts || []).flatMap(group => 
      group.posts.map(post => ({
        ...post,
        postType: group.type || post.postType
      }))
    );

    const iCalContent = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'PRODID:-//Medical Post//Content Calendar//EN',
      ...flattenedPosts.map(post => {
        const date = new Date(post.suggestedDate);
        const timeToUse = post.bestTimeToPost || post.scheduledTime || '12:00';
        const [hours, minutes] = timeToUse.split(':');
        date.setHours(parseInt(hours), parseInt(minutes));
        
        const dateString = format(date, "yyyyMMdd'T'HHmmss");
        const endDate = new Date(date.getTime() + 60 * 60 * 1000);
        const endDateString = format(endDate, "yyyyMMdd'T'HHmmss");

        const savedPostsForDay = (savedPosts || []).filter(savedPost => 
          isSameDay(new Date(savedPost.scheduled_date), date)
        );
        
        const description = [
          'Content Plan:',
          `Key Points:\n${(post.keyPoints || []).join('\n')}`,
          `Hashtags: ${(post.hashtags || []).join(' ') || ''}`,
          savedPostsForDay.length > 0 ? '\nSaved Posts:' : '',
          ...savedPostsForDay.map(savedPost => 
            `- ${savedPost.title} (${savedPost.type})`
          )
        ].filter(Boolean).join('\n');
        
        return [
          'BEGIN:VEVENT',
          `DTSTART:${dateString}`,
          `DTEND:${endDateString}`,
          `SUMMARY:${post.postType?.toUpperCase() || 'POST'}: ${post.topic || 'Untitled'}`,
          `DESCRIPTION:${description.replace(/\n/g, '\\n')}`,
          'END:VEVENT'
        ].join('\n');
      }),
      'END:VCALENDAR'
    ].join('\n');

    const blob = new Blob([iCalContent], { type: 'text/calendar' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'content-calendar.ics';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Update copyToClipboard function
  const copyToClipboard = async () => {
    // Flatten the grouped posts array
    const flattenedPosts = (posts || []).flatMap(group => 
      group.posts.map(post => ({
        ...post,
        postType: group.type || post.postType
      }))
    );

    const textContent = flattenedPosts.map(post => {
      const date = new Date(post.suggestedDate);
      const savedPostsForDay = (savedPosts || []).filter(savedPost => 
        isSameDay(new Date(savedPost.scheduled_date), date)
      );

      const planContent = [
        `Date: ${post.suggestedDate} at ${post.bestTimeToPost || post.scheduledTime || '12:00'}`,
        `Type: ${post.postType || 'Text'}`,
        `Topic: ${post.topic || 'Untitled'}`,
        '\nKey Points:',
        (post.keyPoints || []).map(point => `- ${point}`).join('\n'),
        `\nHashtags: ${(post.hashtags || []).join(' ')}`
      ].join('\n');

      const savedPostsContent = savedPostsForDay.length > 0 
        ? [
            '\nSaved Posts:',
            ...savedPostsForDay.map(savedPost => 
              `- ${savedPost.title} (${savedPost.type})`
            )
          ].join('\n')
        : '';

      return `${planContent}${savedPostsContent}\n-------------------`;
    }).join('\n\n');

    await navigator.clipboard.writeText(textContent);
    
    toast({
      title: "Copied to Clipboard",
      description: "Calendar content has been copied to your clipboard.",
    });
  };

  const handleSave = () => {
    if (onSave) {
      onSave();
      toast({
        title: "Calendar Saved",
        description: "Your content calendar has been saved to your dashboard.",
      });
    }
  };

  const parsePostDate = (dateString: string, timeString?: string): Date => {
    try {
      const date = parseISO(dateString);
      if (timeString) {
        const [hours, minutes] = timeString.split(':').map(Number);
        date.setHours(hours || 0, minutes || 0);
      }
      return date;
    } catch (error) {
      console.error('Error parsing date:', dateString, timeString);
      return new Date();
    }
  };

  const handleEditPost = (post: ExtendedContentPlanPost, index: number) => {
    if (!onUpdatePost) return;
    
    try {
      const updatedPost = {
        ...post,
        suggestedDate: post.suggestedDate || format(new Date(), 'yyyy-MM-dd'),
        scheduledTime: post.scheduledTime || '12:00',
        postType: post.postType || 'text',
        topic: post.topic || 'Untitled Post'
      };
      onUpdatePost(updatedPost, index);
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  // Helper function to find a post in the grouped structure
  const findPostInGroups = (date: string, time: string): { post: ExtendedContentPlanPost; index: number } | null => {
    for (const group of posts) {
      const postIndex = group.posts.findIndex(p => 
        p.suggestedDate === date && (p.scheduledTime === time || p.bestTimeToPost === time)
      );
      if (postIndex !== -1) {
        return { post: { ...group.posts[postIndex], postType: group.type }, index: postIndex };
      }
    }
    return null;
  };

  const handleSaveEdit = () => {
    if (editedPost && editingPost && onUpdatePost) {
      // Find the post in the grouped structure
      const foundPost = findPostInGroups(editedPost.suggestedDate, editedPost.scheduledTime || '');
      if (foundPost) {
        onUpdatePost(editedPost, foundPost.index);
      }
      setEditingPost(null);
      setEditedPost(null);
      toast({
        title: "Post updated",
        description: "Your changes have been saved.",
      });
    }
  };

  const handleInputChange = (field: keyof ExtendedContentPlanPost, value: string | string[]) => {
    if (!editedPost) return;

    if (field === 'keyPoints' || field === 'hashtags') {
      // Handle arrays by splitting on newlines or commas
      const arrayValue = typeof value === 'string' 
        ? value.split(/[\n,]/).map(item => item.trim()).filter(Boolean)
        : value;
      setEditedPost({ ...editedPost, [field]: arrayValue });
    } else {
      setEditedPost({ ...editedPost, [field]: value });
    }
  };

  const generatePost = async (post: ExtendedContentPlanPost) => {
    try {
      setIsGenerating(post.suggestedDate);

      const prompt = `Create a LinkedIn post based on the following content plan item:
Topic: ${post.topic}
Post Type: ${post.postType}
Key Points:
${(post.keyPoints || []).join('\n')}
Hashtags: ${(post.hashtags || []).join(', ')}

Create an engaging post and provide additional metadata in a strict JSON format.

The response must be a valid JSON object with these exact fields:
- content: the main post content with emojis and formatting
- target_audience: array of 3-5 target audience segments
- call_to_action: clear call-to-action statement
- tone: overall tone of the post (professional, inspirational, or educational)

Requirements for the content:
1. Expand on the key points while maintaining the original message
2. Use professional yet conversational tone
3. Include appropriate emojis for visual appeal
4. Maintain the same hashtags but can add more relevant ones
5. End with a clear call to action

Return ONLY the JSON object with no additional formatting, markdown, or code blocks.`;

      const response = await openai.chat.completions.create({
        model: 'deepseek-chat',
        messages: [
          { 
            role: 'system', 
            content: 'You are an expert LinkedIn content creator. Always return a clean, valid JSON object with no markdown formatting, no code blocks, and no additional text. The response should be directly parseable by JSON.parse().'
          },
          { role: 'user', content: prompt }
        ],
        temperature: 0.7,
      });

      const generatedContent = response.choices[0].message.content?.trim();
      if (!generatedContent) throw new Error('No content generated');

      try {
        // Parse the JSON response
        const parsedResponse = JSON.parse(generatedContent);

        // Validate the required fields
        if (!parsedResponse.content || !parsedResponse.target_audience || 
            !parsedResponse.call_to_action || !parsedResponse.tone) {
          throw new Error('Missing required fields in generated content');
        }

        // Save the generated post
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user) throw new Error('No authenticated user found');

        const postData = {
          user_id: session.user.id,
          type: post.postType,
          title: post.topic,
          content: parsedResponse.content,
          hashtags: post.hashtags,
          target_audience: parsedResponse.target_audience,
          call_to_action: parsedResponse.call_to_action,
          tone: parsedResponse.tone,
          metadata: {
            content_plan: {
              scheduled_date: post.suggestedDate,
              scheduled_time: post.bestTimeToPost || post.scheduledTime
            }
          },
          created_at: new Date().toISOString(),
          status: 'draft'
        };

        const { error } = await supabase
          .from('posts')
          .insert(postData);

        if (error) throw error;

        toast({
          title: "Post Generated",
          description: "Your post has been generated and saved successfully.",
        });

        // Reload saved posts to show the new one
        loadSavedPosts();
      } catch (parseError) {
        console.error('Generated content:', generatedContent);
        throw new Error('Failed to parse generated content as JSON. Please try again.');
      }
    } catch (error) {
      console.error('Error generating post:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to generate post. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsGenerating(null);
    }
  };

  // Add goToToday function
  const goToToday = () => {
    setCurrentDate(new Date());
  };

  // Add null check for post.keyPoints
  const renderKeyPoints = (post: ExtendedContentPlanPost) => {
    return (post.keyPoints || []).map((point, i) => (
      <li key={i}>{point}</li>
    ));
  };

  // Add null check for post.hashtags
  const renderHashtags = (post: ExtendedContentPlanPost) => {
    return (post.hashtags || []).map((tag, i) => (
      <span key={i} className="text-xs bg-gray-100/50 px-1.5 py-0.5 rounded">
        #{tag}
      </span>
    ));
  };

  // Add null check for connectedPosts
  const renderConnectedPosts = (connectedPosts: SavedPost[]) => {
    return (connectedPosts || []).map((savedPost) => (
      <TooltipProvider key={savedPost.id}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div
              className="text-xs px-2 py-1 bg-gray-50 rounded-md mb-1 flex items-center justify-between hover:bg-gray-100 cursor-pointer transition-colors group/saved"
              onClick={() => navigate(`/posts/${savedPost.id}`)}
            >
              <span className="truncate flex-1">{savedPost.title}</span>
              <span className="text-gray-400 ml-1">
                {savedPost.metadata?.content_plan?.scheduled_time || ''}
              </span>
              <div className="absolute right-0 top-0 hidden group-hover/saved:flex gap-1 bg-white shadow-sm rounded-md p-1 z-10">
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-6 w-6"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/posts/${savedPost.id}/edit`);
                  }}
                >
                  <Edit2 className="h-3 w-3" />
                </Button>
              </div>
            </div>
          </TooltipTrigger>
          <TooltipContent 
            side="top" 
            className="bg-white shadow-lg border p-3 max-w-[300px] z-50"
          >
            <div className="space-y-2">
              <p className="font-medium">{savedPost.title}</p>
              <p className="text-xs text-gray-500">
                {format(new Date(savedPost.metadata?.content_plan?.scheduled_date || new Date()), 'EEEE, MMMM d')} at {savedPost.metadata?.content_plan?.scheduled_time || ''}
              </p>
              <div className="text-xs">
                <span className={cn(
                  "px-1.5 py-0.5 rounded",
                  getPostTypeColor(savedPost.type)
                )}>
                  {savedPost.type}
                </span>
              </div>
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ));
  };

  return (
    <Card className="p-6 bg-white">
      <div className="space-y-6">
        {/* Calendar Header */}
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <CalendarIcon className="h-5 w-5 text-gray-500" />
                <h2 className="text-xl font-semibold">
                  {format(currentDate, view === 'day' ? 'MMMM d, yyyy' : 'MMMM yyyy')}
                </h2>
              </div>
              <div className="flex gap-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setView('month')}
                  className={view === 'month' ? 'bg-primary text-white hover:bg-primary/90' : ''}
                >
                  Month
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setView('week')}
                  className={view === 'week' ? 'bg-primary text-white hover:bg-primary/90' : ''}
                >
                  Week
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setView('day')}
                  className={view === 'day' ? 'bg-primary text-white hover:bg-primary/90' : ''}
                >
                  Day
                </Button>
                {view === 'week' && (
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setIsVerticalWeek(!isVerticalWeek)}
                    className="gap-2"
                  >
                    {isVerticalWeek ? 'Horizontal' : 'Vertical'} Layout
                  </Button>
                )}
                <Button
                  variant="outline"
                  size="sm"
                  onClick={goToToday}
                  className="gap-2"
                >
                  Today
                </Button>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={downloadICalFile}
                className="gap-2"
              >
                <Download className="h-4 w-4" />
                Export
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={copyToClipboard}
                className="gap-2"
              >
                <Copy className="h-4 w-4" />
                Copy
              </Button>
              <Button
                variant="outline"
                size="icon"
                onClick={() => navigateDate('prev')}
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="icon"
                onClick={() => navigateDate('next')}
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
          </div>

          {/* Color Key */}
          <ColorKey />
        </div>

        {/* Calendar Grid */}
        <div className={cn(
          "grid gap-px bg-gray-200 rounded-lg overflow-hidden",
          {
            'grid grid-cols-7': isMonthView(view) || (isWeekView(view) && !isVerticalWeek),
            'grid grid-cols-[auto_1fr]': isWeekView(view) && isVerticalWeek,
            'grid grid-cols-1': isDayView(view)
          }
        )}>
          {/* Week day headers */}
          {(isMonthView(view) || (isWeekView(view) && !isVerticalWeek)) && ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div
              key={day}
              className="bg-gray-50 p-2 text-center text-sm font-medium text-gray-500"
            >
              {day}
            </div>
          ))}

          {/* Empty cells for offset in month view */}
          {isMonthView(view) && Array.from({ length: firstDayOffset }).map((_, index) => (
            <div key={`empty-${index}`} className="bg-white p-2 min-h-[120px]" />
          ))}

          {/* Calendar days */}
          {calendarDays.map((date, index) => {
            const dayPosts = postsForDate(date);
            const isCurrentMonth = isSameMonth(date, currentDate);
            const connectedPosts = savedPosts.filter(savedPost => 
              isSameDay(new Date(savedPost.scheduled_date), date)
            );

            // For vertical week view, add day labels on the left
            if (isWeekView(view) && isVerticalWeek) {
              return (
                <>
                  <div key={`label-${date.toISOString()}`} 
                    className={cn(
                      "text-sm font-medium py-3 px-4 text-right border-r bg-gray-50",
                      isToday(date) && "text-primary font-semibold"
                    )}>
                    <div>{format(date, 'EEEE')}</div>
                    <div className="text-xs text-gray-500 mt-1">{format(date, 'MMM d')}</div>
                  </div>
                  <div
                    key={date.toISOString()}
                    className={cn(
                      "bg-white p-3 transition-all",
                      "min-h-[150px]",
                      isToday(date) && "ring-2 ring-primary/20 bg-primary/5",
                      "hover:bg-gray-50/80 group"
                    )}
                  >
                    {/* Add create post button on hover */}
                    <div className="hidden group-hover:flex justify-end mb-2">
                      <Button
                        variant="outline"
                        size="sm"
                        className="text-xs"
                        onClick={() => {
                          const searchParams = new URLSearchParams();
                          searchParams.set('date', format(date, 'yyyy-MM-dd'));
                          if (posts.length > 0 && posts[0].posts.length > 0) {
                            const planName = posts[0].planName;
                            if (planName) {
                              searchParams.set('planName', planName);
                            }
                          }
                          navigate(`/posts/new?${searchParams.toString()}`);
                        }}
                      >
                        Create Post
                      </Button>
                    </div>
                    <div className="flex flex-col h-full">
                      <div className="mt-1 space-y-1 flex-grow">
                        {/* Planned Posts */}
                        {dayPosts.map((post, postIndex) => {
                          // Ensure we have a valid ExtendedContentPlanPost
                          const validPost: ExtendedContentPlanPost = {
                            ...post,
                            postType: post.postType || 'text',
                            suggestedDate: post.suggestedDate || format(new Date(), 'yyyy-MM-dd'),
                            topic: post.topic || 'Untitled Post',
                            category: post.category || post.postType || 'text',
                            keyPoints: post.keyPoints || [],
                            hashtags: post.hashtags || []
                          };

                          return (
                            <div key={postIndex} className="group/post relative">
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <div
                                      className={cn(
                                        "transition-all group-hover:shadow-sm",
                                        getPostDisplayStyle(),
                                        "rounded-md border cursor-pointer",
                                        getPostTypeColor(validPost.postType),
                                        "hover:ring-2 hover:ring-primary/20"
                                      )}
                                      onClick={() => handleEditPost(validPost, postIndex)}
                                    >
                                      <div className={cn(
                                        'space-y-2',
                                        (isWeekView(view) && !isVerticalWeek) && 'p-2'
                                      )}>
                                        <div className="font-medium">{validPost.topic}</div>
                                        {(isDayView(view) || (isWeekView(view) && !isVerticalWeek)) && (
                                          <>
                                            <div className="text-xs text-gray-600 flex items-center gap-2">
                                              <CalendarIcon className="h-3 w-3" />
                                              {validPost.bestTimeToPost || validPost.scheduledTime || '12:00'} • {validPost.postType}
                                            </div>
                                            <div className="text-xs space-y-1">
                                              <div className="font-medium">Key Points:</div>
                                              <ul className="list-disc pl-4 space-y-1">
                                                {renderKeyPoints(validPost)}
                                              </ul>
                                            </div>
                                            {validPost.description && (
                                              <div className="text-xs text-gray-600 line-clamp-3">
                                                {validPost.description}
                                              </div>
                                            )}
                                            <div className="flex flex-wrap gap-1">
                                              {renderHashtags(validPost)}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </TooltipTrigger>
                                  <TooltipContent 
                                    side="top" 
                                    className="bg-white shadow-lg border p-4 max-w-[400px] z-50"
                                  >
                                    <div className="space-y-3">
                                      <div>
                                        <p className="font-medium text-sm">{validPost.topic}</p>
                                        <p className="text-xs text-gray-500 mt-1">
                                          {format(parseISO(validPost.suggestedDate), 'h:mm a')} • {validPost.postType}
                                        </p>
                                      </div>
                                      {validPost.description && (
                                        <div className="text-xs">
                                          <p className="font-medium">Content Preview:</p>
                                          <p className="mt-1 text-gray-600 line-clamp-4">
                                            {validPost.description}
                                          </p>
                                        </div>
                                      )}
                                      <div className="text-xs">
                                        <p className="font-medium">Key Points:</p>
                                        <ul className="list-disc pl-4 mt-1 space-y-1">
                                          {renderKeyPoints(validPost)}
                                        </ul>
                                      </div>
                                      <div className="flex flex-wrap gap-1 mt-2">
                                        {renderHashtags(validPost)}
                                      </div>
                                    </div>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                              
                              {/* Post Actions */}
                              <div className="absolute right-0 top-0 hidden group-hover/post:flex gap-1 bg-white shadow-sm rounded-md p-1 z-10">
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="h-6 w-6"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const searchParams = new URLSearchParams();
                                    searchParams.set('date', validPost.suggestedDate);
                                    searchParams.set('title', validPost.topic);
                                    searchParams.set('type', validPost.postType);
                                    searchParams.set('keyPoints', validPost.keyPoints.join('\n'));
                                    if (validPost.hashtags) {
                                      searchParams.set('hashtags', validPost.hashtags.join('\n'));
                                    }
                                    // Add plan name to URL params
                                    if (posts.length > 0 && posts[0].planName) {
                                      searchParams.set('planName', posts[0].planName);
                                    }
                                    navigate(`/posts/new?${searchParams.toString()}`);
                                  }}
                                >
                                  <Edit2 className="h-3 w-3" />
                                </Button>
                              </div>
                            </div>
                          );
                        })}

                        {/* Connected Posts */}
                        {connectedPosts.length > 0 && (
                          <div className="mt-2 pt-2 border-t border-dashed border-gray-200">
                            <p className="text-xs text-gray-500 mb-1">Connected Posts:</p>
                            {renderConnectedPosts(connectedPosts)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            }

            return (
              <div
                key={date.toISOString()}
                className={cn(
                  "bg-white p-2 transition-colors",
                  getCellHeight(),
                  !isCurrentMonth && isMonthView(view) && "bg-gray-50/50",
                  isToday(date) && "ring-2 ring-primary/20",
                  "hover:bg-gray-50/80 relative group"
                )}
              >
                {/* Add create post button on hover */}
                <div className="hidden group-hover:flex justify-end absolute top-2 right-2 z-10">
                  <Button
                    variant="outline"
                    size="sm"
                    className="text-xs"
                    onClick={() => {
                      const searchParams = new URLSearchParams();
                      searchParams.set('date', format(date, 'yyyy-MM-dd'));
                      if (posts.length > 0 && posts[0].posts.length > 0) {
                        const planName = posts[0].planName;
                        if (planName) {
                          searchParams.set('planName', planName);
                        }
                      }
                      navigate(`/posts/new?${searchParams.toString()}`);
                    }}
                  >
                    Create Post
                  </Button>
                </div>

                <div className="flex flex-col h-full">
                  <span className={cn(
                    "text-sm font-medium",
                    !isCurrentMonth && isMonthView(view) && "text-gray-400"
                  )}>
                    {format(date, getDateFormat(date))}
                  </span>
                  
                  <div className="mt-1 space-y-1 flex-grow">
                    {/* Planned Posts */}
                    {dayPosts.map((post, postIndex) => {
                      // Ensure we have a valid ExtendedContentPlanPost
                      const validPost: ExtendedContentPlanPost = {
                        ...post,
                        postType: post.postType || 'text',
                        suggestedDate: post.suggestedDate || format(new Date(), 'yyyy-MM-dd'),
                        topic: post.topic || 'Untitled Post',
                        category: post.category || post.postType || 'text',
                        keyPoints: post.keyPoints || [],
                        hashtags: post.hashtags || []
                      };

                      return (
                        <div key={postIndex} className="group/post relative">
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <div
                                  className={cn(
                                    "transition-all group-hover:shadow-sm",
                                    getPostDisplayStyle(),
                                    "rounded-md border cursor-pointer",
                                    getPostTypeColor(validPost.postType),
                                    "hover:ring-2 hover:ring-primary/20"
                                  )}
                                  onClick={() => handleEditPost(validPost, postIndex)}
                                >
                                  <div className={cn(
                                    'space-y-2',
                                    (isWeekView(view) && !isVerticalWeek) && 'p-2'
                                  )}>
                                    <div className="font-medium">{validPost.topic}</div>
                                    {(isDayView(view) || (isWeekView(view) && !isVerticalWeek)) && (
                                      <>
                                        <div className="text-xs text-gray-600 flex items-center gap-2">
                                          <CalendarIcon className="h-3 w-3" />
                                          {validPost.bestTimeToPost || validPost.scheduledTime || '12:00'} • {validPost.postType}
                                        </div>
                                        <div className="text-xs space-y-1">
                                          <div className="font-medium">Key Points:</div>
                                          <ul className="list-disc pl-4 space-y-1">
                                            {renderKeyPoints(validPost)}
                                          </ul>
                                        </div>
                                        {validPost.description && (
                                          <div className="text-xs text-gray-600 line-clamp-3">
                                            {validPost.description}
                                          </div>
                                        )}
                                        <div className="flex flex-wrap gap-1">
                                          {renderHashtags(validPost)}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </TooltipTrigger>
                              <TooltipContent 
                                side="top" 
                                className="bg-white shadow-lg border p-4 max-w-[400px] z-50"
                              >
                                <div className="space-y-3">
                                  <div>
                                    <p className="font-medium text-sm">{validPost.topic}</p>
                                    <p className="text-xs text-gray-500 mt-1">
                                      {format(parseISO(validPost.suggestedDate), 'h:mm a')} • {validPost.postType}
                                    </p>
                                  </div>
                                  {validPost.description && (
                                    <div className="text-xs">
                                      <p className="font-medium">Content Preview:</p>
                                      <p className="mt-1 text-gray-600 line-clamp-4">
                                        {validPost.description}
                                      </p>
                                    </div>
                                  )}
                                  <div className="text-xs">
                                    <p className="font-medium">Key Points:</p>
                                    <ul className="list-disc pl-4 mt-1 space-y-1">
                                      {renderKeyPoints(validPost)}
                                    </ul>
                                  </div>
                                  <div className="flex flex-wrap gap-1 mt-2">
                                    {renderHashtags(validPost)}
                                  </div>
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                          
                          {/* Post Actions */}
                          <div className="absolute right-0 top-0 hidden group-hover/post:flex gap-1 bg-white shadow-sm rounded-md p-1 z-10">
                            <Button
                              variant="ghost"
                              size="icon"
                              className="h-6 w-6"
                              onClick={(e) => {
                                e.stopPropagation();
                                const searchParams = new URLSearchParams();
                                searchParams.set('date', validPost.suggestedDate);
                                searchParams.set('title', validPost.topic);
                                searchParams.set('type', validPost.postType);
                                searchParams.set('keyPoints', validPost.keyPoints.join('\n'));
                                if (validPost.hashtags) {
                                  searchParams.set('hashtags', validPost.hashtags.join('\n'));
                                }
                                // Add plan name to URL params
                                if (posts.length > 0 && posts[0].planName) {
                                  searchParams.set('planName', posts[0].planName);
                                }
                                navigate(`/posts/new?${searchParams.toString()}`);
                              }}
                            >
                              <Edit2 className="h-3 w-3" />
                            </Button>
                          </div>
                        </div>
                      );
                    })}

                    {/* Connected Posts */}
                    {connectedPosts.length > 0 && (
                      <div className="mt-2 pt-2 border-t border-dashed border-gray-200">
                        <p className="text-xs text-gray-500 mb-1">Connected Posts:</p>
                        {renderConnectedPosts(connectedPosts)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
} 