import * as z from "zod";

export const TextALignType = z.enum(["Left", "Center", "Right"]);
export const FontSizeType = z.enum(["Small", "Medium", "Large"]);

export const TextStyleSchema = z.object({
  fontSize: FontSizeType,
  align: TextALignType,
});

export const TextSchema = z.object({
  content: z.string(),
  style: TextStyleSchema,
}); 