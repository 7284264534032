import * as z from "zod";
import { ElementType } from "./element-type";
import { TextSchema } from "./text-schema";
import { ImageSchema } from "./image-schema";

export const ElementSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal(ElementType.enum.Title),
    ...TextSchema.shape,
  }),
  z.object({
    type: z.literal(ElementType.enum.Subtitle),
    ...TextSchema.shape,
  }),
  z.object({
    type: z.literal(ElementType.enum.Description),
    ...TextSchema.shape,
  }),
  z.object({
    type: z.literal(ElementType.enum.ContentImage),
    ...ImageSchema.shape,
  }),
]); 