export interface ProfessionalAnalysis {
  description: string;
}

export const analyzeProfilePrompt = `Analyze this LinkedIn Profile and return a valid JSON object with the following structure. Do not include any text outside the JSON object.

{
  "description": "Sarah is a seasoned healthcare technology executive with 15+ years of experience transforming patient care through digital solutions. Her expertise spans EHR implementation, clinical decision support systems, and healthcare data analytics. As former CTO of MedTech Solutions and current Digital Health Advisor, she consistently delivers results improving operational efficiency and patient outcomes.\n\nDriven by modernizing healthcare delivery, she combines technical knowledge with domain expertise to bridge clinical needs and technology solutions. Track record includes AI-powered clinical support tools across 50+ hospitals and interoperability solutions reducing data silos."
}

The description should be:
- 2 short paragraphs
- Paragraphs should be separated by double newlines (\\n\\n)
- Factual and professional, only use information from the profile 
- Engaging and clear
- 200-300 words total
- Focus on professional identity, expertise, motivation, and experience
- Two small and concise paragraphs giving a summary of the profile. Use first person and be conversational.

Ensure the response is valid JSON.`;

export function validateProfessionalAnalysis(json: unknown): json is ProfessionalAnalysis {
  if (!json || typeof json !== 'object') return false;
  const analysis = json as ProfessionalAnalysis;
  
  return typeof analysis.description === 'string' && analysis.description.length > 0;
}

export function formatProfessionalAnalysis(analysis: ProfessionalAnalysis): string {
  return analysis.description;
} 