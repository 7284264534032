import { useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/components/ui/use-toast';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { setAIModel, getCurrentModel, type AIModel } from '@/services/deepseek';
import { AI_MODELS, type AIModelId } from '@/components/ui/model-selector';

interface SettingsDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  isSubscribed: boolean;
}

export function SettingsDialog({ open, onOpenChange, isSubscribed }: SettingsDialogProps) {
  const [language, setLanguage] = useState<'en-GB' | 'en-US'>('en-GB');
  const [currentAIModel, setCurrentAIModel] = useState<AIModel>(getCurrentModel());
  const [enabledModels, setEnabledModels] = useState<Record<string, boolean>>({});
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();
  const [showUnsubscribeDialog, setShowUnsubscribeDialog] = useState(false);

  useEffect(() => {
    if (open) {
      fetchSettings();
      setIsDirty(false);
    }
  }, [open]);

  const fetchSettings = async () => {
    try {
      // Fetch model availability settings
      const { data: modelSettings, error: modelError } = await supabase
        .from('llm_settings')
        .select('id, enabled');

      if (modelError) throw modelError;

      const settingsMap = (modelSettings || []).reduce((acc, setting) => {
        acc[setting.id] = setting.enabled;
        return acc;
      }, {} as Record<string, boolean>);

      // Initialize any missing models as enabled by default
      AI_MODELS.forEach(model => {
        if (settingsMap[model.id] === undefined) {
          settingsMap[model.id] = true;
        }
      });

      setEnabledModels(settingsMap);

      // Fetch user settings
      const { data: { session } } = await supabase.auth.getSession();
      if (session?.user) {
        const { data: profile } = await supabase
          .from('profiles')
          .select('language, default_model')
          .eq('id', session.user.id)
          .single();
        
        if (profile) {
          setLanguage(profile.language || 'en-GB');
          
          // Set appropriate default model based on subscription and availability
          let defaultModel = profile.default_model as AIModel;
          if (!defaultModel || !settingsMap[defaultModel]) {
            defaultModel = getDefaultModel(isSubscribed, settingsMap);
          }
          
          setCurrentAIModel(defaultModel);
          setAIModel(defaultModel);
        }
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
      toast({
        title: "Error",
        description: "Failed to load settings",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const getDefaultModel = (isSubscribed: boolean, enabledModels: Record<string, boolean>): AIModel => {
    if (isSubscribed) {
      // Try Claude-3 first for pro users
      if (enabledModels['claude-3']) {
        return 'claude-3';
      }
    }
    
    // Default to Gemini Flash for free users or if Claude is not available
    if (enabledModels['gemini-flash']) {
      return 'gemini-flash';
    }
    
    // Fallback to first available model if neither preferred option is available
    const firstAvailableModel = AI_MODELS.find(model => enabledModels[model.id]);
    return (firstAvailableModel?.id || 'gemini-flash') as AIModel;
  };

  const handleLanguageChange = (newLanguage: 'en-GB' | 'en-US') => {
    setLanguage(newLanguage);
    setIsDirty(true);
  };

  const handleModelChange = (model: AIModel) => {
    if (!enabledModels[model]) {
      toast({
        title: "Model Unavailable",
        description: "This model is currently disabled by the administrator.",
        variant: "destructive",
      });
      return;
    }

    setCurrentAIModel(model);
    setIsDirty(true);
  };

  const handleSave = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session?.user) {
      const { error } = await supabase
        .from('profiles')
        .update({
          language: language,
          default_model: currentAIModel
        })
        .eq('id', session.user.id);

      if (!error) {
        setAIModel(currentAIModel);
        setIsDirty(false);
        toast({
          title: "Settings Saved",
          description: "Your preferences have been updated successfully.",
        });
        onOpenChange(false);
      } else {
        toast({
          title: "Error",
          description: "Failed to save settings. Please try again.",
          variant: "destructive",
        });
      }
    }
  };

  const handleUnsubscribe = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) return;

      const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/cancel-subscription`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to cancel subscription');
      }

      toast({
        title: "Subscription Cancelled",
        description: "Your subscription has been cancelled successfully. You will have access until the end of your current billing period.",
      });
      
      onOpenChange(false);
      window.location.reload(); // Refresh to update subscription status
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      toast({
        title: "Error",
        description: "Failed to cancel subscription. Please try again or contact support.",
        variant: "destructive",
      });
    }
  };

  if (loading) {
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <div className="flex justify-center items-center p-8">
            <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full"></div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  // Filter out disabled models
  const availableModels = AI_MODELS.filter(model => enabledModels[model.id]);

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Settings</DialogTitle>
            <DialogDescription>
              Manage your account settings and preferences
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid gap-2">
              <Label htmlFor="language">Language</Label>
              <Select
                value={language}
                onValueChange={(value: 'en-GB' | 'en-US') => handleLanguageChange(value)}
              >
                <SelectTrigger id="language" className="bg-white dark:bg-gray-950">
                  <SelectValue placeholder="Select language" />
                </SelectTrigger>
                <SelectContent className="bg-white dark:bg-gray-950">
                  <SelectItem value="en-GB">British English</SelectItem>
                  <SelectItem value="en-US">American English</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="ai-model">Default AI Model</Label>
              <Select
                value={currentAIModel}
                onValueChange={(value: AIModel) => handleModelChange(value)}
              >
                <SelectTrigger id="ai-model" className="bg-white dark:bg-gray-950">
                  <SelectValue placeholder="Select AI model" />
                </SelectTrigger>
                <SelectContent className="bg-white dark:bg-gray-950">
                  {availableModels.map((model) => (
                    <SelectItem key={model.id} value={model.id}>
                      {model.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            {isSubscribed && (
              <div className="grid gap-2">
                <Label>Subscription Status</Label>
                <div className="flex justify-between items-center">
                  <div className="text-sm text-gray-500">
                    You are currently on the Professional plan
                  </div>
                  <Button 
                    variant="destructive" 
                    size="sm"
                    onClick={() => setShowUnsubscribeDialog(true)}
                  >
                    Unsubscribe
                  </Button>
                </div>
              </div>
            )}
          </div>
          <DialogFooter className="flex gap-2">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
            <Button 
              onClick={handleSave} 
              disabled={!isDirty}
              variant="default"
            >
              Save Changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AlertDialog open={showUnsubscribeDialog} onOpenChange={setShowUnsubscribeDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure you want to unsubscribe?</AlertDialogTitle>
            <AlertDialogDescription>
              You will lose access to premium features at the end of your current billing period. 
              Your data will be preserved if you choose to resubscribe later.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleUnsubscribe}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Unsubscribe
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
} 